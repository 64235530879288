
import ICommit from '../contracts/commit'
const REQUIRED_CULTURE = 'occ-required-culture'

const persistAndCommitSession = (commit: any, session: any) => {
  localStorage.setItem(REQUIRED_CULTURE, JSON.stringify(session.required))
  commit('setAttributes', session)
  return session
}

const baseState = {
  required: JSON.parse(localStorage.getItem(REQUIRED_CULTURE) || '{}')
}

const actions = {
  saveAttributes ({ commit }: ICommit, data: any) {
    persistAndCommitSession(commit, data)
  },
  clearAttributes ({ commit }: ICommit) {
    localStorage.removeItem(REQUIRED_CULTURE)
    commit('clearAttributes')
  },
  getRefreshState () {
    return Promise.resolve({
      required: localStorage.getItem(REQUIRED_CULTURE)
    })
  }
}

const mutations = {
  setAttributes (state: any, data: any) {
    state.required = data.required
  },
  clearAttributes (state: any) {
    state.required = {}
  }
}

export default {
  namespaced: true,
  state: baseState,
  actions,
  mutations
}
