import pdf from '../utils/pdf'
export default {
  methods: {
    $generateAtrributesTables () {
      let headersDimensions = [
        'values',
        'sustainability',
        'customers',
        'innovation',
        'achievement',
        'leadership',
        'talent',
        'relationship',
        'communication'
      ]
      let tables = []
      for (let index = 0; index < 9; index++) {
        let color = ''
        if (index < 3) {
          color = '#0889D6'
        } else if (index > 2 && index < 6) {
          color = '#EB493C'
        } else {
          color = '#48BBA1'
        }
        const arrayA = this.questionDataByAttributes.filter(element => element.dimensionsName === headersDimensions[index])
        const arrayByItems = this.questionDataByAttributesByItems.filter(element => element.dimensionsName === headersDimensions[index])
        const dimTrend = this.hasPrevious ? this.round(this.averagePerDimensionsByItems[index].average - this.previousAveragePerDimensions[index].average, 2) : 0
        const dimActualCut = this.round(this.averagePerDimensionsByItems[index].average, 2)
        tables.push(
          {
            text: '',
            pageBreak: 'before',
            pageOrientation: 'landscape'
          },
          {
            columns: [
              {
                table: {
                  color: 'white',
                  widths: [479],
                  body: [
                    [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                  ]
                },
                layout: {
                  fillColor: '#BFBFBF',
                  defaultBorder: ''
                },
                alignment: 'left',
                bold: true,
                margin: [-40, -35, 0, 0]
              },
              {
                table: {
                  color: 'white',
                  widths: [260],
                  body: [
                    [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [30, 8, 0, 5], alignment: 'left' }]
                  ]
                },
                layout: {
                  fillColor: color,
                  defaultBorder: ''
                },
                alignment: 'left',
                bold: true,
                margin: [36, -35, 0, 0]
              }
            ]
          },
          {
            table: {
              color: '#BFBFBF',
              fontSize: 8,
              widths: ['40%', '6%', '6%', '6%', '6%', '18%', '6%', '6%', '6%'],
              body: [
                [{ text: this.$t('cultureReports.positive_attribute').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.act_cut').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.act_org').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.diff2').toUpperCase(), color: '#5c5655', margin: [0, 4, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.disp').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 4, 0, 0], width: 125, height: 30, alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.req').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.diff3').toUpperCase(), color: '#5c5655', margin: [0, 4, 0, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.tend').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 0], alignment: 'center', border: [false, false, false, false] }
                ],
                [{ text: arrayA[0].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[0].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[0].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[0].actScore - arrayA[0].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[0].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][0], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][0].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[0].actScore - this.dataAttributes[index][0].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[0].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[0].tendencies) }
                ],
                [{ text: arrayA[1].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[1].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[1].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[1].actScore - arrayA[1].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[1].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][1], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][1].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[1].actScore - this.dataAttributes[index][1].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[1].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[1].tendencies) }
                ],
                [{ text: arrayA[2].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[2].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[2].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[2].actScore - arrayA[2].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[2].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][2], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][2].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[2].actScore - this.dataAttributes[index][2].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[2].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[2].tendencies) }
                ],
                [{ text: arrayA[3].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[3].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[3].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[3].actScore - arrayA[3].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[3].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][3], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][3].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[3].actScore - this.dataAttributes[index][3].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[3].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[3].tendencies) }
                ],
                [{ text: arrayA[4].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[4].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[4].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[4].actScore - arrayA[4].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[4].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][4], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][4].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[4].actScore - this.dataAttributes[index][4].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[4].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[4].tendencies) }
                ],
                [{ text: arrayA[5].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[5].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[5].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[5].actScore - arrayA[5].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[5].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][5], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][5].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[5].actScore - this.dataAttributes[index][5].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[5].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[5].tendencies) }
                ],
                [{ text: arrayA[6].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[6].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[6].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[6].actScore - arrayA[6].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[6].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][6], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][6].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[6].actScore - this.dataAttributes[index][6].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[6].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[6].tendencies) }
                ],
                [{ text: arrayA[7].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 0, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[7].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayA[7].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[7].actScore - arrayA[7].actScore, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(arrayByItems[7].dispersion, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][7], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.dataAttributes[index][7].score.toFixed(2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasRequired ? this.round(arrayByItems[7].actScore - this.dataAttributes[index][7].score.toFixed(2), 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.round(arrayByItems[7].tendencies, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(arrayByItems[7].tendencies) }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              },
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            fontSize: 10,
            margin: [-40, 3, 11, 3]
          },
          {
            columns: [
              {
                width: '79%',
                table: {
                  color: 'white',
                  widths: ['16%', '10%', '10%', '9%', '23%', '11%', '8%', '10%'],
                  body: [
                    [{ text: this.$t('cultureReports.dimension').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 0], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.actual_cut').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { text: this.$t('cultureReports.actual_org').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 0], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.difference_cut_org').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 0], border: [false, false, false, false] },
                      { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 6], width: 150, height: 25, alignment: 'center', border: [false, false, false, false] },
                      { text: this.$t('cultureReports.required').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 0], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.difference_cut_req').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 0], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.trend').toUpperCase(), color: '#5c5655', margin: [-4, 15, -4, 0], alignment: 'center', border: [false, false, false, false] }
                    ],
                    [{ text: this.$t(`cultureReports.${headersDimensions[index]}`), color: '#5c5655', margin: [10, 6, 0, 6], alignment: 'center', border: [false, false, false, false] },
                      { text: dimActualCut, color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { text: this.round(this.averagePerDimensions[index].average, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { text: this.round(this.averagePerDimensionsByItems[index].average - this.averagePerDimensions[index].average, 2), color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { image: this.horizontalBarChartsDimensions[`${index}-${headersDimensions[index]}`], color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                      { text: this.hasRequired ? this.round(this.dimensionAverage[index].score, 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { text: this.hasRequired ? this.round(this.averagePerDimensionsByItems[index].average - this.dimensionAverage[index].score, 2) : '--', color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false] },
                      { text: `${this.hasPrevious ? dimTrend : ''}`, color: '#5c5655', margin: [0, 6, 0, 0], alignment: 'center', border: [false, false, false, false], ...pdf.styleTendency(dimTrend) }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0) ? '#d1d1d1' : null
                  }
                },
                alignment: 'center',
                bold: true,
                margin: [0, 0, 0, 0]
              },
              [
                {
                  columns: [
                    { width: '4%', text: ' \u0020 ', background: 'black' },
                    { width: '96%', text: this.$t('requiredActualReport.current_culture_cut_abr') }
                  ],
                  margin: [0, 20, 0, 0]
                },
                {
                  columns: [
                    { width: '4%', text: ' \u0020 ', background: color },
                    { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
                  ],
                  margin: [0, 5, 0, 0]
                },
                {
                  columns: [
                    { width: '4%', text: ' \u0020 ', background: '#BFBFBF' },
                    { width: '96%', text: this.$t('requiredActualReport.required_culture') }
                  ],
                  margin: [0, 5, 0, 0]
                }
              ]
            ],
            margin: [-30, 0, 0, 0]
          })
        let array = []
        let column1 = []
        let column2 = []
        let column3 = []
        for (let j = 0; j < this.barChartsAttributes[headersDimensions[index]].length; j++) {
          if (j <= 2) {
            column1.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else if (j > 2 && j <= 5) {
            column2.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else {
            column3.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          }
        }
        array = [
          {
            text: '',
            pageBreak: 'before',
            pageOrientation: 'portrait'
          },
          {
            table: {
              color: 'white',
              widths: [479],
              body: [
                [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: '#BFBFBF',
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [-40, 40, 0, 8]
          },
          {
            table: {
              color: 'white',
              widths: [459],
              body: [
                [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: color,
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [36, 15, 0, 0]
          },
          {
            columns: [
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '-', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_negative_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [36, 15, 0, 10],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '0', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_neutral_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 0, 0],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '+', margin: [-2, 0, -10, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_positive_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 12, 10],
                alignment: 'center'
              }
            ]
          },
          {
            columns: column1
          },
          {
            columns: column2
          },
          {
            columns: column3
          }
        ]
        tables.push(...array)
      }
      return tables
    }
  }
}
