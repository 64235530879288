
<template>
  <v-container fluid>
    <v-row v-if="poll.name">
      <v-col cols="12">
        <h4 class="display-1 left break-name d-inline">{{ poll.name }}</h4>
        <v-chip disabled
          v-if="poll.displayName"
          color="primary"
          class="mt-n3 ml-4 white--text right break-name"
        >
          {{ poll.displayName }}
        </v-chip>
      </v-col>

      <!-- Additional Questions -->
      <v-col cols="12" class="mb-1" v-if="computedShowAdditionalsBtn">
        <x-open-pie-pulse
          :poll-name="poll.displayName || poll.name"
          :questions="poll.additionalQuestions"
        ></x-open-pie-pulse>
      </v-col>

      <v-col cols="12">
        <v-card>
         <v-toolbar flat>
            <v-toolbar-title>{{ $t('culturePulses.pulse_report') }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pa-0">
            <!-- Power Bi Embed -->
            <section
              id="embedreport"
              style="height: 583px"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <x-loading
      :display.sync="loadingPoll"
    ></x-loading>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import * as pbi from 'powerbi-client'
import powerBiService from '../../services/power-bi'

import culturePulseService from '../../services/culture-pulse'

import XOpenPiePulse from '../reports/open-pie-pulse'

export default {
  components: {
    XOpenPiePulse
  },
  data () {
    return {
      poll: {},
      loadingPoll: false,
      report: null,
      token: '',
      embedUrl: ''
    }
  },
  computed: {
    computedShowAdditionalsBtn () {
      return this.poll.additionalQuestions[0].question !== ''
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getPoll()
  },
  methods: {
    getPoll () {
      this.loadingPoll = true
      culturePulseService.getOneById(this.$route.params.id)
        .then((res) => {
          this.poll = res
          if (this.poll.status !== 'completed') {
            const objError = { code: 'culture-pulse/assessment-still-progress' }
            throw (objError)
          } else {
            this.generateEmbed()
          }
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          setTimeout(() => {
            this.$router.back()
          }, 2000)
          setTimeout(() => {
            this.loadingPoll = false
          }, 2400)
        })
    },
    generateEmbed () {
      powerBiService.getEmbedData()
        .then(res => {
          if (res.token && res.embedUrl) {
            this.token = res.token
            this.embedUrl = res.embedUrl
            this.loadEmbed()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    loadEmbed () {
      const permissions = pbi.models.Permissions.All
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.token,
        embedUrl: this.embedUrl,
        pageView: 'fitToWidth',
        permissions: permissions
      }

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      const embedContainer = document.getElementById('embedreport')
      // this.report = powerbi.load(embedContainer, config)
      this.report = powerbi.embed(embedContainer, config)

      const enterpriseFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'enterprises',
          column: 'id'
        },
        operator: 'Equal',
        values: [this.user.enterprise.id || 0]
      }

      const pollTypeFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'uni_polls',
          column: 'Cultura'
        },
        operator: 'Equal',
        values: ['P']
      }

      const pollFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'uni_polls',
          column: 'ID'
        },
        operator: 'Equal',
        values: [`${this.$route.params.id}`]
      }

      this.report.on('loaded', async () => {
        this.report.setFilters([enterpriseFilter])

        const pages = await this.report.getPages()
        const filteredPages = pages.filter(function (page) {
          return !['Filtro Comp', 'Comparativo', 'Comp Atributos'].includes(page.displayName)
        })
        for (const page of filteredPages) {
          await page.updateFilters(pbi.models.FiltersOperations.Add, [pollTypeFilter, pollFilter])
          // To hide pages/tabs
          // if (page.displayName === 'Top/Low') {
          //   page.delete()
          // }
        }

        this.loadingPoll = false
        this.report.off('loaded')
      })

      this.report.on('error', (err) => {
        console.log(err.detail ? err.detail : err)
        this.report.off('error')
      })
    }
  }
}
</script>
