
export default {
  methods: {
    $generateDispersionRanking () {
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.10 ${this.$t('cultureReports.analysis_dispersion_section_cut_req_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attribute_higher_dispersion'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '34%', '34%', '10%', '10%', '10%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dispersion_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dispersion_org'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 1].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 1].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 1].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 1].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 1].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 2].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 2].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 2].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - 2].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 2].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 3].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 3].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 3].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 3].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 3].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 4].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 4].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 4].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 4].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 4].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 5].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 5].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 5].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 5].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 5].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 6].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 6].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 6].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 6].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 6].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 7].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 7].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 7].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 7].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 7].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 8].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 8].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 8].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 8].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.highestDispersionByAttributesByItems[this.highestDispersionByAttributesByItems.length - 8].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.10 ${this.$t('cultureReports.analysis_dispersion_section_cut_req_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas bajo
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attribute_lower_dispersion'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '34%', '34%', '10%', '10%', '10%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dispersion_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dispersion_org'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[0].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[0].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[0].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[0].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[0].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[1].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[1].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[1].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[1].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[1].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[2].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[2].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[2].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[2].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[2].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[3].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[3].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[3].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[3].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[3].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[4].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[4].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[4].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[4].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[4].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[5].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[5].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[5].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[5].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[5].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[6].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[6].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[6].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[6].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[6].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.lowestDispersionByAttributesByItems[7].limiting[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.lowestDispersionByAttributesByItems[7].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributesByItems[7].dimensionsName}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.lowestDispersionByAttributesByItems[7].dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(this.dispersionByAttributesOrg.find(attribute => this.lowestDispersionByAttributesByItems[7].idx === attribute.idx).dispersion, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        }
      ]
    }
  }
}
