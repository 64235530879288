
import dataObj from '../utils/data-obj'

export default {
  methods: {
    $generateTrendRanking () {
      const hasHTBA = this.highestTendenciesByAttributes.length > 0 ? this.highestTendenciesByAttributes : false
      const hasLTBA = this.lowestTendenciesByAttributes.length > 0 ? this.lowestTendenciesByAttributes : false
      const baseTableCell = {
        color: '#6d7a7a',
        borderColor: ['', '', '', '#78909C'],
        border: [false, false, false, true]
      }
      let highestTendenciesByAttributesTable = []
      let lowestTendenciesByAttributesTable = []
      for (let i = 0; i < 6; i++) {
        highestTendenciesByAttributesTable.push([
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? i + 1 : '',
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].limiting[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].positive[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.$t(`requiredReport.initials.${this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].dimensionsName}`) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.round(this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].actScore, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.round(this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].previousAvg, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)] ? this.round(this.highestTendenciesByAttributes[this.highestTendenciesByAttributes.length - (i + 1)].tendencies, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
        lowestTendenciesByAttributesTable.push([
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? i + 1 : '',
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.lowestTendenciesByAttributes[i].limiting[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.lowestTendenciesByAttributes[i].positive[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.$t(`requiredReport.initials.${this.lowestTendenciesByAttributes[i].dimensionsName}`) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.round(this.lowestTendenciesByAttributes[i].actScore, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.round(this.lowestTendenciesByAttributes[i].previousAvg, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributes[i] ? this.round(this.lowestTendenciesByAttributes[i].tendencies, 2) : '--',
            ...baseTableCell,
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.10 ${this.$t('cultureReports.ranking_of_rates')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.highest_positive_rate_attribute'), margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.current_population'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.preview_population'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.change_value'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...highestTendenciesByAttributesTable
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          table: {
            body: [
              [{ text: (hasHTBA ? '' : this.$t('requiredActualReport.no_tend_msg')).toUpperCase(), bold: true, margin: [50, 8, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, -15, 0, 0]
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.10 ${this.$t('cultureReports.ranking_of_rates')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.highest_negative_rate_attribute'), margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.current_population'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.preview_population'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.change_value'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...lowestTendenciesByAttributesTable
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          table: {
            body: [
              [{ text: (hasLTBA ? '' : this.$t('requiredActualReport.no_tend_msg')).toUpperCase(), bold: true, margin: [50, 8, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, -15, 0, 0]
        }
      ]
    }
  }
}
