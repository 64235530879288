
export default {
  notifications: 'Notifications',
  see_more: 'See all notifications.',
  plan_expires_today: 'Your license expires today, go to the update plan module to renew your license.',
  one_month_left: 'You have one month left until your license expires, go to the plan update panel to continue using our services.',
  one_week_left: 'You have one week left until your license expires, go to the plan update panel to continue using our services.',
  one_month_expired: 'Your license expired a month ago, we invite you to update you plan, so that you can use our services again.',
  demo_expired_yesterday: 'Your demo plan expired yesterday, we invite you to update you plan, so that you can use each of our services.',
  pulse_month_ago: 'It\'s been a month since you last did a Pulse. If you do not have the permissions, we invite you to go to the plan extension panel.',
  engagement_closed: 'Your Engagement survey has been closed',
  pulse_closed: 'Your Pulse survey has been closed.',
  admin_plan_expires_today: 'The license for the company {enterprise} expires today.',
  admin_one_month_left: 'The company {enterprise} has one month left until its license expires.',
  admin_one_week_left: 'The company {enterprise} has one week left until its license expires.',
  admin_one_month_expired: 'The license for the company {enterprise} expired a month ago.',
  admin_demo_expired_yesterday: 'The company {enterprise} demo plan expired yesterday.',
  admin_enterprise_enabled: 'The company {enterprise} has been activated',
  admin_enterprise_disabled: 'The company {enterprise} has been deactivated',
  admin_engagement_closed: 'The Engagement  survey {poll} of the company {enterprise} has been closed.',
  admin_pulse_closed: 'The pulse survey{poll} for company {enterprise} has been closed.'
}
