
export default {
  title: 'Instructivo OCC Solutions - Pulse: Módulo de carga masiva.',
  title_body: 'Este instructivo tiene como finalidad orientar al usuario final, en la forma correcta de ' +
    'ingresar el formato de carga masiva, para prevenir errores en la carga de datos de usuarios.',
  demographic_items: 'Cortes demográficos: ',
  identifyType: 'Tipo de documento de identidad.',
  matchCaseValue: 'Se debe especificar uno de los valores almacenados en la aplicación para este corte ' +
    'demográfico, respetando mayúsculas y minúsculas.',
  example: 'Ejemplo:',
  identifyDocument: 'Documento de identidad',
  matchCaseNumber: 'Se debe ingresar únicamente números, sin letras, ni espacios ni caracteres especiales.',
  firstName: 'Nombre',
  matchCaseText: 'Se debe ingresar únicamente letras o espacios, sin números ni caracteres especiales.',
  lastName: 'Apellido',
  email: 'Correo electonico',
  matchCaseEmail: 'Se debe especificar correo electrónico válido, no puede repetirse. ' +
    'O bien se puede dejar este campo vacío.',
  gender: 'Genero',
  age: 'Rango de edad (Fecha de nacimiento)',
  antiquity: 'Rango de Antigüedad (Fecha de Ingreso)',
  matchCaseDate: 'Se debe ingresar una fecha válida en formato aaaa/mm/dd o aaaa-mm-dd, sin espacios ni letras',
  departments: 'Departamento/Área',
  charge: 'Cargo',
  charge_dependent_info: 'Los cargos con departamentos identificados entre paréntesis "()", solo pueden ser\n asígnados a colaboradores que pertenezcan a dicho departamento.',
  jobTypes: 'Tipo de Contratación',
  academicDegree: 'Nivel Académico',
  country: 'País',
  headquarter: 'Sede',
  headquarter_dependent_info: 'Las sedes con países identificados entre paréntesis "()", solo pueden ser\n asígnados a colaboradores que pertenezcan a dichos país.',
  importDataToExcelTitle: 'Importar datos de archivo CSV a Documento Excel.',
  step1: 'Paso 1',
  step1_desc: 'Abrir Excel.',
  step2: 'Paso 2',
  step2_desc: 'Seleccionar la opción Libro en blanco',
  step3: 'Paso 3',
  step3_desc: 'Ir a la pestaña de Datos y en el apartado de Obtener datos externos, seleccionar la opción\n' +
    'Desde un archivo de texto.',
  step4: 'Paso 4',
  step4_desc: 'Seleccionar el archivo de datos CSV que desea importar. y seguidamente presione el botón\nImportar.',
  step5: 'Paso 5',
  step5_desc: 'En este punto se especifica la configuración que se usara para importar el archivo de datos.\n' +
    'Se debe indicar que los valores están Delimitados, y que se usará la codificación Unicode uft- 8,\n' +
    'como se muestra en la imagen siguiente. Esto para la correcta conversión de las palabras\nacentuadas. ' +
    'Luego se debe presionar el botón Siguiente.',
  step6: 'Paso 6',
  step6_desc: 'En este punto se debe indicar qué carácter se usara para la separación de valores.\n' +
    'Se debe indicar la coma como se muestra en la imagen siguiente.Luego se debe presionar\nel botón Finalizar.',
  step7: 'Paso 7',
  step7_desc: 'Seguidamente se le preguntará si estos datos se importarán en la hoja existente\n' +
    'o en una hoja nueva.Por defecto aparecerá seleccionada importar en la hoja existente,\n' +
    'si no es así, seleccione esta opción y presione Aceptar.',
  step8_desc: 'Finalmente los datos aparecerán separados correctamente y las palabras acentuadas se\n' +
    'importarán correctamente.',
  availableItems: 'Valores disponibles:'
}
