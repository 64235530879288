
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1 left">{{ currentCulture.name }}</h4>
        <v-chip disabled
          v-if="currentCulture.displayName !== ''"
          color="primary"
          class="mb-3 white--text right"
        >
          {{ currentCulture.displayName }}
        </v-chip>
      </v-col>

      <v-col cols="12">
        <v-card>
          <!-- General -->
          <template v-if="!typeReport">
            <template v-if="!currentCulture.requiredCultureId">
              <v-card-title class="py-0">
                <v-row>
                  <v-col cols="12">
                    <h5 class="headline">{{ $t('reports.current_culture_title') }}</h5>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <p>{{ $t('reports.current_culture_description') }}</p>
              </v-card-text>
            </template>
            <!-- Req + Act -->
            <template v-else>
              <v-card-title class="py-0">
                <v-row>
                  <v-col cols="12">
                    <h5 class="headline">{{ $t('reports.required_current_culture_title') }}</h5>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <p>{{ $t('reports.required_current_culture_description') }}</p>
              </v-card-text>
            </template>
            <v-card-text class="pt-0">
              <v-row justify="center">
                <v-col cols="12">
                  <v-select chips multiple persistent-hint
                    v-model="demographicItemsKeys"
                    :items="demographicsItems"
                    :label="$t('reports.select_general_demographics_items')"
                    :hint="$t('reports.select_general_demographics_items_hint')"
                    item-value='code'
                    item-text='translate.label'
                    class="demographic-cuts-selector"
                  >
                    <template v-slot:message="{ message, key }">
                      <span class="caption primary--text text--lighten-1" v-html="message"></span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <v-row v-if="displayReport && !typeReport">
            <v-col cols="12" justify="center" align="center">
              <x-thread-report
                :demographics-selected="demographicItemsKeys"
                :items-report="typeReport"
                :list-of-demographics="demographicCuts"
                :poll-id="$route.params.id"
                :check-generated="checkIfAlreadyGenerated"
                :disable-button="((!typeReport && demographicItemsKeys.length !== 0)) && disableButton"
                :button-text="$t('reports.general_report')"
              ></x-thread-report>
            </v-col>
          </v-row>

          <!--
          <v-divider class="mt-6" v-if="!typeReport"></v-divider>

          <!- Summary ->
          <v-row no-gutters v-if="!typeReport">
            <v-col cols="12" class="pt-3 pl-4 pb-0">
              <h5 class="headline">
                {{ $t('reports.demographic_summary') }}
              </h5>
            </v-col>
            <v-col cols="12" class="pt-2 pb-6 px-5 text-center">
              <p class="mb-5 text-justify grey--text text--darken-2">
                {{ $t('reports.demographic_summary_desc') }}
              </p>
              <x-generate-summary-report
                :poll-id="$route.params.id"
                :already-generated="summaryAlreadyGenerated"
              ></x-generate-summary-report>
            </v-col>
          </v-row>
          -->

          <v-divider class="mt-6" v-if="!typeReport"></v-divider>

          <!-- Population Report Switch -->
          <v-row no-gutters>
            <v-col cols="12" class="mt-3 pl-4">
              <h5 class="headline">
                {{
                  !typeReport ?
                    `${$t('cultureReports.reports')} ${$t('cultureReports.subculture')}` :
                    `${$t('reports.subculture_title')}`
                }}
              </h5>
            </v-col>
            <v-col cols="12" class="mt-5 px-5">
              <v-switch hide-details
                v-model="typeReport"
                color="primary"
                class="mt-0 pt-0"
              >
                <template v-slot:label>
                  <span class="mr-2">
                    {{ $t('reports.change_report') }}
                  </span>
                </template>
              </v-switch>
              <p v-if="!typeReport" class="mt-6 mb-0 text-justify grey--text text--darken-2">
                {{ $t('reports.subculture_description') }}
              </p>
            </v-col>
          </v-row>

          <template v-if="typeReport">
            <v-card-text>
              <p class="mt-2 pl-2">{{ $t('reports.subculture_description2') }}</p>
              <v-row justify="end">
                <v-col cols="6" md="4" class="text-right pr-11">
                  <h3 class="display-1">
                    {{ $t('cultureReports.population') }}: {{this.poblationValue}}
                  </h3>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="11" class="px-1">
                  <x-filters-items
                    :lang="this.user.lang"
                    :current-id="$route.params.id"
                    :demographic-cuts="demographicCuts"
                    @updatePopulation="($event) => poblationValue = $event"
                    @updateCutsSelected="($event) => cutsSelected = $event"
                  ></x-filters-items>
                </v-col>
              </v-row>
              <v-row v-if="displayReport">
                <v-col cols="12" justify="center" align="center">
                  <x-no-population-modal
                    v-show="poblationValue < min"
                    :button-text="$t('reports.subculture_report_btn')"
                  />
                  <x-thread-report
                    v-if="poblationValue >= min"
                    :demographics-selected="cutsSelected"
                    :items-report="typeReport"
                    :list-of-demographics="demographicCuts"
                    :poll-id="$route.params.id"
                    :check-generated="checkIfAlreadyGenerated"
                    :disable-button="disableButton"
                    :button-text="$t('reports.subculture_report_btn')"
                  ></x-thread-report>
                </v-col>
              </v-row>
            </v-card-text>
          </template>

          <v-divider class="mt-6 mb-n1"></v-divider>

          <v-row class="mt-1 pr-8" v-if="currentTheads.length">
            <v-col cols="12" sm="8" class="pl-7">
              <h5 class="headline">{{ $t('reports.generated_reports') }}</h5>
            </v-col>
            <!-- Refresh Page Button -->
            <v-col cols="12" sm="4" class="pr-8 text-right">
              <template v-if="showUpdateProgressBtn">
                <span
                  class="caption d-inline-block mt-2 grey--text text--darken-1 cursor-pointer"
                  @click="refreshProgress"
                >
                  {{ $t('input.update_progress') }}
                </span>
                <v-btn fab dark x-small
                  absolute
                  right
                  color="primary"
                  class="refresh-fab"
                  :loading="loadingRefresh"
                  @click="refreshProgress"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
            </v-col>
            <v-col cols="12" class="pt-0 pl-8">
              <p class="mb-0 text-justify grey--text text--darken-2"
                v-html="$t('reports.generated_reports_desc')"
              ></p>
            </v-col>
          </v-row>

          <!-- Report Requests -->
          <v-row v-if="currentTheads.length">
            <v-col cols="12" class="mt-4 py-0">
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(currentThead, $index) in currentTheads"
                  :key="$index"
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    <span v-if="currentThead.data.itemsReport">
                      {{ $t('reports.sub_culture_title') }}
                    </span>
                    <span v-else-if="currentThead.data.current && currentThead.data.current.requiredCultureId">
                      {{
                        currentThead.data.type === 'summary'
                          ? $t('reports.demographic_summary')
                          : $t('reports.required_current_culture_title')
                      }}
                    </span>
                    <span v-else-if="currentThead.data.type === 'summary'">
                      {{ $t('reports.demographic_summary') }}
                    </span>
                    <span v-else>
                      {{ $t('reports.current_culture_title') }}
                    </span>
                    <template v-slot:actions>
                      <span
                        v-if="currentThead.data.progress !== 100"
                        class="caption d-inline-block mt-1 mr-2 grey--text text--darken-1 font-weight-bold"
                      >
                        {{ currentThead.data.progress }}%
                      </span>
                      <v-icon color="primary" v-if="currentThead.status === 'pending'">mdi-progress-clock</v-icon>
                      <v-icon color="primary" v-else-if="currentThead.status === 'in_progress' || currentThead.status === 'in_action'">mdi-progress-alert</v-icon>
                      <v-icon color="red" v-else-if="currentThead.status === 'failed'">mdi-ladybug</v-icon>
                      <v-icon color="primary" v-else>mdi-check-circle</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row v-if="currentThead.data.itemsReport === true && currentThead.status !== 'completed'">
                      <v-col class="text-center">
                        <div class="headline">{{ $t('reports.demographics_response_rate') }}</div>
                        <v-chip v-for="(demographic, i) in getDemographicChip2(currentThead)"
                          :key="`2-${i}`"
                          class="ma-2"
                          @click="openModalDemographic(demographic.text, demographic.key, demographic.answerRates)"
                        >
                          {{ demographic.text }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-if="currentThead.data.demographicsSelected && currentThead.data.demographicsSelected.length">
                      <v-col class="text-center">
                        <div class="headline">{{ $t('reports.demographics_response_rate') }}</div>
                        <v-chip v-for="(demographic, $index) in currentThead.data.demographicsSelected"
                          :key="$index"
                          class="ma-2"
                        >
                          {{
                            demographicsItems.find(d => d.code === demographic)
                              ? demographicsItems.find(d => d.code === demographic).translate.label
                              : demographic
                          }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="currentThead.status === 'completed'">
                      <v-col v-if="currentThead.data.type !== 'summary'" class="text-center">
                        <div class="headline">{{ $t('reports.demographics_response_rate') }}</div>
                        <v-chip v-for="(demographic, $index) in getDemographicChip(currentThead)"
                          :key="$index"
                          class="ma-2"
                          @click="openModalDemographic(demographic.text, demographic.key, demographic.answerRates)"
                        >
                          {{ demographic.text }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-if="currentThead.status !== 'completed'">
                      <v-col cols="12">
                        <v-progress-linear
                          color="light-blue"
                          height="10"
                          :value="currentThead.data.progress"
                          striped
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row v-if="currentThead.data.itemsReport">
                      <v-col cols="12" class="text-center">
                        <x-thread-report-by-items-req-exec
                          @render-pdf="reloadReport"
                          :poll-id="$route.params.id"
                          :thread-id="currentThead._id"
                          :disable-button="currentThead.status === 'completed'"
                          :button-text="$t('reports.download_report')"
                        ></x-thread-report-by-items-req-exec>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="currentThead.data.current && currentThead.data.current.requiredCultureId">
                      <v-col cols="12" class="text-center">
                        <x-download-summary-report
                          v-if="currentThead.data.type === 'summary'"
                          :poll-id="$route.params.id"
                          :thread-id="currentThead._id"
                          @render-pdf="reloadReport"
                        />
                        <x-thread-required-actual-report-exec
                          v-else
                          :poll-id="$route.params.id"
                          :thread-id="currentThead._id"
                          :disable-button="currentThead.status === 'completed'"
                          :button-text="$t('reports.download_report')"
                          @renderPdf="reloadReport"
                        ></x-thread-required-actual-report-exec>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="currentThead.data.type === 'summary'">
                      <v-col cols="12" class="text-center">
                        <x-download-summary-report
                          :disable-btn="currentThead.status !== 'completed'"
                          :poll-id="$route.params.id"
                          :thread-id="currentThead._id"
                          @render-pdf="reloadReport"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12" class="text-center">
                        <x-thread-report-exec
                          @render-pdf="reloadReport"
                          :poll-id="$route.params.id"
                          :thread-id="currentThead._id"
                          :disable-button="currentThead.status === 'completed'"
                          :button-text="$t('reports.download_report')"
                        ></x-thread-report-exec>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <v-btn
                class="ml-6 my-3"
                @click="goBack()"
                block
                large
              >{{ $t('input.back') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-text style="padding: 24px 20px;">
          {{ $t('reports.no_answers_modal_msg') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showModal = !showModal"
          >
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDemographic.show" width="600px">
      <v-card>
        <v-card-text class="text-center">
          <div class="headline">{{ modalDemographic.name }}</div>
          <v-chip
            class="ma-2"
            v-for="(demographic, $index) in modalDemographic.demographics"
            :key="$index"
          >{{ demographic }}</v-chip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="modalDemographic.show = false"
          >
            {{ $t('input.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss">
  .refresh-fab {
    &.v-btn--fab.v-btn--absolute {
      z-index: 3;
    }
    &.v-btn--absolute.v-btn--right {
      right: 12px;
    }
  }
  .v-input--reverse .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .v-application--is-ltr & {
      .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    .v-application--is-rtl & {
      .v-input--selection-controls__input {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>

<script>
import { mapState } from 'vuex'

import XThreadReport from '../reports/thread_current_culture_report.vue'
import XThreadReportExec from '../reports/thread_current_culture_report_exec.vue'
import XThreadReportByItemsReqExec from '../reports/thread_current_culture_report_by_items_req_exec.vue'
import XThreadRequiredActualReportExec from '../reports/thread_required_actual_report_ecex.vue'

// import XGenerateSummaryReport from '../reports/summary_report.vue'
import XDownloadSummaryReport from '../reports/summary_report_download.vue'

import XNoPopulationModal from '../reports/no-population-modal.vue'
import XFiltersItems from '../reports/filters-items.vue'

import currentCultureAssessment from '../../services/current-culture-assessment'

import resolver from '../../utils/resolver'

export default {
  name: 'CurrentCultureReport',
  components: {
    XThreadReport,
    XThreadReportExec,
    XThreadReportByItemsReqExec,
    XThreadRequiredActualReportExec,
    // XGenerateSummaryReport,
    XDownloadSummaryReport,
    XNoPopulationModal,
    XFiltersItems
  },
  data () {
    return {
      min: 2,
      poblationValue: 0,
      demographicsItems: [],
      demographicItemsKeys: [],
      demographicCuts: {},
      cutsSelected: {},
      typeReport: false,
      displayCuts: false,
      disableButton: false,
      disableReq: false,
      displayReport: true,
      demographicsFiltered: [],
      demographicsSelects: {},
      showModal: false,
      currentCulture: {
        name: '',
        displayName: '',
        status: '',
        requiredCultureId: undefined
      },
      pollCurrent: null,
      currentTheads: [],
      modalDemographic: {
        show: false,
        name: '',
        demographics: {}
      },
      dictionary: {
        academicDegree: 'academicDegreeId',
        age: 'birthdate',
        antiquity: 'admission',
        charge: 'chargeId',
        country: 'countryId',
        departments: 'departmentId',
        gender: 'genderId',
        headquarter: 'headquarterId',
        jobTypes: 'jobTypeId',
        optionalDemo1: 'additionalDemographic1Id',
        optionalDemo2: 'additionalDemographic2Id'
      },
      antiquity: {
        es: [
          'Menos de 6 meses',
          'De 6 meses a 1 año',
          'De 1 año a 3 años',
          'De 3 años a 5 años',
          'De 5 años a 10 años',
          'De 10 años a 20 años',
          'Más de 20 años'
        ],
        en: [
          'Less than 6 months',
          'From 6 months to 1 year',
          'From 1 year to 3 years',
          'From 3 years to 5 years',
          'From 5 years to 10 years',
          'From 10 years to 20 years',
          'More than 20 years'
        ]
      },
      age: {
        es: [
          'Menos de 25 años',
          'De 25 a 35 años',
          'De 35 a 45 años',
          'De 45 a 55 años',
          'Más de 55 años'
        ],
        en: [
          'Less than 25 years',
          'From 25 to 35 years',
          'From 35 to 45 years',
          'From 45 to 55 years',
          'More than 55 years'
        ]
      },
      loadingRefresh: false
    }
  },
  computed: {
    summaryAlreadyGenerated () {
      const found = this.currentTheads.find(x => x.data.type === 'summary')
      return found !== undefined
    },
    showUpdateProgressBtn () {
      let shouldShow = false
      if (this.currentTheads.length) {
        const inProgress = this.currentTheads.find((t) => {
          return t.data.progress !== 100
        })
        if (inProgress) {
          shouldShow = true
        }
      }
      return shouldShow
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    resolver.all({
      currentCulture: currentCultureAssessment.getOneLightById(this.$route.params.id),
      currentTheads: currentCultureAssessment.currentTheads(this.$route.params.id)
    })
      .then(res => {
        if (res.currentCulture.status !== 'completed') {
          this.$router.go(-1)
        } else if (!res.currentCulture.answers) {
          this.showModal = true
        } else {
          this.getDemographicItems()
            .then(() => {
              this.currentCulture.name = res.currentCulture.name
              this.currentCulture.displayName = res.currentCulture.displayName
              this.currentCulture.status = res.currentCulture.status
              this.currentCulture.requiredCultureId = res.currentCulture.requiredCultureId
              this.disableButton = this.currentCulture.status !== 'in_progress'
              this.pollCurrent = res.currentCulture
              this.currentTheads = res.currentTheads
            })
        }
      })
  },
  watch: {
    typeReport (val) {
      if (!val && this.poblationValue) {
        this.poblationValue = 0
      }
    },
    '$i18n.locale': {
      handler () {
        this.getDemographicItems()
      }
    }
  },
  methods: {
    refreshProgress () {
      this.loadingRefresh = true
      currentCultureAssessment.currentTheads(this.$route.params.id)
        .then((res) => {
          this.currentTheads = res
        })
        .finally(() => {
          this.loadingRefresh = false
        })
    },
    openModalDemographic (name, keyDemographicCuts, answerRates) {
      this.modalDemographic.name = name
      const demographics = []
      if (this.demographicCuts[keyDemographicCuts]) {
        for (const demographicKeys of answerRates) {
          if (['antiquity', 'age'].includes(keyDemographicCuts)) {
            if (demographicKeys.startsWith('rank_')) {
              const nmbr = demographicKeys.replace(/[^0-9]/g, '')
              demographics.push(this[keyDemographicCuts][this.user.lang][nmbr - 1])
            } else {
              demographics.push(demographicKeys)
            }
          } else {
            const idDemographic = Number.isInteger(demographicKeys)
              ? demographicKeys
              : demographicKeys.replace('id', '')
            const demographic = this.demographicCuts[keyDemographicCuts].find(dc => Number(dc.id) === Number(idDemographic))
            if (demographic) {
              if (keyDemographicCuts === 'headquarter') {
                demographics.push(demographic.name)
              } else {
                demographics.push(demographic.translate.label)
              }
            }
          }
        }
      }

      this.modalDemographic.demographics = demographics
      this.modalDemographic.show = true
    },
    getDemographicChip (currentThead) {
      if (!currentThead || currentThead.status !== 'completed' || !currentThead.data || !currentThead.data.structures) {
        return []
      }
      const response = []
      for (const demographic of Object.values(currentThead.data.structures.answerRates)) {
        const name = (this.demographicsItems.find(item => item.code === demographic.key) || {
          translate: { label: '' }
        }).translate.label
        const temp = {
          text: name,
          answerRates: demographic.counts.length ? demographic.counts.map(i => i.text) : Object.keys(demographic.counts),
          key: demographic.key
        }

        if (currentThead.data.itemsReport) {
          temp.text = `${temp.text} - ${temp.answerRates.length} ${this.$t('cultureReports.selected')}`
        }
        response.push(temp)
      }
      return response
    },
    getDemographicChip2 (currentThead) {
      const response = []
      const selection = currentThead.data.demographicsSelected
      for (const key of Object.keys(selection)) {
        const name = (this.demographicsItems.find(item => item.code === key) || {
          translate: { label: '' }
        }).translate.label
        const temp = {
          text: `${name} - ${selection[key].length} ${this.$t('cultureReports.selected')}`,
          answerRates: selection[key],
          key: key
        }
        response.push(temp)
      }
      return response
    },
    getDemographicItems () {
      return currentCultureAssessment.getDemographicsItemsByEntreprise(this.user.lang)
        .then((res) => {
          this.demographicsItems = res.demographicItem
          this.demographicCuts = {
            'departments': res.data.departments,
            'charge': res.data.charge,
            'academicDegree': res.data.academicDegree,
            'jobTypes': res.data.jobTypes,
            'age': res.data.age,
            'antiquity': res.data.antiquity,
            'gender': res.data.gender,
            'spacer': [],
            'country': res.data.country,
            'headquarter': res.data.headquarter,
            'optionalDemo1': res.data.optionalDemo1,
            'optionalDemo2': res.data.optionalDemo2
          }
          const mapRank = (rank, $idx) => ({ id: `rank_${$idx + 1}`, translate: { label: rank } })
          this.demographicCuts.age = { es: this.age.es.map(mapRank), en: this.age.en.map(mapRank) }
          this.demographicCuts.antiquity = { es: this.antiquity.es.map(mapRank), en: this.antiquity.en.map(mapRank) }
        })
    },
    checkIfAlreadyGenerated (isByItems, filters) {
      const found = this.currentTheads.find(t => {
        if (isByItems) {
          if (t.data.itemsReport === true) {
            const keys1 = Object.keys(filters)
            if (t.status === 'completed') {
              const obj = t.data.structures.answerRates
              const keys2 = []
              for (const key in obj) {
                keys2[obj[key].key] = obj[key].counts
              }
              if (keys1.length === Object.keys(keys2).length) {
                let cnt = 0
                keys1.forEach(key => {
                  const arr = keys2[key] ? Object.keys(keys2[key]).map(x => Number(x.replace('id', ''))) : []
                  if (arr && arr.length === filters[key].length && arr.every(val => filters[key].includes(val))) {
                    cnt++
                  }
                })
                return cnt === keys1.length
              }
            } else {
              const keys2 = Object.keys(t.data.demographicsSelected)
              if (keys1.length === keys2.length) {
                let cnt = 0
                keys1.forEach(key => {
                  const arr = t.data.demographicsSelected[key]
                  if (arr && arr.length === filters[key].length && arr.every(val => filters[key].includes(val))) {
                    cnt++
                  }
                })
                return cnt === keys1.length
              }
            }
          }
        } else {
          if (t.data.itemsReport === false) {
            if (t.status === 'completed') {
              const formattedFilters = filters.map(f => this.dictionary[f]).sort()
              const arr = Object.keys(t.data.structures.answerRates).sort()
              if (arr.length === formattedFilters.length && arr.every(val => formattedFilters.includes(val))) {
                return true
              }
            } else {
              const arr1 = t.data.demographicsSelected.sort()
              const arr2 = filters.sort()
              if (arr1.length === arr2.length && arr1.every(val => arr2.includes(val))) {
                return true
              }
            }
          }
          return false
        }
      })

      return found !== undefined
    },
    goBack () {
      this.$router.go(-1)
    },
    reloadReport () {
      this.disableButton = false
      this.$nextTick().then(() => { this.disableButton = true })
    }
  }
}
</script>
