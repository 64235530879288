
import auth from './auth'
import customers from './customers'
import culturePulses from './culture-pulses'
import currentCulture from './current_culture'
import cultureReports from './culture-reports'
import dashboard from './dashboard'
import employees from './employees'
import enterprises from './enterprises'
import errors from './errors'
import followupReport from './followup-report'
import groups from './groups'
import help from './help'
import input from './input'
import instructive from './instructive'
import languages from './languages'
import language from './language'
import navigation from './navigation'
import notifications from './notifications'
import operationSummary from './operation-summary'
import outside from './outside'
import pluralization from './pluralization'
import settings from './settings'
import tokens from './tokens'
import services from './services'
import pricing from './pricing'
import calculatorView from './calculator_view'
import questionnaires from './questionnaires'
import evaluations from './evaluations'
import actualEvaluation from './actual_evaluation'
import requiredCulture from './required-culture'
import questions from './questions'
import reports from './reports'
import requiredReport from './required_report'
import requiredActualReport from './required_actual_report'
import summaryReport from './summary_report'

export default {
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Active',
  inactive: 'Inactive',
  no_data: 'There are no records',
  registration_updated_successfully: 'Registration updated successfully',
  record_saved_successfully: 'Registration successfully saved',
  error_trying_update: 'An error occurred when trying to update',
  error_trying_save: 'An error occurred while trying to save',
  copyright: 'All rights reserved 2019',
  go_back: 'Return',
  results_per_pages: 'Results per page',
  manual: 'User\'s guide',
  go_to_manual: 'Click here to go to the manuals section',
  page_all: 'Everyone',
  pending: 'Pending',
  in_progress: 'In Progress',
  completed: 'Completed',
  /* Models */
  models: {
    enterprises: 'Companies',
    groups: 'Business Groups',
    pulse: 'Pulse'
  },
  waiting: 'Please wait',
  maintenance: 'This page is under construction.',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'We work every day to achieve the objectives and give you better results and experiences.',
  /* Labels grouped by modules or type */
  auth,
  customers,
  culturePulses,
  currentCulture,
  cultureReports,
  dashboard,
  employees,
  enterprises,
  errors,
  followupReport,
  help,
  input,
  instructive,
  navigation,
  notifications,
  outside,
  pluralization,
  languages,
  language,
  questionnaires,
  questions,
  requiredCulture,
  settings,
  calculatorView,
  tokens,
  services,
  pricing,
  groups,
  page_text: 'of',
  evaluations,
  actualEvaluation,
  reports,
  requiredReport,
  requiredActualReport,
  operationSummary,
  summaryReport
}
