
export default {
  add_group: 'Create Group',
  groups_list: 'List of Groups',
  watch_requests: 'See Requests',
  no_data: 'There are no groups to display',
  // Headers
  id: 'ID',
  group_name: 'Group name',
  enterprises_no: 'Number of companies',
  main_customer: 'Leader',
  main_customer_tokens: 'Leader Token Balance',
  group_tokens: 'Group Token Balance',
  change_leader: 'Change Leader',
  delete_from_group: 'Remove customer from Group ',
  customer_no_group: 'This Customer has not been assigned to any Group',
  login_as_customer: 'Access as this Customer',
  add_member: 'Add Member',
  actual_tokens: 'Current Token Balance',
  delete_member: 'Remove Member',
  add_member_success: 'Member successfully added',
  delete_member_message: 'Member successfully deleted',
  leader_changed_success: 'Leader successfully changed',
  delete_member_question: 'Do you want to remove the member from the group?',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the group?',
  modal_disable: 'Do you want to disable the group?',
  // tslint:disable-next-line:max-line-length
  modal_enable_des: 'Once the group is enabled, the group administrator will be able to manage the licensing of companies in the group.',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Once the group is disabled, the group administrator will not be able to manage the licensing of companies in the group.',
  modal_save_btn: 'Accept',
  created_group: 'Group created',
  updated_group: 'Group successfully modified!',
  edit_group: 'Edit Group',
  main_enterprise: 'Lead customer',
  current_plan: 'Balance',
  associated_enterprises: 'Company',
  want_to_select_enterprises: 'Do you want to select the companies in the group that this change will apply to?',
  want_other_start: 'Do you want to specify a start date?',
  must_select_enterprises: 'You must select the companies to update from the list.',
  must_select_plan: 'You must select the new plan',
  no_update_requests: 'This group has no pending update requests.',
  plan_updated: 'This request has been successfully applied.',
  plan_rejected: 'This request has been successfully rejected.'
}
