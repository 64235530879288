
export default {
  poll_title: 'Required Culture',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Read each of the statements below and select the option that best represents your opinion according to the following scale: Strongly disagree, Partially disagree, Neither disagree nor agree, Partially agree, Strongly agree.',
  totally_disagree: 'Strongly disagree',
  parcially_disagree: 'Partially Disagree',
  nor_agree_disagree: 'Neither disagree nor agree',
  parcially_agree: 'Partially Agree',
  totally_agree: 'Totally agree',
  entry_title: 'Welcome to your Engagement survey',
  // tslint:disable-next-line:max-line-length
  entry_text: 'We invite you to take the Engagement survey. We are sure that with your answers we will build a better work environment. You have your own link for your survey, so you can answer without any worries, since your answers are confidential.',
  questions_65: '65 Questions',
  minutes_n: '{n} Minutes',
  complete_anonymity: 'Total anonymity',
  finish_title: 'Thank you very much for completing the survey.',
  finish_text: 'Your perception is very important to us.',
  new: 'New Required Culture Measurement',
  overview: 'Workshop name',
  receptors: 'Respondents',
  date: 'Date',
  questions: 'Questions',
  questionnaire: 'Questionnaire',
  leaders_selection: 'Selection of leaders',
  revition: 'Review',
  workshop_name: 'Measurement name',
  want_external_name: 'Do you want to use an external name for the respondents?',
  workshop_cost: 'Workshop cost',
  report_cost: 'Report Cost',
  balance_after: 'Balance after creating the workshop:',
  balance_after_generate_report: 'Balance after generating the report',
  token_unit: 'OCC Tokens',
  non_active_employees: 'You must have at least one active employee for this survey.',
  operation_failed: 'Unsuccessful operation, you should:',
  acquire_tokens: 'Acquire more OCC Tokens',
  no_balance: 'Insufficient balance',
  no_balance_msg: 'Company with an insufficient balance, if the process continues, the system will try to consume authorized balances.',
  want_to_send: 'Who do you want to send this survey to?',
  hours: 'Time sent',
  hours_valid_until: 'Valid until',
  minutes: 'Minute',
  period_validity: 'Validity period',
  start_date: 'Start date',
  workshop_valid_until: 'Measurement valid until',
  time_zone: 'Time zone',
  want_send_reminders: 'Do you want to send reminders?',
  reminders: 'Send reminder',
  want_open_question: 'Do you want to add additional open questions?',
  open_question: 'Open questions',
  add_open_question: 'Add another open question',
  remove_open_question: 'Delete last open question',
  min_open_question: 'You must have at least one open question available',
  max_open_question: 'You can only have {n} open questions per survey',
  insert_question: 'Insert content of question {n}',
  insert_question_option: 'Insert option {o} for question {n}',
  add_question_option: 'Add another option',
  remove_question_option: 'Delete option',
  min_question_option: 'You must have at least {n} options available',
  max_question_option: 'You can only select {n} options for an open question',
  total_receptors: 'Total respondents: {n}',
  porcent_total: 'Percentage of the total to be taken',
  general_revition: 'General review',
  settings: 'Settings',
  status: 'Status',
  inter_name: 'Internal name',
  send_reminders: 'Sending reminders',
  personalization: 'Email personalization',
  poll_invitation: 'survey invitation',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message Subject',
  body_message: 'Body of message',
  message_from_the_company: 'Message from the company',
  created_workshop: 'Measurement created successfully! You will be redirected to the summary of the operation shortly',
  id: 'ID',
  name: 'Name',
  participants: 'Participants',
  responses: 'Responses',
  leaders: 'Leaders',
  period: 'Period',
  action: 'Actions',
  filter_by: 'Filter by...',
  look_for: 'Find a survey',
  no_data: 'There are no measurements to display',
  engagement_realized: 'Engagements performed',
  create_required_culture: 'Create',
  pending: 'Pending',
  completed: 'Completed',
  in_progress: 'In progress',
  cancelled: 'Cancelled',
  progress: 'In progress',
  building: 'Under construction',
  at: 'at',
  download: 'Download',
  aprogress: 'Progress',
  edit: 'Measurement edited successfully!',
  tooltip_name: 'Internal name of the survey',
  tooltip_display_name: 'Survey name for respondents',
  summary: 'Measurement Summary',
  to_edit: 'Edit measurement',
  total_progress: 'Total progress',
  deadline_at: 'Closing date',
  poll_completed: 'Surveys completed',
  poll_no_completed: 'Surveys not completed',
  resend: 'Resend Uncompleted Surveys',
  resend_success: 'Surveys resent successfully!',
  question_n: '{n} Questions',
  no_actions: 'Not available',
  questionnaire_e: 'Engagement Questionnaire 3',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'In this file you can see the questions belonging to the  "{name}" questionnaire, grouped by dimension and attribute.',
  open_question_info: 'You can indicate additional personalized questions, by indicating the possible answers.',
  // tslint:disable-next-line:max-line-length
  congratulations: 'Congratulations, you completed <span class="headline" style="color: #44C156">{progress}%</span> of the survey!',
  incompleted: 'Incomplete',
  no_questionnaries_title: 'You cannot continue creating this survey',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'There are no active questionnaires for your company. For this reason you cannot continue to create surveys of this type. Please contact your system administrator to solve this problem.',
  to_the: 'to the',
  link: 'Copy link',
  dimensional_scores: 'Scoring for Dimensions',
  workshop_calibration: 'Measurement Calibration: ',
  done: 'Done',
  unrealized: 'Not done',
  attributes_relevance: 'Attribute requirement level assessment',
  confirm_create_title: 'Confirm workshop creation',
  confirm_generate_report: 'Confirm the report generation',
  your_balance: 'Your balance:',
  close_workshop: 'Close workshop',
  msg_close_workshop: 'After closing the Measurement,  none of your employees will be able to continue to use it, and this action cannot be reversed. All reporting information will be saved up to this point.',
  copy_invitation_url: 'Copy the link and send it to the workshop participants before or at the beginning of it.',
  link_copied: 'Link copied.',
  no_required_culture: 'It has no required culture records',
  go_create: 'Go to required culture creation',
  resume: 'Summary of required culture',
  not_enough_population: 'Insufficient population.',
  population_more_than_five: 'The population must be greater than one',
  no_population_msg: 'To guarantee the anonymity of our measurements, we cannot allow filtering of populations with fewer than two results. If you want to know more about our policies.',
  please_contact_us: 'please contact us',
  date_selection_subtitle: 'In order to have the link enabled, we recommend setting the start date and time at least 24 hours before the workshop.',
  questionnaire_selection_subtitle: 'Select the same questionnaire in the current culture assessment, so that the answers are 100% comparable.'
}
