
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" sm="5" class="pt-4">
        <h4 class="display-1">{{ $t('requiredCulture.poll_title') }}</h4>
      </v-col>

      <v-col cols="12" sm="7" class="d-flex justify-end">
        <v-btn large
          color="primary"
          to="required-culture/create"
        >
          {{ $t('requiredCulture.create_required_culture') }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-3">
        <v-card>
          <v-row class="ml-3">
            <v-col cols="12" sm="3">
              <v-select
                :label="$t('employees.filter_by')"
                :items="filters"
                clearable
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-select>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getRequiredCultures"
            :options="options"
            no-data="requiredCulture.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center">
                {{ prop.item.name }}
              </td>
              <td class="text-center">
                <v-chip outlined label :color="getColor(prop.item.status)">
                  {{ $t(`requiredCulture.${prop.item.status}`) }}
                </v-chip>
              </td>
              <td class="text-center">{{ prop.item.dimensionsScores.length }}</td>
              <td class="text-center">
                {{ formatDate(prop.item.releaseAt) }} {{$t('requiredCulture.to_the')}} {{ formatDate(prop.item.deadlineAt)}}
              </td>
              <td class="text-center">
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="`/required-culture/${prop.item._id}/show`" icon>
                      <v-icon small>visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.view_details') }}</span>
                </v-tooltip>
                <v-tooltip :disabled="notEditable(prop.item.status)" bottom color="green">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/required-culture/${prop.item._id}/edit`"
                      v-on="on"
                      icon
                      :disabled="notEditable(prop.item.status)"
                    >
                      <v-icon small>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>
                <v-tooltip :disabled="prop.item.status!=='progress'" bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" :disabled="prop.item.status!=='progress'" @click="showLink(prop.item.tokenId)">
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('requiredCulture.link') }}</span>
                </v-tooltip>

                <v-tooltip :disabled="prop.item.status !== 'completed'" bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/required-culture/reports/${prop.item._id}`"
                      :disabled="prop.item.status !== 'completed'"
                      v-on="on"
                      icon
                    >
                      <v-icon small>mdi-chart-bar-stacked</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.view_pdf') }}</span>
                </v-tooltip>

                <v-tooltip :disabled="prop.item.status!=='progress'" bottom color="orange">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`/evaluation/required/${prop.item.tokenId}`"
                      v-on="on"
                      icon
                      :disabled="prop.item.status!=='progress'"
                    >
                      <v-icon small>mdi-settings</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('evaluations.calibrate') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showLinkModal" width="650px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('requiredCulture.copy_invitation_url') }}</span>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="11">
              <v-text-field
                :label="$t('input.invitation_url')"
                :readonly="true"
                outlined
                v-model="tokenUrl"
                id="tokenUrl"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="copyUrl" class="mt-2">
                    <v-icon>file_copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('requiredCulture.link') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block large @click="closeModal">{{ $t('input.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import requiredCultureService from '../../services/required-culture'

export default {
  data () {
    return {
      headers: [
        'requiredCulture.name',
        'requiredCulture.status',
        'requiredCulture.responses',
        'requiredCulture.period',
        'requiredCulture.action'
      ],
      engagements: [],
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      showLinkModal: false,
      tokenUrl: '',
      filters: [
        { text: this.$t(`page_all`), value: null },
        { text: this.$t(`pending`), value: 'pending' },
        { text: this.$t(`in_progress`), value: 'in_progress' },
        { text: this.$t(`completed`), value: 'completed' }
      ],
      options: {
        filter: null,
        search: null
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.filters = [
          { text: this.$t(`page_all`), value: null },
          { text: this.$t(`pending`), value: 'pending' },
          { text: this.$t(`in_progress`), value: 'in_progress' },
          { text: this.$t(`completed`), value: 'completed' }
        ]
      }
    }
  },
  methods: {
    showLink (tokenUrl) {
      const host = window.location.origin + '/evaluations/required/'
      this.tokenUrl = host + tokenUrl
      this.showLinkModal = true
    },
    getColor (status) {
      switch (status) {
        case 'pending':
          return 'gray'
        case 'completed':
          return 'green'
        case 'progress':
          return 'blue'
      }
    },
    getRequiredCultures (options) {
      return requiredCultureService.list(options, this.user.role)
        .then((res) => ({
          items: res.items,
          total: res.length
        }))
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      let dateAux = date.split('T')[0]
      const [year, month, day] = dateAux.split('-')
      return `${day}/${month}/${year}`
    },
    copyUrl () {
      let input = document.getElementById('tokenUrl')
      input.select()
      document.execCommand('copy')
      this.$store.dispatch('alert/success', this.$t('requiredCulture.link_copied'))
    },
    closeModal () {
      this.showLinkModal = false
    },
    notEditable (status) {
      return status === 'completed'
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    }
  }
}
</script>
