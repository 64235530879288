
import pdfUtil from '../utils/pdf'

export default {
  methods: {
    $generateIntroduction () {
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('requiredReport.index')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 20]
        },
        {
          text: `1. ${this.$t('requiredReport.introduction')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredReport.index_stack1'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '3\n3',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `2. ${this.$t('requiredReport.tool_desc')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredReport.index_stack2'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '4\n4\n5',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `3. ${this.$t('requiredReport.understanding_results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredReport.index_stack3'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '8\n8\n',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `4. ${this.$t('requiredReport.results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredReport.index_stack4'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '9\n10\n11\n20\n21\n',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `1. ${this.$t('requiredReport.introduction')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `1.1 ${this.$t('requiredReport.what_is_organization_culture')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredReport.organization_culture_definition'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredReport.organization_culture_definition2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredReport.organization_culture_definition3'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `1.2 ${this.$t('requiredReport.for_what')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredReport.for_what1'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredReport.for_what2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredReport.for_what3'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        // 2
        {
          table: {
            widths: [479],
            body: [
              [{ text: `2. ${this.$t('requiredReport.tool_desc')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `2.1 ${this.$t('requiredReport.what_the_tool_measure')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredReport.what_the_tool_measure_text'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredReport.what_the_tool_measure_text2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `2.2 ${this.$t('requiredReport.culture_types')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        // 2.2 Cultura responsable
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredReport.responsible_culture'),
                  color: '#0889D6',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredReport.responsible_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredReport.values'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('requiredReport.sustainability'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('requiredReport.customers'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura competitiva
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredReport.competitive_culture'),
                  color: '#EB493C',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredReport.competitive_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredReport.innovation'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('requiredReport.achievement'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('requiredReport.leadership'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura humanista
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredReport.humanist_culture'),
                  color: '#36ad92',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredReport.humanist_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredReport.talent'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('requiredReport.relationship'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('requiredReport.communication'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `2.3 ${this.$t('requiredReport.culture_dimensions')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 35, 0, 15],
          pageBreak: 'before'
        },
        {
          color: '#444',
          margin: [15, 10],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.values').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.values_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.values_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.sustainability').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.customers').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.customers_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.customers_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#2196F3' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [15, 35],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.innovation').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.achievement').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.leadership').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#F23636' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [15, 35],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.talent').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.talent_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.talent_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.relationship').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.communication').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.communication_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.communication_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#4DC285' : null
            }
          }
        },
        // 3
        {
          table: {
            widths: [479],
            body: [
              [{ text: `3. ${this.$t('requiredReport.understanding_results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `3.1 ${this.$t('requiredReport.current_context')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredReport.current_context_text'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          ul: this.$t('requiredReport.current_context_questions'),
          alignment: 'justify',
          margin: [0, 0, 22, 15],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `3.2 ${this.$t('requiredReport.results_interpretation')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          ul: this.$t('requiredReport.results_analysis'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        }
      ]
    }
  }
}
