import pdf from '../utils/pdf'
export default {
  methods: {
    barChartDimesions () {
      const totalActualAverage = this.round((this.averagePerCultureTypesTotal / 3), 2)
      const totalPreviousAverage = this.hasPrevious ? this.round((this.previousAveragePerCultureTypesTotal / 3), 2) : 0
      const respActualAverage = this.round(this.averagePerCultureTypes[0].average, 2)
      const compActualAverage = this.round(this.averagePerCultureTypes[1].average, 2)
      const humaActualAverage = this.round(this.averagePerCultureTypes[2].average, 2)
      const respTrend = this.hasPrevious ? this.round(this.averagePerCultureTypes[0].average - this.previousAveragePerCultureTypes[0].average, 2) : 0
      const compTrend = this.hasPrevious ? this.round(this.averagePerCultureTypes[1].average - this.previousAveragePerCultureTypes[1].average, 2) : 0
      const humaTrend = this.hasPrevious ? this.round(this.averagePerCultureTypes[2].average - this.previousAveragePerCultureTypes[2].average, 2) : 0
      let rateT
      if (this.hasPrevious) {
        rateT = {
          pr: +(this.averagePerDimensions[0].average.toFixed(2)) - +(this.previousAveragePerDimensions[0].average.toFixed(2)),
          so: +(this.averagePerDimensions[1].average.toFixed(2)) - +(this.previousAveragePerDimensions[1].average.toFixed(2)),
          cl: +(this.averagePerDimensions[2].average.toFixed(2)) - +(this.previousAveragePerDimensions[2].average.toFixed(2)),
          in: +(this.averagePerDimensions[3].average.toFixed(2)) - +(this.previousAveragePerDimensions[3].average.toFixed(2)),
          lo: +(this.averagePerDimensions[4].average.toFixed(2)) - +(this.previousAveragePerDimensions[4].average.toFixed(2)),
          li: +(this.averagePerDimensions[5].average.toFixed(2)) - +(this.previousAveragePerDimensions[5].average.toFixed(2)),
          ta: +(this.averagePerDimensions[6].average.toFixed(2)) - +(this.previousAveragePerDimensions[6].average.toFixed(2)),
          re: +(this.averagePerDimensions[7].average.toFixed(2)) - +(this.previousAveragePerDimensions[7].average.toFixed(2)),
          co: +(this.averagePerDimensions[8].average.toFixed(2)) - +(this.previousAveragePerDimensions[8].average.toFixed(2))
        }
      }
      let test = [
        // 4 Resultados
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4. ${this.$t('cultureReports.results')}`.toUpperCase(), color: '#fff', margin: [50, 6, 200, 6], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 20],
          pageBreak: 'before'
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.questionaire_result'),
          margin: [0, 0, 22, 0],
          lineHeight: 1.5,
          alignment: 'justify'
        },
        {
          columns: [
            {
              text: `${this.$t('cultureReports.total_obtained')} ${this.totalObtained}`,
              width: '*',
              alignment: 'center',
              margin: [0, 50],
              color: '#839291'
            },
            {
              image: this.chartCake,
              width: 200,
              height: 150
            },
            {
              text: `${this.$t('cultureReports.total_sent')} ${this.totalReceived}`,
              width: '*',
              alignment: 'center',
              margin: [0, 50],
              color: '#839291'
            }
          ]
        },
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.1 ${this.$t('cultureReports.answers_rate')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 0, 0, 0]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.completed_questionaires_persons'),
          margin: [0, 10, 35, 5],
          alignment: 'justify'
        },
        ...this.answersRateTables,
        // 4.2
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.2 ${this.$t('cultureReports.results_per_culture')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#0889D6' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 10, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.values'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.values_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.sustainability'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.sustainability_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.customers'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.customers_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.innovation'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.innovation_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.achievement'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.achievement_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.leadership'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.leadership_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.talent'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.talent_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.relationship'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.relationship_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.communication'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.communication_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : (rowIndex === 1 && columnIndex === 0)
                ? '#0889D6' : (rowIndex === 2 && columnIndex === 0)
                  ? '#EB493C' : (rowIndex === 3 && columnIndex === 0)
                    ? '#48BBA1' : (rowIndex === 4 && columnIndex === 0)
                      ? '#BFBFBF' : ''
            }
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: this.$t('cultureReports.culture').toUpperCase(), margin: [15, 3, 30, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.current').toUpperCase(), margin: [20, 3, 30, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.preview_population').toUpperCase(), margin: [20, 3, 30, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.rate').toUpperCase(), margin: [20, 3, 40, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t('cultureReports.responsible'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 10, 0, 0] },
                { text: respActualAverage, borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? this.round(this.previousAveragePerCultureTypes[0].average, 2) : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? respTrend : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0], alignment: 'center', ...pdf.styleTendency(respTrend) }
              ],
              [{ text: this.$t('cultureReports.competitive'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: compActualAverage, borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? this.round(this.previousAveragePerCultureTypes[1].average, 2) : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? compTrend : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center', ...pdf.styleTendency(compTrend) }
              ],
              [{ text: this.$t('cultureReports.humanist'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: humaActualAverage, borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? this.round(this.previousAveragePerCultureTypes[2].average, 2) : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? humaTrend : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center', ...pdf.styleTendency(humaTrend) }
              ],
              [{ text: 'Total', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: totalActualAverage, borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? totalPreviousAverage : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' },
                { text: this.hasPrevious ? this.round((totalActualAverage - totalPreviousAverage), 2) : '--', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center', ...pdf.styleTendency(totalPreviousAverage ? (totalActualAverage - totalPreviousAverage) : 0) }
              ]
            ]
          },
          margin: [0, 0, 10, 0]
        },
        {
          image: this.chartPie,
          width: 500,
          height: 400
        },
        // 4.3
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.dimension').toUpperCase(), margin: [8, 6, 30, 6], bold: true, style: { color: '#000' } }],
                  [{ text: this.$t('cultureReports.current_culture'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }],
                  [{ text: this.$t('cultureReports.preview_population'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }],
                  [{ text: this.$t('cultureReports.rate'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }]
                ]
              },
              margin: [-5, 15, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#2196F3' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: 'Pr', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'So', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'Cl', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: `${this.averagePerDimensions[0].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[1].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[2].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? this.previousAveragePerDimensions[0].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[1].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[2].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? rateT.pr.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.pr : 0) },
                    { text: `${this.hasPrevious ? rateT.so.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.so : 0) },
                    { text: `${this.hasPrevious ? rateT.cl.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.cl : 0) }
                  ]
                ]
              },
              margin: [0, 15, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: 'In', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'Lo', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'Li', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: `${this.averagePerDimensions[3].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[4].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[5].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? this.previousAveragePerDimensions[3].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[4].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[5].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? rateT.in.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.in : 0) },
                    { text: `${this.hasPrevious ? rateT.lo.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.lo : 0) },
                    { text: `${this.hasPrevious ? rateT.li.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.li : 0) }
                  ]
                ]
              },
              margin: [0, 15, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: 'Ta', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'Re', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: 'Co', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: `${this.averagePerDimensions[6].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[7].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.averagePerDimensions[8].average.toFixed(2)}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? this.previousAveragePerDimensions[6].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[7].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasPrevious ? this.previousAveragePerDimensions[8].average.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? rateT.ta.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.ta : 0) },
                    { text: `${this.hasPrevious ? rateT.re.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.re : 0) },
                    { text: `${this.hasPrevious ? rateT.co.toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? rateT.co : 0) }
                  ]
                ]
              },
              margin: [0, 15, 73, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          image: this.flowerChart,
          width: 520,
          height: 370,
          margin: [0, 25, 0, 0]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ],
          margin: [0, 40, 0, 4]
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions_actual_culture')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 30, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_dimensions_actual_culture_text'),
          alignment: 'justify',
          margin: [50, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `${this.$t('cultureReports.culture_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 5, 0, 0]
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '-', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_negative_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [36, 8, 0, 10],
              alignment: 'center'
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '0', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_neutral_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [10, 8, 0, 0],
              alignment: 'center'
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '+', margin: [-2, 0, -10, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_positive_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [10, 8, 12, 10],
              alignment: 'center'
            }
          ]
        }
      ]
      let array = []
      let column1 = []
      let column2 = []
      let column3 = []
      for (let index = 0; index < this.barCharts.length; index++) {
        if (index <= 2) {
          column1.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        } else if (index > 2 && index <= 5) {
          column2.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        } else {
          column3.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        }
      }
      array = [
        {
          columns: column1
        },
        {
          columns: column2
        },
        {
          columns: column3,
          pageBreak: 'after'
        }
      ]
      test = test.concat(array)
      return test
    },
    $generateResults () {
      return this.barChartDimesions()
    }
  }
}
