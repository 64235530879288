
export default {
  methods: {
    $generateDimensionRates () {
      return [
        // 4
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4. ${this.$t('cultureReports.results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        // 4.1
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.1 ${this.$t('cultureReports.answers_rate')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 8, 0, 0]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.response_rate_description'),
          aligment: 'justify',
          margin: [0, 10, 0, 5]
        },
        ...this.answersRateTables
      ]
    }
  }
}
