
<template>
  <v-stepper-content :step="step">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('currentCulture.date') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <x-date-picker
              :readonly="currentCulture.status != 'pending'"
              v-model="currentCulture.releaseAt.value"
              @date-picked="currentCulture.releaseAt.value = $event"
              :label="$t('currentCulture.date_delivery')"
              :name="$t('currentCulture.date_delivery') | lowerCase"
              vid="currentCulture.date_delivery"
              ref="delivered"
              rules="required"
              :append-outer-icon="$t('help.icon')"
              :help="$t('help.culture.create.releaseAt')"
            ></x-date-picker>
          </v-col>

          <v-col cols="12" sm="3">
            <x-inputs-autocomplete
              :filled="currentCulture.status !== 'pending'"
              v-bind:style="currentCulture.status != 'pending' ? 'cursor: not-allowed;' : ''"
              :readonly="currentCulture.status != 'pending'"
              :items="hours"
              persistent-hint
              :input="currentCulture.releaseAt.hour"
              @updateInput="($event) => currentCulture.releaseAt.hour= $event"
              light
              :label="$t('currentCulture.hours')"
              autofocus
              name="hours"
              :disabled="currentCulture.status!=='pending'"
              rules="required"
              :data-vv-as="$t('currentCulture.hours') | lowerCase"
            ></x-inputs-autocomplete>
          </v-col>

          <v-col cols="12" sm="3">
            <x-date-picker
              v-model="currentCulture.deadlineAt.value"
              @date-picked="currentCulture.deadlineAt.value = $event"
              :label="$t('currentCulture.poll_valid_until')"
              :name="$t('currentCulture.poll_valid_until') | lowerCase"
              vid="currentCulture.poll_valid_until"
              rules="required"
              :help="$t('help.culture.create.deadlineAt')"
            ></x-date-picker>
          </v-col>

          <v-col cols="12" sm="3">
            <x-inputs-autocomplete
              v-if="currentCulture.status === 'progress'"
              :items="hours"
              persistent-hint
              :input="currentCulture.deadlineAt.hour"
              @updateInput="($event) => currentCulture.deadlineAt.hour= $event"
              light
              :label="$t('currentCulture.hours_valid_until')"
              autofocus
              name="hours_valid_until"
              :data-vv-as="$t('currentCulture.hours_valid_until') | lowerCase"
            ></x-inputs-autocomplete>

            <x-inputs-autocomplete
              v-else
              :box="currentCulture.status != 'pending'"
              v-bind:style="currentCulture.status != 'pending' ? 'cursor: not-allowed;' : ''"
              :readonly="currentCulture.status != 'pending'"
              :items="hours"
              persistent-hint
              :input="currentCulture.deadlineAt.hour"
              @updateInput="($event) => currentCulture.deadlineAt.hour= $event"
              light
              :label="$t('currentCulture.hours_valid_until')"
              autofocus
              name="hours_valid_until"
              :data-vv-as="$t('currentCulture.hours_valid_until') | lowerCase"
            ></x-inputs-autocomplete>
          </v-col>

          <v-col cols="12">
            <x-inputs-autocomplete
              v-bind:style="currentCulture.status != 'pending' ? 'cursor: not-allowed;' : ''"
              persistent-hint
              :readonly="currentCulture.status != 'pending'"
              :items="timezones"
              rules="required"
              :input="currentCulture.timeZone"
              @updateInput="($event) => currentCulture.timeZone= $event"
              light
              :label="$t('currentCulture.time_zone')"
              name="selection_type"
              :data-vv-as="$t('currentCulture.time_zone') | lowerCase"
              :append-outer-icon="$t('help.icon')"
              :disabled="currentCulture.status!=='pending'"
              :help-message="$t('help.culture.create.timezone')"
            ></x-inputs-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="currentCulture.switchDate"
              color="primary"
              :label="$t('currentCulture.want_send_reminders')"
              :append-icon="$t('help.icon')"
              @click:append="$store.dispatch('help/display', $t('help.culture.create.reminders'))"
            ></v-switch>
          </v-col>
        </v-row>

        <!-- Reminders -->
        <v-row
          row wrap
          v-bind:key="index" v-for="(reminder,index) in currentCulture.reminders"
          v-show="currentCulture.switchDate"
        >
          <v-col cols="12" sm="7" md="3" class="mr-2" >
            <x-date-picker
              v-model="currentCulture.reminders[index].value"
              @date-picked="currentCulture.reminders[index].value = $event"
              :label="$t('currentCulture.date')"
              :rules="currentCulture.switchDate ?
                `required|dateBetween:${validateUntil.ref},${validateUntil.finish},${currentCulture.reminders[index].hour}` : ''
              "
              name="reminders"
              vid="input.the_reminders"
              :reff="'child_picker' + index"
              ref="parent_picker"
            ></x-date-picker>
          </v-col>

          <v-col cols="12" sm="4" md="3">
            <x-inputs-autocomplete
              :items="hours"
              persistent-hint
              :input="currentCulture.reminders[index].hour"
              @updateInput="($event) => currentCulture.reminders[index].hour= $event"
              light
              :label="$t('currentCulture.hours')"
              autofocus
              name="hours"
              :data-vv-as="$t('currentCulture.hours') | lowerCase"
            ></x-inputs-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="5" v-if="currentCulture.reminders.length == index + 1" class="pt-5">
            <v-btn outlined rounded
              color="red"
              class="mr-2"
              @click="currentCulture.status !== 'completed' ? deleteReminder(index) : {}"
            >
              {{ $t('input.trash') }}
            </v-btn>

            <v-btn outlined rounded
              color="green"
              @click="currentCulture.status !== 'completed' ? addReminder(index) : {}"
            >
              {{ $t('input.add') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(+step - 1)"
            >{{ $t('input.back') }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              large
              type="submit"
            >{{ $t('input.next') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    </ValidationObserver>
  </v-stepper-content>
</template>

<script>

import timeZoneService from '../../services/time-zones'

export default {
  props: {
    currentCulture: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      edit: Boolean(this.$route.params.id),
      hours: [],
      timezones: []
    }
  },
  computed: {
    validateUntil () {
      const { releaseAt, deadlineAt } = this.currentCulture
      return {
        ref: new Date(`${releaseAt.value} ${releaseAt.hour}:00`).getTime(),
        finish: new Date(`${deadlineAt.value} ${deadlineAt.hour}:00`).getTime()
      }
    }
  },
  methods: {
    validateReminders () {
      if (this.currentCulture.reminders && this.currentCulture.reminders.length > 0) {
        for (const rem of this.currentCulture.reminders) {
          let remTime = new Date(`${rem.value} ${rem.hour}:00`).getTime()
          if (this.validateUntil.ref < remTime) {
            if (this.validateUntil.finish < remTime && rem.value !== '') {
              return false
            }
          } else if (rem.value !== '') {
            return false
          }
        }
      }
      return true
    },
    onSubmit () {
      if (this.validTimeZone() && this.validateUntil.ref < this.validateUntil.finish && this.validateReminders()) {
        this.changeStep(+this.step + 1)
      } else {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_dates'))
      }
    },
    getOffsetObject () {
      const text = this.currentCulture.timeZone
      const arr = []
      this.timezones.forEach(element => {
        if (element.text === `${text}`) {
          arr.push(element)
        }
      })
      return arr
    },
    validTimeZone () {
      if (this.edit) {
        return true
      }
      var offsetObj = this.getOffsetObject()
      if (offsetObj.length < 1) {
        return false
      }
      var date = new Date()
      var utc = date.getTime() + (date.getTimezoneOffset() * 60000)
      var newDate = new Date(utc + (3600000 * (parseFloat(offsetObj[0].offset))))
      const dates = this.validateUntil
      if (dates) {
        return newDate.getTime() < dates.ref
      } else {
        return false
      }
    },
    changeStep (step) {
      this.$emit('changeStep', this.currentCulture, step)
    },
    getHours () {
      for (let i = 0; i < 24; i++) {
        i < 10 ? this.hours.push('0' + i + ':00') : this.hours.push(i + ':00')
      }
    },
    addReminder (i) {
      this.$refs.parent_picker[i].$refs['child_picker' + i].validate()
        .then(res => {
          if (res.valid) {
            this.currentCulture.reminders.length === 5
              ? this.$store.dispatch('alert/error', this.$t('errors.validator/reminders'))
              : this.currentCulture.reminders.push({
                value: '',
                hour: '23:00'
              })
          }
        })
    },
    deleteReminder (index) {
      if (this.currentCulture.reminders.length === 1) {
        this.$store.dispatch('alert/error', this.$t('errors.validator/delete_reminder'))
      } else {
        this.currentCulture.reminders.splice(index, 1)
      }
    }
  },
  created () {
    this.getHours()
    timeZoneService.list()
      .then(res => {
        this.timezones = res.items.map((item) => ({
          value: item.text,
          text: item.text,
          offset: item.offset
        }))
      })
  }
}
</script>
