
export default {
  copyright: 'OCC - All rights reserved',
  introduction: 'Introduction',
  description: 'Description of the OCC tool',
  results: 'Results',
  ranking: 'Ranking',
  index: 'Index',
  topic: 'Topic',
  page: 'Page',
  humanist: 'Humanist',
  competitive: 'Competitive',
  responsible: 'Responsible',
  responsible_culture: 'Responsible Culture',
  competitive_culture: 'Competitive Culture',
  humanist_culture: 'Humanist Culture',
  culture: 'Culture',
  required_culture: 'Required Culture',
  tool_desc: 'Tool description',
  understanding_results: 'Understanding the Results',
  current_culture_organization: 'Current Organizational Culture',
  occ_tool: 'OCC tool',
  index_stack1: [
    '1.1 What is Organizational Culture?\n',
    '1.2 Why define the Required Culture??\n'
  ],
  index_stack2: [
    '2.1 What does the Organizational Culture Compass (OCC) tool measure?\n',
    '2.2 Types of Culture\n',
    '2.3 Dimensions of Culture\n'
  ],
  index_stack3: [
    '3.1 Current context of the Organization\n',
    '3.2 Interpretation of Results\n'
  ],
  index_stack4: [
    '4.1 Results by Types of Culture\n',
    '4.2 Results by Dimensions\n',
    '4.3 Results by Attributes\n',
    '4.4 Ranking of Dimensions for Required Culture\n',
    '4.5 Ranking of Attributes for Required Culture\n'
  ],
  initials: {
    values: 'Pr',
    sustainability: 'Su',
    customers: 'Cl',
    innovation: 'In',
    achievement: 'Ac',
    leadership: 'Le',
    talent: 'Ta',
    relationship: 'Re',
    communication: 'Co'
  },
  // Introduction
  what_is_organization_culture: 'What is Organizational Culture?',
  // tslint:disable-next-line:max-line-length
  organization_culture_definition: 'Organizational Culture is understood as the way of thinking, feeling and acting that distinguishes the members of an organization. Culture is the reflection of the deepest beliefs, values ​​and norms and traditions that define the personality of the organization.\n\n',
  organization_culture_definition2: 'Organizational Culture is the last frontier of competitive advantages and which generates a sustainable differentiation that is difficult to imitate. The culture of an organization is reflected in the daily habits of its members, in their observable traits and the way in which decisions are made.\n\n',
  organization_culture_definition3: 'Organizational Culture is directly related to the execution of the strategy and the achievement of results; many organizations fail to execute their strategy because they do not have an adequate and coherent culture.\n\n',
  // Para qué definir la cultura requerida
  for_what: 'Why define the required culture?',
  for_what1: 'With the complexity of today\'s world and the challenges that arise in attracting clients, talent or investors, cultural capital has become the new priority in relation to the competitive advantages of an organization. The culture and values ​​it represents are as important as the quality of the products and services an organization offers.\n\n',
  for_what2: 'The objective of defining the Required Culture is to identify the culture that the Organization must develop to successfully implement its strategy. It is defined by the management team reviewing the current and future reality of the Organization, its strategic direction, the competitive environment and the main challenges.\n\n',
  for_what3: 'A culture is measurable, dynamic, transformable, and unique. That said, there are no better cultures than others, simply more or less adequate; We believe that it is important to contrast this definition with a diagnosis of Culture.\n\n',
  // Descripción de la herramienta occ
  what_the_tool_measure: 'What does the organizational culture compass (OCC) tool measure?',
  what_the_tool_measure_text: 'The OCC questionnaire is based on observable behaviors associated with values ​​that define Organizational Culture. The results presented in this report are based on the perceptions of the people who answered the questionnaire. The 72 Attributes (behaviors) form nine dimensions organized into three types of culture.\n\n',
  what_the_tool_measure_text2: 'By analyzing the data, you can identify the elements of culture that are predominant and those that are less obvious. Remember, there are no good or bad cultures, just different.\n\n',
  // Tipos de cultura
  culture_types: 'Types of Culture',
  responsible_text: 'An organization admired by society and its clients, a "good citizen", oriented towards its stakeholders with ethical and impeccable management, comprises the following dimensions:',
  competitive_text: 'A successful, efficient and vibrant organization balances innovation and the effectiveness of its current processes through the management of world-class leaders. It comprises the following dimensions:',
  humanist_text: 'An organization that understands that it needs competent and committed talent to achieve its results. A company that attracts, develops and maintains the best talent, promotes excellent relationships and effective and transparent communication. It comprises the following dimensions:',
  what_is_engagement_text: ['At OCC Solutions we understand ', { text: 'Engagement', italics: true }, ' a high level of emotional commitment as a sense of belonging combined with enthusiasm and passion for work and for the Organization, which is manifested in a permanent desire to always give one\'s best.'],
  // Dimensiones de cultura
  culture_dimensions: 'Culture Dimensions',
  culture_dimensions_next: 'The nine Dimensions of Culture are described below:',
  yes: 'Yes',
  no: 'No',
  // Principios
  values_positive: 'Leadership and a principle-centered culture define and defend patterns of human behavior that become the moral compass of the organization. The principles can be understood as values ​​and today it is clear that their experience is essential to ensure the survival of organizations.',
  values_negative: ['For organizations ', { text: 'not', decoration: 'underline' }, ' focused on principles, the end justifies the means. Values ​​do not exist or are lived and the associated behaviors are examples of behavior, morals or ethics. They usually have a bad reputation and do not build trust with their stakeholders.'],
  // Sostenibilidad
  sustainability_positive: 'A culture of sustainability promotes the efficient use of current resources to ensure the future sustainability of the organization. It gives the same importance to all stakeholders and seeks balanced results in the financial, human, social and the planet.',
  sustainability_negative: ['A culture ', { text: 'not', decoration: 'underline' }, ' focused on sustainability, aims to obtain short-term results, prioritizing achievements for shareholders over other stakeholders. Social responsibility, the planet and future generations are ', { text: 'not', decoration: 'underline' }, ' a priority'],
  // Clientes
  customers_positive: 'A customer-centric culture focuses its efforts on understanding, meeting, and exceeding customer needs and expectations. The definition of the processes and systems, the structure and the competencies of the people are defined by taking into account the current and future realities of the clients.',
  customers_negative: ['A culture ', { text: 'not', decoration: 'underline' }, ' focused on the client, defines the client as a means to obtain the results and ', { text: 'not', decoration: 'underline' }, ' shows interest in their satisfaction or loyalty. The vision is more inward, towards processes, control and short-term financial results. Requests, complaints, claims and suggestions are seen as a nuisance and customers, at best, are only important to those who are in direct contact with them.'],
  // Innovación
  innovation_positive: 'Innovation is understood as the ability to create new realities, it is a vital aspect for the success of organizations in today\'s world. A culture of innovation fosters creativity, initiative and the ability to reinvent yourself to prosper and compete over time, while allowing you to take risks and learn from mistakes.',
  innovation_negative: ['In organizations where innovation ', { text: 'is not', decoration: 'underline' }, ' valued, they seek to maintain and preserve what is known. Most people are paid to “do their job and obey” and the ability to innovate is, at best, the privilege of a few. There is inflexibility, difficulty in adapting and changing, fear of taking risks and mistakes are often punished.'],
  // Logro
  achievement_positive: 'Organizations must deliver positive results to progress and achieve their goals. A culture of effectiveness and obtaining results promotes achievement-oriented behaviors and processes with a high level of performance. A direct line is created between the contribution of individual and team performance to the performance of the organization. Positive results are the consequence of a culture of high performance and commitment.',
  achievement_negative: ['In organizations where results ', { text: 'are not', decoration: 'underline' }, ' valued, they are not important and when they are not achieved there are usually "excuses or culprits". There is usually no commitment and a sense of belonging. Compliance with processes and controls are more important than achieving defined goals.'],
  // Liderazgo
  leadership_positive: 'The quality and capacity of leaders is essential to understand the success of organizations; understanding leadership as the art of mobilizing and guiding people to ensure the achievement of common objectives and purposes. In this dimension, it is analyzed how leaders achieve commitment, generate trust, are interested in developing people, give autonomy, create environments that empower and accompany their teams in their development.',
  leadership_negative: ['In organizations that ', { text: 'do not', decoration: 'underline' }, ' value transformative leadership, people are simply one more resource and are dedicated only to implementing the goals, objectives and tasks defined by the bosses. The bosses assign activities and control the results. It is managed from the imposition, authoritarianism, distrust and there is usually a high level of "micro-management".'],
  // Talento
  talent_positive: 'A people-centered culture seeks to attract, develop and keep the best talent in the organization as the main lever to achieve its goals. The purpose is to have competent people with a high level of commitment and performance, who feel proud of belonging to the organization. These organizations strive to deserve the enthusiasm and intelligence of all, with their vision, their coherence with values, their leadership and their achievements.',
  talent_negative: ['For companies that ', { text: 'do not', decoration: 'underline' }, ' valoran la gente, las personas son recursos que se utilizan para lograr sus objetivos, sin tener en cuenta sus objetivos y metas individuales. Las emociones, competencias y desarrollo de las personas no son importantes o, a lo sumo, lo son para las áreas de Recursos Humanos.'],
  // Relaciones
  relationship_positive: 'In these organizations, the connection between the quality of relationships and results is clear. These companies promote teamwork, solidarity and the ability to join forces to achieve common goals. Topics such as shared goals, trust, coordination, interdependence, and collaboration are common.',
  relationship_negative: 'Organizations that value only individual work, internal competition, and the win-lose game describe the everyday way of acting and leading. Relationships are strictly professional, collaboration is understood as favor, and division between areas reflects a clear sense of independence. Energy is wasted on bad relationships and mistrust.',
  // Comunicación
  communication_positive: 'The way people communicate and the stories told are part of, reflect and define the culture and leadership styles. In an organization with a strong communication culture, information is shared transparently, people are listened to attentively, questions are encouraged, and relationships of trust and mutual respect are created and maintained. Vision and goals are clearly communicated.',
  communication_negative: ['In organizations that ', { text: 'do not', decoration: 'underline' }, ' value communication, "he/she who has the information has the power." Information does not flow in all directions, nor is the importance of timeliness and transparency valued. Strategies and objectives are not clearly communicated. There is often no "time" to listen to people and questions are perceived as inappropriate and unnecessary. The complexity of communications reflects mistrust, disrespect, and hierarchical leadership styles.'],
  // Entendimiento de los resultados
  current_context: 'Current Context of the Organization',
  current_context_text: 'When interpreting the results, it is essential to take into account the current context of the organization and the population being analyzed. The following questions can give you some guidelines:',
  current_context_questions: [
    '  What are our current challenges?',
    '  Where are we going?',
    '  What do we want to achieve?',
    '  What are our opportunities and threats?',
    '  What are our strengths and opportunities for improvement'
  ],
  results_interpretation: 'Results analysis',
  results_analysis: [
    'The Required Culture is displayed on a scale of 2 to 4.',
    'The results obtained allow defining the features that Culture should have. We insist that it is important to make this definition taking into account the current and future challenges of the Organization.',
    'By analyzing the information, they will be able to find the dimension and the attributes with the highest score. From here the values ​​can be deduced.',
    'It is also important to review the dimensions and attributes with the lowest score. This can give you information about what is least valued by leaders and generate high-value reflection.',
    'A low dimension or attribute can mean that it is a "resolved issue." You have to verify the information. Although it is a "resolved issue", it may be necessary to continue working on it in Culture.',
    'We recommend diagnosing the Current Culture, since the identification of gaps is essential to define focused and relevant action plans.',
    'When measured a second time, trend analysis also provides valuable information'
  ],
  results_per_culture: 'Results by Types of Culture',
  results_per_culture_text: 'The abbreviations for each dimension are the first two initials as follows:',
  results_per_dimension: 'Results by dimensions',
  results_per_attribute: 'Results by attributes',
  innovation: 'Innovation',
  achievement: 'Achievement',
  leadership: 'Leadership',
  talent: 'Talent',
  relationship: 'Relationships',
  communication: 'Communication',
  values: 'Principles',
  sustainability: 'Sustainability',
  customers: 'Clients',
  dimension: 'Dimension',
  initial: 'Init',
  required: 'Required',
  required_abbreviation: 'Req.',
  previous_score_abbreviation: 'Prev. Sco.',
  last_score: 'Last score',
  last_score_2: 'Last score',
  current_organization: 'Current organization',
  ranking_dimensions: 'Ranking of the dimensions for the required culture',
  dimensions_with_higher_score: 'Highest Scored Dimensions by Required Culture',
  dimensions_with_lower_score: 'Dimensions with the lowest score according to Required Culture',
  dimensions_with_higher_dispersion: 'Highest dispersion dimensions',
  dimensions_with_lower_dispersion: 'Lower dispersion dimensions',
  ranking_attributes: 'Ranking of the attributes for the required culture',
  attributes_with_higher_score: 'Highest scoring attributes',
  attributes_with_lower_score: 'Lowest scoring attributes',
  ranking_rates: 'Ranking of required culture rates',
  ranking_of_the_variables: 'Ranking of the variables',
  rates_by_dimension: 'Rates by dimension',
  variation: 'Variation',
  rates: 'Rates',
  highest_positive_rate_attribute: 'Highest positive rates',
  highest_negative_rate_attribute: 'Highest negative rates',
  highest_positive_rate_variable: 'Highest positive rates by variable',
  highest_negative_rate_variable: 'Highest negative rates by variable',
  rates_by_variables: 'Rates by variables',
  rates_by_attributes: 'Rates by attributes',
  // tslint:disable-next-line:max-line-length
  reports: 'Reports',
  general_title: 'General',
  general_desc: 'This report has the statistics of the entire population included in the survey.',
  demographic_title: 'By demographic cuts',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'This report has population statistics filtered through certain demographic cuts; these cuts can be selected below:',
  demographic_button: 'Generate Engagement report by demographic cuts',
  display_demographic: 'Show demographic cuts',
  population: 'Population',
  sent: 'Sent',
  obtained: 'Obtained',
  AcademicDegree: 'Academic degree',
  Age: 'Age',
  Antiquity: 'Seniority',
  Charge: 'Job title',
  Country: 'Country',
  Department: 'Area / Department',
  Gender: 'Gender',
  JobType: 'Contract type',
  years_range: 'Between {min} and {max} years',
  my_inspiration_chart: 'My\ninspiration',
  my_job_chart: 'My\njob',
  work_enviroment_chart: 'Work environment\npositive',
  my_team_chart: 'My\nteam',
  my_development_and_learning_chart: 'My development\nand learning',
  the_leaders_chart: 'The\nleaders',
  current_plus_req: 'Current vs Required Culture',
  cover: {
    ByItems: 'Report by population',
    general: 'General Report'
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  title: 'OCC - Required Culture Report',
  // tslint:disable-next-line:max-line-length
  positive_attribute_footer: 'The following attributes have a high score exactly equal to the attribute ',
  negative_attribute_footer: 'The following attributes have a low score exactly equal to the attribute ',
  positive_variable_footer: 'The following variables have a high score exactly equal to the variable ',
  negative_variable_footer: 'The following variables have a low score exactly equal to the variable ',
  no_answers_modal_msg: 'The current survey does not have any answers, so a report cannot be generated.',
  positive: 'positive',
  limiting: 'limiting',
  attribute: 'Attribute'
}
