<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1 text-capitalize">{{ $t('requiredCulture.new') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" >
            <v-card>
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <x-poll-steps-overview
                  v-show="step === 1"
                  :requiredCulture="requiredCulture"
                  step="1"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview>

                <x-poll-steps-date
                  v-show="step === 2"
                  :requiredCulture="requiredCulture"
                  step="2"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date>

                <x-step-question
                  v-show="step === 3"
                  :requiredCulture="requiredCulture"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-question>

                <x-step-revition
                  v-show="step === 4"
                  :requiredCulture="requiredCulture"
                  step="4"
                  :price="price"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-revition>

              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <x-loading v-if="loading" :display="loading"/>
      <x-confirm-spend-dialog
        :confirmText="$t('requiredCulture.confirm_create_title')"
        :costText="$t('requiredCulture.workshop_cost')"
        :balanceAfterText="$t('requiredCulture.balance_after')"
        :showModalConfirm="showModalConfirm"
        :balance="balance"
        :price="price"
        :noActiveEmployee="false"
        :noBalanceResponse="noBalanceResponse"
        :disableButtonModal="disableButton"
        @result="verifySpend"
        :userType="user.customer.type"
        @update="update">
      </x-confirm-spend-dialog>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import requiredCultureService from '../../services/required-culture'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'

import XStepQuestion from './steps/question.vue'
import XStepRevition from './steps/revition.vue'

export default {
  components: {
    XStepQuestion,
    XStepRevition
  },
  data () {
    return {
      stepperHeaders: [
        'requiredCulture.overview',
        'requiredCulture.date',
        'requiredCulture.questions',
        'requiredCulture.revition'
      ],
      requiredCulture: {
        name: '',
        enterprise: {},
        enterpriseId: 0,
        status: 'pending',
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        questionnaire: '',
        tokenId: '',
        releaseAt: {
          value: '',
          hour: '23:00'
        },
        deadlineAt: {
          value: '',
          hour: '23:00'
        },
        dimensionsScores: [],
        dimensionsEndScore: {},
        attributeRelevance: [],
        questionnaires: []
      },
      price: 0,
      balance: 0,
      step: 1,
      enterpriseId: null,
      loading: false,
      showModalConfirm: false,
      noBalanceResponse: false,
      disableButton: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    verifyStepChanged (data, step) {
      switch (Number(step)) {
        case 0: return this.$router.push('/required-culture')
        case 5: return this.toCofirm()
        default: this.step = step
      }
    },
    verifySpend (data) {
      if (data === 1) {
        return this.create()
      }
      return this.closeModal()
    },
    update () {
      this.showModalConfirm = false
      this.loading = true
      this.getBalance()
      this.showModalConfirm = true
    },
    toCofirm () {
      this.showModalConfirm = true
    },
    closeModal () {
      this.showModalConfirm = false
    },

    create () {
      this.loading = true
      this.disableButton = false
      let data = JSON.parse(JSON.stringify(this.requiredCulture))
      if (this.user.enterprise) {
        data.enterprise = this.user.enterprise
        data.enterpriseId = this.user.enterprise.id
      }
      return requiredCultureService.create(data)
        .then((res) => {
          if (!res._id) {
            if (res.status === 401) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
            } else {
              let errorMsg = this.$t(`errors.${err.code}`)
              if (errorMsg === `errors.${err.code}` && err.code.indexOf('suite-fail/') !== -1) {
                let errorMsg = this.$t('errors.suite-fail/not-found')
              }
              this.$store.dispatch('alert/error', errorMsg)
            }
            this.loading = false
            this.disableButton = true
            return
          }
          this.$store.dispatch('alert/success', this.$t('requiredCulture.created_workshop'))
          this.loading = false
          this.disableButton = false
          setTimeout(this.redirectSummary, 3000)
        })
        .catch((err) => {
          this.loading = false
          let errorMsg = this.$t(`errors.${err.code}`)
          if (errorMsg === `errors.${err.code}` && err.code.indexOf('suite-fail/') !== -1) {
            let errorMsg = this.$t('errors.suite-fail/not-found')
          }
          this.$store.dispatch('alert/error', errorMsg)
        })
    },
    getBalance () {
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          this.balance = res.balance
          this.getServicePrice()
          this.loading = false
        })
    },
    getServicePrice () {
      return servicePriceService.getOne('Requerida')
        .then((res) => {
          this.getServicePriceFromSuite(res)
          this.loading = false
        })
    },
    getServicePriceFromSuite (service) {
      return servicePriceService.getByCodeFromSuite(service.code)
        .then((res) => {
          this.price = res.tokenPrice
        })
    },
    redirectSummary () {
      this.loading = false
      this.$router.push('/operation-summary/required')
    }
  },
  created () {
    this.getBalance()
  }
}
</script>
