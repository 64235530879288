
const examplesWords = ['Cuerda', 'Limpieza', 'Esmalte', 'Propina', 'Bailarina', 'Micro', 'Venecia', 'Chinche', 'Alzar', 'Casado', 'trasnocha', 'Cabina', 'Nombre', 'Malo', 'Pesadilla', 'Vegetal', 'Buzo', 'Tragaluz', 'Detenerse', 'Judas', 'Morder', 'Trigo', 'Alquiler', 'Kamikaze', 'Cordón', 'Oasis', 'Biplano', 'Fingir', 'Insensible', 'Lucifer', 'Calentador', 'Camping', 'Pasamanos', 'Nacimiento', 'Adoptar', 'Tierras', 'Soldar', 'Indios', 'Picotear', 'Visera', 'Lanza', 'Remolcador', 'Manubrio', 'Velero', 'Papelera', 'Reno', 'Ovillo', 'Transparente', 'Calculadora', 'China', 'Sastre', 'Altavoz', 'Consistencia', 'Granjero', 'Decir', 'Negocios', 'Revocar', 'Estatua', 'Cometa', 'Sentado', 'Acupuntura', 'Momia', 'Perla', 'Ciego', 'Fecha', 'Hincha', 'Involuntario', 'Diagrama', 'Escuchar', 'Bailarina', 'Moverse', 'Abrigo', 'Coma']

function getIdx (max, min) {
  return Math.floor(Math.random() * (max - min) + min)
}

export default (vueInstance, options) => {
  options = options || {}
  const min = options.min && typeof options.min === 'number' ? options.min - 1 : -4
  const max = options.max && typeof options.max === 'number' ? options.max - 1 : 4
  const words = options.words && typeof options.words === 'object' ? options.words : examplesWords
  const devSelected = vueInstance.dimensions
  const devSelectedWords = vueInstance.openQuestions
  for (const dimension of devSelected) {
    for (const question of dimension.questions) {
      question.value = getIdx(max, min)
      question.selected = true
    }
  }

  for (const open of devSelectedWords) {
    const shuffled = examplesWords.sort(() => { return 0.5 - Math.random() })
    open.answer = shuffled.slice(0, open.answer.length)
  }

  vueInstance.step = devSelected.length - 1
  vueInstance.dimensions = devSelected
  vueInstance.openQuestions = devSelectedWords

  vueInstance.answerOpenQuestions()
  vueInstance.nextPageView()
}
