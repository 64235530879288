
import pdfUtil from '../utils/pdf'

export default {
  methods: {
    $generateIntroduction () {
      return [
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `${this.$t('requiredActualReport.index')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 10]
        },
        {
          text: `1. ${this.$t('requiredActualReport.introduction')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 6],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredActualReport.index_stack1'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '3\n3',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `2. ${this.$t('requiredActualReport.tool_desc')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 6],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredActualReport.index_stack2'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '4\n4\n5',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `3. ${this.$t('requiredActualReport.understanding_results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 6],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredActualReport.index_stack3'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '8\n8\n8\n9\n',
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        {
          text: `4. ${this.$t('requiredActualReport.results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 6],
          color: '#6d7a7a'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('requiredActualReport.index_stack4'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: `10\n${11 + (this.extraPage + this.fitsExtraPage)}\n${12 + (this.extraPage + this.fitsExtraPage)}\n${14 + (this.extraPage + this.fitsExtraPage)}\n${32 + (this.extraPage + this.fitsExtraPage)}\n${34 + (this.extraPage + this.fitsExtraPage)}\n${38 + (this.extraPage + this.fitsExtraPage)}\n${39 + (this.extraPage + this.fitsExtraPage)}\n${41 + (this.extraPage + this.fitsExtraPage)}\n${43 + (this.extraPage + this.fitsExtraPage)}\n${44 + (this.extraPage + this.fitsExtraPage)}\n`,
              color: '#6d7a7a',
              lineHeight: 2
            }
          ]
        },
        // 1
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `1. ${this.$t('requiredActualReport.introduction')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `1.1 ${this.$t('requiredActualReport.what_is_organization_culture')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredActualReport.organization_culture_definition'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredActualReport.organization_culture_definition2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredActualReport.organization_culture_definition3'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `1.2 ${this.$t('requiredActualReport.for_what')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredActualReport.for_what1'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredActualReport.for_what2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredActualReport.for_what3'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        // 2
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `2. ${this.$t('requiredActualReport.tool_desc')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.1 ${this.$t('requiredActualReport.what_the_tool_measure')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('requiredActualReport.what_the_tool_measure_text'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          text: this.$t('requiredActualReport.what_the_tool_measure_text2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        // 2.2
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.2 ${this.$t('requiredActualReport.culture_types')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        // 2.2 Cultura responsable
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredActualReport.responsible_culture'),
                  color: '#0889D6',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredActualReport.responsible_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredActualReport.values'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('requiredActualReport.sustainability'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('requiredActualReport.customers'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura competitiva
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredActualReport.competitive_culture'),
                  color: '#EB493C',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredActualReport.competitive_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredActualReport.innovation'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('requiredActualReport.achievement'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('requiredActualReport.leadership'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura humanista
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('requiredActualReport.humanist_culture'),
                  color: '#36ad92',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('requiredActualReport.humanist_text'),
                  color: '#6d7a7a',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('requiredActualReport.talent'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('requiredActualReport.relationship'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('requiredActualReport.communication'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.3 ${this.$t('requiredReport.culture_dimensions')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 35, 0, 15],
          pageBreak: 'before'
        },
        {
          color: '#444',
          margin: [15, 10],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.values').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.values_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.values_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.sustainability').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.customers').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.customers_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.customers_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#2196F3' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [15, 35],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.innovation').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.achievement').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.leadership').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#F23636' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [15, 35],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.talent').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.talent_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.talent_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.relationship').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.communication').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.communication_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.communication_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#4DC285' : null
            }
          }
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `3. ${this.$t('requiredActualReport.understanding_results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 6], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.1 ${this.$t('requiredActualReport.answers_rate')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 5, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 1, 0, 15]
        },
        {
          width: 'auto',
          color: '#6d7a7a',
          text: this.$t('requiredActualReport.answers_rate_text'),
          margin: [0, 0, 22, 0],
          lineHeight: 1.2,
          aligment: 'justify'
        },
        {
          ul: this.$t('requiredActualReport.answers_rate_items'),
          width: 'auto',
          color: '#6d7a7a',
          aligment: 'justify',
          margin: [0, 0, 22, 0],
          lineHeight: 1.2
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.2 ${this.$t('requiredActualReport.current_context')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 5, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 1, 0, 15]
        },
        {
          width: 'auto',
          color: '#6d7a7a',
          text: this.$t('requiredActualReport.current_context_text'),
          margin: [0, 0, 22, 0],
          lineHeight: 1.2,
          aligment: 'justify'
        },
        {
          ul: this.$t('requiredActualReport.current_context_questions'),
          width: 'auto',
          color: '#6d7a7a',
          aligment: 'justify',
          margin: [0, 0, 22, 0],
          lineHeight: 1.2
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.3 ${this.$t('requiredActualReport.result_analysis')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 5, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 1, 0, 15]
        },
        {
          ul: this.$t('requiredActualReport.results_analysis'),
          width: 'auto',
          color: '#6d7a7a',
          aligment: 'justify',
          margin: [0, 0, 22, 0],
          lineHeight: 1.3,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.4 ${this.$t('requiredActualReport.what_do_with_results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 5, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 35, 0, 15]
        },
        {
          width: 'auto',
          color: '#6d7a7a',
          text: this.$t('requiredActualReport.what_do_with_results_text'),
          margin: [0, 0, 10, 0],
          lineHeight: 1.5,
          aligment: 'justify'
        },
        {
          ul: this.$t('requiredActualReport.results_steps'),
          type: 'square',
          width: 'auto',
          color: '#6d7a7a',
          aligment: 'justify',
          margin: [0, 0, 10, 0],
          lineHeight: 1.4
        }
      ]
    }
  }
}
