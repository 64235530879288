<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1">{{ $t('currentCulture.to_edit') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <x-poll-steps-overview-current-culture
                  :is-edit="true"
                  :currentCulture="currentCulture"
                  :total-receptors="populationCount"
                  :new-participants="newColaborators"
                  step="1"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview-current-culture>

                <x-poll-steps-date-current-culture
                  :currentCulture="currentCulture"
                  step="2"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date-current-culture>

                <x-step-question
                  :currentCulture="currentCulture"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-step-question>

                <x-step-revition
                  :is-edit="true"
                  :currentCulture="currentCulture"
                  step="4"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                  @delete-invitation-file="deleteInvitationFile"
                  @delete-reminder-file="deleteReminderFile"
                ></x-step-revition>
              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <x-loading v-if="loading" :display="loading"/>
    <x-confirm-spend-dialog
      :confirmText="$t('currentCulture.confirm_create_title')"
      :costText="$t('currentCulture.assessment_cost')"
      :balanceAfterText="$t('currentCulture.balance_after')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="currentCulture.totalPrice"
      :noActiveEmployee="false"
      :noBalanceResponse="!balance"
      :disableButtonModal="true"
      @result="verifySpend"
      :userType="user.customer.type"
      @update="initLoad">
    </x-confirm-spend-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import currentCultureService from '../../services/current-culture-assessment'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees-services'

import XStepQuestion from './steps/question.vue'
import XStepRevition from './steps/revition.vue'

export default {
  components: {
    XStepQuestion,
    XStepRevition
  },
  data () {
    return {
      stepperHeaders: [
        'currentCulture.overview',
        'currentCulture.date',
        'currentCulture.questions',
        'currentCulture.revition'
      ],
      currentCulture: {
        name: '',
        displayName: '',
        enterprise: {},
        enterpriseId: 0,
        population: [],
        timeZone: '',
        questionnaire: '',
        releaseAt: {
          value: '',
          hour: '23:00'
        },
        deadlineAt: {
          value: '',
          hour: '23:00'
        },
        model: null,
        status: '',
        emailData: '',
        totalReceivers: 20,
        switchName: false,
        switchQuestion: false,
        radios: 1,
        questionnaires: [],
        switchDate: false,
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        porcent_total: 0,
        opens: [],
        pollInvitation: {
          subject: '',
          body: '',
          companyMessage: ''
        },
        reminderMail: {
          subject: '',
          body: '',
          companyMessage: ''
        },
        thankMessage: '',
        active: null,
        offset: '',
        totalPrice: 0,
        isEdit: true,
        invitationFileFlag: true,
        reminderFileFlag: true
      },
      populationCount: 0,
      loading: false,
      step: 1,
      suiteEmployees: [],
      existingPopulation: [],
      diffEvaluated: [],
      price: 0,
      balance: 0,
      showModalConfirm: false,
      noActiveEmployee: false,
      noBalanceResponse: false,
      enoughBalance: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    newColaborators () {
      return this.suiteEmployees.length > this.existingPopulation.length && this.diffEvaluated.length
        ? this.diffEvaluated
        : []
    }
  },
  watch: {
    diffEvaluated: {
      handler (val) {
        if (val.length) {
          this.currentCulture.population = val
        }
      }
    }
  },
  methods: {
    getSuiteEmployees () {
      this.$store.dispatch('loading/show')
      EmployeesServices.listActive()
        .then(res => {
          this.suiteEmployees = res.items.map((emp) => ({
            indEmpId: emp.employeeId,
            indEmpEntId: emp.id
          }))
          this.mapDiffEvaluated()
        })
        .finally(() => {
          this.$store.dispatch('loading/hide')
        })
    },
    mapDiffEvaluated () {
      this.diffEvaluated = this.suiteEmployees.filter(suiteEmp => {
        return !this.existingPopulation.some(x => suiteEmp.indEmpId === x.indEmpId && suiteEmp.indEmpEntId === x.indEmpEntId)
      })
    },
    getcurrentCulture () {
      currentCultureService.getOneToEdit(this.$route.params.id)
        .then(({ assessment, population }) => {
          const status = assessment.status
          if (status === 'completed' || status === 'cancelled') { this.$router.go(-1) }
          this.responseParse(assessment)
          this.existingPopulation = population
          this.getSuiteEmployees()
        })
    },
    responseParse (res) {
      this.currentCulture.name = res.name
      this.currentCulture.displayName = res.displayName
      this.currentCulture.switchName = this.currentCulture.displayName
      this.currentCulture.timeZone = res.timeZone
      this.currentCulture.status = res.status
      this.currentCulture.releaseAt = this.getDatecurrentCulture(res.releaseAt)
      this.currentCulture.deadlineAt = this.getDatecurrentCulture(res.deadlineAt)
      this.currentCulture.pollInvitation = res.customEmailRelease
      this.currentCulture.reminderMail = res.customReminderEmail
      this.currentCulture.thankMessage = res.customEmailDeadline.body
      this.currentCulture.enterprise = res.enterprise
      this.currentCulture.enterpriseId = res.enterpriseId
      this.populationCount = res.populationCount
      this.currentCulture.questionnaire = res.questionnaire.slug
      if (res.reminders && res.reminders.length > 0) {
        // this.currentCulture.reminderMail = res.reminders[0].customEmail
        res.reminders.forEach((element, i) => {
          this.currentCulture.switchDate = true
          this.currentCulture.reminders[i] = this.getDatecurrentCulture(element.dateTime)
        })
      }
    },
    getDatecurrentCulture (ref) {
      if (!ref) {
        return {
          value: '',
          hour: '23:00'
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0] + ':00'
      }
    },
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/current-culture-assessments/list')
        case 5: return this.diffEvaluated.length ? this.confirm() : this.update()
        default: this.step = step
      }
    },
    deleteInvitationFile () {
      delete this.currentCulture.pollInvitation.attachment
      this.currentCulture.invitationFileFlag = false
    },
    deleteReminderFile () {
      delete this.currentCulture.reminderMail.attachment
      this.currentCulture.reminderFileFlag = false
    },
    getBalance () {
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          this.balance = res.balance
          this.getServicePrice()
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    getServicePrice () {
      return servicePriceService.getOne('Cultura Actual')
        .then((res) => {
          this.getServicePriceFromSuite(res)
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    getServicePriceFromSuite (service) {
      return servicePriceService.getByCodeFromSuite(service.code)
        .then((res) => {
          this.currentCulture.totalPrice = res.tokenPrice * this.currentCulture.population.length
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    confirm () {
      this.showModalConfirm = true
    },
    verifySpend (data) {
      if (data === 1) {
        return this.update()
      }
      this.showModalConfirm = false
    },
    update () {
      this.loading = true
      let pollInvitationFile = null
      let reminderMailFile = null

      if (this.currentCulture.pollInvitation.file && typeof this.currentCulture.pollInvitation.file === 'object') {
        pollInvitationFile = this.currentCulture.pollInvitation.file
        this.currentCulture.pollInvitation.file = this.currentCulture.pollInvitation.file.name
      }
      if (this.currentCulture.reminderMail.file && typeof this.currentCulture.reminderMail.file === 'object') {
        reminderMailFile = this.currentCulture.reminderMail.file
        this.currentCulture.reminderMail.file = this.currentCulture.reminderMail.file.name
      }
      const data = JSON.parse(JSON.stringify(this.currentCulture))
      delete data.population
      return currentCultureService.update(this.$route.params.id, data)
        .then((res) => {
          return pollInvitationFile ? currentCultureService.sendInvitationFiles(this.$route.params.id, { pollInvitationFile }) : Promise.resolve()
        })
        .then((res) => {
          return reminderMailFile ? currentCultureService.sendReminderFiles(this.$route.params.id, { reminderFile: reminderMailFile }) : Promise.resolve()
        })
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('currentCulture.edit'))
          this.loading = false
          this.$router.push('/current-culture-assessments/list')
        })
        .catch((err) => {
          this.loading = false
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    initLoad () {
      this.getcurrentCulture()
      this.getBalance()
    }
  },
  created () {
    this.initLoad()
  }
}
</script>
