
export default {
  add: 'Create',
  id: 'ID',
  name: 'Name',
  status: 'Status',
  participants: 'Participants',
  period: 'Period',
  action: 'Actions',
  no_data: 'No information to show',
  new: 'New Current Culture Survey',
  overview: 'Survey Name',
  date: 'Date',
  questions: 'Questionnaire',
  revition: 'Revision',
  poll_name: 'Survey Name',
  want_external_name: 'Do you want to use an external name for the respondents?',
  external_name: 'External Name',
  want_to_send: 'Who do you want to send this survey to?',
  hours: 'Time sent',
  hours_valid_until: 'Valid until',
  minutes: 'Minute',
  date_delivery: 'Launch date',
  poll_valid_until: 'Survey valid until',
  time_zone: 'Time zone',
  want_send_reminders: 'Do you want to send reminders?',
  reminders: 'Send reminder',
  reminders_info: 'The reminder will be sent to participating employees who have not completed the survey.',
  want_open_question: 'Do you want to add additional open questions?',
  open_question: 'Open questions',
  add_open_question: 'Add another open question',
  remove_open_question: 'Delete last open question',
  min_open_question: 'You must have at least one open question available',
  max_open_question: 'You can only have {n} open questions per survey',
  insert_question: 'Insert content of question {n}',
  insert_question_option: 'Insert option {o} for question {n}',
  add_question_option: 'Add another option',
  remove_question_option: 'Delete option',
  min_question_option: 'You must have at least {n} options available',
  max_question_option: 'You can only indicate {n} options for an open question',
  total_receptors: 'Total respondents: {n}',
  population_diff_singular: 'With the latest Collaborators update, (1) new participant will be added when confirming the assessment edition.',
  population_diff_plural: 'With the latest Collaborators update, ({n}) new participants will be added when confirming the assessment edition.',
  porcent_total: 'Percentage of the total to be taken',
  general_revition: 'General Review',
  settings: 'Settings',
  cost: 'Survey cost:',
  poll_paid: 'Survey has already been paid for.',
  inter_name: 'Internal name',
  send_reminders: 'Dispatch of reminders',
  personalization: 'Email personalization',
  poll_invitation: 'survey invitation',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message Subject',
  body_message: 'Message body',
  message_from_the_company: 'Message from the company',
  automatic_message: 'This message was sent automatically from',
  created_current_culture: 'Current Culture Survey successfully created! You will be redirected to the summary of the operation shortly',
  filter_by: 'Filter by...',
  look_for: 'Find a survey',
  engagement_realized: 'Engagements performed',
  add_engagement: 'Create Engagement',
  creating: 'Creating',
  pending: 'Pending',
  completed: 'Completed',
  cancelled: 'Cancelled',
  progress: 'In progress',
  in_progress: 'In progress',
  building: 'Under construction',
  at: 'At',
  download: 'Download',
  aprogress: 'Progress',
  edit: 'Survey edited successfully!',
  tooltip_name: 'Internal name of the survey',
  tooltip_display_name: 'Survey name for respondents',
  summary: 'Survey Summary',
  to_edit: 'Edit survey',
  total_progress: 'Total progress',
  end_date: 'Closing date',
  poll_completed: 'Surveys completed',
  poll_no_completed: 'Surveys not completed',
  resend: 'Resend uncompleted Surveys',
  resend_success: 'Surveys resent successfully!',
  question_n: '{n} Questions',
  no_actions: 'Not available',
  all_right: 'All rights reserved 2020. ©',
  questionnaire_e: 'Culture Questionnaire',
  questionnaire: 'Questionnaire',
  closed_poll: 'Survey closed successfully',
  associate_required_culture: 'Associate required culture',
  associate_required_culture_title: 'Associate Required Culture',
  associate_required_culture_desc: 'Select a previously performed Required Culture measure in order to compare it in the reports of this Current Culture measure.',
  associate_required_culture_select: 'Select a Required Culture:',
  associate_saved: 'Association saved.',
  no_questionnaries_title: 'You cannot continue creating this survey',
  results: 'Results',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'There are no active questionnaires for your company. Consequently, you cannot continue to create surveys of this type. Please contact the system administrator to solve this problem.',
  pdf_explained: 'In this file you can see the questions belonging to the "{name}" questionnaire grouped by dimension and attribute.',
  requested: 'Expected',
  received: 'Received',
  confirm_create_title: 'Confirm assessment creation',
  assessment_cost: 'Assessment cost',
  balance_after: 'Balance after creating measurement:',
  no_current_culture: 'No records of current culture',
  go_creation: 'Go to creation of current culture',
  answers_percent: 'Percentage of responses:',
  resume: 'Summary of current culture',
  fileExist: 'Attached video',
  deleteFile: 'Delete file',
  fileExistNote: 'To replace the attached file it is not necessary to delete the previous one.',
  formatDateAt: 'at',
  default_body: '<p class="ql-align-justify">Welcome to the Current Culture assessment process with the OCC tool which will allow us to obtain valuable information about the Organizational Culture of our company. Key facts about this survey: The assessment takes less than 30 minutes. The information provided will be treated as confidential and will be used for statistical purposes. For a better experience, use Google Chrome or Internet Explorer 11 or higher and remember that you must be connected to the internet. If you have any questions or problems with the survey, please do not hesitate to contact us at: info@occsolutions.org "Remember that this link is personal and must not be shared. We thank you in advance for your time and valuable contributions in this important process.</p>',
  default_body_r: '<p class="ql-align-justify">Hello again. Remember to complete the Current Culture assessment process with the OCC tool, which will allow us to obtain valuable information about the Organizational Culture of our company. Key facts about this survey: The assessment takes less than 30 minutes. The information provided will be treated as confidential and will be used for statistical purposes. For a better experience, use Google Chrome and remember that you must be connected to the internet. If you have any questions or problems with the survey, please do not hesitate to contact us at: info@occsolutions.org "Remember that this link is personal and must not be shared. We thank you in advance for your time and valuable contributions in this important process.</p>'
}
