
function getIdx (max, min) {
  return Math.floor(Math.random() * (max - min) + min)
}

export default (vueInstance, options) => {
  options = options || {}
  const min = options.min && typeof options.min === 'number' ? options.min - 1 : -4
  const max = options.max && typeof options.max === 'number' ? options.max - 1 : 4
  vueInstance.tempSelectedAnswers = JSON.parse(JSON.stringify(vueInstance.selected))
  const devSelected = vueInstance.tempSelectedAnswers.dimension
  const devAdditionals = vueInstance.tempSelectedAnswers.additional
  const lastPage = vueInstance.pages.length - 1
  vueInstance.rndOnce = true

  for (const dimension of devSelected) {
    for (const attr of dimension.attributes) {
      attr.selected = true
      attr.score = getIdx(max, min)
    }
  }
  vueInstance.tempSelectedAnswers.dimension = devSelected

  for (const additional of devAdditionals) {
    const thisAdditionalOptions = vueInstance.pages[lastPage].find(x => x.question === additional.question).options
    const randomOpt = thisAdditionalOptions[Math.floor(Math.random() * thisAdditionalOptions.length)]
    additional.answer[0] = randomOpt
  }
  vueInstance.tempSelectedAnswers.additional = devAdditionals

  vueInstance.saveCurrentAnswers()
  vueInstance.currentPage = lastPage
  vueInstance.handlepaginate(1)
}
