
export default {
  methods: {
    $generateDispersionRanking () {
      let highestDispersionByAttributesTable = []
      let lowestDispersionByAttributesTable = []
      for (let i = 0; i < 8; i++) {
        // Generate table of the 8 highest dispersion by attributes
        highestDispersionByAttributesTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.attributesRanking.find(attribute => attribute.attrIndex === this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].idx).score.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].actScore - this.attributesRanking.find(attribute => attribute.attrIndex === this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].idx).score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.highestDispersionByAttributes[this.highestDispersionByAttributes.length - (i + 1)].dispersion, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
        // Generate table of the 8 lowest dispersion by attributes
        lowestDispersionByAttributesTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.lowestDispersionByAttributes[i].limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.lowestDispersionByAttributes[i].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.lowestDispersionByAttributes[i].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [20, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: this.lowestDispersionByAttributes[i].actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.attributesRanking.find(attribute => attribute.attrIndex === this.lowestDispersionByAttributes[i].idx).score.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestDispersionByAttributes[i].actScore - this.attributesRanking.find(attribute => attribute.attrIndex === this.lowestDispersionByAttributes[i].idx).score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestDispersionByAttributes[i].dispersion, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.9 ${this.$t('requiredActualReport.higher_dispersion_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            }
            // Atributos con puntaje mas alto
            // {
            //   layout: {
            //     defaultBorder: '',
            //     fillColor: '#BFBFBF'
            //   },
            //   table: {
            //     body: [
            //       [{ text: this.$t('cultureReports.attributes_with_higher_dispersion'), margin: [30, 5, 115, 5], bold: true, color: '#fff' }]
            //     ]
            //   },
            //   margin: [50, -30, -40, 10]
            // }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dispersion'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...highestDispersionByAttributesTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 10.5
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.9 ${this.$t('requiredActualReport.lower_dispersion_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            }
            // Atributos con puntaje mas bajo
            // {
            //   layout: {
            //     defaultBorder: '',
            //     fillColor: '#BFBFBF'
            //   },
            //   table: {
            //     body: [
            //       [{ text: this.$t('cultureReports.attributes_with_lower_dispersion'), margin: [30, 5, 112, 5], bold: true, color: '#fff' }]
            //     ]
            //   },
            //   margin: [50, -30, -40, 10]
            // }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dispersion'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...lowestDispersionByAttributesTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 10.5
        }
      ]
    }
  }
}
