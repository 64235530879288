
export default {
  methods: {
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    $generateRanking () {
      const greatestDimensionAvg = this.dimensionAverage.sort((a, b) => b.score - a.score)
      this.attributesRanking = this.attributesArray.sort((a, b) => b.score - a.score)
      let highestAttributesCurrent = []
      let lowestAttributesCurrent = []
      let highestAttributesRequired = []
      let lowestAttributesRequired = []
      for (let i = this.highestScoresByAttributes.length - 1; i >= 0; i--) {
        const requiredAttribute = this.attributesRanking.find(attribute => this.highestScoresByAttributes[i].idx === attribute.attrIndex)
        highestAttributesCurrent.push([
          {
            text: this.highestScoresByAttributes.length - i,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.highestScoresByAttributes[i].limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.highestScoresByAttributes[i].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.highestScoresByAttributes[i].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: this.highestScoresByAttributes[i].actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(requiredAttribute.score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.highestScoresByAttributes[i].actScore - requiredAttribute.score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious ? this.highestScoresByAttributes[i].tendencies.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      for (let j = 0; j < this.lowestScoresByAttributes.length; j++) {
        const requiredAttributes = this.attributesRanking.find(attribute => this.lowestScoresByAttributes[j].idx === attribute.attrIndex)
        lowestAttributesCurrent.push([
          {
            text: j + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.lowestScoresByAttributes[j].limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.lowestScoresByAttributes[j].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.lowestScoresByAttributes[j].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: this.lowestScoresByAttributes[j].actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(requiredAttributes.score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestScoresByAttributes[j].actScore - requiredAttributes.score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious ? this.lowestScoresByAttributes[j].tendencies.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      for (let k = 0; k < 8; k++) {
        const dataByAttributes = this.questionDataByAttributes.find(attribute => this.attributesRanking[k].attrIndex === attribute.idx)
        highestAttributesRequired.push([
          {
            text: k + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: dataByAttributes.limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: dataByAttributes.positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.attributesRanking[k].dimension}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: dataByAttributes.actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.attributesRanking[k].score.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(dataByAttributes.actScore - this.attributesRanking[k].score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: !this.hasPrevious ? '--' : dataByAttributes.tendencies.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
        const lowerDataByAttributes = this.questionDataByAttributes.find(attribute => this.attributesRanking[this.attributesRanking.length - (k + 1)].attrIndex === attribute.idx)
        lowestAttributesRequired.push([
          {
            text: k + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: lowerDataByAttributes.limiting[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: lowerDataByAttributes.positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - (k + 1)].dimension}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: lowerDataByAttributes.actScore.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.attributesRanking[this.attributesRanking.length - (k + 1)].score.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(lowerDataByAttributes.actScore - this.attributesRanking[this.attributesRanking.length - (k + 1)].score, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious ? lowerDataByAttributes.tendencies.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      return [
        // 4.5 Ranking dimensiones
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('cultureReports.dimensions_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        // Puntaje mas alto
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_with_higher_score'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t('requiredActualReport.dimension'), margin: [50, 5, 50, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.actual'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.required'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.gap'), margin: [15, 5, 20, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.highestScoresByDimensions[2].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.highestScoresByDimensions[2].average - this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.highestScoresByDimensions[1].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.highestScoresByDimensions[1].average - this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.highestScoresByDimensions[0].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.highestScoresByDimensions[0].average - this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ]
            ]
          },
          margin: [15, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.required_dimensions_with_higher_score'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t('requiredActualReport.dimension'), margin: [50, 5, 50, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.actual'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.required'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.gap'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[0].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[0].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[0].name).average - greatestDimensionAvg[0].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[1].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[1].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[1].name).average - greatestDimensionAvg[1].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[2].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[2].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[2].name).average - greatestDimensionAvg[2].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ]
            ]
          },
          margin: [15, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Puntaje mas bajo
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('cultureReports.dimensions_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_with_lower_score'), margin: [50, 5, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t('requiredActualReport.dimension'), margin: [50, 5, 50, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.actual'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.required'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.gap'), margin: [15, 5, 20, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.lowestScoresByDimensions[0].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.lowestScoresByDimensions[0].average - this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.lowestScoresByDimensions[1].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.lowestScoresByDimensions[1].average - this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.lowestScoresByDimensions[2].average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.lowestScoresByDimensions[2].average - this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ]
            ]
          },
          margin: [15, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.required_dimensions_with_lower_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t('requiredActualReport.dimension'), margin: [50, 5, 50, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.actual'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.required'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredActualReport.gap'), margin: [15, 5, 15, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[8].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[8].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[8].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[8].name).average - greatestDimensionAvg[8].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[7].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[7].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[7].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[7].name).average - greatestDimensionAvg[7].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${greatestDimensionAvg[6].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 15, 0, 15] },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[6].name).average, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(greatestDimensionAvg[6].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' },
                { text: this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === greatestDimensionAvg[6].name).average - greatestDimensionAvg[6].score, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 15, 0, 15], alignment: 'center' }
              ]
            ]
          },
          margin: [15, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Ranking cultura actual
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_higher_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              // Highest Current Attributes Ranking Table
              ...highestAttributesCurrent
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_lower_score'), margin: [50, 5, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              // Lowest Current Attributes Ranking Table
              ...lowestAttributesCurrent
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Ranking Cultura requerida
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.required_ranking_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_higher_score'), margin: [50, 5, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              // Highest Required Attributes Ranking Table
              ...highestAttributesRequired
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.required_ranking_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_lower_score'), margin: [50, 5, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 0, 5],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              // Lowest Required Attributes Ranking Table
              ...lowestAttributesRequired
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        }
      ]
    }
  }
}
