<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid py-0 fill-height>
        <v-row class="fill-height" align="center">
          <v-col cols="12"
            class="pa-0 pt-12 text-center grey--text text--darken-1 justify-center"
          >
            <img src="/img/alerta.png" width="214"/>
            <h2 class="display-3 mt-5 error--text text--darken-1">{{ $t('errors.wait') }}</h2>
            <p class="headline mt-9" v-html="message"></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    message: String,
    backButton: Boolean
  }
}
</script>
