<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1">{{ $t('currentCulture.new') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" >
            <v-card class="mt-4">
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <x-poll-steps-overview-current-culture
                  :currentCulture="currentCulture"
                  :total-receptors="populationCount"
                  v-show="step === 1"
                  step="1"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview-current-culture>
                <x-poll-steps-date-current-culture
                  :currentCulture="currentCulture"
                  v-show="step === 2"
                  step="2"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date-current-culture>

                <x-step-question-current-culture
                  v-show="step === 3"
                  :currentCulture="currentCulture"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-question-current-culture>

                <x-step-revition-current-culture
                  v-show="step === 4"
                  :currentCulture="currentCulture"
                  step="4"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-revition-current-culture>
              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <x-loading v-if="loading" :display="loading"/>
      <x-confirm-spend-dialog
        :confirmText="$t('currentCulture.confirm_create_title')"
        :costText="$t('currentCulture.assessment_cost')"
        :balanceAfterText="$t('currentCulture.balance_after')"
        :showModalConfirm="showModalConfirm"
        :balance="balance"
        :price="currentCulture.totalPrice"
        :noActiveEmployee="noActiveEmployee"
        :noBalanceResponse="noBalanceResponse"
        :disableButtonModal="disableButton && !this.noActiveEmployee"
        @result="verifySpend"
        :userType="user.customer.type"
        @update="update">
      </x-confirm-spend-dialog>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'

import currentCultureService from '../../services/current-culture-assessment'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees-services'

import XStepQuestionCurrentCulture from './steps/question.vue'
import XStepRevitionCurrentCulture from './steps/revition.vue'

export default {
  components: {
    XStepQuestionCurrentCulture,
    XStepRevitionCurrentCulture
  },
  data () {
    return {
      stepperHeaders: [
        'currentCulture.overview',
        'currentCulture.date',
        'currentCulture.questions',
        'currentCulture.revition'
      ],
      createdCurrentCultureId: null,
      currentCulture: {
        name: '',
        displayName: '',
        enterprise: {},
        enterpriseId: 0,
        population: [],
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        releaseAt: {
          value: '',
          hour: '23:00'
        },
        deadlineAt: {
          value: '',
          hour: '23:00'
        },
        emailData: '',
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        switchName: false,
        switchQuestion: false,
        switchDate: false,
        questionnaire: '',
        questionnaires: [],
        porcent_total: 0,
        opens: [{
          question: '',
          options: ['', '']
        }],
        pollInvitation: {
          subject: '',
          body: '',
          file: ''
        },
        reminderMail: {
          subject: '',
          body: '',
          file: ''
        },
        thankMessage: '',
        active: null,
        offset: '',
        price: 0,
        totalPrice: 0
      },
      populationCount: 0,
      step: 1,
      dialog: {
        show: false,
        msg: ''
      },
      loading: false,
      price: 0,
      balance: 0,
      showModalConfirm: false,
      noActiveEmployee: false,
      noBalanceResponse: false,
      isEdit: false,
      disableButton: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/current-culture-assessments/list')
        case 5: return this.toConfirm()
        default: this.step = +step
      }
    },
    verifySpend (data) {
      if (data === 1) {
        return this.create()
      }
      this.showModalConfirm = false
    },
    update () {
      this.showModalConfirm = false
      this.loading = true
      this.initForm()
      this.showModalConfirm = true
    },
    toConfirm () {
      this.showModalConfirm = true
    },
    create () {
      if (this.noActiveEmployee) {
        return
      }
      this.loading = true
      this.disableButton = false
      const pollInvitationFile = this.currentCulture.pollInvitation.file
      const reminderFile = this.currentCulture.reminderMail.file
      let data = JSON.parse(JSON.stringify(this.currentCulture))
      if (this.user.enterprise) {
        data.enterprise = this.user.enterprise
        data.enterpriseId = this.user.enterprise.id
      }
      delete data.population
      return currentCultureService.create(data)
        .then((res) => {
          if (!res._id) {
            if (res.status === 401) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
            } else {
              this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            }
            this.loading = false
            this.disableButton = true
            return Promise.reject(this.$t('errors.no_balance'))
          }
          return pollInvitationFile ? currentCultureService.sendInvitationFiles(res._id, { pollInvitationFile }).then(() => res).catch(() => { this.$store.dispatch('alert/error', this.$t('errors.uploadInvitationError')); setTimeout(this.redirectSummary, 3000) }) : Promise.resolve(res)
        })
        .then((res) => {
          return reminderFile
            ? currentCultureService.sendReminderFiles(res._id, { reminderFile })
              .then(() => res)
              .catch(() => {
                this.$store.dispatch('alert/error', this.$t('errors.uploadReminderError'))
                // setTimeout(this.redirectSummary, 3000)
              })
            : Promise.resolve(res)
        })
        .then((res) => {
          if (res) {
            this.$store.dispatch('alert/success', this.$t('currentCulture.created_current_culture'))
            this.disableButton = false
            this.createdCurrentCultureId = res._id
            setTimeout(this.redirectSummary, 3000)
          }
          return false
        })
        .catch((err) => {
          this.loading = false
          this.disableButton = true
          let errorMsg = this.$t(`errors.${err.code}`)
          if (errorMsg === `errors.${err.code}` && err.code.indexOf('suite-fail/') !== -1) {
            let errorMsg = this.$t('errors.suite-fail/not-found')
          }
          this.$store.dispatch('alert/error', errorMsg)
        })
    },
    initForm () {
      if (this.user.enterprise) {
        this.currentCulture.enterprise = {
          id: this.user.enterprise.id,
          customerId: this.user.enterprise.customerId,
          name: this.user.enterprise.name,
          active: this.user.enterprise.active,
          createdAt: this.user.enterprise.createdAt,
          updatedAt: this.user.enterprise.updatedAt,
          logo: this.user.enterprise.logo
        }
        this.currentCulture.enterpriseId = this.user.enterprise.id
        resolver.all({
          population: EmployeesServices.listActive().then(res => res.items),
          balance: balanceService.getBalanceFromSuite().then(res => res.balance),
          servicePrice: servicePriceService.getOne('Cultura Actual').then(res => res)
        })
          .then((res) => {
            this.balance = res.balance
            this.currentCulture.population = res.population
            this.populationCount = res.population.length
            return servicePriceService.getByCodeFromSuite(res.servicePrice.code)
          })
          .then(res => {
            this.currentCulture.price = res.tokenPrice
            this.noActiveEmployee = !this.currentCulture.population || !this.currentCulture.population.length
            this.currentCulture.totalPrice = res.tokenPrice * (this.currentCulture.population ? this.currentCulture.population.length : 0)
            this.loading = false
          })
          .catch((err) => {
            this.dialog.show = true
            this.dialog.msg = this.$t(`errors.${err.code}`)
          })
      }
    },
    redirectSummary () {
      this.loading = false
      this.$router.push(`/operation-summary/actual/${this.createdCurrentCultureId}`)
    }
  },
  created () {
    this.initForm()
  }
}
</script>
