<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1">{{ $t('culturePulses.new') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-4">
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <x-poll-steps-overview-current-culture
                  :currentCulture="culturePulse"
                  v-show="step === 1"
                  step="1"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview-current-culture>

                <ValidationObserver ref="step2">
                  <x-step-receptors-culture-pulse
                    :culturePulse="culturePulse"
                    v-show="step === 2"
                    :employeesCount="populationCount"
                    :is-edit="isEdit"
                    step="2"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @parentLoading="parentLoading"
                    @changeStep="verifyStepChanged"
                  ></x-step-receptors-culture-pulse>
                </ValidationObserver>

                <x-poll-steps-date-current-culture
                  :currentCulture="culturePulse"
                  v-show="step === 3"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date-current-culture>

                <x-step-question-culture-pulse
                  :culturePulse="culturePulse"
                  v-show="step === 4"
                  step="4"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-question-culture-pulse>

                <x-step-revition-culture-pulse
                  :culturePulse="culturePulse"
                  v-show="step === 5"
                  step="5"
                  :key="step"
                  nextAction="input.confirm_save"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-revition-culture-pulse>
              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <x-loading v-if="loading" :display="loading"/>
      <x-confirm-spend-dialog
        :confirmText="$t('culturePulses.confirm_create_title')"
        :costText="$t('culturePulses.assessment_cost')"
        :balanceAfterText="$t('culturePulses.balance_after')"
        :showModalConfirm="showModalConfirm"
        :balance="balance"
        :price="culturePulse.totalPrice"
        :noActiveEmployee="noActiveEmployee"
        :noBalanceResponse="noBalanceResponse"
        :disableButtonModal="disableButton && !this.noActiveEmployee"
        @result="verifySpend"
        :userType="user.customer.type"
        @update="update">
      </x-confirm-spend-dialog>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'

import culturePulseService from '../../services/culture-pulse'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees-services'

import XStepReceptorsCulturePulse from './steps/receptors.vue'
import XStepQuestionCulturePulse from './steps/question.vue'
import XStepRevitionCulturePulse from './steps/revition.vue'

export default {
  components: {
    XStepReceptorsCulturePulse,
    XStepQuestionCulturePulse,
    XStepRevitionCulturePulse
  },
  data () {
    return {
      stepperHeaders: [
        'culturePulses.overview',
        'culturePulses.receptors',
        'culturePulses.date',
        'culturePulses.questions',
        'culturePulses.revition'
      ],
      createdCulturePulseId: null,
      culturePulse: {
        name: '',
        displayName: '',
        selectionType: 1,
        totalReceivers: 0,
        enterprise: {},
        enterpriseId: 0,
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        releaseAt: {
          value: '',
          hour: '23:00'
        },
        deadlineAt: {
          value: '',
          hour: '23:00'
        },
        emailData: '',
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        switchName: false,
        switchDate: false,
        switchCurrentCultureQuestionnaire: false,
        questionnaire: '',
        questionnaires: [],
        selectedDimensions: {},
        switchAdditionalQuestion: false,
        additionalQuestions: [{
          question: '',
          options: ['', '']
        }],
        pollInvitation: {
          subject: '',
          body: '',
          file: ''
        },
        reminderMail: {
          subject: '',
          body: '',
          file: ''
        },
        thankMessage: '',
        active: null,
        offset: '',
        price: 0,
        totalPrice: 0,
        demographicItems: {},
        departmentIds: [],
        chargeIds: [],
        academicDegreeIds: [],
        jobTypeIds: [],
        rangeAge: [],
        rangeAntiquity: [],
        genderId: '',
        countrySelect: [],
        headquarterSelect: [],
        additionalDemographic1Ids: [],
        additionalDemographic2Ids: []
      },
      populationCount: 0,
      step: 1,
      dialog: {
        show: false,
        msg: ''
      },
      loading: false,
      balance: 0,
      showModalConfirm: false,
      noActiveEmployee: false,
      noBalanceResponse: false,
      disableButton: true,
      isEdit: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  watch: {
    //
  },
  methods: {
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/culture-pulses/list')
        case 6: return this.toConfirm()
        default: this.step = +step
      }
    },
    verifySpend (data) {
      if (data === 1) {
        return this.createCulturePulse()
      }
      this.showModalConfirm = false
    },
    update () {
      this.showModalConfirm = false
      this.loading = true
      this.initForm()
      this.showModalConfirm = true
    },
    toConfirm () {
      this.showModalConfirm = true
    },
    createCulturePulse () {
      if (this.noActiveEmployee) {
        return
      }
      this.loading = true
      this.disableButton = false
      const pollInvitationFile = this.culturePulse.pollInvitation.file
      const reminderFile = this.culturePulse.reminderMail.file
      let payload = JSON.parse(JSON.stringify(this.culturePulse))
      if (this.user.enterprise) {
        payload.enterprise = this.user.enterprise
        payload.enterpriseId = this.user.enterprise.id
      }
      delete payload.questionnaires
      delete payload.demographicItems
      return culturePulseService.create(payload)
        .then((res) => {
          if (!res._id) {
            if (res.status === 401) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
            } else {
              this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            }
            this.loading = false
            this.disableButton = true
            return Promise.reject(this.$t('errors.no_balance'))
          }
          return pollInvitationFile ? culturePulseService.sendInvitationFiles(res._id, { pollInvitationFile }).then(() => res).catch(() => { this.$store.dispatch('alert/error', this.$t('errors.uploadInvitationError')); setTimeout(this.redirectSummary, 3000) }) : Promise.resolve(res)
        })
        .then((res) => {
          return reminderFile
            ? culturePulseService.sendReminderFiles(res._id, { reminderFile })
              .then(() => res)
              .catch(() => {
                this.$store.dispatch('alert/error', this.$t('errors.uploadReminderError'))
              })
            : Promise.resolve(res)
        })
        .then((res) => {
          if (res) {
            this.$store.dispatch('alert/success', this.$t('culturePulses.created_culture_pulse'))
            this.disableButton = false
            this.createdCulturePulseId = res._id
            setTimeout(this.redirectSummary, 3000)
          }
          return false
        })
        .catch((err) => {
          this.loading = false
          this.disableButton = true
          let errorMsg = this.$t(`errors.${err.code}`)
          if (errorMsg === `errors.${err.code}` && err.code.indexOf('suite-fail/') !== -1) {
            let errorMsg = this.$t('errors.suite-fail/not-found')
          }
          this.$store.dispatch('alert/error', errorMsg)
        })
    },
    initForm () {
      if (this.user.enterprise) {
        this.culturePulse.enterprise = {
          id: this.user.enterprise.id,
          customerId: this.user.enterprise.customerId,
          name: this.user.enterprise.name,
          active: this.user.enterprise.active,
          createdAt: this.user.enterprise.createdAt,
          updatedAt: this.user.enterprise.updatedAt,
          logo: this.user.enterprise.logo
        }
        this.culturePulse.enterpriseId = this.user.enterprise.id
        resolver.all({
          population: EmployeesServices.getParticipantsCount().then(res => res.cid),
          balance: balanceService.getBalanceFromSuite().then(res => res.balance),
          servicePrice: servicePriceService.getOne('Pulsos en Cultura').then(res => res)
        })
          .then((res) => {
            this.balance = res.balance
            this.populationCount = res.population
            this.culturePulse.totalReceivers = this.populationCount
            return servicePriceService.getByCodeFromSuite(res.servicePrice.code)
          })
          .then(res => {
            this.culturePulse.price = res.tokenPrice
            this.noActiveEmployee = !this.populationCount
            this.culturePulse.totalPrice = res.tokenPrice * (this.populationCount ? this.populationCount : 0)
            this.loading = false
          })
          .catch((err) => {
            this.dialog.show = true
            this.dialog.msg = this.$t(`errors.${err.code}`)
          })
      }
    },
    parentLoading (val) {
      this.loading = val
    },
    redirectSummary () {
      this.loading = false
      this.$router.push(`/operation-summary/pulse/${this.createdCulturePulseId}`)
    }
  },
  created () {
    this.initForm()
  }
}
</script>
