
<template>
  <v-container fluid>
    <v-row v-if="poll.name">
      <v-col cols="12">
        <h4 class="display-1 left break-name d-inline">{{ poll.name }}</h4>
        <v-chip disabled
          v-if="poll.displayName"
          color="primary"
          class="mt-n3 ml-4 white--text right break-name"
        >
          {{ poll.displayName }}
        </v-chip>
      </v-col>

      <v-col cols="12">
        <v-card>
         <v-toolbar flat>
            <v-toolbar-title>Reporte de Cultura Actual</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pa-0">
            <!-- Power Bi Embed -->
            <section
              id="embedreport"
              :style="{ height: reportHeight + 'px' }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <x-loading
      :display.sync="loadingPoll"
    ></x-loading>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import * as pbi from 'powerbi-client'
import powerBiService from '../../services/power-bi'

import currentCultureService from '../../services/current-culture-assessment'

export default {
  components: {
    //
  },
  data () {
    return {
      poll: {},
      loadingPoll: false,
      report: null,
      token: '',
      embedUrl: '',
      reportHeight: 600
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getPoll()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      const embedContainer = document.getElementById('embedreport')
      this.reportHeight = Math.round(embedContainer.offsetWidth / 1.5665)
    },
    getPoll () {
      this.loadingPoll = true
      currentCultureService.getOneById(this.$route.params.id)
        .then((res) => {
          this.poll = res
          if (this.poll.status !== 'completed') {
            const objError = { code: 'culture-pulse/assessment-still-progress' }
            throw (objError)
          } else {
            this.generateEmbed()
          }
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          setTimeout(() => {
            this.$router.back()
          }, 2000)
          setTimeout(() => {
            this.loadingPoll = false
          }, 2400)
        })
    },
    generateEmbed () {
      powerBiService.getEmbedData(true)
        .then(res => {
          if (res.token && res.embedUrl) {
            this.token = res.token
            this.embedUrl = res.embedUrl
            this.loadEmbed()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getEnterpriseBaseFilter (table, values, column = 'enterpriseId') {
      return {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          $schema: 'http://powerbi.com/product/schema#column',
          table,
          column
        },
        operator: 'In',
        values
      }
    },
    loadEmbed () {
      const permissions = pbi.models.Permissions.All
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.token,
        embedUrl: this.embedUrl,
        pageView: 'fitToWidth',
        permissions: permissions,
        settings: {
          filterPaneEnabled: false
        }
      }

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      const embedContainer = document.getElementById('embedreport')
      this.report = powerbi.embed(embedContainer, config)

      const enterpriseId = [this.user.enterprise.id || 0]

      const enterpriseFilter1 = this.getEnterpriseBaseFilter('currentcultureassessments', enterpriseId)
      const enterpriseFilter2 = this.getEnterpriseBaseFilter('currentcultureassessmentanswers', enterpriseId)
      const enterpriseFilter3 = this.getEnterpriseBaseFilter('requiredcultures', [0, ...enterpriseId])
      const enterpriseFilter4 = this.getEnterpriseBaseFilter('Encuestas_Aplicadas', enterpriseId)

      this.report.on('loaded', async () => {
        this.report.setFilters([enterpriseFilter1, enterpriseFilter2, enterpriseFilter3, enterpriseFilter4])

        this.loadingPoll = false
        this.handleResize()
        this.report.off('loaded')
      })

      this.report.on('error', (err) => {
        console.log(err.detail ? err.detail : err)
        this.report.off('error')
      })
    }
  }
}
</script>
