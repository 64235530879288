
import Services from './base-services'

const service = new Services('token-account-detail')

export default {
  getBalanceFromSuite: () => {
    return service.suiteOperation(() => service.get('balance'))
  }
}
