
export default {
  title: 'Configuring Service',
  name: 'Service name',
  modality: 'Modality',
  periocity: 'Periodicity',
  periocities: {
    Annual: 'Annual',
    Biannual: 'Biannual',
    Quarterly: 'Quarterly',
    Monthly: 'Monthly',
    Biweekly: 'Bimonthly',
    Weekly: 'Weekly',
    Diary: 'Daily',
    OneTime: 'Once',
    ByDemand: 'On demand'
  },
  recomendation: 'Recommendation',
  price: 'Price in Tokens',
  modalities: {
    Download: 'Download',
    Person: 'Person',
    Medition: 'Measurement'
  }
}
