
export default {
  current_culture_title: 'Reporte Organizacional - Cultura Actual',
  sub_culture_title: 'Reporte Subcultura',
  current_culture_description: 'Este reporte agrupa las respuestas de todas las personas que respondieron la encuesta.',
  no_answers_modal_msg: 'La encuesta actual no posee respuestas, por lo cual no será posible generar un reporte.',
  general_report: 'Generar reporte Organizacional',
  download_report: 'Descargar reporte',
  view_report: 'Ver reporte',
  required_general_title: 'Reporte cultura requerida',
  required_general_desc: 'Este reporte cuenta con los resultados de la cultura requerida.',
  change_report: '¿Deseas generar un reporte por subcultura?',
  select_general_demographics_items: 'Selecciona uno de los demográficos para visualizar la tasa de respuesta dentro del reporte.',
  select_general_demographics_items_hint: 'Esta selección no afecta los puntajes del reporte general',
  subculture_title: 'Reportes por subcultura - Cultura Actual',
  subculture_description: 'En este reporte podrás escoger y filtrar los puntajes de los diferentes cortes demográficos que hayas configurado al momento de crear la medición.',
  subculture_description2: 'Este reporte cuenta con los puntajes de la población filtrada por los cortes demográficos que selecciones a continuación:',
  subculture_report_btn: 'Generar Reporte Subcultura',
  required_current_culture_title: 'Reporte Cultura Actual vs Cultura Requerida',
  required_sub_culture_title: 'Reporte Subcultura vs Cultura Requerida',
  required_current_culture_description: 'Reporte que posee estadísticas de toda la población incluída en la encuesta y la cultura requerida asignada.',
  required_actual_button: 'Generar Reporte',
  generated_reports: 'Reportes Generados',
  generated_reports_desc: 'En esta sección encontrarás todos los reportes que has generado.<br>Dependiendo del número de participantes cada reporte puede tardarse en generar hasta 30 minutos. Puedes actualizar el progreso recargando la página.<br>Se recomienda generar  un máximo de 5 reportes a la vez, para mejorar el rendimiento de la plataforma.',
  already_generated_report: 'Este reporte ya ha sido generado. Puedes descargarlo en la parte inferior, donde se encuentran todos los reportes generados.',
  need_demographic_cut: 'No seleccionó ningún corte demografico.',
  demographicCuts: {
    jobTypes: 'Tipo de contratación',
    headquarter: 'Sede',
    gender: 'Género',
    departments: 'Departamento/Área',
    country: 'País',
    charge: 'Cargo',
    antiquity: 'Rango de antigüedad',
    age: 'Rango de edad',
    academicDegree: 'Nivel académico',
    optionalDemo1: 'Demográfico adicional 1',
    optionalDemo2: 'Demográfico adicional 2'
  },
  demographics_response_rate: 'Demografía de tasa de respuesta',

  // Reporte resumen por cortes demográficos
  operation_init: 'Se ha dado inicio a un proceso de descarga "Reporte Resumen por Cortes Demográficos", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  demographic_summary: 'Reporte Resumen por Cortes Demográficos',
  demographic_summary_desc: 'Este reporte cuenta con los puntajes de las diferentes dimensiones segmentadas por cortes demográficos.',
  already_generated_summary: 'El reporte ya ha sido generado'
}
