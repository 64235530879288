
export default {
  title: 'Prices and recommendations',
  cost: 'It costs',
  rate: 'Tokens by',
  table_ranges: 'Token range',
  table_prices: 'Cost per token',
  medition: 'Survey',
  download: 'Download',
  person: 'Participant',
  recomendation: 'It is recommended',
  onetime: 'One time execution',
  annual: 'Annual',
  ondemand: 'On demand',
  ormore: 'Or more',
  services: 'SERVICES',
  recomendations: 'RECOMMENDATIONS'
}
