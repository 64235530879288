
<template>
  <v-container>
    <v-row>
      <!-- Current -->
      <v-col class="ml-2 mr-2">
        <v-card>
          <v-toolbar dense color="primary" class="white--text">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.resume_current') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" v-for="(header, k) in currentHeaders" :key="k"> {{ $t(`dashboard.${header}`) }} </th>
                  </tr>
                </thead>
                <tbody v-if="info.current && info.current.length">
                  <tr v-for="evaluation in info.current" :key="evaluation._id">
                    <td class="text-center">
                      {{ evaluation.displayName || evaluation.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.releaseAt | date }} {{ $t('dashboard.to') }} {{ evaluation.deadlineAt | date }}
                    </td>
                    <td class="text-center">
                      {{ $t(`evaluations.${evaluation.status}_status`) }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.enterprise.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.populationCount }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5">
                      <v-alert text dense prominent type="error" icon="mdi-shield-remove" class="text-xs-center mt-3">
                        <h3>{{ $t('no_data') }}</h3>
                      </v-alert>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Required -->
      <v-col class="ml-2 mr-2">
        <v-card>
          <v-toolbar dense color="primary" class="white--text">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.resume_required') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" v-for="(header, k) in requiredHeaders" :key="k"> {{ $t(`dashboard.${header}`) }} </th>
                  </tr>
                </thead>
                <tbody v-if="info.required && info.required.length">
                  <tr v-for="evaluation in info.required" :key="evaluation._id">
                    <td class="text-center">
                      {{ evaluation.displayName || evaluation.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.releaseAt | date }} {{ $t('dashboard.to') }} {{ evaluation.deadlineAt | date }}
                    </td>
                    <td class="text-center">
                      {{ $t(`evaluations.${evaluation.status}_status`) }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.enterprise.name }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">
                      <v-alert text dense prominent type="error" icon="mdi-shield-remove" class="text-xs-center mt-3">
                        <h3>{{ $t('no_data') }}</h3>
                      </v-alert>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Pulse -->
      <v-col class="ml-2 mr-2">
        <v-card>
          <v-toolbar dense color="primary" class="white--text">
            <v-toolbar-title class="text-uppercase">
              {{ $t('dashboard.resume_pulse') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" v-for="(header, k) in currentHeaders" :key="k"> {{ $t(`dashboard.${header}`) }} </th>
                  </tr>
                </thead>
                <tbody v-if="info.pulse && info.pulse.length">
                  <tr v-for="evaluation in info.pulse" :key="evaluation._id">
                    <td class="text-center">
                      {{ evaluation.displayName || evaluation.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.releaseAt | date }} {{ $t('dashboard.to') }} {{ evaluation.deadlineAt | date }}
                    </td>
                    <td class="text-center">
                      {{ $t(`evaluations.${evaluation.status}_status`) }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.enterprise.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.populationCount }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5">
                      <v-alert text dense prominent type="error" icon="mdi-shield-remove" class="text-xs-center mt-3">
                        <h3>{{ $t('no_data') }}</h3>
                      </v-alert>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Object
  },
  data () {
    return {
      currentHeaders: [
        'name',
        'dates',
        'status',
        'enterprise',
        'population'
      ],
      requiredHeaders: [
        'name',
        'dates',
        'status',
        'enterprise'
      ]
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.expired-plan{
  color: red;
  font-weight: bold;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.text-progress{
  color: #ad7816 !important;
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.no-color{
  color: #ffffff00;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}

.no-decoration{
  text-decoration: none;
}

.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
</style>
