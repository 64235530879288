<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1">{{ $t('questionnaires.question_questionnaire_edit') }}</h4>
      </v-col>
    </v-row>
    <v-row v-if="dimentions.length">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-tabs v-model="tab" background-color="transparent" light grow>
              <v-tab key="positive">{{ $t(`questionnaires.positive`) }}</v-tab>
              <v-tab key="limiting">{{ $t(`questionnaires.limiting`) }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="type in ['positive', 'limiting']" :key="type">
                <v-expansion-panels>
                  <v-expansion-panel v-for="dimention in dimentions" :key="dimention.name">
                    <v-expansion-panel-header> {{ $t(`questionnaires.dimensions.${dimention.name}`) }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-tooltip bottom color="blue">
                      <template v-slot:activator="{ on }" >
                        <v-text-field
                          light outlined
                          label="Pregunta"
                          name="question"
                          v-for="(question, i) in dimention.questions"
                          :key="i"
                          :input="question[type][user.lang]"
                          v-model="question[type][user.lang]"
                          @keypress.enter="sendForm"
                          @blur="sendForm"
                          v-on="on"
                          outline
                          append-icon="mdi-content-save-edit-outline"
                          color="blue"
                        ></v-text-field>
                      </template>
                      <span>
                        {{ $t('questionnaires.tooltip_edit') }}
                      </span>
                    </v-tooltip>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" align="end">
                <v-btn to="/questionnaires" text>{{ $t('input.back') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      questionnaire: null,
      dimentions: [],
      tab: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getQuestionnaire()
  },
  methods: {
    getQuestionnaire () {
      this.$store.dispatch('loading/show')
      return questionnairesService.getOne(this.$route.params.slug)
        .then((res) => {
          this.questionnaire = res
          this.dimentions.push(...this.questionnaire.cultureTypes.competitive)
          this.dimentions.push(...this.questionnaire.cultureTypes.humanist)
          this.dimentions.push(...this.questionnaire.cultureTypes.responsible)
          this.$store.dispatch('loading/hide')
        }).catch((err) => {
          this.$store.dispatch('loading/hide')
          return err
        })
    },
    sendForm () {
      this.$store.dispatch('loading/show')
      return questionnairesService.edit(this.questionnaire.slug, this.questionnaire)
        .then((res) => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/success', 'Cuestionario editado correctamente')
        }).catch((err) => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/error', 'Error al editar el cuestionario')
          return err
        })
    }
  }
})
</script>
