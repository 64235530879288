
import axios from 'axios'
import sendformData from '../utils/form-data'
import mapResponse from '../utils/map-response'

export default class Services {
  private readonly suiteBaseUrl = process.env.VUE_APP_SUITE_BASE_API;
  private readonly baseUrl = process.env.VUE_APP_CULTURA_BASE_API;
  // private readonly suiteBaseUrl = 'http://localhost:3000/';
  // private readonly baseUrl = 'http://localhost:3002/';
  // private readonly suiteBaseUrl = 'https://occsuiteserver.azurewebsites.net/';
  // private readonly baseUrl = 'https://occculturaserver.azurewebsites.net/';
  private module: string | undefined = '';
  private suite = false;

  constructor (module?: string) {
    this.module = module
  }

  public get (url: string, params?: object) {
    const response = mapResponse(axios.get(this.getUrl(url), params)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.get(this.getUrl(url), params))
        }
        return Promise.reject(err)
      })
    return response
  }

  public post (url: string, data?: object) {
    const response = mapResponse(axios.post(this.getUrl(url), data)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.post(this.getUrl(url), data))
        }
        return Promise.reject(err)
      })
    return response
  }

  public put (url: string, data?: object) {
    const response = mapResponse(axios.put(this.getUrl(url), data)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.put(this.getUrl(url), data))
        }
        return Promise.reject(err)
      })
    return response
  }

  public form (url: string, data?: object, transf?: object) {
    const response = sendformData(this.getUrl(url), data, transf).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return sendformData(this.getUrl(url), data, transf)
        }
        return Promise.reject(err)
      })
    return response
  }

  public bGet (url: string, params?: object) {
    const response = mapResponse(axios.get(this.getUrl(url, true), params)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.get(this.getUrl(url, true), params))
        }
        return Promise.reject(err)
      })
    return response
  }

  public bPost (url: string, data?: object) {
    const response = mapResponse(axios.post(this.getUrl(url, true), data)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.post(this.getUrl(url, true), data))
        }
        return Promise.reject(err)
      })
    return response
  }

  public bPut (url: string, data?: object) {
    const response = mapResponse(axios.put(this.getUrl(url, true), data)).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return mapResponse(axios.put(this.getUrl(url, true), data))
        }
        return Promise.reject(err)
      })
    return response
  }

  public bForm (url: string, data?: object, transf?: object) {
    const response = sendformData(this.getUrl(url, true), data, transf).then((res) => { return res })
      .catch((err) => {
        if (err.code === 'auth/updated-info') {
          return sendformData(this.getUrl(url, true), data, transf)
        }
        return Promise.reject(err)
      })
    return response
  }

  public suiteOperation (callBack: Function) {
    this.suite = true
    return new Promise((resolve, reject) => {
      try {
        resolve(callBack())
      } catch (error) {
        reject(error)
      }
    }).then(resp => {
      this.suite = false
      return resp
    })
  }

  private getUrl (url: string, onlyBase = false) {
    return `${this.suite ? this.suiteBaseUrl : this.baseUrl}${onlyBase ? url : this.checkModule(url)}`
  }

  private checkModule (url: string) {
    return (this.module ? `${this.module}/` : '') + url
  }
}
