
<template>
  <v-col cols="12" class="py-0">
    <v-row justify="center">
      <v-col class="py-0 px-4 px-sm-0 px-lg-9" cols="10" lg="9" style="position: absolute; z-index: 2; opacity: 0.5">
        <v-slider
          v-model="slideValue"
          ticks="always"
          tick-size="2"
          max="4" min="-4"
          :tick-labels="ticksLabels"
          :thumb-label="selected ? 'always' : ''"
          :thumb-color="slideValue < 0 ? 'red darken-4' : slideValue == 0 ? 'blue-grey darken-4' : 'blue darken-4' "
          @change="changeValue"
          >
          <template v-slot:thumb-label="props">
            <span>
              {{ props.value > 0 ? '+ ' + props.value : props.value  }}
            </span>
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6" lg="5" class="mr-n5 py-0 pl-md-6">
        <v-slider
          v-model="negativeValue"
          max="0" min="-4"
          :disabled="slideValue > 0 ? true : false"
          :color="colorNegative"
          :track-color="colorNegative"
          ticks="always"
          tick-size="2"
          inverse-label readonly
          ></v-slider>
      </v-col>
      <v-col cols="6" lg="5" class="ml-n5 py-0 pr-md-6">
        <v-slider
          v-model="positiveValue"
          max="4" min="0"
          :disabled="slideValue < 0 ? true : false"
          :color="colorPositive"
          :track-color="colorPositive"
          ticks="always"
          tick-size="2"
          inverse-label readonly
          ></v-slider>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    colorNegative: {
      type: String,
      default: 'deep-orange accent-3'
    },
    model: {
      type: Object,
      required: true
    },
    colorPositive: {
      type: String,
      default: 'cyan accent-4'
    },
    ticksLabels: {
      type: Array
    },
    value: Number,
    positions: {
      dimension: Number,
      attribute: Number
    }
  },
  data () {
    return {
      slideValue: undefined,
      negativeValue: 0,
      positiveValue: 0,
      selected: false,
      temporalItem: {},
      max: 10,
      min: 0
    }
  },
  watch: {
    slideValue () {
      this.positiveValue = this.slideValue
      this.negativeValue = this.slideValue
    }
  },
  methods: {
    update () {
      this.$emit('update', this.slideValue)
    },
    changeValue () {
      if (this.slideValue !== undefined) {
        this.selected = true
      }
      this.$emit('change-value', this.slideValue, this.positions.dimension, this.positions.attribute, this.selected)
    }
  },
  created () {
    this.slideValue = this.value
  }
}
</script>
