
<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400px">
    <v-card>
      <v-card-text class="pt-7 pb-5 px-8 text-justify">
        {{ $t('reports.already_generated_report') }}
      </v-card-text>
      <v-card-actions class="pt-1 px-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="accept()"
        >
          {{ $t('input.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    show: Boolean
  },
  methods: {
    accept () {
      this.$emit('close')
    }
  }
})
</script>
