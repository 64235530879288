
export default {
  list_title: 'Questionnaires',
  list_add_questionnaire: 'Add Questionnaire',
  list_questionnaires: 'List of Questionnaires',
  edit_title: 'Questionnaire editing',
  edit_questions: 'Edit questions',
  edit_open_dimension: 'Open each of the dimensions to edit the corresponding questions',
  create_title: 'Add questionnaire',
  dimensions: {
    values: 'Principles',
    sustainability: 'Sustainability',
    customers: 'Customers',
    talent: 'Talent',
    relationship: 'Relationships',
    communication: 'Communication',
    innovation: 'Innovation',
    achievement: 'Achievement',
    leadership: 'Leadership'
  },
  name: 'Name',
  last_modification: 'Last modification',
  status: 'Status',
  actions: 'Actions',
  limiting: 'limitation',
  positive: 'positive',
  questionnaires: 'Questionnaires',
  create: 'Create questionnaire',
  edit: 'Edit questionnaire',
  no_data: 'There are no questionnaires to display',
  as_person: 'As a person',
  management: 'Development',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  tooltip_edit: 'You must press ENTER to edit',
  customer: 'Customer',
  enterprise: 'Company',
  sector: 'Sector',
  assignation_type: 'Assignment type',
  assignation_to: 'Assign to',
  base: 'Base questionnaire',
  question_questionnaire_edit: 'Edit questionnaire questions',
  list_create: 'Create',
  list_modal_enable: 'Do you want to enable the questionnaire?',
  list_modal_enable_des: 'Once enabled, the questionnaire can be selected again for customers who have it to create surveys',
  list_modal_disable: 'Do you want to disable the questionnaire?',
  list_modal_disable_des: 'Once disabled, customers who have this questionnaire will not be able to choose the questionnaire to create a survey',
  list_toggle_status_error_disabled: 'Error deactivating questionnaire',
  list_toggle_status_error_enable: 'Error activating the questionnaire',
  list_toggle_status_error_by_limit: 'Not all questionnaires can be deactivated',
  edit_questionnaire_success: 'Questionnaire edited correctly',
  edit_questionnaire_error: 'Error editing the questionnaire'
}
