
<template>
  <div style="display:inline;">
    <v-btn
      @click="openPdf"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      color="primary"
      large
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ this.buttonText }}
    </v-btn>

    <img
      src="/img/20200302_x2_occ_cultura_logo.png"
      style="visibility:hidden;"
      id="occCultureLogo"
      alt="logo"
      width="0"
      height="0"
    />
    <img
      src="/img/portada-cultura.jpg"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />
    <x-loading
      :display="loading"
    ></x-loading>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import pdfUtil from './utils/pdf'

import is from 'is_js'
import cover from './mixins_required/cover'
import introduction from './mixins_required/introduction'
import results from './mixins_required/results'
import attributes from './mixins_required/attributes'
import ranking from './mixins_required/ranking'
import initial from './mixins_required/initial_calcs'
import dataObj from './utils/data-obj'
const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
const echarts = require('echarts')
require('echarts-wordcloud')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  name: 'required-report',
  mixins: [
    cover,
    initial,
    introduction,
    results,
    attributes,
    ranking
  ],
  props: {
    pollId: Number,
    disableButton: Boolean,
    demographicsSelected: Array,
    demographicsSelects: Object,
    listOfDemographics: Object,
    buttonText: String,
    filters: null
  },
  data () {
    return {
      renderPart: {
        chart: false,
        chart2: false,
        dimensions: 0
      },
      // GRÁFICOS
      chartPie: null,
      barChart: null,
      // IMÁGENES
      cultureLogoSrc: null,
      cultureLogoBase64: null,
      cultureCoverSrc: null,
      cultureCoverBase64: null,
      lockPdfButton: false,
      // DATOS DE ENCUESTAS
      calibrationScore: [],
      dimensionsEndScore: {},
      attributesName: [],
      dimensionAverage: [],
      dataAttributes: [],
      cultureAverage: [],
      attributesRanking: [],
      attributesArray: [],
      dimensions: [],
      attributes: [],
      averages: {},
      current: {},
      previous: {},
      currentRequired: {},
      previousRequired: {},
      previousAverages: {},
      previousCultureAverage: [],
      previousDimensionAverage: [],
      previousDataAttributes: [],
      previousAttributesArray: [],
      hasPreviousRequired: false,
      hasBackendPrevResults: false,
      backendPrevResults: {},
      loading: false
    }
  },
  mounted () {
    this.cultureLogoSrc = document.getElementById('occCultureLogo').src
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
  },
  watch: {
    renderPart: {
      handler () {
        const hasFalses = Object.values(this.renderPart).includes(false)
        const dimensions = this.renderPart.dimensions === 9
        if (!hasFalses && dimensions) this.renderPdf()
      },
      deep: true
    },
    cultureLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureLogoSrc, (dataURL) => {
          this.cultureLogoBase64 = dataURL
        })
      }
    },
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    generateBarChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 720
      canvas.height = 480

      const tableBarsChart = echarts.init(canvas)

      const options = {
        xAxis: {
          type: 'category',
          data: [this.$t('requiredReport.responsible'), this.$t('requiredReport.competitive'), this.$t('requiredReport.humanist')],
          axisLabel: {
            fontSize: 16
          }
        },
        yAxis: {
          type: 'value',
          max: 4
        },
        series: [{
          data: [{
            value: Number(Math.round(this.cultureAverage[0] + 'e' + 2) + 'e-' + 2),
            itemStyle: { color: '#0889D6' }
          },
          {
            value: Number(Math.round(this.cultureAverage[1] + 'e' + 2) + 'e-' + 2),
            itemStyle: { color: '#EB493C' }
          },
          {
            value: Number(Math.round(this.cultureAverage[2] + 'e' + 2) + 'e-' + 2),
            itemStyle: { color: '#48BBA1' }
          }],
          type: 'bar',
          label: {
            show: true,
            fontWeight: 'bold',
            fontSize: 20
          }
        }],
        barWidth: '80%'
      }
      tableBarsChart.setOption(options)
      tableBarsChart.on('finished', () => {
        this.barChart = tableBarsChart.getDataURL()
        this.renderPart.chart = true
      })
    },
    generateChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 1040
      canvas.height = 740

      const chartPieLocal = echarts.init(canvas)

      const base = {
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a'
      }

      const baseBlack = {
        ...base,
        name: 'z',
        color: '#000'
      }

      const baseBlank = {
        ...base,
        name: 'blank',
        color: 'rgba(0,0,0,0)'
      }

      const colorData = [
        'rgba(129, 212, 250, 0.8)',
        'rgba(79, 195, 247, 0.8)',
        'rgba(41, 182, 246, 0.8)',
        'rgba(239, 154, 154, 0.8)',
        'rgba(229, 115, 115, 0.8)',
        'rgba(239, 83, 80, 0.8)',
        'rgba(128, 203, 196, 0.8)',
        'rgba(77, 182, 172, 0.8)',
        'rgba(38, 166, 154, 0.8)'
      ]

      const setData = (idx, value) => {
        const baseData = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        baseData[idx] = value
        return baseData
      }

      const setColor = (idx, value) => ({
        ...base,
        data: setData(idx, value),
        name: headerData[idx],
        color: colorData[idx]
      })

      const setSpace = (idx, value) => ({
        ...baseBlank,
        data: setData(idx, value)
      })

      const lineWidth = 0.05

      const setBlack = (idx) => ({
        ...baseBlack,
        data: setData(idx, lineWidth)
      })

      const getData = (req, current, idx) => {
        const data = []
        if (req > current) {
          if ((req - current) <= lineWidth) {
            data.push(setColor(idx, current - lineWidth))
            data.push(setBlack(idx))
          } else {
            data.push(setColor(idx, current - lineWidth))
            data.push(setBlack(idx))
            data.push(setColor(idx, (req - current) - lineWidth))
          }
        } else {
          data.push(setColor(idx, req))
          data.push(setSpace(idx, current - req))
          data.push(setBlack(idx))
        }
        return data
      }

      const headerData = [
        { value: this.$t('cultureReports.values'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.sustainability'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.customers'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.innovation'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.achievement'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.leadership'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.talent'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.relationship'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.communication'), textStyle: { fontSize: 25 } }
      ]

      const getSerie = (idx, value) => {
        if (this.hasPreviousRequired) {
          return getData(
            dataObj.arrayFindByName(this.dimensionAverage, value, 'score'),
            dataObj.arrayFindByName(this.previousDimensionAverage, value, 'score'),
            idx
          )
        } else {
          return [{
            ...base,
            data: setData(idx, (dataObj.arrayFindByName(this.dimensionAverage, value, 'score'))),
            color: colorData[idx]
          }]
        }
      }

      chartPieLocal.setOption({
        angleAxis: {
          type: 'category',
          data: headerData,
          z: 10
        },
        radiusAxis: {
          min: 0,
          max: 4,
          interval: 0.25,
          axisLabel: {
            show: true,
            formatter: (value) => +value % 1 === 0 ? value : '',
            fontSize: 20,
            fontWeight: 'bold'
          },
          splitLine: {
            interval: 5,
            show: true
          },
          splitArea: {
            interval: 5,
            show: true
          }
        },
        polar: {},
        series: [
          ...getSerie(0, 'values'),
          ...getSerie(1, 'sustainability'),
          ...getSerie(2, 'customers'),
          ...getSerie(3, 'innovation'),
          ...getSerie(4, 'achievement'),
          ...getSerie(5, 'leadership'),
          ...getSerie(6, 'talent'),
          ...getSerie(7, 'relationship'),
          ...getSerie(8, 'communication')
        ],
        barWidth: '100%'
      })

      chartPieLocal.on('finished', () => {
        this.chartPie = chartPieLocal.getDataURL()
        this.renderPart.chart2 = true
      })
    },
    async openPdf () {
      this.loading = true
      this.lockPdfButton = true
      await this.$getInitialData(this.$route.params.id)
      await this.generateBarChart()
      this.generateChart()
    },
    writeRotatedText (text, color) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842
      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    calculatePages () {
      return [
        { text: this.$t('requiredReport.current_culture_organization'), sta: 3, end: 3, color: '#BFBFBF' },
        { text: this.$t('requiredReport.occ_tool'), sta: 4, end: 4, color: '#BFBFBF' },
        { text: this.$t('requiredReport.responsible_culture'), sta: 5, end: 5, color: '#309DE0' },
        { text: this.$t('requiredReport.competitive_culture'), sta: 6, end: 6, color: '#EB493C' },
        { text: this.$t('requiredReport.humanist_culture'), sta: 7, end: 7, color: '#48BBA1' },
        { text: this.$t('requiredReport.understanding_results'), sta: 8, end: 8, color: '#BFBFBF' },
        { text: this.$t('requiredReport.results'), sta: 9, end: 9, color: '#BFBFBF' },
        { text: this.$t('requiredReport.culture_types'), sta: 10, end: 10, color: '#BFBFBF' },
        { text: this.$t('requiredReport.responsible_culture'), sta: 11, end: 13, color: '#309DE0' },
        { text: this.$t('requiredReport.competitive_culture'), sta: 14, end: 16, color: '#EB493C' },
        { text: this.$t('requiredReport.humanist_culture'), sta: 17, end: 19, color: '#48BBA1' },
        { text: this.$t('requiredReport.ranking'), sta: 20, end: 22, color: '#a6a6a6' }
      ]
    },
    renderPdf () {
      this.$emit('render-pdf')
      Object.keys(this.renderPart).forEach((key) => { this.renderPart[key] = false })

      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('requiredReport.title'),
          author: 'OCC',
          subject: this.$t('requiredReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: (currentPage, pageSize) => {
          const resultObj = {
            image: this.cultureLogoBase64,
            width: 150,
            height: 59,
            margin: [15, 15, 25, 15]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return [
            {
              columns: pdfUtil.generateCenteredText(this.$t('requiredReport.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage, pageSize) => {
          const temp = this.calculatePages()
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const label = pageCont.text
          const color = pageCont.color
          const result = {
            image: this.writeRotatedText(label, color),
            aligment: 'center',
            absolutePosition: { x: 565, y: 0 }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice e información de introducción
          this.$generateIntroduction(),
          // Resultados (Gráficas)
          this.$generateResults(),
          // Tablas de los atributos por dimensiones
          this.$generateAttributes(),
          // Ranking
          this.$generateRanking()
        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.currentRequired.name}.pdf`)
          this.loading = false
          this.lockPdfButton = false
        })
      } else {
        pdfmake.createPdf(configuration).download(`${this.currentRequired.name}.pdf`, () => {
          this.loading = false
          this.lockPdfButton = false
        })
      }
    }
  }
}
</script>
