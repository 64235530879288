
export default {
  required_information: 'Bienvenido a la herramienta OCC para definir la Cultura Requerida para el logro de la estrategia de su organización.<br>Para empezar, te pedimos que repartas 100 puntos entre las nueve dimensiones de Cultura. Cada dimensión debe tener  un puntaje mínimo de 1 punto.<br>Para esto tenga en cuenta la importancia que cada tema tiene para ejecutar la estrategia de la Organización, alcanzar los resultados y hacer de esta una organización exitosa.<br>Ten en cuenta que estamos definiendo la cultura que requiere la organización, más no la que tiene actualmente.<br>Recuerde que la estrategia implica escogencia.',
  dimensions_information: 'Para cada una de las 9 dimensiones antes apuntadas, encontrarás 8 Atributos. A continuación selecciona los 3 atributos más requeridos y los 3 menos requeridos para que la organización ejecute la estrategia, alcance los resultados y sea una organización exitosa.',
  required_title: 'Valoración cultura requerida',
  values: 'Principios',
  sustainability: 'Sostenibilidad',
  customers: 'Clientes',
  achievement: 'Logro',
  leadership: 'Liderazgo',
  innovation: 'Innovación',
  talent: 'Talento',
  relationship: 'Relaciones',
  communication: 'Comunicación',
  dim_values: 'Dimensión de Principios',
  dim_sustainability: 'Dimensión de Sostenibilidad',
  dim_customers: 'Dimensión de Clientes',
  dim_achievement: 'Dimensión de Logro',
  dim_leadership: 'Dimensión de Liderazgo',
  dim_innovation: 'Dimensión de Innovación',
  dim_talent: 'Dimensión de Talento',
  dim_relationship: 'Dimensión de Relaciones',
  dim_communication: 'Dimensión de Comunicación',
  cul_responsible: 'Cultura responsable',
  cul_competitive: 'Cultura competitiva',
  cul_humanist: 'Cultura humanista',
  responsible: 'Responsable',
  competitive: 'Competitiva',
  humanist: 'Humanista',
  dont_close: 'Importante: No cerrar esta ventana.',
  please_wait: 'Por favor espere por la confirmación de su Facilitador para avanzar.',
  if_accepted: 'Una vez aceptado no podrá regresar a la calibración de las dimensiones.',
  end: 'Muchas gracias por tu participación. Has completado la medición satisfactoriamente.',
  invalid_token: 'No existe medición para este acceso, por favor coloque uno valido',
  before_date: 'La medición no se encuentra disponible para la fecha actual',
  pending: 'La medición no se encuentra disponible en estos momentos',
  expiration_date: 'La medición a la que se está intentando ingresar ha terminado',
  calibrate: 'Calibrar',
  calibrated: 'La cultura requerida ha sido calibrada exitosamente.',
  progress: 'Progreso',
  actual_point: 'Promedio actual',
  dispersion: 'Dispersión',
  final_point: 'Puntaje final',
  current_total: 'Total actual',
  final_total: 'Total final',
  important: 'importante',
  required: 'requerido',
  dimension_text: {
    values: 'El liderazgo y la cultura centrada en principios definen y defiende pautas de conducta humana que se convierten en la brújula moral de la organización. Los principios se pueden entender como los valores y hoy es claro que su vivencia es fundamental para asegurar la supervivencia de las organizaciones.',
    sustainability: 'Una cultura de sostenibilidad promueve el uso eficiente de los recursos actuales para asegurar la sostenibilidad futura de la organización. Da la misma importancia a todos los grupos de interés y busca resultados equilibrados en lo financiero, humano, social y para el planeta.',
    customers: 'Una cultura centrada en los clientes enfoca sus esfuerzos en entender, satisfacer y superar las necesidades y expectativas de los clientes. La definición de los procesos y sistemas, la estructura y las competencias de las personas se definen teniendo en cuenta las realidades actuales y futuras de los clientes.',
    achievement: 'Las organizaciones deben dar resultados positivos para progresar y lograr sus objetivos. Una cultura de efectividad y obtención de resultados promueve conductas y procesos orientados al logro con alto nivel de desempeño. Se crea un lineamiento directo entre la contribución del desempeño individual y de equipo con el desempeño de la organización. Los resultados positivos son la consecuencia de una cultura de alto desempeño y compromiso.',
    leadership: 'La calidad y capacidad de los líderes es fundamental para entender el éxito de las organizaciones; entendiendo el liderazgo como el arte de movilizar y orientar personas para asegurar el logro de objetivos y propósitos comunes. En esta dimensión se analiza cómo los líderes logran compromiso, generan confianza, se interesan por desarrollar personas, dan autonomía, crean ambientes que empoderan y acompañan a sus equipos en su desarrollo.',
    innovation: 'La innovación es entendida como la capacidad de crear nuevas realidades, es un aspecto vital para el éxito de las organizaciones en el mundo de hoy. Una cultura de innovación fomenta la creatividad, la iniciativa y la capacidad de reinventarse para prosperar y competir en el tiempo, a la vez que permite asumir riesgos y aprender de los errores.',
    talent: 'Una cultura centrada en las personas, busca atraer, desarrollar y mantener el mejor talento en la organización como principal palanca para el logro de sus metas. El propósito es tener gente competente y con alto nivel de compromiso y desempeño, que se sienta orgullosa de pertenecer a la organización. Estas organizaciones se esfuerzan por merecer el entusiasmo y la inteligencia de todos, con su visión, su coherencia con valores, su liderazgo y sus logros.',
    relationship: 'En estas organizaciones es clara la conexión entre la calidad de las relaciones y los resultados. Estas compañías promueven el trabajo en equipo, la solidaridad y la capacidad de unir esfuerzos para lograr objetivos comunes. Temas como metas compartidas, confianza, coordinación, inter-dependencia y colaboración son comunes en este tipo de organizaciones.',
    communication: 'La forma en que las personas se comunican y las historias que se cuentan hacen parte, son reflejo y definen la cultura y los estilos de liderazgo. En una organización con una cultura fuerte de comunicación, se comparte información con transparencia, se escucha con atención, se promueven las preguntas y se crean y mantienen relaciones de confianza y respeto mutuo. La visión y los objetivos se comunican con claridad.'
  },
  creating_status: 'Creando',
  in_progress_status: 'En progreso',
  progress_status: 'En progreso',
  pending_status: 'Pendiente',
  closed_status: 'Cerrada',
  completed_status: 'Terminada'

}
