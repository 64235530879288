
<template>
  <v-app>
    <v-content>
      <v-container style="backgorund-coler: grey;">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <img class="hidden-xs-only" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 25em; height: 25em;"/>
            <img class="hidden-sm-and-up" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 250px;"/>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-3 mt-4">
            <h2 class="display-2 text-center">{{ $t('operationSummary.title')}}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="display-1" v-if="type === 'required'">
              {{ $t('requiredCulture.workshop_cost') }}:
            </span>
            <span class="display-1" v-else>
              {{ $t('currentCulture.assessment_cost') }}:
            </span>
            <span class="display-1">
              {{price}} {{ $t('requiredCulture.token_unit') }}
            </span>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="display-1">{{ $t('requiredCulture.your_balance') }} </span>
            <span class="display-1">
              {{balance}} {{ $t('requiredCulture.token_unit') }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span>{{ $t('operationSummary.information') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn :href="operationsLink" color="primary">{{ $t('operationSummary.get_more_tokens') }}</v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="display-1">{{ $t('operationSummary.or') }}</span>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn v-if="type === 'required'"
              color="primary"
              @click="$router.push('/required-culture')"
            >
              {{ $t('operationSummary.go_to_required_list') }}
            </v-btn>
            <v-btn v-else-if="type === 'actual'"
              color="primary"
              @click="$router.push('/current-culture-assessments/list')"
            >
              {{ $t('operationSummary.go_to_actual_list') }}
            </v-btn>
            <v-btn v-else-if="type === 'pulse'"
              color="primary"
              @click="$router.push('/culture-pulses/list')"
            >
              {{ $t('operationSummary.go_to_pulse_list') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import culturePulseService from '../../services/culture-pulse'
import currentCultureService from '../../services/current-culture-assessment'

export default Vue.extend({
  data () {
    return {
      typeStr: '',
      tokenPrice: 0,
      price: 0,
      balance: 0,
      operationsLink: ''
    }
  },
  methods: {
    getBalance () {
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          this.balance = res.balance
          this.getServicePrice()
        })
    },
    getServicePrice () {
      switch (this.$route.params.type) {
        case 'required':
          this.typeStr = 'Requerida'
          break
        case 'actual':
          this.typeStr = 'Cultura Actual'
          break
        case 'pulse':
          this.typeStr = 'Pulsos en Cultura'
          break
      }
      return servicePriceService.getOne(this.typeStr)
        .then((res) => {
          this.getServicePriceFromSuite(res)
        })
    },
    getServicePriceFromSuite (service) {
      return servicePriceService.getByCodeFromSuite(service.code)
        .then((res) => {
          this.tokenPrice = res.tokenPrice
          if (this.$route.params.type === 'required') {
            this.price = this.tokenPrice
          } else {
            this.getPopulation()
          }
        })
    },
    getPopulation () {
      switch (this.type) {
        case 'pulse':
          culturePulseService.getOneById(this.$route.params.pollId)
            .then((res) => {
              this.price = this.tokenPrice * res.population
            })
          break
        case 'actual':
          currentCultureService.getOneById(this.$route.params.pollId)
            .then((res) => {
              this.price = this.tokenPrice * res.population
            })
          break
      }
    }
  },
  computed: {
    type () {
      return this.$route.params.type
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    if (this.$route.params.type !== 'required' && !this.$route.params.pollId) {
      this.$router.push('/404')
    } else {
      this.getBalance()
      this.$store.dispatch('session/getSuiteWebHost')
        .then((res) => {
          if (res) {
            this.operationsLink = `${res}/tokens/operations`
          } else {
            next('/404')
          }
        })
    }
  }
})
</script>
