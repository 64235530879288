import pdf from '../utils/pdf'
export default {
  methods: {
    calculateHighestPosititveGaps () {
      let gaps = []
      for (let index = 0; index < this.averagePerDimensions.length; index++) {
        gaps.push({
          name: this.averagePerDimensions[index].name,
          averageDimension: this.averagePerDimensions[index].average,
          averageDimensionCut: this.averagePerDimensionsByItems[index].average,
          required: this.hasRequired ? this.dimensionAverage.find(dimension => dimension.name === this.averagePerDimensions[index].name).score : '--',
          image: '',
          gap: this.averagePerDimensionsByItems[index].average - this.averagePerDimensions[index].average
        })
        for (let j = 0; j < 9; j++) {
          if (this.horizontalBarChartsDimensions[`${j}-${this.averagePerDimensions[index].name}`]) {
            gaps[index].image = this.horizontalBarChartsDimensions[`${j}-${this.averagePerDimensions[index].name}`][0]
          }
        }
      }
      gaps.sort((a, b) => (b.gap > a.gap) ? 1 : -1)
      let gapsPositiveRows = []
      let gapsNegativeRows = []
      for (let i = 0; i < 3; i++) {
        gapsPositiveRows.push(
          [
            { text: gaps[i].gap < 0 ? '--' : this.$t(`cultureReports.${gaps[i].name}`), color: '#5c5655', margin: [10, 6, 0, 6], alignment: 'left', border: [false, false, false, false] },
            { text: gaps[i].gap < 0 ? '--' : this.round(gaps[i].averageDimensionCut, 2), color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[i].gap < 0 ? '--' : this.round(gaps[i].averageDimension, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[i].gap < 0 ? '--' : this.round(gaps[i].gap, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[i].gap < 0 ? '--' : this.round(gaps[i].required, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] }
          ])
        if (gaps[i].gap < 0) {
          gapsPositiveRows[i][5] = { text: '--', color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
        } else {
          gapsPositiveRows[i][5] = { image: gaps[i].image, color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
        }
        gapsNegativeRows.push(
          [
            { text: gaps[(gaps.length - (i + 1))].gap > 0 ? '--' : this.$t(`cultureReports.${gaps[(gaps.length - (i + 1))].name}`), color: '#5c5655', margin: [10, 6, 0, 6], alignment: 'left', border: [false, false, false, false] },
            { text: gaps[(gaps.length - (i + 1))].gap > 0 ? '--' : this.round(gaps[(gaps.length - (i + 1))].averageDimensionCut, 2), color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[(gaps.length - (i + 1))].gap > 0 ? '--' : this.round(gaps[(gaps.length - (i + 1))].averageDimension, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[(gaps.length - (i + 1))].gap > 0 ? '--' : this.round(gaps[(gaps.length - (i + 1))].gap, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
            { text: gaps[(gaps.length - (i + 1))].gap > 0 ? '--' : this.round(gaps[(gaps.length - (i + 1))].required, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] }
          ])
        if (gaps[(gaps.length - (i + 1))].gap > 0) {
          gapsNegativeRows[i][5] = { text: '--', color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
        } else {
          gapsNegativeRows[i][5] = { image: gaps[(gaps.length - (i + 1))].image, color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
        }
      }
      return [{
        columns: [
          {
            width: '95%',
            table: {
              color: 'white',
              widths: ['20%', '10%', '10%', '10%', '15%', '35%'],
              body: [
                [{ text: this.$t('cultureReports.dimension').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.actual_cut').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.actual_org').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.difference_cut_org_2').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.required').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 4], width: 150, height: 25, alignment: 'center', border: [false, false, false, false] }
                ],
                ...gapsPositiveRows
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              }
            },
            alignment: 'center',
            bold: true,
            margin: [0, 30, 0, 0],
            fontSize: 10
          }
        ]
      },
      {
        table: {
          color: 'white',
          widths: [459],
          body: [
            [{ text: this.$t(`cultureReports.negative_gaps_dimensions_with_higher_score`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
          ]
        },
        layout: {
          fillColor: '#BFBFBF',
          defaultBorder: ''
        },
        alignment: 'left',
        bold: true,
        margin: [36, 30, 0, 0]
      },
      {
        columns: [
          {
            width: '95%',
            table: {
              color: 'white',
              widths: ['20%', '10%', '10%', '10%', '15%', '35%'],
              body: [
                [{ text: this.$t('cultureReports.dimension').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.actual_cut').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.actual_org').toUpperCase(), color: '#5c5655', margin: [0, 10, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.difference_cut_org_2').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.required').toUpperCase(), color: '#5c5655', margin: [0, 15, 0, 4], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 4], width: 150, height: 25, alignment: 'center', border: [false, false, false, false] }
                ],
                ...gapsNegativeRows
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              }
            },
            alignment: 'center',
            bold: true,
            margin: [0, 30, 0, 0],
            fontSize: 10
          }
        ]
      }]
    },
    $generateRankings () {
      this.attributesRanking = this.sortedquestionDataByAttributes.sort((a, b) => b.actScore - a.actScore)
      let gapHighestPositiveTable = this.calculateHighestPosititveGaps()
      let highestByAttrByItemTable = []
      let lowestByAttrByItemTable = []
      let highestByAttrTable = []
      let lowestByAttrTable = []
      this.highestScoresByAttributes = this.highestScoresByAttributes.reverse()
      for (let i = 0; i < this.highestScoresByAttributesByItems.length; i++) {
        const highestScoresByAttributesByItem = this.highestScoresByAttributesByItems[this.highestScoresByAttributesByItems.length - (i + 1)]
        const trendHighest = this.round(highestScoresByAttributesByItem.tendencies, 2)
        const trendLowest = this.round(this.lowestScoresByAttributesByItems[i].tendencies, 2)
        let requiredAttribute = this.attributesArray.find(attribute => highestScoresByAttributesByItem.idx === attribute.attrIndex)
        highestByAttrByItemTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: highestScoresByAttributesByItem.positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${highestScoresByAttributesByItem.dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(highestScoresByAttributesByItem.actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.attributesRanking.find(attribute => highestScoresByAttributesByItem.idx === attribute.idx).actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(highestScoresByAttributesByItem.actScore - this.attributesRanking.find(attribute => highestScoresByAttributesByItem.idx === attribute.idx).actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? requiredAttribute.score.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? this.round(highestScoresByAttributesByItem.actScore - requiredAttribute.score, 2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: trendHighest,
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center',
            ...pdf.styleTendency(trendHighest)
          }
        ])
        requiredAttribute = this.attributesArray.find(attribute => this.lowestScoresByAttributesByItems[i].idx === attribute.attrIndex)
        lowestByAttrByItemTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.lowestScoresByAttributesByItems[i].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.lowestScoresByAttributesByItems[i].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestScoresByAttributesByItems[i].actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.attributesRanking.find(attribute => this.lowestScoresByAttributesByItems[i].idx === attribute.idx).actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestScoresByAttributesByItems[i].actScore - this.attributesRanking.find(attribute => this.lowestScoresByAttributesByItems[i].idx === attribute.idx).actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? requiredAttribute.score.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? this.round(this.lowestScoresByAttributesByItems[i].actScore - requiredAttribute.score, 2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: trendLowest,
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center',
            ...pdf.styleTendency(trendLowest)
          }
        ])
        const sortedQuestionDataByAttributesByItems = this.questionDataByAttributesByItems.find(attribute => this.highestScoresByAttributes[i].idx === attribute.idx)
        requiredAttribute = this.attributesArray.find(attribute => this.highestScoresByAttributes[i].idx === attribute.attrIndex)
        highestByAttrTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.highestScoresByAttributes[i].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.highestScoresByAttributes[i].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(sortedQuestionDataByAttributesByItems.actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.highestScoresByAttributes[i].actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(sortedQuestionDataByAttributesByItems.actScore - this.highestScoresByAttributes[i].actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? requiredAttribute.score.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? this.round(sortedQuestionDataByAttributesByItems.actScore - requiredAttribute.score, 2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: !this.hasPrevious ? '--' : sortedQuestionDataByAttributesByItems.tendencies.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center',
            ...pdf.styleTendency(sortedQuestionDataByAttributesByItems.tendencies)
          }
        ])
        const lowestSortedQuestionDataByAttributesByItems = this.questionDataByAttributesByItems.find(attribute => this.lowestScoresByAttributes[i].idx === attribute.idx)
        requiredAttribute = this.attributesArray.find(attribute => this.lowestScoresByAttributes[i].idx === attribute.attrIndex)
        lowestByAttrTable.push([
          {
            text: i + 1,
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.lowestScoresByAttributes[i].positive[this.user.lang],
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0]
          },
          {
            text: this.$t(`requiredReport.initials.${this.lowestScoresByAttributes[i].dimensionsName}`),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(lowestSortedQuestionDataByAttributesByItems.actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(this.lowestScoresByAttributes[i].actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.round(lowestSortedQuestionDataByAttributesByItems.actScore - this.lowestScoresByAttributes[i].actScore, 2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? requiredAttribute.score.toFixed(2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasRequired ? this.round(lowestSortedQuestionDataByAttributesByItems.actScore - requiredAttribute.score, 2) : '--',
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: !this.hasPrevious ? '--' : lowestSortedQuestionDataByAttributesByItems.tendencies.toFixed(2),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center',
            ...pdf.styleTendency(lowestSortedQuestionDataByAttributesByItems.tendencies)
          }
        ])
      }
      const hActualCutHigScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).average, 2)
      const hActualOrgHigScore = this.round(this.highestScoresByDimensions[2].average, 2)
      const hActualReqHigScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).score, 2) : '--'
      const hActualCutMidScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).average, 2)
      const hActualOrgMidScore = this.round(this.highestScoresByDimensions[1].average, 2)
      const hActualReqMidScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).score, 2) : '--'
      const hActualCutLowScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).average, 2)
      const hActualOrgLowScore = this.round(this.highestScoresByDimensions[0].average, 2)
      const hActualReqLowScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).score, 2) : '--'
      const hActualCutItemHighScore = this.round(this.highestScoresByDimensionsByItems[2].average, 2)
      const hActualOrgItemHighScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[2].name).average, 2)
      const hActualReqItemHighScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[2].name).score, 2) : '--'
      const hActualCutItemMidScore = this.round(this.highestScoresByDimensionsByItems[1].average, 2)
      const hActualOrgItemMidScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[1].name).average, 2)
      const hActualReqItemMidScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[1].name).score, 2) : '--'
      const hActualCutItemLowScore = this.round(this.highestScoresByDimensionsByItems[0].average, 2)
      const hActualOrgItemLowScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[0].name).average, 2)
      const hActualReqItemLowScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.highestScoresByDimensionsByItems[0].name).score, 2) : '--'

      const lActualCutHigScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).average, 2)
      const lActualOrgHigScore = this.round(this.lowestScoresByDimensions[0].average, 2)
      const lActualReqHigScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).score, 2) : '--'
      const lActualCutMidScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).average, 2)
      const lActualOrgMidScore = this.round(this.lowestScoresByDimensions[1].average, 2)
      const lActualReqMidScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).score, 2) : '--'
      const lActualCutLowScore = this.round(this.sortedAveragePerDimensionsByItems.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).average, 2)
      const lActualOrgLowScore = this.round(this.lowestScoresByDimensions[2].average, 2)
      const lActualReqLowScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).score, 2) : '--'
      const lActualCutItemHighScore = this.round(this.lowestScoresByDimensionsByItems[0].average, 2)
      const lActualOrgItemHighScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[0].name).average, 2)
      const lActualReqItemHighScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[0].name).score, 2) : '--'
      const lActualCutItemMidScore = this.round(this.lowestScoresByDimensionsByItems[1].average, 2)
      const lActualOrgItemMidScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[1].name).average, 2)
      const lActualReqItemMidScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[1].name).score, 2) : '--'
      const lActualCutItemLowScore = this.round(this.lowestScoresByDimensionsByItems[2].average, 2)
      const lActualOrgItemLowScore = this.round(this.sortedAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[2].name).average, 2)
      const lActualReqItemLowScore = this.hasRequired ? this.round(this.dimensionAverage.find(dimension => dimension.name === this.lowestScoresByDimensionsByItems[2].name).score, 2) : '--'
      return [
        // 4.5 Ranking dimensiones
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('cultureReports.dimensions_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        // Puntaje mas alto
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [460],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_per_higher_score_org'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [35, 10, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['24%', '16%', '16%', '24%', '16%'],
            body: [
              [{ text: this.$t('cultureReports.dimension'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_cut'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_org'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_org_2'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutHigScore - hActualOrgHigScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutMidScore - hActualOrgMidScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutLowScore - hActualOrgLowScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [460],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_per_higher_score_cut'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [35, 10, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['24%', '16%', '16%', '24%', '16%'],
            body: [
              [{ text: this.$t('cultureReports.dimension'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_cut'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_org'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_org_2'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensionsByItems[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutItemHighScore - hActualOrgItemHighScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensionsByItems[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutItemMidScore - hActualOrgItemMidScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensionsByItems[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: hActualCutItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualOrgItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(hActualCutItemLowScore - hActualOrgItemLowScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: hActualReqItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Puntaje mas bajo
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [460],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_per_lower_score_org'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [35, 10, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['24%', '16%', '16%', '24%', '16%'],
            body: [
              [{ text: this.$t('cultureReports.dimension'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_cut'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_org'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_org_2'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutHigScore - lActualOrgHigScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqHigScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutMidScore - lActualOrgMidScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutLowScore - lActualOrgLowScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [460],
            body: [
              [{ text: this.$t('cultureReports.current_dimensions_per_lower_score_cut'), margin: [50, 8, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [35, 10, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['24%', '16%', '16%', '24%', '16%'],
            body: [
              [{ text: this.$t('cultureReports.dimension'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_cut'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_org'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_org_2'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required'), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensionsByItems[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutItemHighScore - lActualOrgItemHighScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqItemHighScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensionsByItems[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutItemMidScore - lActualOrgItemMidScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqItemMidScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensionsByItems[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'left' },
                { text: lActualCutItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualOrgItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: this.round(lActualCutItemLowScore - lActualOrgItemLowScore, 2), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: lActualReqItemLowScore, color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Ranking cultura actual
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes_cut')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              width: '50%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_higher_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, 40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '35%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                { text: this.$t('cultureReports.positive_attribute'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.dimension'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_cut'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_org'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_org_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.required'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_req_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.rate'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...highestByAttrByItemTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes_cut')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas bajo
            {
              width: '50%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_lower_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, 40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '35%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                { text: this.$t('cultureReports.positive_attribute'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.dimension'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_cut'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_org'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_org_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.required'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_req_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.rate'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...lowestByAttrByItemTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Ranking Cultura requerida
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes_org')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              width: '50%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_higher_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, 40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '35%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                { text: this.$t('cultureReports.positive_attribute'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.dimension'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_cut'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_org'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_org_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.required'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_req_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.rate'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...highestByAttrTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes_org')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -30, 0, 0]
            },
            // Atributos con puntaje mas bajo
            {
              width: '50%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.attributes_with_lower_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -30, 40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '35%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                { text: this.$t('cultureReports.positive_attribute'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.dimension'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_cut'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.actual_org'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_org_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.required'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.difference_cut_req_2'), margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' },
                { text: this.$t('cultureReports.rate'), margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...lowestByAttrTable
            ]
          },
          margin: [-10, 10, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'portrait'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.7 ${this.$t('cultureReports.ranking_of_gaps_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t(`cultureReports.positive_gaps_dimensions_with_higher_score`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 15, 0, 0]
        },
        gapHighestPositiveTable,
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#000' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_cut_abr') }
          ],
          margin: [0, 40, 0, 4]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ],
          margin: [0, 0, 0, 4]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#BFBFBF' },
            { width: '96%', text: this.$t('requiredActualReport.required_culture') }
          ]
        }
      ]
    }
  }
}
