
import Services from './base-services'

const service = new Services('employees')

export default {
  getByEnterpriseIdFromSuite: () => {
    return service.suiteOperation(() => service.get('by-enterprise'))
  },
  getParticipantsCount: () => {
    return service.suiteOperation(() => service.get('participants-count'))
  },
  getDemographicParticipants: (data: object) => {
    return service.suiteOperation(() => service.post('by-demographic-filter', data))
  },
  listActive: () => {
    return service.suiteOperation(() => service.get('participants-list'))
  }
}
