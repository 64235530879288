
export default {
  methods: {
    $generateWordQuestions () {
      return [
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.9 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: this.openQuestions[0][this.user.lang].toUpperCase(), color: '#fff', margin: [5, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 30, 0, 10]
        },
        {
          image: this.wordsCharts[0],
          width: 500,
          height: 500,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `4.9 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.openQuestions[1][this.user.lang].toUpperCase(), color: '#fff', margin: [5, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 30, 0, 10]
        },
        {
          image: this.wordsCharts[1],
          width: 500,
          height: 500,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.9 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.openQuestions[2][this.user.lang].toUpperCase(), color: '#fff', margin: [5, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 30, 0, 10]
        },
        {
          image: this.wordsCharts[2],
          width: 500,
          height: 500
        }
      ]
    }
  }
}
