<template>
  <v-dialog persistent
    v-model="showDialog"
    width="314px"
  >
    <v-card tile>
      <v-card-title>
        Sábana de Datos
      </v-card-title>

      <v-card-text class="text-center pt-1 pb-0">
        <v-progress-circular indeterminate
          v-if="loading"
          :size="77"
          :width="4"
          color="primary"
          class="my-4"
        >
          <span class="caption">
            {{batchCount}}/{{totalAnswers}}
          </span>
        </v-progress-circular>
        <div v-else class="pt-4 pb-7">
          <v-btn block outlined
            color="success darken-2"
            class="mb-4"
            :loading="loadingBtn1"
            @click="downloadXls1()"
          >
            Cultura Actual <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
          <v-btn block outlined
            color="success darken-2"
            class="mt-4"
            :loading="loadingBtn2"
            @click="downloadXls2()"
          >
            Preguntas abiertas <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end px-2">
        <v-btn
          :disabled="loading"
          @click="$emit('close')"
        >
          {{ $t('input.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import resolver from '../../utils/resolver'
import currentCultureService from '../../services/current-culture-assessment'
import xlsxDownload from '../../utils/xlsx-download'

import { ageRanges, antiquityRanges } from '@/utils/ranges'

export default {
  props: {
    questionnaire: Object,
    showDialog: Boolean
  },
  data () {
    return {
      loading: true,
      loadingBtn1: false,
      loadingBtn2: false,
      formattedDemographics: {},
      totalAnswers: 0,
      batchCount: 0,
      jsonResponse: [],
      currentCultureData: [],
      openQuestionsData: [],
      dKeys: {
        academicDegree: 'academicDegreeId',
        age: 'birthdate',
        antiquity: 'admission',
        charge: 'chargeId',
        country: 'countryId',
        departments: 'departmentId',
        gender: 'genderId',
        headquarter: 'headquarterId',
        jobTypes: 'jobTypeId',
        optionalDemo1: 'additionalDemographic1Id',
        optionalDemo2: 'additionalDemographic2Id'
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  watch: {
    showDialog (val) {
      if (val) {
        if (!this.jsonResponse.length) {
          this.getInitialData()
        } else {
          this.generateXlsx()
        }
      }
    }
  },
  methods: {
    formatDemoItems (demographicItems) {
      const data = {}
      Object.keys(demographicItems).forEach(key => {
        if (demographicItems[key].length) {
          data[this.dKeys[key]] = {}
          demographicItems[key].forEach(d => {
            data[this.dKeys[key]][d.id] = d.name || d.translate.label
          })
        }
      })

      return data
    },
    getInitialData () {
      resolver.all({
        demographics: currentCultureService.getDemographicsItemsByEntreprise(this.user.lang),
        total: currentCultureService.getAnswersCount(this.$route.params.id)
      })
        .then(async (res) => {
          this.formattedDemographics = await this.formatDemoItems(res.demographics.data)
          this.totalAnswers = res.total.count
          this.getAnswers()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAnswers () {
      currentCultureService.getAnswersWithParticipant(this.$route.params.id, this.batchCount)
        .then(async (res) => {
          this.batchCount += res.length
          const formattedAnswers = await this.formatAnswers(res)
          this.jsonResponse = [...this.jsonResponse, ...formattedAnswers]

          if (this.batchCount !== this.totalAnswers) {
            setTimeout(async () => {
              await this.getAnswers()
            }, 10000)
          } else {
            this.loading = false
            this.generateXlsx()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatAnswers (answers) {
      answers.forEach(a => {
        Object.keys(a.demographicItems).forEach(key => {
          if (this.formattedDemographics[key]) {
            const cleanKey = key.endsWith('Id') ? key.slice(0, -2) : key
            a.demographicItems[cleanKey] = this.formattedDemographics[key][a.demographicItems[key]]
            delete a.demographicItems[key]
          }
        })
      })

      return answers
    },
    getRange (date, type) {
      const today = new Date()
      const formattedDate = new Date(date)

      const diff = (today.getTime() - formattedDate.getTime()) / 1000
      const diffInDays = diff / (60 * 60 * 24)

      const year = diffInDays / 365.25

      const rng = type === 'age' ? ageRanges : antiquityRanges
      const found = rng.find(x => year >= x.min && year < x.max)

      return found ? found.text[this.user.lang] : ''
    },
    generateXlsx () {
      this.jsonResponse.forEach(item => {
        const obj = {
          'País': item.demographicItems.country,
          'Sede': item.demographicItems.headquarter,
          'Departamento': item.demographicItems.department,
          'Cargo': item.demographicItems.charge,
          'Tipo de Contratación': item.demographicItems.jobType,
          'Nivel Académico': item.demographicItems.academicDegree,
          'Genero': item.demographicItems.gender,
          'ID Interno': `ObjectId(${item.participant.id})`,
          'Documento de Identidad': item.participant.identifyDocument,
          'Correo': item.participant.email,
          'Edad': this.getRange(item.demographicItems.birthdate, 'age'),
          'Antigüedad': this.getRange(item.demographicItems.admission, 'antiquity'),
          'AddDemo1': item.demographicItems.additionalDemographic1,
          'AddDemo2': item.demographicItems.additionalDemographic2
        }

        // Load Current Culture XLS data
        item.cultureTypes.forEach(cType => {
          cType.dimension.forEach(dim => {
            const foundQ = this.questionnaire.cultureTypes[cType.name].find(x => x.name === dim.name).questions
            dim.attributes.forEach((attr, i) => {
              const clone = JSON.parse(JSON.stringify(obj))
              clone['Tipo de Cultura'] = this.$t(`cultureReports.${cType.name}`)
              clone['Dimensión'] = this.$t(`cultureReports.${dim.name}`)
              clone['Atributo'] = foundQ[i].positive[this.user.lang]
              clone['Respuesta'] = attr.score
              this.currentCultureData.push(clone)
            })
          })
        })

        // Load Open Questions XLS data
        item.openQuestions.forEach(q => {
          q.answer.forEach(answer => {
            const clone2 = JSON.parse(JSON.stringify(obj))
            clone2['Pregunta'] = q.question.translation[this.user.lang]
            clone2['Respuesta'] = answer
            this.openQuestionsData.push(clone2)
          })
        })
      })
    },
    downloadXls1 () {
      this.loadingBtn1 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.currentCultureData,
          'Cultura Actual',
          `Sabana de Datos - Cultura Actual.xlsx`,
          true
        )
        this.loadingBtn1 = false
      }, 1000)
    },
    downloadXls2 () {
      this.loadingBtn2 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.openQuestionsData,
          'Abiertas',
          `Sabana de Datos - Preguntas Abiertas.xlsx`,
          true
        )
        this.loadingBtn2 = false
      }, 1000)
    }
  }
}
</script>
