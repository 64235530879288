
class DataObj {
  objOrEmptyObj (obj: any | undefined) {
    return obj || {}
  }

  propertieOrDefault (obj: any | undefined, key: string, df?: string | undefined) {
    let value = df || '--'
    if (obj) {
      value = isNaN(obj[key]) ? obj[key] : obj[key].toFixed(2)
    }
    return value
  }

  arrayFindByName (array: [any] | undefined, value: string, key: string, df?: string | undefined) {
    const obj = array ? array.find(element => element.name === value) : undefined
    return obj ? Number(obj[key]) : (df || '--')
  }

  arrayFindByNameFixed (array: [any] | undefined, value: string, key: string, df?: string | undefined) {
    let val = this.arrayFindByName(array, value, key, df)
    return (typeof val === 'number' ? val.toFixed(2) : val)
  }

  objectInArrayFindByName (array: [any] | undefined, key: string, index: number) {
    const obj = array ? array[index] : undefined
    return obj ? Number(obj[key]) : '--'
  }

  objectInArrayFindByNameFixed (array: [any] | undefined, key: string, index: number) {
    let val = this.objectInArrayFindByName(array, key, index)
    return (typeof val === 'number' ? val.toFixed(2) : val)
  }
}

export default new DataObj()
