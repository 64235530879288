
export default {
  tokens_list: 'OCC Tokens',
  acquire: 'Purchase',
  total_amount: 'Total amount',
  tool: 'Tool',
  calculator: 'Calculator',
  operations: 'Operations',
  operations_group: 'Group Operations',
  operation_detail: 'Operation detail',
  operation_details: 'Operation details',
  transaction_number: 'Transaction number',
  success_transaction: 'Transaction successfully completed ',
  created_movement: 'Purchase successful.',
  created_transfer: 'Transfer successful.',
  created_authorization: 'Authorization created successfully.',
  eliminated_authorization: 'Authorization was removed successfully.',
  authorization_message: 'authorizes',
  authorization_message_p1: 'By enabling this option',
  authorization_message_p2: 'to use your OCC Tokens, if required, when buying and not having enough balance.',
  invalid_quantity: 'Incorrect amount.',
  balance: 'Balance',
  add_new: 'Add New',
  date: 'Date',
  token_qty: 'Number of OCC Tokens',
  actions: 'Actions',
  no_data: 'There are no operations to display',
  purchase: 'Purchase of OCC Tokens',
  create: 'Account creation',
  transfer: 'Transfer',
  receiving: 'Receiving',
  sending: 'Sending',
  received: 'received',
  received_female: 'received',
  sended: 'sent',
  sended_female: 'sent',
  spend: 'Consumption',
  consumption: 'Consumption',
  by: ' by ',
  of: ' of ',
  authorization: 'Authorization',
  authorized: 'authorized',
  medition: 'Measurement',
  download: 'Download',
  downloads: 'Downloads',
  person: 'Person',
  persons: 'People',
  poblation: 'Population',
  annual: 'Annually',
  biannual: 'Biannually',
  quarterly: 'Quarterly ',
  monthly: 'Monthly',
  biweekly: 'Fortnightly',
  weekly: 'Weekly',
  diary: 'Daily',
  one_time: 'Once',
  products: 'Products',
  creation: 'creation',
  employees_lowercase: 'employees',
  collaborators_lowercase: 'employees',
  filter_by: 'Filter by...',
  look_for: 'Search by transaction number',
  consumer_transactions: 'Consumer transactions',
  authorization_transactions: 'Authorization transactions',
  movements_between_accounts: 'Movements between accounts',
  customer_balance: 'Customer Balance',
  enterprise_balance: 'Company Balance',
  group_balance: 'Group Balance',
  administrator: 'Administrator',
  since: 'since',
  to: 'to',
  for: 'for',
  see_all_companies: 'See all companies',
  see_all_members: 'See all members',
  no_operations: 'There are no operations to display.',
  cost: 'Cost',
  calculator_closing_message: 'Are you sure you want to cancel the operation?',
  return: 'Return',
  confirm_close_calculator: 'Yes, I\'m sure',
  required: 'Required',
  times: 'times',
  leaders: 'leaders',
  evaluation: 'Evaluation',
  total: 'Total',
  services: 'Services',
  modality: 'Modality',
  recomendation: 'Recommendation',
  unit: 'OCC Tokens',
  money_unit: 'USD'
}
