<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1">{{ $t('culturePulses.to_edit') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-4">
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <x-poll-steps-overview-current-culture
                  :currentCulture="culturePulse"
                  v-show="step === 1"
                  :is-edit="isEdit"
                  step="1"
                  :total-receptors="populationCount"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview-current-culture>

                <ValidationObserver ref="step2">
                  <x-step-receptors-culture-pulse
                    :culturePulse="culturePulse"
                    v-show="step === 2"
                    :employeesCount="populationCount"
                    :is-edit="isEdit"
                    step="2"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @parentLoading="parentLoading"
                    @changeStep="verifyStepChanged"
                  ></x-step-receptors-culture-pulse>
                </ValidationObserver>

                <x-poll-steps-date-current-culture
                  :currentCulture="culturePulse"
                  v-show="step === 3"
                  :is-edit="isEdit"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date-current-culture>

                <x-step-question-culture-pulse
                  :culturePulse="culturePulse"
                  v-show="step === 4"
                  :is-edit="isEdit"
                  step="4"
                  nextAction="input.next"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-question-culture-pulse>

                <x-step-revition-culture-pulse
                  :culturePulse="culturePulse"
                  v-show="step === 5"
                  :is-edit="isEdit"
                  step="5"
                  :key="step"
                  nextAction="input.update"
                  prevAction="input.back"
                  @changeStep="verifyStepChanged"
                ></x-step-revition-culture-pulse>
              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <x-loading v-if="loading" :display="loading"/>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

import resolver from '../../utils/resolver'

import culturePulseService from '../../services/culture-pulse'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees-services'

import XStepReceptorsCulturePulse from './steps/receptors.vue'
import XStepQuestionCulturePulse from './steps/question.vue'
import XStepRevitionCulturePulse from './steps/revition.vue'

export default {
  components: {
    XStepReceptorsCulturePulse,
    XStepQuestionCulturePulse,
    XStepRevitionCulturePulse
  },
  data () {
    return {
      stepperHeaders: [
        'culturePulses.overview',
        'culturePulses.receptors',
        'culturePulses.date',
        'culturePulses.questions',
        'culturePulses.revition'
      ],
      culturePulse: {
        name: '',
        displayName: '',
        selectionType: 1,
        selectionDetails: {},
        totalReceivers: 0,
        enterprise: {},
        enterpriseId: 0,
        timeZone: '',
        releaseAt: {
          value: '',
          hour: '23:00'
        },
        deadlineAt: {
          value: '',
          hour: '23:00'
        },
        emailData: '',
        status: '',
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        switchName: false,
        switchDate: false,
        switchCurrentCultureQuestionnaire: false,
        questionnaire: '',
        questionnaires: [],
        selectedDimensions: {},
        switchAdditionalQuestion: false,
        additionalQuestions: [{
          question: '',
          options: ['', '']
        }],
        pollInvitation: {
          subject: '',
          body: '',
          file: ''
        },
        reminderMail: {
          subject: '',
          body: '',
          file: ''
        },
        thankMessage: '',
        active: null,
        offset: '',
        price: 0,
        totalPrice: 0,
        demographicItems: {},
        departmentIds: [],
        chargeIds: [],
        academicDegreeIds: [],
        jobTypeIds: [],
        rangeAge: [],
        rangeAntiquity: [],
        genderId: '',
        countrySelect: [],
        headquarterSelect: [],
        additionalDemographic1Ids: [],
        additionalDemographic2Ids: [],
        invitationFileFlag: true,
        reminderFileFlag: true
      },
      populationCount: 0,
      step: 1,
      dialog: {
        show: false,
        msg: ''
      },
      loading: false,
      balance: 0,
      isEdit: true
    }
  },
  methods: {
    getCulturePulse () {
      culturePulseService.getOneToEdit(this.$route.params.id)
        .then((res) => {
          this.responseParse(res)
          const status = res.status
          if (status === 'completed' || status === 'cancelled') { this.$router.go(-1) }
        })
    },
    responseParse (res) {
      this.culturePulse.name = res.name
      this.culturePulse.displayName = res.displayName
      this.culturePulse.selectionType = res.selectionType === 'everybody' ? 1 : 2
      this.culturePulse.selectionDetails = res.selectionDetails
      this.culturePulse.switchName = this.culturePulse.displayName
      this.culturePulse.timeZone = res.timeZone
      this.culturePulse.status = res.status
      this.culturePulse.releaseAt = this.getDate(res.releaseAt)
      this.culturePulse.deadlineAt = this.getDate(res.deadlineAt)
      this.culturePulse.pollInvitation = res.customEmailRelease
      this.culturePulse.reminderMail = res.customReminderEmail
      this.culturePulse.thankMessage = res.customEmailDeadline.body
      this.culturePulse.enterprise = res.enterprise
      this.culturePulse.enterpriseId = res.enterpriseId
      this.culturePulse.totalReceivers = res.populationCount
      this.culturePulse.selectedDimensions = res.selectedDimensions
      this.culturePulse.additionalQuestions = res.additionalQuestions
      if (res.additionalQuestions[0].question !== '') {
        this.culturePulse.switchAdditionalQuestion = true
      }
      this.populationCount = res.populationCount
      setTimeout(() => {
        this.culturePulse.questionnaire = res.questionnaire.slug
      }, 700)
      if (res.reminders && res.reminders.length > 0) {
        // this.culturePulse.reminderMail = res.reminders[0].customEmail
        res.reminders.forEach((element, i) => {
          this.culturePulse.switchDate = true
          this.culturePulse.reminders[i] = this.getDate(element.dateTime)
        })
      }
    },
    getDate (ref) {
      if (!ref) {
        return {
          value: '',
          hour: '23:00'
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0] + ':00'
      }
    },
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/culture-pulses/list')
        case 6: return this.update()
        default: this.step = step
      }
    },
    deleteInvitationFile () {
      delete this.culturePulse.pollInvitation.attachment
      this.culturePulse.invitationFileFlag = false
    },
    deleteReminderFile () {
      delete this.culturePulse.reminderMail.attachment
      this.culturePulse.reminderFileFlag = false
    },
    getBalance () {
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          this.balance = res.balance
          this.getServicePrice()
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    getServicePrice () {
      return servicePriceService.getOne('Pulsos en Cultura')
        .then((res) => {
          this.getServicePriceFromSuite(res)
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    getServicePriceFromSuite (service) {
      return servicePriceService.getByCodeFromSuite(service.code)
        .then((res) => {
          this.culturePulse.totalPrice = res.tokenPrice * this.culturePulse.population.length
        }).catch((err) => {
          this.dialog.show = true
          this.dialog.msg = this.$t(`errors.${err.code}`)
        })
    },
    update () {
      this.loading = true
      let pollInvitationFile = null
      let reminderMailFile = null

      if (this.culturePulse.pollInvitation.file && typeof this.culturePulse.pollInvitation.file === 'object') {
        pollInvitationFile = this.culturePulse.pollInvitation.file
        this.culturePulse.pollInvitation.file = this.culturePulse.pollInvitation.file.name
      }
      if (this.culturePulse.reminderMail.file && typeof this.culturePulse.reminderMail.file === 'object') {
        reminderMailFile = this.culturePulse.reminderMail.file
        this.culturePulse.reminderMail.file = this.culturePulse.reminderMail.file.name
      }
      const data = JSON.parse(JSON.stringify(this.culturePulse))
      delete data.population
      return culturePulseService.update(this.$route.params.id, data)
        .then((res) => {
          return pollInvitationFile ? culturePulseService.sendInvitationFiles(this.$route.params.id, { pollInvitationFile }) : Promise.resolve()
        })
        .then((res) => {
          return reminderMailFile ? culturePulseService.sendReminderFiles(this.$route.params.id, { reminderFile: reminderMailFile }) : Promise.resolve()
        })
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('culturePulses.edit'))
          this.loading = false
          this.$router.push('/culture-pulses/list')
        })
        .catch((err) => {
          this.loading = false
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    parentLoading (val) {
      this.loading = val
    }
  },
  created () {
    this.getCulturePulse()
    this.getBalance()
  }
}
</script>
