
<template>
  <v-stepper-content step="3" class="pb-0">
    <v-col cols="12" class="headline">
      {{ $t('currentCulture.questions') }}
    </v-col>
    <p class="ml-4 subtitle-2 grey--text text--darken-1 text-justify">
      {{ $t('input.questionnaire_selection_subtitle') }}
    </p>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form fluid lazy-validation data-vv-scope="form-3" @submit.prevent="handleSubmit(onSubmit)">
      <v-container>
        <v-row align-space-between justify-center column fill-height
          v-for="(item,index) in currentCulture.questionnaires"
          v-bind:key="index"
        >
          <v-row align-center justify-start fill-height>
            <v-col cols="12" sm="7" max-height="20px" class="pt-0 pl-5">
              <v-radio-group
                v-model="currentCulture.questionnaire"
                :value="currentCulture.questionnaire"
                :disabled="currentCulture.status === 'in_progress' || currentCulture.status === 'completed'"
              >
                <v-radio
                  color="primary"
                  :label="item.name"
                  :value="item.slug"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="5" class="pt-3">
              <v-btn dark small
                color="primary"
                @click="getPdfAsync(item)"
              >
                {{ $t('requiredCulture.download') }} {{ $t('requiredCulture.questionnaire') }}
                <v-icon dark right small>mdi-file-pdf</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(+step - 1)"
            >{{ $t('input.back') }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              large
              type="submit"
            >{{ $t('input.next') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="showDialog" width="600px" :persistent="true">
        <v-card>
          <v-card-title>
            <span class="headline"> {{ $t('currentCulture.no_questionnaries_title') }} </span>
          </v-card-title>
          <v-card-text>
            {{ $t('currentCulture.no_questionnaries_body') }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="white--text"
              large
              block
              color="#3898d9"
              @click="showDialog = !showDialog"
            >
              {{ $t('input.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>

    <img
      src="../../../../public/img/20200302_occ_cultura_logo.png"
      style="visibility:hidden;"
      id="occCultureLogo"
      width="0"
      height="0"
      alt=""
    />
  </v-stepper-content>
</template>
<script>

import { mapState } from 'vuex'
import is from 'is_js'

import questionnairesService from '../../../services/questionnaires'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  props: {
    currentCulture: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      cultureLogoSrc: null,
      cultureLogoBase64: null,
      minOpenQuestion: 1,
      maxOpenQuestion: 3,
      minOptionOpenQuestion: 2,
      maxOptionOpenQuestion: 5,
      showDialog: false,
      loading: false
    }
  },
  watch: {
    loading (val) {
      if (val) {
        this.$store.dispatch('loading/show')
      } else {
        this.$store.dispatch('loading/hide')
      }
    },
    cultureLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureLogoSrc, (dataURL) => {
          this.cultureLogoBase64 = dataURL
        })
      }
    },
    '$i18n.locale': {
      handler () {
        questionnairesService.listFiltered()
          .then(res => {
            this.currentCulture.questionnaires = res.items
            this.showDialog = !this.currentCulture.questionnaires.length
          })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    changeStep (step) {
      this.$emit('changeStep', this.currentCulture, step)
    },
    onSubmit () {
      if (!this.currentCulture.questionnaire) {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_questionnaires'))
      } else {
        this.changeStep(+this.step + 1)
      }
    },
    removeOpenQuestion () {
      this.currentCulture.opens.pop()
    },
    addOpenQuestion () {
      this.currentCulture.opens.push({
        question: '',
        options: ['', '']
      })
    },
    removeOptionOpenQuestion (open, idx) {
      open.options.splice(idx, 1)
    },
    addOptionOpenQuestion (open) {
      open.options.push('')
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 50
      canvas.height = 845

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#2196F3'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '20pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    getPdfAsync (questionnaire) {
      this.loading = true
      new Promise((resolve, reject) => {
        setTimeout(() => { resolve() }, 10)
      }).then(() => {
        this.getPdf(questionnaire)
      })
    },
    getPdf (questionnaire) {
      let details = []
      let i = 0
      details.push({ text: this.$t('cultureReports.responsible').toUpperCase(), bold: true, fontSize: 15, margin: [15, 0, 0, 0] })
      for (const attr of questionnaire.cultureTypes.responsible) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 10],
          pageBreak: i === 1 ? 'after' : ''
        })
        i++
      }
      details.push({ text: this.$t('cultureReports.competitive').toUpperCase(), bold: true, fontSize: 15, margin: [15, 5, 0, 0] })
      for (const attr of questionnaire.cultureTypes.competitive) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 20],
          pageBreak: i === 3 || i === 5 ? 'after' : ''
        })
        i++
      }
      details.push({ text: this.$t('cultureReports.humanist').toUpperCase(), bold: true, fontSize: 15, margin: [15, 5, 0, 0] })
      for (const attr of questionnaire.cultureTypes.humanist) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 20],
          pageBreak: i === 7 ? 'after' : ''
        })
        i++
      }
      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('cultureReports.title'),
          author: 'OCC',
          subject: this.$t('cultureReports.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.cultureLogoBase64,
            height: 30,
            width: 40,
            margin: [15, 15, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('cultureReports.copyright'),
                  color: 'grey',
                  fontSize: 10
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.name),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 }
          }

          return result
        },
        content: [
          // Título
          {
            text: this.$t('currentCulture.questionnaire_e'),
            fontSize: 20,
            margin: [0, 20, 0, 10]
          },
          // Explicación
          {
            text: this.$t('currentCulture.pdf_explained', { name: questionnaire.name }),
            alignment: 'justify',
            margin: [0, 0, 20, 10]
          },
          // Cuestionario
          ...details
        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'questionnaire.pdf')
          this.loading = false
        })
      } else {
        pdfmake.createPdf(configuration).download('questionnaire', () => { this.loading = false })
      }
    }
  },
  created () {
    questionnairesService.listFiltered()
      .then(res => {
        this.currentCulture.questionnaires = res.items
        this.showDialog = !this.currentCulture.questionnaires.length
      })
  },
  mounted () {
    this.cultureLogoSrc = document.getElementById('occCultureLogo').src
  }

}
</script>
