
import { IMenuGroup } from './interfaces/menu-group'
import { IMenuLeaf } from './interfaces/menu-leaf'

const baseGroups: Array<IMenuGroup | IMenuLeaf> = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: 'dashboard'
  },
  {
    title: 'questionnaires',
    path: '/questionnaires',
    icon: 'poll',
    permission: 'questionnairesCulture.list'
  },
  {
    title: 'open_questions',
    path: '/open-questions',
    icon: 'mdi-comment-question-outline',
    permission: 'openQuestionsCulture.list'
  },
  {
    title: 'required_culture',
    path: '/required-culture',
    icon: 'poll',
    permission: 'requiredCulture.list'
  },
  { title: 'current_culture',
    path: '/current-culture-assessments/list',
    icon: 'poll',
    permission: 'currentCulture.list'
  },
  {
    title: 'culture_pulses',
    path: '/culture-pulses/list',
    icon: 'mdi-clipboard-pulse',
    permission: 'culturePulses.list'
  },
  {
    title: 'suite',
    icon: 'mdi-apps',
    path: '/',
    goto: 'suite'
  }
]

export { baseGroups }
