
<template>
  <div style="display:inline;">
    <v-btn
      @click="openPdf"
      color="primary"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      large
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ this.buttonText }}
    </v-btn>

    <img
      src="/img/20200302_x2_occ_cultura_logo.png"
      style="visibility:hidden;"
      id="occCultureLogo"
      alt="logo"
      width="0"
      height="0"
    />

    <img
      src="/img/portada-cultura.jpg"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />

    <img
      src="/img/slider-for-results.PNG"
      style="visibility:hidden;"
      id="actualCultureSlider"
      alt="hidden"
      width="0"
      height="0"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import is from 'is_js'

import cover from './mixins-culture-by-items-req/cover'
import dimensions from './mixins-culture-by-items-req/dimensions'
import introduction from './mixins-culture-by-items-req/introduction'
import rates from './mixins-culture-by-items-req/rates'
import results from './mixins-culture-by-items-req/results'
import attributesResults from './mixins-culture-by-items-req/attributes-results'
import rankings from './mixins-culture-by-items-req/ranking'
import diffRanking from './mixins-culture-by-items-req/diff_ranking'
import diffRankingReq from './mixins-culture-by-items-req/diff_ranking_req'
import dispersionRanking from './mixins-culture-by-items-req/dispersion_ranking'
import trendRanking from './mixins-culture-by-items-req/trend_ranking'
import wordQuestions from './mixins-culture-by-items-req/word-questions'
import initialCalcs from './mixins-culture-by-items-req/initial-calcs'

import initialRequired from './mixins_req_actual/initial_calcs'

import pdfUtil from './utils/pdf'
import dataObj from './utils/data-obj'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
const echarts = require('echarts')
require('echarts-wordcloud')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default Vue.extend({
  name: 'thread-current-culture-report-by-items-req-exec',
  mixins: [
    cover,
    dimensions,
    introduction,
    rates,
    results,
    attributesResults,
    rankings,
    diffRanking,
    diffRankingReq,
    dispersionRanking,
    trendRanking,
    initialCalcs,
    initialRequired,
    wordQuestions
  ],
  props: {
    pollId: String,
    threadId: String,
    disableButton: Boolean,
    buttonText: String
  },
  data () {
    return {
      renderPart: {
        chartPie: false,
        chartCake: false,
        flowerChart: false,
        wordsCloud1: false,
        wordsCloud2: false,
        wordsCloud3: false,
        dimentionsBars: 0,
        attributesBars: 0,
        horizontalAttributesBars: 0,
        horizontalDimensionsBars: 0
      },
      wordColors: [
        '#1a98da',
        '#51c7af',
        '#eb5f4c'
      ],
      // GRÁFICOS
      chartPie: null,
      chartCake: null,
      flowerChart: null,
      barCharts: [],
      barChartsAttributes: {},
      horizontalBarChartsAttributes: {},
      horizontalBarChartsDimensions: {},
      wordsCharts: [],
      bars: {},
      // IMÁGENES
      actualCultureSliderSrc: null,
      cultureLogoSrc: null,
      cultureCoverSrc: null,
      cultureLogoBase64: null,
      cultureCoverBase64: null,
      actualCultureSliderBase64: null,
      lockPdfButton: false,
      // DATOS DE ENCUESTAS
      currentPoll: {},
      previousPoll: {},
      coverItemsName: {},
      coverItemsNameText: '',
      reportItemsNameText: '',
      totalParticipantsPercent: 0,
      totalObtained: 0,
      averagePerCultureTypes: {},
      averagePerDimensions: {},
      averagePerCultureTypesTotal: 0,
      previousAveragePerCultureTypes: {},
      previousAveragePerDimensions: {},
      previousAveragePerCultureTypesTotal: 0,
      answerValuesByDimensions: [],
      answerValuesByAtributtes: [],
      questionDataByAttributes: [],
      highestScoresByDimensions: [],
      lowestScoresByDimensions: [],
      highestScoresByAttributes: [],
      lowestScoresByAttributes: [],
      lowestDispersionByAttributes: [],
      highestDispersionByAttributes: [],
      hasPrevious: undefined,
      hasRequired: false,
      filteredAnswers: [],
      previousFilteredAnswers: [],
      totalFiltered: 0,
      answersOpenQuestionsPopulation: [],
      openQuestions: [],
      // ByItems
      questionDataByAttributesByItems: null,
      answerValuesByDimensionsByItems: null,
      answerValuesByAtributtesByItems: null,
      averagePerDimensionsByItems: null,
      averagePerCultureTypesTotalByItems: null,
      averagePerCultureTypesByItems: null,
      lowestScoresByDimensionsByItems: null,
      highestScoresByDimensionsByItems: null,
      lowestScoresByAttributesByItems: null,
      highestScoresByAttributesByItems: null,
      lowestDispersionByAttributesByItems: null,
      highestDispersionByAttributesByItems: null,
      extraPage: 0,
      fitsExtraPage: 0,
      answerRates: {},
      answersRateTables: [],
      balance: 0,
      price: 0,
      showModalConfirm: false,
      disableConfirm: true,
      lineLength: 43,
      // Required
      currentRequired: {},
      dimensionsEndScore: {},
      averages: {},
      attributesName: [],
      previousRequired: {},
      hasPreviousRequired: false,
      previousAverages: {}, // No necesario
      previousCultureAverage: [],
      previousDimensionAverage: [],
      previousDataAttributes: [],
      previousAttributesArray: [],
      cultureAverage: [],
      dimensionAverage: [],
      dataAttributes: [],
      attributesArray: [],
      // Para tabla 10
      dispersionByAttributesOrg: [],
      // Para tabla 11
      highestTendenciesByAttributes: [],
      highestTendenciesByAttributesByItems: [],
      lowestTendenciesByAttributesByItems: [],
      sortedquestionDataByAttributes: [],
      sortedAveragePerDimensions: [],
      sortedByDispersionQuestionDataByAttributes: [],
      sortedquestionDataByAttributesByItems: [],
      sortedAveragePerDimensionsByItems: [],
      sortedByDispersionQuestionDataByAttributesByItems: [],
      averagePerDimensionsObj: {},
      averagePerDimensionsByItemsObj: {},
      previousAveragePerDimensionsObj: {},
      dimensionAverageObj: {}
    }
  },
  mounted () {
    this.cultureLogoSrc = document.getElementById('occCultureLogo').src
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
    this.actualCultureSliderSrc = document.getElementById('actualCultureSlider').src
  },
  computed: {
    ...mapState({ user: (state) => state.session.user })
  },
  watch: {
    renderPart: {
      handler () {
        const hasFalses = Object.values(this.renderPart).includes(false)
        const dimentionsBars = this.renderPart.dimentionsBars === 9
        const attributesBars = this.renderPart.attributesBars === 72
        const horizontalAttributesBars = this.renderPart.horizontalAttributesBars === 72
        if (!hasFalses && dimentionsBars && attributesBars && horizontalAttributesBars) { this.renderPdf() }
      },
      deep: true
    },
    cultureLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureLogoSrc, (dataURL) => {
          this.cultureLogoBase64 = dataURL
        })
      }
    },
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    },
    actualCultureSliderSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.actualCultureSliderSrc, (dataURL) => {
          this.actualCultureSliderBase64 = dataURL
        })
      }
    }
  },
  methods: {
    writeRotatedText (text, color, format) {
      const canvas = document.createElement('canvas')
      if (format === 'portrait') {
        canvas.width = 50
        canvas.height = 845
      } else {
        canvas.width = 50
        canvas.height = 595
      }

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '20pt Roboto'
      ctx.fillStyle = 'white'
      if (format === 'portrait') {
        ctx.fillText(text.toUpperCase(), 290, -15)
      } else {
        ctx.fillText(text.toUpperCase(), 450, -15)
      }
      ctx.restore()

      return canvas.toDataURL()
    },
    generateBarChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 720
      canvas.height = 480

      const tableBarsChart = echarts.init(canvas)

      const required = this.hasRequired ? {
        data: [{
          value: this.round(this.cultureAverage[0], 2),
          itemStyle: { color: '#BFBFBF' }
        },
        {
          value: this.round(this.cultureAverage[1], 2),
          itemStyle: { color: '#BFBFBF' }
        },
        {
          value: this.round(this.cultureAverage[2], 2),
          itemStyle: { color: '#BFBFBF' }
        }],
        type: 'bar',
        label: {
          show: true,
          fontSize: 15,
          fontWeight: 'bold'
        }
      } : null

      const options = {
        xAxis: {
          type: 'category',
          data: [this.$t('requiredReport.responsible'), this.$t('requiredReport.competitive'), this.$t('requiredReport.humanist')]
        },
        yAxis: {
          type: 'value',
          max: 4,
          min: -4,
          interval: 1
        },
        series: [
          {
            data: [{
              value: this.round(this.averagePerCultureTypesByItems[0].average, 2),
              itemStyle: { color: '#000' }
            },
            {
              value: this.round(this.averagePerCultureTypesByItems[1].average, 2),
              itemStyle: { color: '#000' }
            },
            {
              value: this.round(this.averagePerCultureTypesByItems[2].average, 2),
              itemStyle: { color: '#000' }
            }],
            type: 'bar',
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold'
            }
          },
          {
            data: [{
              value: this.round(this.averagePerCultureTypes[0].average, 2),
              itemStyle: { color: '#0889D6' }
            },
            {
              value: this.round(this.averagePerCultureTypes[1].average, 2),
              itemStyle: { color: '#EB493C' }
            },
            {
              value: this.round(this.averagePerCultureTypes[2].average, 2),
              itemStyle: { color: '#48BBA1' }
            }],
            type: 'bar',
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold'
            }
          },
          required
        ]
      }
      tableBarsChart.setOption(options)
      tableBarsChart.on('finished', () => {
        this.chartPie = tableBarsChart.getDataURL()
        this.renderPart.chartPie = true
      })
      this.lockPdfButton = false
    },
    generateCakeChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 500
      canvas.height = 400

      const tableBarsChart = echarts.init(canvas)

      const options = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: ['hola']
        },
        series: [
          {
            name: 'grafica',
            type: 'pie',
            radius: ['60%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: `${this.totalReceived}`,
                name: '70%',
                itemStyle: {
                  color: 'grey'
                },
                label: {
                  show: false
                }
              },
              {
                value: `${this.totalObtained}`,
                name: `${this.totalParticipantsPercent}%`,
                itemStyle: {
                  color: '#0889D6'
                },
                label: {
                  position: 'center',
                  fontSize: 60
                }
              }
            ]
          }
        ]
      }
      tableBarsChart.setOption(options)
      tableBarsChart.on('finished', () => {
        this.chartCake = tableBarsChart.getDataURL()
        this.renderPart.chartCake = true
      })
      this.lockPdfButton = false
    },
    generateFlowerChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 1040
      canvas.height = 740

      const chartPieLocal = echarts.init(canvas)

      const base = {
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a'
      }

      const baseBlack = {
        ...base,
        name: 'z',
        color: '#000'
      }

      const baseGray = {
        ...base,
        name: 'z',
        color: '#BFBFBF'
      }

      const baseBlank = {
        ...base,
        name: 'blank',
        color: 'rgba(0,0,0,0)'
      }

      const headerData = [
        { value: this.$t('cultureReports.values'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.sustainability'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.customers'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.innovation'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.achievement'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.leadership'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.talent'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.relationship'), textStyle: { fontSize: 25 } },
        { value: this.$t('cultureReports.communication'), textStyle: { fontSize: 25 } }
      ]

      const colorData = [
        'rgba(139, 194, 229, 0.8)',
        'rgba(66, 139, 190, 0.8)',
        'rgba(41, 89, 125, 0.8)',
        'rgba(213, 84, 75, 0.8)',
        'rgba(225, 85, 71, 0.8)',
        'rgba(184, 54, 24, 0.8)',
        'rgba(151, 214, 201, 0.8)',
        'rgba(77, 187, 154, 0.8)',
        'rgba(72, 142, 118, 0.8)'
      ]

      const setData = (idx, value) => {
        const baseData = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        baseData[idx] = value
        return baseData
      }

      const setColor = (idx, value) => ({
        ...base,
        data: setData(idx, value),
        name: headerData[idx],
        color: colorData[idx]
      })

      const setSpace = (idx, value) => ({
        ...baseBlank,
        data: setData(idx, value)
      })

      const setBlack = (idx) => ({
        ...baseBlack,
        data: setData(idx, 0.1)
      })

      const setGray = (idx) => ({
        ...baseGray,
        data: setData(idx, 0.1)
      })

      const getData = (current, cut, idx) => {
        const data = []
        if (current > cut) {
          data.push(setColor(idx, cut + 4))
          data.push(setBlack(idx))
          data.push(setColor(idx, current - cut))
        } else {
          data.push(setColor(idx, current + 4))
          data.push(setSpace(idx, cut - current))
          data.push(setBlack(idx))
        }
        return data
      }

      // Black cut - Color current - Gray req
      const getDataReq = (current, cut, req, idx) => {
        const data = []
        if (cut < req) {
          if (cut < current) {
            data.push(setColor(idx, (cut + 4) - 0.1))
            data.push(setBlack(idx))
            if (req > current) {
              data.push(setColor(idx, current - cut))
              data.push(setSpace(idx, req - current - 0.1))
              data.push(setGray(idx))
            } else {
              data.push(setColor(idx, req - 0.1))
              data.push(setGray(idx))
              if (req < current && (req - current) > 0.1) {
                data.push(setColor(idx, current - req))
              }
            }
          } else {
            data.push(setColor(idx, current + 4))
            data.push(setSpace(idx, cut - current - 0.1))
            data.push(setBlack(idx))
            if (req > cut && (req - cut) > 0.1) {
              data.push(setSpace(idx, req - cut - 0.1))
            }
            data.push(setGray(idx))
          }
        } else {
          if (req < current) {
            data.push(setColor(idx, (req + 4) - 0.1))
            data.push(setGray(idx))
            if (cut > current) {
              data.push(setColor(idx, current - req))
              data.push(setSpace(idx, cut - current - 0.1))
              data.push(setBlack(idx))
            } else {
              data.push(setColor(idx, cut - req - 0.1))
              data.push(setBlack(idx))
              if (cut < current && (cut - current) > 0.1) {
                data.push(setColor(idx, current - cut))
              }
            }
          } else {
            data.push(setColor(idx, current + 4))
            data.push(setSpace(idx, req - current - 0.1))
            data.push(setGray(idx))
            if (cut > req && (cut - req) > 0.1) {
              data.push(setSpace(idx, cut - req - 0.1))
            }
            data.push(setBlack(idx))
          }
        }
        return data
      }
      const ooo = {
        angleAxis: {
          type: 'category',
          data: headerData,
          z: 10
        },
        radiusAxis: {
          min: 0,
          max: 8,
          interval: 0.5,
          axisLabel: {
            show: true,
            fontWeight: 'bold',
            fontSize: 18,
            formatter: (value) => +value % 1 === 0 ? (value - 4) : ''
          },
          splitLine: {
            interval: 0.5,
            show: true
          },
          splitArea: {
            interval: 0.5,
            show: true
          }
        },
        polar: {},
        series: this.hasRequired
          ? [
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'values', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'values', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'values', 'score'), 0),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'sustainability', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'sustainability', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'sustainability', 'score'), 1),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'customers', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'customers', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'customers', 'score'), 2),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'innovation', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'innovation', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'innovation', 'score'), 3),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'achievement', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'achievement', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'achievement', 'score'), 4),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'leadership', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'leadership', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'leadership', 'score'), 5),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'talent', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'talent', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'talent', 'score'), 6),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'relationship', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'relationship', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'relationship', 'score'), 7),
            ...getDataReq(dataObj.arrayFindByName(this.averagePerDimensions, 'communication', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'communication', 'average'), dataObj.arrayFindByName(this.dimensionAverage, 'communication', 'score'), 8)
          ]
          : [
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'values', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'values', 'average'), 0),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'sustainability', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'sustainability', 'average'), 1),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'customers', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'customers', 'average'), 2),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'innovation', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'innovation', 'average'), 3),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'achievement', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'achievement', 'average'), 4),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'leadership', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'leadership', 'average'), 5),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'talent', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'talent', 'average'), 6),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'relationship', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'relationship', 'average'), 7),
            ...getData(dataObj.arrayFindByName(this.averagePerDimensions, 'communication', 'average'), dataObj.arrayFindByName(this.averagePerDimensionsByItems, 'communication', 'average'), 8)
          ],
        barWidth: '100%'
      }
      chartPieLocal.setOption(ooo)

      chartPieLocal.on('finished', () => {
        this.flowerChart = chartPieLocal.getDataURL()
        this.renderPart.flowerChart = true
      })
    },
    insertLineBreak (text) {
      let texto = text.split('')
      const long = texto.length
      if (long > this.lineLength) {
        for (let i = this.lineLength; i >= 0; i--) {
          if (texto[i] === ' ') {
            texto[i] = '\n'
            const restan = long - (i + 1)
            if (restan > this.lineLength) {
              const text1 = texto.splice(i + 1, restan).join('')
              const resto = this.insertLineBreak(text1)
              texto = texto.splice(0, i + 1).concat(resto.split(''))
            }
            break
          }
        }
      }
      return texto.join('')
    },
    generateBarChartPerCultures (dimensions, attributes) {
      this.barCharts = []
      for (let index = 0; index < dimensions.length; index++) {
        let percentNegative = (dimensions[index].totalNegative / (this.totalFiltered * 8)) * 100
        let percentNeutral = (dimensions[index].totalNeutral / (this.totalFiltered * 8)) * 100
        let percentPositive = (dimensions[index].totalPositive / (this.totalFiltered * 8)) * 100
        const canvas = document.createElement('canvas')
        canvas.width = 720
        canvas.height = 700
        const tableBarsChart = echarts.init(canvas)
        let colorCulture = ''
        let color1 = ''
        let color2 = ''
        let color3 = ''
        if (index < 3) {
          colorCulture = '#0889D6'
          color1 = '#3473D0'
          color2 = '#0946A0'
          color3 = '#06367C'
        } else if (index > 2 && index < 6) {
          colorCulture = '#EB493C'
          color1 = '#FF6050'
          color2 = '#DD1803'
          color3 = '#AD1000'
        } else {
          colorCulture = '#48BBA1'
          color1 = '#2BA379'
          color2 = '#037A50'
          color3 = '#005F3D'
        }
        const options = {
          title: {
            text: (this.$t(`cultureReports.${dimensions[index].name}`)).toUpperCase(),
            textAlign: 'middle',
            left: 'middle',
            textStyle: {
              fontSize: 40
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '8%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: [
              {
                // value: `-\n${percentNegative.toFixed(2)} %`,
                value: `-\n${this.round(percentNegative, 2)} %`,
                textStyle: {
                  fontSize: 35
                }
              },
              {
                // value: `0\n${percentNeutral.toFixed(2)} %`,
                value: `0\n${this.round(percentNeutral, 2)} %`,
                textStyle: {
                  fontSize: 35
                }
              },
              {
                // value: `+\n${percentPositive.toFixed(2)} %`,
                value: `+\n${this.round(percentPositive, 2)} %`,
                textStyle: {
                  fontSize: 35
                }
              }
            ]
          },
          yAxis: {
            type: 'value',
            data: [
              {
                textStyle: {
                  fontSize: 55
                }
              }
            ]
          },
          series: [{
            data: [{
              value: dimensions[index].totalNegative,
              itemStyle: { color: color1 }
            },
            {
              value: dimensions[index].totalNeutral,
              itemStyle: { color: color2 }
            },
            {
              value: dimensions[index].totalPositive,
              itemStyle: { color: color3 }
            }],
            type: 'bar',
            label: {
              show: true,
              fontSize: 50,
              fontWeight: 'bold'
            }
          }],
          barWidth: '55%'
        }
        tableBarsChart.setOption(options)
        tableBarsChart.on('finished', () => {
          this.barCharts.push(tableBarsChart.getDataURL())
          this.renderPart.dimentionsBars++
        })
        this.generateHorizontalBarPerDimension(index, dimensions[index].name, colorCulture)
      }

      for (let key in attributes) {
        let color1 = ''
        let color2 = ''
        let color3 = ''
        let colorCulture = ''
        if (key === 'values' || key === 'sustainability' || key === 'customers') {
          colorCulture = '#0889D6'
          color1 = '#3473D0'
          color2 = '#0946A0'
          color3 = '#06367C'
        } else if (key === 'innovation' || key === 'achievement' || key === 'leadership') {
          colorCulture = '#EB493C'
          color1 = '#FF6050'
          color2 = '#DD1803'
          color3 = '#AD1000'
        } else {
          colorCulture = '#48BBA1'
          color1 = '#2BA379'
          color2 = '#037A50'
          color3 = '#005F3D'
        }
        this.barChartsAttributes[key] = []
        for (let index = 0; index < attributes[key].length; index++) {
          let percentNegative = this.$round((attributes[key][index].totalNegative / this.totalFiltered) * 100)
          let percentNeutral = this.$round((attributes[key][index].totalNeutral / this.totalFiltered) * 100)
          let percentPositive = this.$round((attributes[key][index].totalPositive / this.totalFiltered) * 100)
          const canvas = document.createElement('canvas')
          canvas.width = 720
          canvas.height = 700
          const tableBarsChart = echarts.init(canvas)
          const title = attributes[key][index].traduction[this._i18n.locale]
          const top = Math.ceil(title.length / this.lineLength) * 5
          const optionsTableBarCharts = {
            title: {
              text: this.insertLineBreak(title),
              textAlign: 'justify',
              top: 0,
              textStyle: {
                fontSize: 30
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '5%',
              top: top >= 10 ? top + '%' : '10%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: [
                {
                  value: `-\n${percentNegative} %`,
                  textStyle: {
                    fontSize: 30
                  }
                },
                {
                  value: `0\n${percentNeutral} %`,
                  textStyle: {
                    fontSize: 30
                  }
                },
                {
                  value: `+\n${percentPositive} %`,
                  textStyle: {
                    fontSize: 30
                  }
                }
              ]
            },
            yAxis: {
              type: 'value',
              data: [
                {
                  textStyle: {
                    fontSize: 50
                  }
                }
              ]
            },
            series: [{
              data: [{
                value: attributes[key][index].totalNegative,
                itemStyle: { color: color1, barBorderRadius: [0, 0, 0, 0] }
              },
              {
                value: attributes[key][index].totalNeutral,
                itemStyle: { color: color2, barBorderRadius: [0, 0, 0, 0] }
              },
              {
                value: attributes[key][index].totalPositive,
                itemStyle: { color: color3, barBorderRadius: [0, 0, 0, 0] }
              }],
              type: 'bar',
              label: {
                show: true,
                fontSize: 50,
                fontWeight: 'bold'
              }
            }],
            barWidth: '50%'
          }

          tableBarsChart.setOption(optionsTableBarCharts)
          tableBarsChart.on('finished', () => {
            this.barChartsAttributes[key].push(tableBarsChart.getDataURL())
            this.renderPart.attributesBars++
          })
          this.generateHorizontalBarCharts(key, index, colorCulture)
        }
      }
      this.lockPdfButton = false
    },
    generateHorizontalBarCharts (k, i, c) {
      this.horizontalBarChartsAttributes[k] = []
      const secondCanvas = document.createElement('canvas')
      secondCanvas.width = 500
      secondCanvas.height = 600
      const horizontalBarsChart = echarts.init(secondCanvas)
      const value = this.questionDataByAttributes.filter(element => element.dimensionsName === k)
      const valueByItem = this.questionDataByAttributesByItems.filter(element => element.dimensionsName === k)
      const dimention = k === 'values' ? 0 : (k === 'sustainability' ? 1 : (k === 'customers' ? 2 : (k === 'innovation' ? 3 : (k === 'achievement' ? 4 : (k === 'leadership' ? 5 : (k === 'talent' ? 6 : (k === 'relationship' ? 7 : 8)))))))
      const valueReq = this.hasRequired ? this.dataAttributes[dimention] : valueByItem
      const optionsHorizontalBarCharts = {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          min: -4,
          max: 4,
          interval: 1
        },
        yAxis: {
          type: 'category'
        },
        series: [
          {
            type: 'bar',
            data: [{
              value: valueByItem[i].actScore === 0 ? 0.01 : valueByItem[i].actScore,
              label: `${valueByItem[i].actScore === 0 ? 0.01 : valueByItem[i].actScore}`,
              itemStyle: { color: 'black', barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          },
          {
            type: 'bar',
            barGap: 0,
            data: [{
              value: value[i].actScore === 0 ? 0.01 : value[i].actScore,
              itemStyle: { color: c, barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          },
          this.hasRequired ? {
            type: 'bar',
            data: [{
              value: valueReq[i].score === 0 ? 0.01 : valueReq[i].score,
              itemStyle: { color: '#BFBFBF', barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          } : null
        ]
      }
      horizontalBarsChart.setOption(optionsHorizontalBarCharts)
      horizontalBarsChart.on('finished', () => {
        this.horizontalBarChartsAttributes[k].push(horizontalBarsChart.getDataURL())
        this.renderPart.horizontalAttributesBars++
      })
    },
    generateHorizontalBarPerDimension (index, keyName, color) {
      this.horizontalBarChartsDimensions[`${index}-${keyName}`] = []
      const canvas = document.createElement('canvas')
      canvas.width = 500
      canvas.height = 600
      const horizontalBarsChart = echarts.init(canvas)
      const optionsHorizontalBarCharts = {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          min: -4,
          max: 4,
          interval: 1
        },
        yAxis: {
          type: 'category'
        },
        series: [
          {
            type: 'bar',
            barGap: 0,
            data: [{
              value: this.averagePerDimensionsByItems[index].average === 0 ? 0.01 : this.averagePerDimensionsByItems[index].average,
              itemStyle: { color: 'black', barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          },
          {
            type: 'bar',
            barGap: 0,
            data: [{
              value: this.averagePerDimensions[index].average === 0 ? 0.01 : this.averagePerDimensions[index].average,
              itemStyle: { color: color, barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          },
          this.hasRequired ? {
            type: 'bar',
            barGap: 0,
            data: [{
              value: this.dimensionAverage[index].score === 0 ? 0.01 : this.dimensionAverage[index].score,
              itemStyle: { color: '#BFBFBF', barBorderRadius: [0, 0, 0, 0] }
            }],
            label: {
              show: false,
              fontSize: 60
            }
          } : null
        ]
      }
      horizontalBarsChart.setOption(optionsHorizontalBarCharts)
      horizontalBarsChart.on('finished', () => {
        this.horizontalBarChartsDimensions[`${index}-${keyName}`].push(horizontalBarsChart.getDataURL())
        this.renderPart.horizontalDimensionsBars++
      })
    },
    setValuesInBarChart () {
      let dimensions = []
      let attributes = {
        values: [],
        sustainability: [],
        customers: [],
        talent: [],
        relationship: [],
        communication: [],
        innovation: [],
        achievement: [],
        leadership: []
      }
      let attrIndex = 0
      const keys = Object.keys(this.answerValuesByDimensionsByItems)
      for (let i = 0; i < keys.length; i++) {
        const dimensionsValues = this.answerValuesByDimensionsByItems[keys[i]]
        dimensions.push({
          name: dimensionsValues.name,
          totalNegative: dimensionsValues.negAcum,
          totalNeutral: dimensionsValues.zeroAcum,
          totalPositive: dimensionsValues.posAcum
        })
        for (let j = 0; j < this.answerValuesByAtributtesByItems[keys[i]].values.length; j++) {
          let attributesValues = {
            totalNegative: this.answerValuesByAtributtesByItems[keys[i]].values[j].negAcum,
            totalNeutral: this.answerValuesByAtributtesByItems[keys[i]].values[j].zeroAcum,
            totalPositive: this.answerValuesByAtributtesByItems[keys[i]].values[j].posAcum,
            traduction: this.questionDataByAttributes[attrIndex].positive,
            averageScore: this.questionDataByAttributes[attrIndex].actScore,
            dispersion: this.questionDataByAttributes[attrIndex++].dispersion
          }
          attributes[keys[i]].push(attributesValues)
        }
      }
      this.generateBarChartPerCultures(dimensions, attributes)
    },
    openPdf () {
      this.$store.dispatch('loading/show')
      this.$getCalcData(this._i18n.locale)
        .then(() => this.hasRequired ? this.$getInitialData() : undefined)
        .then(() => {
          this.setAverageArrayToObject()
          this.generateCakeChart()
          this.generateBarChart()
          this.generateFlowerChart()
          this.setValuesInBarChart()
          this.$generateWordsCloud()
        })
    },
    calculatePages () {
      // Rojo: #EB493C
      // Azul: #0889D6
      // Verde: #48BBA1
      return [
        { text: this.$t('cultureReports.current_culture_organization'), sta: 3, end: 3, color: '#BFBFBF' },
        { text: this.$t('cultureReports.occ_tool'), sta: 4, end: 4, color: '#BFBFBF' },
        { text: this.$t('cultureReports.responsible_culture'), sta: 5, end: 5, color: '#309DE0' },
        { text: this.$t('cultureReports.competitive_culture'), sta: 6, end: 6, color: '#EB493C' },
        { text: this.$t('cultureReports.humanist_culture'), sta: 7, end: 7, color: '#48BBA1' },
        { text: this.$t('cultureReports.understanding_results'), sta: 8, end: 9, color: '#BFBFBF' },
        { text: this.$t('cultureReports.results'), sta: 10, end: 10 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.culture_types'), sta: 11 + (this.extraPage + this.fitsExtraPage), end: 11 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.culture_dimensions'), sta: 12 + (this.extraPage + this.fitsExtraPage), end: 13 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.responsible_culture'), sta: 14 + (this.extraPage + this.fitsExtraPage), end: 19 + (this.extraPage + this.fitsExtraPage), color: '#0889D6' },
        { text: this.$t('cultureReports.competitive_culture'), sta: 20 + (this.extraPage + this.fitsExtraPage), end: 25 + (this.extraPage + this.fitsExtraPage), color: '#EB493C' },
        { text: this.$t('cultureReports.humanist_culture'), sta: 26 + (this.extraPage + this.fitsExtraPage), end: 31 + (this.extraPage + this.fitsExtraPage), color: '#48BBA1' },
        { text: this.$t('cultureReports.ranking'), sta: 32 + (this.extraPage + this.fitsExtraPage), end: 37 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.gaps'), sta: ((this.extraPage + this.fitsExtraPage) + 38), end: ((this.extraPage + this.fitsExtraPage) + 41), color: '#BFBFBF' },
        { text: this.$t('cultureReports.dispersion'), sta: 42 + (this.extraPage + this.fitsExtraPage), end: 43 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.rates'), sta: 44 + (this.extraPage + this.fitsExtraPage), end: 44 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' },
        { text: this.$t('cultureReports.open_questions'), sta: 45 + (this.extraPage + this.fitsExtraPage), end: 47 + (this.extraPage + this.fitsExtraPage), color: '#BFBFBF' }
      ]
    },
    renderPdf () {
      this.$store.dispatch('loading/show')
      this.$emit('render-pdf')
      Object.keys(this.renderPart).forEach((key) => { this.renderPart[key] = false })

      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.hasRequired ? this.$t('reports.required_sub_culture_title') : this.$t('reports.sub_culture_title'),
          author: 'OCC',
          subject: this.hasRequired ? this.$t('reports.required_sub_culture_title') : this.$t('reports.sub_culture_title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: (currentPage, pageCount, pageSize) => {
          const resultObj = {
            image: this.cultureLogoBase64,
            height: 60,
            width: 180,
            margin: [15, 15, 25, 15]
          }
          if (currentPage === 1) {
            return [{}]
          }

          const p = currentPage - (this.extraPage + this.fitsExtraPage)
          if ([14, 16, 18, 20, 22, 24, 26, 28, 30, 33, 34, 35, 36, 38, 39, 40, 41, 42, 43].indexOf(p) !== -1) {
            return [{}]
          }
          return [resultObj]
        },
        footer: (currentPage, pageCount) => {
          return [
            {
              columns: pdfUtil.generateCenteredText(this.$t('cultureReports.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage, pageSize) => {
          const temp = this.calculatePages()
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const label = pageCont.text
          const color = pageCont.color
          let result = {}
          const p = currentPage - (this.extraPage + this.fitsExtraPage)
          if ([14, 16, 18, 20, 22, 24, 26, 28, 30, 33, 34, 35, 36, 38, 39, 40, 41, 42, 43].indexOf(p) !== -1) {
            result = {
              image: this.writeRotatedText(label, color, 'landscape'),
              aligment: 'center',
              absolutePosition: { x: 792, y: 0 }
            }
          } else {
            result = {
              image: this.writeRotatedText(label, color, 'portrait'),
              aligment: 'center',
              absolutePosition: { x: 545, y: 0 }
            }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice
          this.$generateIntroduction(),
          // 2 Dimensions
          this.$generateDimensions(),
          // Rates
          this.$generateDimensionRates(),
          // Resultados por dimesiones
          this.$generateResults(),
          // Resultados por atributos
          this.$generateAtrributesTables(),
          // Rankings
          this.$generateRankings(),
          this.$generateDiffRanking(),
          this.$generateDiffRankingReq(),
          this.$generateDispersionRanking(),
          this.$generateTrendRanking(),
          // Open questions
          this.$generateWordQuestions()

        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.currentPoll.name} (${this.reportItemsNameText}).pdf`)
          this.extraPage = 0
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration)
          .download(`${this.currentPoll.name} (${this.reportItemsNameText})`, () => {
            this.extraPage = 0
            this.$store.dispatch('loading/hide')
          })
      }

      this.lockPdfButton = false
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    }
  }
})
</script>
