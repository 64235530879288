
import dataObj from '../utils/data-obj'

export default {
  methods: {
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    $generateRanking () {
      const greatestDimensionAvg = this.dimensionAverage.sort((a, b) => b.score - a.score)
      this.attributesRanking = this.attributesArray.sort((a, b) => b.score - a.score)
      return [
        // 4.4 Ranking dimensiones
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.4 ${this.$t('requiredReport.ranking_dimensions')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        // Puntaje mas alto
        {
          layout: {
            defaultBorder: '',
            fillColor: '#a6a6a6'
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('requiredReport.dimensions_with_higher_score'), margin: [25, 5, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [50, 10, 20, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: this.$t('requiredReport.dimension'), margin: [80, 5, 80, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.required'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.last_score'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[0].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[0].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[0].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[1].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[1].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[1].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[2].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[2].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[2].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ]
            ]
          },
          margin: [10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 14
        },
        // Puntaje mas bajo
        {
          layout: {
            defaultBorder: '',
            fillColor: '#a6a6a6'
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('requiredReport.dimensions_with_lower_score'), margin: [25, 5, 0, 5], bold: true, color: '#fff', fontSize: 13 }]
            ]
          },
          margin: [50, 10, 20, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: this.$t('requiredReport.dimension'), margin: [80, 5, 80, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.required'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.last_score'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[8].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[8].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[8].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[7].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[7].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[7].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ],
              [{ text: this.$t(`requiredReport.${greatestDimensionAvg[6].name}`), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: this.round(greatestDimensionAvg[6].score, 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] },
                { text: dataObj.arrayFindByNameFixed(this.previousDimensionAverage, greatestDimensionAvg[6].name, 'score', ''), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 15, 0, 15] }
              ]
            ]
          },
          margin: [10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 14
        },
        // 4.5 Ranking de los atributos
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('requiredReport.ranking_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        // Atributos con puntaje mas alto
        {
          layout: {
            defaultBorder: '',
            fillColor: '#a6a6a6'
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('requiredReport.attributes_with_higher_score'), margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [50, 10, -40, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredReport.attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.previous_score_abbreviation'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[0].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[0].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[0].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[0].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[1].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[1].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[1].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[1].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[2].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[2].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[2].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[2].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[3].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[3].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[3].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[3].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[4].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[4].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[4].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[4].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[5].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[5].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[5].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[5].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[6].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[6].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[6].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[6].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[7].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[7].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[7].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[7].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [0, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Puntaje mas bajo
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('requiredReport.ranking_attributes')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0],
          pageBreak: 'before'
        },
        // Atributos con puntaje mas bajo
        {
          layout: {
            defaultBorder: '',
            fillColor: '#a6a6a6'
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('requiredReport.attributes_with_lower_score'), margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [50, 10, -40, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredReport.attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredReport.previous_score_abbreviation'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 1].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 1].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 1].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 1].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 2].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 2].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 2].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 2].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 3].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 3].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 3].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 3].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 4].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 4].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 4].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 4].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 5].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 5].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 5].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 5].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 6].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 6].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 6].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 6].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 7].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 7].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 7].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 7].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 15, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 8].positive[this.user.lang],
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15]
                },
                {
                  text: this.$t(`requiredReport.initials.${this.attributesRanking[this.attributesRanking.length - 8].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: this.attributesRanking[this.attributesRanking.length - 8].score.toFixed(2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                },
                {
                  text: dataObj.arrayFindByNameFixed(this.previousAttributesArray, this.attributesRanking[this.attributesRanking.length - 8].name, 'score', ''),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#ddd'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 15],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [0, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        }
      ]
    }
  }
}
