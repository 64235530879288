
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1 left">{{ requiredCulture.name }}</h4>
        <v-chip
          disabled
          class="mb-3 white--text right"
          color="primary"
          v-if="requiredCulture.displayName"
        >{{ requiredCulture.displayName }}</v-chip>
      </v-col>

      <v-col cols="12">
        <v-card>
          <!-- General -->
          <v-card-text>
            <h5 class="headline mb-2">{{ $t('reports.required_general_title') }}</h5>

            <p class="mt-5 mb-12">{{ $t('reports.required_general_desc') }}</p>
            <v-row justify="center">
              <span v-if="displayReport">
                <x-report
                  @render-pdf="reloadReport"
                  :poll-id="Number.parseInt($route.params.id)"
                  :disable-button="disableButton"
                  :button-text="$t('reports.general_report')"
                ></x-report>
              </span>
            </v-row>
          </v-card-text>

          <v-divider class="mt-12"></v-divider>

          <v-row justify="center">
            <v-col cols="10">
              <v-btn
                class="ml-5 my-3"
                @click="goBack()"
                large
                block
              >{{ $t('input.back') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-text style="padding: 24px 20px;">
          {{ $t('reports.no_answers_modal_msg') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showModal = !showModal"
          >
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

// import resolver from '../../utils/resolver';
import XReport from '../reports/required_culture_report.vue'
import requiredCultureService from '../../services/required-culture'

export default {
  components: {
    XReport
  },
  data () {
    return {
      cutsSelected: {},
      displayCuts: false,
      disableButton: false,
      disableBottomButton: false,
      requiredCulture: {},
      displayReport: true,
      openAnswers: [],
      disableOpenPie: true,
      demographicsFiltered: [],
      demographicsSelects: {},
      showModal: false
    }
  },
  created () {
    this.getRequiredCulture()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getRequiredCulture () {
      this.loading = true
      requiredCultureService.getOneById(this.$route.params.id)
        .then((res) => {
          if (res.status !== 'completed') {
            this.goBack()
          }
          if (res.attributeRelevance.length === 0) {
            this.showModal = true
          } else {
            this.requiredCulture = res
            this.disableButton = true
          }
          this.loading = false
        })
    },
    reloadReport () {
      this.disableButton = false
      this.$nextTick().then(() => { this.disableButton = true })
    }
  }
}
</script>
