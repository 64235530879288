<template>
  <v-row row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex
              xs12
              md8
              class="pt-12 offset-md2 text-sm-center grey--text text--darken-1"
            >
              <img :src="icon" width="220"/>

              <h2 class="display-3 mt-5 error--text">{{ text }}</h2>

              <p class="headline mt-5"></p>

              <v-btn
                v-if="button"
                @click="goBack()"
                class="mt-4"
                color="primary"
                large
              >{{ $t('input.back') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    dialog: Boolean,
    icon: String,
    text: String,
    button: {
      type: Boolean,
      default: false,
      optional: true
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
})
</script>
