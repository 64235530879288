
export default {
  methods: {
    $generateDimensions () {
      return [
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.3 ${this.$t('cultureReports.culture_dimensions')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 35, 0, 15],
          pageBreak: 'before'
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.culture_dimensions_next'),
          aligment: 'justify'
        },
        {
          color: '#444',
          margin: [0, 10],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.values').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.values_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.values_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.sustainability').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.sustainability_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.customers').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.customers_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.customers_negative'), alignment: 'justify' }]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#2196F3' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [0, 35, 0, 0],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.innovation').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.innovation_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.achievement').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.achievement_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.leadership').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.leadership_negative'), alignment: 'justify' }]
            ],
            margin: [0, 35, 0, 0]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#F23636' : null
            }
          }
        },
        {
          color: '#444',
          pageBreak: 'before',
          margin: [0, 35, 0, 0],
          table: {
            widths: ['5%', '85%'],
            body: [
              [{ text: this.$t('cultureReports.talent').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.talent_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.talent_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.relationship').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.relationship_negative'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.communication').toUpperCase(), color: '#fff', margin: [0, 6], colSpan: 2, alignment: 'center' }, {}],
              [{ text: this.$t('cultureReports.yes'), alignment: 'center' }, { text: this.$t('cultureReports.communication_positive'), alignment: 'justify' }],
              [{ text: this.$t('cultureReports.no'), alignment: 'center' }, { text: this.$t('cultureReports.communication_negative'), alignment: 'justify' }]
            ],
            margin: [0, 35, 0, 0]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 3 === 0) ? '#4DC285' : null
            }
          }
        },
        // 3
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `3. ${this.$t('cultureReports.understanding_results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.1 ${this.$t('cultureReports.answers_rate')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 1, 0, 15]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.answers_rate_text'),
          margin: [0, 0, 10, 0],
          lineHeight: 1.5,
          aligment: 'justify'
        },
        {
          ul: this.$t('cultureReports.answers_rate_items'),
          width: 'auto',
          color: '#839291',
          aligment: 'justify',
          margin: [0, -10, 10, 0],
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.2 ${this.$t('cultureReports.current_context')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, -5, 0, 15]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.current_context_text'),
          margin: [0, 0, 10, 0],
          lineHeight: 1.5,
          aligment: 'justify'
        },
        {
          ul: this.$t('cultureReports.current_context_questions'),
          width: 'auto',
          color: '#839291',
          aligment: 'justify',
          margin: [0, -10, 10, 0],
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.3 ${this.$t('cultureReports.result_analysis')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, -5, 0, 15]
        },
        {
          ul: this.$t('cultureReports.results_analysis_req_p1'),
          width: 'auto',
          color: '#839291',
          aligment: 'justify',
          margin: [0, 0, 10, 0],
          lineHeight: 1.5
        },
        {
          ul: this.$t('cultureReports.results_analysis_req_p2'),
          width: 'auto',
          color: '#839291',
          aligment: 'justify',
          margin: [0, 35, 10, 0],
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `3.4 ${this.$t('cultureReports.what_do_with_results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, -5, 0, 15]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.what_do_with_results_text'),
          margin: [0, 0, 22, 0],
          lineHeight: 1.5,
          aligment: 'justify'
        },
        {
          ul: this.$t('cultureReports.results_steps_req'),
          type: 'square',
          width: 'auto',
          color: '#839291',
          aligment: 'justify',
          margin: [0, 0, 22, 0],
          lineHeight: 1.5
        }
      ]
    }
  }
}
