
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1 mb-3">{{ $t('navigation.open_questions') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-row >
            <v-col cols="12" class="pa-5 headline">
              {{ $t('questions.questions') }}
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-card-text>
            <v-container grid-list-md text-xs-center>
              <v-row wrap>
                <v-col cols="12">
                  <v-text-field
                    v-for="(question, index) in formatedQuestions"
                    v-bind:key="index"
                    v-model="question.label"
                    outlined
                    :append-icon="question.icon || 'mdi-content-save-edit-outline'"
                    autofocus
                    name="name"
                    :data-vv-as="$t('input.first_name') | lowerCase"
                    :color="question.color || 'blue'"
                    @keyup.enter="editQuestion(question)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import OpenQuestionService from '../../services/open-questions'

export default Vue.extend({
  data () {
    return {
      formatedQuestions: [],
      language: '',
      languages: [],
      questions: []
    }
  },
  methods: {
    getOpenQuestions (options) {
      OpenQuestionService.list(options)
        .then(resp => ({
          items: resp,
          total: resp.length
        }))
    },
    formatQuestionsByLanguage () {
      this.formatedQuestions = this.questions.map((question) => ({
        name: question.name,
        color: 'blue',
        icon: 'mdi-content-save-edit-outline',
        label: question.translation[this.language]
      }))
    },
    changeColor (item, success) {
      item.icon = success ? 'mdi-check-bold' : 'mdi-cancel'
      item.color = success ? 'green' : 'red'
    },
    defaultColors (item) {
      item.color = 'blue'
      item.icon = 'mdi-content-save-edit-outline'
    },
    questionEmply (item) {
      this.$store.dispatch('alert/error', this.$t('errors.question_emply'))
      this.changeColor(item)
    },
    editQuestion (item) {
      item.label === '' ? this.questionEmply(item) : OpenQuestionService.updateQuestion({ ...item, lang: this.language })
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('questions.update_question'))
          this.changeColor(item, true)
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.changeColor(item)
        })
    }
  },
  created () {
    resolver.all({
      // languages: languagesService.list(),
      questions: OpenQuestionService.list()
    })
      .then(({ languages, questions }) => {
        this.language = this.user.lang
        /* this.languages = languages.items.map((l) => ({
          text: l.translate.label,
          value: l.code
        })) */
        this.questions = questions
      })
      .catch((error) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
      })
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    language () {
      this.formatQuestionsByLanguage()
    }
  }
})
</script>
