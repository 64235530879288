<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" md="7" class="text-center grey--text text--darken-1 mt-4">
            <section class="enterprise-logo">
              <img :src="logo" />
            </section>

            <h3 class="display-2 mt-3">{{ $t('culturePulses.entry_title') }}</h3>

            <p class="headline mt-5" v-html="$t('culturePulses.entry_text')"></p>

            <v-row class="mt-8">
              <v-col cols="12" sm="6">
                <v-icon x-large class="mb-1">mdi-comment-question-outline</v-icon>
                <div class="d-inline headline">
                  {{ $t('culturePulses.questions_n', {n: countQuestion}) }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-icon x-large class="mb-2">mdi-clock-outline</v-icon>
                <div class="d-inline headline">
                  {{ $t('culturePulses.minutes_n', { n: countMinutes }) }}
                </div>
              </v-col>
            </v-row>

          </v-col>

          <!-- Explanation -->
          <v-col cols="12" class="pa-0 text-center grey--text text--darken-3">
            <p class="ma-4 mt-3 px-12" v-html="$t('actualEvaluation.instructions_2')"></p>
            <img src="/img/ejemplo-cultura-actual.gif" width="100%"/>
          </v-col>
        </v-row>
      </v-container>

      <!-- Action Buttons -->
      <v-card-actions class="justify-center pt-0 pb-9">
        <v-btn large
          color="primary"
          class="mt-5"
          @click="() => { $emit('continue-entry') }"
        >
          {{ $t('culturePulses.answer_poll') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    logo: String,
    countQuestion: Number,
    countMinutes: Number
  }
}
</script>
