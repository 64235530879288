
<template>
  <v-container>
    <!-- Current -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense class="mb-3 white--text" color="primary">
            <v-toolbar-title class="text-uppercase">{{ $t('currentCulture.resume') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="info.current.length">
            <v-row justify="center">
              <v-col md="4" xs="12" v-for="current in info.current" :key="current._id">
                <v-card>
                  <v-toolbar dense class="mb-3">
                    <v-toolbar-title class="center--text">{{current.name}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col md="6" xs="12" align="center">
                        <span> {{ $t('input.status') }} </span>
                      </v-col>
                      <v-col md="6" xs="12" align="center" class="hidden-xs-only">
                        <span> {{ $t('currentCulture.answers_percent') }} </span>
                      </v-col>
                      <v-col md="6" xs="12" align="center">
                        <v-chip
                          color="success"
                          outlined
                          v-if="current.status === 'completed'"
                        >
                          {{ $t(`currentCulture.${current.status}`) }}
                        </v-chip>
                        <v-chip
                          color="primary"
                          outlined
                          v-else-if="current.status === 'progress'"
                        >
                          {{ $t(`currentCulture.${current.status}`) }}
                        </v-chip>
                        <v-chip
                          color="warning"
                          outlined
                          v-else-if="current.status === 'pending'"
                        >
                          {{ $t(`currentCulture.${current.status}`) }}
                        </v-chip>
                        <v-chip
                          color="error"
                          outlined
                          v-else
                        >
                          {{ $t(`currentCulture.${current.status}`) }}
                        </v-chip>
                      </v-col>
                      <v-col md="6" xs="12" align="center" class="hidden-xs-only">
                        <v-progress-circular
                          :rotate="360"
                          :size="100"
                          :width="15"
                          :value="current.population ? (current.answers * 100) / current.population : 0"
                          color="primary"
                        >
                          {{ current.population ? ((current.answers * 100) / current.population).toFixed(2) : 0 }}%
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" md="6">
                        {{ $t('currentCulture.requested') }}
                        <v-chip
                          outlined
                          class="px-2"
                        >
                          {{current.population}}
                        </v-chip>
                      </v-col>
                      <v-col xs="12" md="6" class="text-right">
                        {{ $t('currentCulture.received') }}
                        <v-chip
                          outlined
                          class="px-2"
                        >
                          {{current.answers}}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align="center">
                        <v-btn
                          color="primary"
                          :to="`/current-culture-assessments/${current._id}/show`"
                          outlined
                          rounded
                          class="text-capitalize"
                        >
                          {{ $t('input.view_details') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col align="center">
                <span class="headline"> {{ $t('currentCulture.no_current_culture') }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn
                  color="primary"
                  to="/current-culture-assessments/create"
                  outlined
                >
                  {{ $t('currentCulture.go_creation') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Required -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense class="mb-3 white--text" color="primary">
            <v-toolbar-title class="text-uppercase">{{ $t('requiredCulture.resume') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="info.required.length">
            <v-row justify="center">
              <v-col sm="4" xs="12" v-for="required in info.required" :key="required._id">
                <v-card>
                  <v-toolbar dense class="mb-3">
                    <v-toolbar-title class="center--text">{{required.name}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" align="center">
                        <span>{{ $t('input.status') }}</span>
                      </v-col>
                      <v-col cols="12" align="center">
                        <v-chip
                          color="success"
                          outlined
                          v-if="required.status === 'completed'"
                        >
                          {{ $t(`requiredCulture.${required.status}`) }}
                        </v-chip>
                        <v-chip
                          color="primary"
                          outlined
                          v-else-if="required.status === 'progress'"
                        >
                          {{ $t(`requiredCulture.${required.status}`) }}
                        </v-chip>
                        <v-chip
                          color="warning"
                          outlined
                          v-else-if="required.status === 'pending'"
                        >
                          {{ $t(`requiredCulture.${required.status}`) }}
                        </v-chip>
                        <v-chip
                          color="error"
                          outlined
                          v-else
                        >
                          {{ $t(`requiredCulture.${required.status}`) }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" sm="6" align="center">
                        <p>
                          <strong>
                            {{ $t('input.start') }}
                          </strong> <br>
                          <span>
                            {{required.releaseAt | date}}
                          </span>
                        </p>
                      </v-col>
                      <v-col xs="12" sm="6" align="center">
                        <p>
                          <strong>
                            {{ $t('input.closed') }}
                          </strong> <br>
                          <span>
                            {{required.deadlineAt | date}}
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align="center">
                        <v-btn
                          color="primary"
                          :to="`/required-culture/${required._id}/show`"
                          outlined
                          rounded
                          class="text-capitalize"
                        >
                          {{ $t('input.view_details') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col align="center">
                <span class="headline">{{ $t('requiredCulture.no_required_culture') }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn
                  color="primary"
                  to="/required-culture/create"
                  outlined
                >
                  {{ $t('requiredCulture.go_create') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Pulse -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense class="mb-3 white--text" color="primary">
            <v-toolbar-title class="text-uppercase">{{ $t('culturePulses.resume') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="info.pulse.length">
            <v-row justify="center">
              <v-col md="4" xs="12" v-for="pulse in info.pulse" :key="pulse._id">
                <v-card>
                  <v-toolbar dense class="mb-3">
                    <v-toolbar-title class="center--text">{{pulse.name}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col md="6" xs="12" align="center">
                        <span> {{ $t('input.status') }} </span>
                      </v-col>
                      <v-col md="6" xs="12" align="center" class="hidden-xs-only">
                        <span> {{ $t('currentCulture.answers_percent') }} </span>
                      </v-col>
                      <v-col md="6" xs="12" align="center">
                        <v-chip outlined
                          v-if="pulse.status === 'completed'"
                          color="success"
                        >
                          {{ $t(`currentCulture.${pulse.status}`) }}
                        </v-chip>
                        <v-chip outlined
                          v-else-if="pulse.status === 'progress'"
                          color="primary"
                        >
                          {{ $t(`currentCulture.${pulse.status}`) }}
                        </v-chip>
                        <v-chip outlined
                          v-else-if="pulse.status === 'pending'"
                          color="warning"
                        >
                          {{ $t(`currentCulture.${pulse.status}`) }}
                        </v-chip>
                        <v-chip outlined
                          v-else
                          color="error"
                        >
                          {{ $t(`currentCulture.${pulse.status}`) }}
                        </v-chip>
                      </v-col>
                      <v-col md="6" xs="12" align="center" class="hidden-xs-only">
                        <v-progress-circular
                          :rotate="360"
                          :size="100"
                          :width="15"
                          :value="pulse.populationCount ? (pulse.populationCompletedCount * 100) / pulse.populationCount : 0"
                          color="primary"
                        >
                          {{ pulse.populationCount ? ((pulse.populationCompletedCount * 100) / pulse.populationCount).toFixed(2) : 0 }}%
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" md="6">
                        {{ $t('currentCulture.requested') }}
                        <v-chip outlined
                          class="px-2"
                        >
                          {{pulse.populationCount}}
                        </v-chip>
                      </v-col>
                      <v-col xs="12" md="6" class="text-right">
                        {{ $t('currentCulture.received') }}
                        <v-chip outlined
                          class="px-2"
                        >
                          {{pulse.populationCompletedCount}}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align="center">
                        <v-btn outlined rounded
                          color="primary"
                          class="text-capitalize"
                          :to="`/culture-pulse/${pulse._id}/show`"
                        >
                          {{ $t('input.view_details') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col align="center">
                <span class="headline"> {{ $t('culturePulses.no_culture_pulse') }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn
                  color="primary"
                  to="/culture-pulse/create"
                  outlined
                >
                  {{ $t('culturePulses.go_creation') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Object
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.expired-plan{
  color: red;
  font-weight: bold;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.text-progress{
  color: #ad7816 !important;
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.no-color{
  color: #ffffff00;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}

.no-decoration{
  text-decoration: none;
}

.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
</style>
