
export default {
  title: 'Operation successful!',
  get_more_tokens: 'Acquire more OCC Tokens',
  or: 'OR',
  go_to_required_list: 'Go to the Required Culture List',
  go_to_actual_list: 'Go to Current Culture List',
  go_to_pulse_list: 'Go to Culture Pulses List',
  information: 'If you want to acquire more OCC Tokens go to Suite Operations'
}
