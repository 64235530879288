
export default {
  tokens_list: 'OCC Tokens',
  acquire: 'Adquirir',
  total_amount: 'Monto Total',
  tool: 'Herramienta',
  calculator: 'Calculadora',
  operations: 'Operaciones',
  operations_group: 'Operaciones de Grupo',
  operation_detail: 'Detalle operación',
  operation_details: 'Detalles de la operación',
  transaction_number: 'N° de transacción',
  success_transaction: 'Transacción realizada correctamente.',
  created_movement: 'La adquisición fue realizada con éxito.',
  created_transfer: 'La transferencia fue realizada con éxito.',
  created_authorization: 'Se creó la autorización con éxito.',
  eliminated_authorization: 'Se eliminó la autorización con éxito.',
  authorization_message: 'autoriza a',
  authorization_message_p1: 'Al habilitar esta opción',
  authorization_message_p2: 'de utilizar sus OCC Tokens de ser requerido al realizar un consumo y no contar con el balance suficiente.',
  invalid_quantity: 'Cantidad incorrecta.',
  balance: 'Saldo',
  add_new: 'Agregar Nueva',
  date: 'Fecha',
  token_qty: 'Cantidad de OCC Tokens',
  actions: 'Acciones',
  no_data: 'No hay operaciones para mostrar',
  purchase: 'Adquisición de OCC Tokens',
  create: 'Creación de la cuenta',
  transfer: 'Traspaso',
  receiving: 'Recibiendo',
  sending: 'Enviando',
  received: 'recibido',
  received_female: 'recibida',
  sended: 'enviado',
  sended_female: 'enviada',
  spend: 'Consumo',
  consumption: 'Consumo',
  by: ' por ',
  of: ' de ',
  authorization: 'Autorización',
  authorized: 'autorizado',
  medition: 'Medición',
  download: 'Descarga',
  downloads: 'Descargas',
  person: 'Persona',
  persons: 'Personas',
  poblation: 'Población',
  annual: 'Anualmente',
  biannual: 'Semestralmente',
  quarterly: 'Trimestralmente',
  monthly: 'Mensualmente',
  biweekly: 'Quincenalmente',
  weekly: 'Semanalmente',
  diary: 'Diario',
  one_time: 'Una vez',
  products: 'Productos',
  creation: 'creación',
  employees_lowercase: 'colaboradores',
  collaborators_lowercase: 'colaboradores',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar por número de transacción',
  consumer_transactions: 'Transacciones de consumo',
  authorization_transactions: 'Transacciones por autorización',
  movements_between_accounts: 'Movimientos entre cuentas',
  customer_balance: 'Saldo Cliente',
  enterprise_balance: 'Saldo Empresa',
  group_balance: 'Saldo Grupo',
  administrator: 'Administrador',
  since: 'desde',
  to: 'hacia',
  for: 'para',
  see_all_companies: 'Ver todas las empresas',
  see_all_members: 'Ver todos los miembros',
  no_operations: 'No hay operaciones para mostrar.',
  cost: 'Costo',
  calculator_closing_message: '¿Está seguro que desea cancelar la operación?',
  return: 'Volver',
  confirm_close_calculator: 'Si, estoy seguro',
  required: 'Requeridos',
  times: 'veces',
  leaders: 'líderes',
  evaluation: 'Evaluación',
  total: 'Total',
  services: 'Servicios',
  modality: 'Modalidad',
  recomendation: 'Recomendación',
  unit: 'OCC Tokens',
  money_unit: 'USD'
}
