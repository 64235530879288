
export default {
  current_culture_title: 'Organizational Report - Current Culture',
  sub_culture_title: 'Subculture Report',
  current_culture_description: 'This report groups the answers of all the people who responded to the survey.',
  no_answers_modal_msg: 'The current survey does not have answers, so a report cannot be generated.',
  general_report: 'Generate organizational report',
  download_report: 'Download report',
  view_report: 'View report',
  required_general_title: 'Culture report required',
  required_general_desc: 'This report has the results of the required culture.',
  change_report: 'Do you want to generate a report by subculture?',
  select_general_demographics_items: 'Select one of the demographics to view the response rate within the report.',
  select_general_demographics_items_hint: 'This selection does not affect the overall report scores',
  subculture_title: 'Subculture Reports - Current Culture',
  subculture_description: 'In this report you can choose and filter the scores of the different demographic cuts that you have configured when creating the assessment.',
  subculture_description2: 'This report has the scores of the population filtered by the demographic cuts that you select below:',
  subculture_report_btn: 'Generate Subculture Report',
  required_current_culture_title: 'Current Culture vs. Required Culture Report',
  required_sub_culture_title: 'Subculture vs Required Culture Report',
  required_current_culture_description: 'Report that has statistics of the entire population included in the survey and the required culture assigned.',
  required_actual_button: 'Generate Report',
  generated_reports: 'Generated Reports',
  generated_reports_desc: 'In this section you will find all the reports you have generated.<br>Depending on the number of participants, each report can take up to 30 minutes to generate. You can update the progress by reloading the page.<br>It is recommended to generate a maximum of 5 reports at the same time, to improve the performance of the platform.',
  already_generated_report: 'This report has already been generated. You can download it at the bottom, where all the generated reports are located.',
  need_demographic_cut: 'No demographic cut selected.',
  demographicCuts: {
    jobTypes: 'Contract type',
    headquarter: 'Office',
    gender: 'Gender',
    departments: 'Department / Area',
    country: 'Country',
    charge: 'Job title',
    antiquity: 'Seniority range',
    age: 'Age range',
    academicDegree: 'Academic level',
    optionalDemo1: 'Additional demographic 1',
    optionalDemo2: 'Additional demographic 2'
  },
  demographics_response_rate: 'Demographics response rate',

  // Summary report
  operation_init: 'A download process "Summary Report by Demographic Cuts" has started, this may take several minutes, you can see your progress by refreshing the page',
  demographic_summary: 'Summary report by demographics cuts',
  demographic_summary_desc: 'This report has the scores of the different dimensions segmented by demographic cuts.',
  already_generated_summary: 'The report has already been generated'
}
