
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1 mb-3">{{ requiredCulture.name }}</h4>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> {{ $t('requiredCulture.summary') }} </v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">
                          {{ requiredCulture.dimensionsScores }}
                          {{ $t('requiredCulture.dimensional_scores') }}
                        </h6>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('requiredCulture.status') }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text body-1">
                        {{ getStatus() }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">
                          <span class="title">{{ $t('requiredCulture.workshop_calibration') }}</span>
                          <span class="title" v-if="requiredCulture.dimensionsEndScore">{{ $t('requiredCulture.done') }}</span>
                          <span class="title" v-else>{{ $t('requiredCulture.unrealized') }}</span>
                        </h6>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>calendar_today</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('requiredCulture.period_validity') }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text body-1">
                        {{ requiredCulture.releaseAt }} {{$t('requiredCulture.to_the')}} {{ requiredCulture.validUntil }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">
                          {{ requiredCulture.attributeRelevance }}
                          {{ $t('requiredCulture.attributes_relevance') }}
                        </h6>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" v-show="false">
                  <v-text-field
                    :label="$t('input.invitation_url')"
                    :readonly="true"
                    outlined
                    v-model="requiredCulture.tokenUrl"
                    id="tokenUrl"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="mt-5">
                  <v-btn
                    to="/required-culture"
                    large
                    block
                  >{{ $t('input.back') }}</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-overflow-btn
                        class="my-2"
                        :items="actions"
                        append-icon="mdi-settings"
                        :label="$t('requiredCulture.action')"
                        item-value="value"
                        @change="executeAction"
                      >
                        <template v-slot:item="prop">
                          <span>
                            <v-icon medium :color="prop.item.color">{{prop.item.icon}}</v-icon>
                            {{ prop.item.text }}
                          </span>
                        </template>
                      </v-overflow-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-container>
          </v-row>
        </v-card>
      </v-col>
      <v-dialog v-model="showLinkModal" width="650px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('requiredCulture.copy_invitation_url') }}</span>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="11">
                <v-text-field
                  :label="$t('input.invitation_url')"
                  :readonly="true"
                  outlined
                  v-model="tokenUrl"
                  id="tokenUrl"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="copyUrl" class="mt-2">
                      <v-icon>file_copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('requiredCulture.link') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn block large @click="closeModal">{{ $t('input.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <x-confirmation-modal
      :show="closingWorkshop"
      :reversible="false"
      :title="$t('requiredCulture.close_workshop')"
      :action="closeWorkshop"
      :btn-save="$t('requiredCulture.close_workshop')"
      @close="closingWorkshop = false"
    >
      <template v-slot:question>{{ $t('requiredCulture.msg_close_workshop') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import requiredCultureService from '../../services/required-culture'

const formatItems = items => {
  return items.map((item) => {
    return {
      value: item.id,
      text: item.name
    }
  })
}

export default {
  data () {
    return {
      requiredCulture: {
        id: '',
        name: '',
        status: '',
        tokenId: '',
        releaseAt: '',
        validUntil: '',
        dimensionsScores: 0,
        dimensionsEndScore: {},
        attributeRelevance: {}
      },
      closingWorkshop: false,
      showLinkModal: false,
      tokenUrl: '',
      actions: [
        { text: this.$t('evaluations.calibrate'), value: 'Calibrate', icon: 'mdi-settings', color: '' },
        { text: this.$t('requiredCulture.link'), value: 'Link', icon: 'mdi-link-variant', color: '' },
        { text: this.$t('input.edit'), value: 'Edit', icon: 'mdi-square-edit-outline', color: '' },
        { text: this.$t('reports.download_report'), value: 'PDF', icon: 'mdi-chart-bar-stacked', color: '' },
        { text: this.$t('requiredCulture.close_workshop'), value: 'Close', icon: 'mdi-lock', color: '' }
      ]
    }
  },
  methods: {
    getStatus () {
      if (this.requiredCulture.status === 'pending') {
        return this.$t('requiredCulture.pending')
      } else if (this.requiredCulture.status === 'progress') {
        return this.$t('requiredCulture.progress')
      }
      return this.$t('requiredCulture.completed')
    },
    executeAction (action) {
      switch (action) {
        case 'Calibrate':
          return this.requiredCulture.status === 'progress' ? this.$router.push(`/evaluation/required/${this.requiredCulture.tokenId}`) : ''
        case 'Link':
          return this.requiredCulture.status === 'progress' ? this.showLink() : ''
        case 'Edit':
          return this.requiredCulture.status === 'pending' || this.requiredCulture.status === 'progress'
            ? this.$router.push(`/required-culture/${this.requiredCulture.id}/edit`) : ''
        case 'PDF':
          return this.requiredCulture.status === 'completed'
            ? this.$router.push(`/required-culture/reports/${this.requiredCulture.id}`) : ''
        case 'Close':
          return this.requiredCulture.status === 'progress' ? (this.closingWorkshop = true) : ''
        default:
      }
    },
    showLink () {
      const host = window.location.origin + '/evaluations/required/'
      this.tokenUrl = host + this.requiredCulture.tokenId
      this.showLinkModal = true
    },
    copyUrl () {
      let input = document.getElementById('tokenUrl')
      input.select()
      document.execCommand('copy')
      this.$store.dispatch('alert/success', this.$t('requiredCulture.link_copied'))
    },
    closeModal () {
      this.showLinkModal = false
    },
    getRequiredCulture () {
      requiredCultureService.getOneById(this.$route.params.id)
        .then((res) => {
          this.reponseParse(res)
        })
    },
    reponseParse (res) {
      this.requiredCulture.id = res._id
      this.requiredCulture.name = res.name
      this.requiredCulture.status = res.status
      this.requiredCulture.tokenId = res.tokenId
      this.requiredCulture.releaseAt = this.formatDate(this.getDateRequiredCulture(res.releaseAt))
      this.requiredCulture.validUntil = this.formatDate(this.getDateRequiredCulture(res.deadlineAt))
      this.requiredCulture.dimensionsScores = res.dimensionsScores.length
      this.requiredCulture.dimensionsEndScore = res.dimensionsEndScore
      this.requiredCulture.attributeRelevance = res.attributeRelevance.length
      this.filterActions(res.status)
    },
    filterActions (status) {
      for (let action of this.actions) {
        if (status === 'pending' && action.value === 'Edit') {
          action.color = 'blue'
        } else if (status === 'progress' && (action.value === 'Calibrate' || action.value === 'Link' || action.value === 'Edit' || action.value === 'Close')) {
          action.color = 'blue'
        } else if (status === 'completed' && action.value === 'PDF') {
          action.color = 'blue'
        }
      }
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} ${this.$t('requiredCulture.at')} ${data.hour}:${data.minut}`
    },
    getDateRequiredCulture (ref) {
      if (!ref) {
        return {
          value: '',
          hour: 23,
          minut: 59
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0],
        minut: time[1]
      }
    },
    closeWorkshop () {
      return requiredCultureService.close(this.$route.params.id)
        .then((res) => {
          this.getRequiredCulture()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getRequiredCulture()
  }
}
</script>

<style lang="css" scoped>
  .justify-h {
    margin-top: 1em
  }
  .v-list-item__subtitle, .v-list-item__title {
    white-space: normal;
  }
</style>
