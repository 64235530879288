import pdf from '../utils/pdf'
export default {
  methods: {
    $generateAtrributesTables () {
      let headersDimensions = [
        'values',
        'sustainability',
        'customers',
        'innovation',
        'achievement',
        'leadership',
        'talent',
        'relationship',
        'communication'
      ]
      let tables = []
      for (let index = 0; index < 9; index++) {
        let color = ''
        if (index < 3) {
          color = '#0889D6'
        } else if (index > 2 && index < 6) {
          color = '#EB493C'
        } else {
          color = '#48BBA1'
        }
        tables.push(
          {
            table: {
              color: 'white',
              widths: [479],
              body: [
                [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: '#BFBFBF',
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [-40, 35, 0, 8]
          },
          {
            table: {
              color: 'white',
              widths: [459],
              body: [
                [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: color,
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [36, 5, 0, 0]
          },
          {
            table: {
              color: '#BFBFBF',
              fontSize: 8,
              body: [
                [{ text: this.$t('cultureReports.limitant_attribute').toUpperCase(), color: '#5c5655', margin: [10, 10, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 6], width: 135, height: 30, alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.positive_attribute').toUpperCase(), color: '#5c5655', margin: [10, 10, 5, 0], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.atc').toUpperCase(), color: '#5c5655', margin: [5, 10, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.disp').toUpperCase(), color: '#5c5655', margin: [5, 10, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.tend').toUpperCase(), color: '#5c5655', margin: [5, 10, 5, 6], alignment: 'center', border: [false, false, false, false] }
                ],
                [{ text: (this.questionDataByAttributes[0 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][0], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[0 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[0 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[0 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[0 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[0 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[1 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][1], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[1 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[1 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[1 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[1 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[1 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[2 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][2], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[2 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[2 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[2 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[2 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[2 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[3 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][3], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[3 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[3 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[3 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[3 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[3 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[4 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][4], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[4 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[4 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[4 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[4 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[4 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[5 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][5], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[5 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[5 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[5 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[5 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[5 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[6 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][6], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[6 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[6 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[6 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[6 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[6 + (8 * index)].tendencies : 0) }
                ],
                [{ text: (this.questionDataByAttributes[7 + (8 * index)] || {}).limiting[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][7], color: '#5c5655', width: 120, height: 40, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: (this.questionDataByAttributes[7 + (8 * index)] || {}).positive[this.user.lang], color: '#5c5655', margin: [5, 2, 5, 2], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[7 + (8 * index)] || {}).actScore, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round((this.questionDataByAttributes[7 + (8 * index)] || {}).dispersion, 2), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.hasPrevious ? this.round(this.questionDataByAttributes[7 + (8 * index)].tendencies, 2) : '--', color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], ...pdf.styleTendency(this.hasPrevious ? this.questionDataByAttributes[7 + (8 * index)].tendencies : 0) }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              },
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            fontSize: 9,
            margin: [-10, 10, 20, 20]
          },
          {
            table: {
              color: 'white',
              body: [
                [{ text: this.$t('cultureReports.dimension').toUpperCase(), color: '#5c5655', margin: [10, 3, 10, 3], border: [false, false, false, false] },
                  { text: this.$t('cultureReports.actual').toUpperCase(), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.trend').toUpperCase(), color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 3, 0, 3], width: 140, height: 30, alignment: 'center', border: [false, false, false, false] }
                ],
                [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#5c5655', margin: [10, 3, 0, 3], alignment: 'center', border: [false, false, false, false], borderColor: ['', '', '', ''] },
                  { text: `${this.averagePerDimensions[index].average.toFixed(2)}`, color: '#5c5655', margin: [10, 3, 5, 3], alignment: 'center', border: [false, false, false, false], borderColor: ['', '', '', ''] },
                  { text: `${this.hasPrevious && !isNaN(this.previousAveragePerDimensions[index].average) ? (this.averagePerDimensions[index].average - this.previousAveragePerDimensions[index].average).toFixed(2) : '--'}`, color: '#5c5655', margin: [5, 3, 5, 3], alignment: 'center', border: [false, false, false, false], borderColor: ['', '', '', ''] },
                  { image: this.horizontalBarChartsDimensions[index], color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              }
            },
            alignment: 'center',
            bold: true,
            margin: [50, 0, 20, 20],
            pageBreak: 'after'
          })
        let array = []
        let column1 = []
        let column2 = []
        let column3 = []
        for (let j = 0; j < this.barChartsAttributes[headersDimensions[index]].length; j++) {
          if (j <= 2) {
            column1.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else if (j > 2 && j <= 5) {
            column2.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else {
            column3.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          }
        }
        array = [
          {
            table: {
              color: 'white',
              widths: [479],
              body: [
                [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: '#BFBFBF',
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [-40, 40, 0, 8]
          },
          {
            table: {
              color: 'white',
              widths: [459],
              body: [
                [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: color,
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [36, 15, 0, 0]
          },
          {
            columns: [
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '-', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_negative_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [36, 15, 0, 10],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '0', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_neutral_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 0, 0],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '+', margin: [-2, 0, -10, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_positive_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 12, 10],
                alignment: 'center'
              }
            ]
          },
          {
            columns: column1
          },
          {
            columns: column2
          },
          {
            columns: column3,
            pageBreak: 'after'
          }
        ]
        tables.push(...array)
      }
      return tables
    }
  }
}
