
export default {
  generateTitle: (text: string, pageBreak?: string, fontSize?: number, margin?: number[] | number, color?: string): object => {
    return {
      text,
      pageBreak,
      fontSize: fontSize || 20,
      margin: margin || [0, 30],
      color: color || '#839291'
    }
  },
  styleTendency: (tendency: number): object => {
    if (typeof tendency === 'string') {
      tendency = Number(tendency)
    }
    if (isNaN(tendency)) {
      tendency = 0
    }
    if (tendency > 0.5) {
      return { bold: true, decoration: '', color: '#000' }
    } else if (tendency < -0.5) {
      return { decoration: 'underline', bold: false }
    }
    return { bold: false, decoration: '' }
  },
  // tslint:disable-next-line:max-line-length
  generateCenteredText: (text: string, fontSize?: number, bold?: boolean, styled?: boolean, italic?: boolean, pageBreak?: string): object[] => {
    const normal = [
      { width: '*', text: '' },
      {
        width: 'auto',
        text,
        color: '#6d7a7a',
        fontSize: fontSize || 22,
        bold,
        italics: false,
        pageBreak
      },
      { width: '*', text: '' }
    ]

    if (styled) {
      normal[1].color = '#1a98da'
    }

    if (italic) {
      normal[1].italics = true
    }

    return normal
  },
  setLayout: (): object => {
    return {
      hLineColor: '#607D8B',
      vLineColor: '#607D8B',
      fillColor: (rowIndex: any) => {
        return (rowIndex % 2 === 0) ? '#EEEEEE' : null
      }
    }
  },
  generateNote: (text: any): object => {
    return {
      margin: [0, 15, 0, 0],
      text,
      fontSize: 12
    }
  },
  generateTable: (widths: string[], rows: string[][], headers: string[], topTitle?: string, fontSize?: number): object => {
    const colSpaces = []
    for (let i = 0; i < headers.length - 1; i++) {
      colSpaces.push('')
    }
    const body = []
    if (topTitle) {
      body.push([
        {
          text: topTitle,
          fillColor: '#FFFFFF',
          colSpan: headers.length,
          border: [false, false, false, false],
          margin: [-4, 0, 0, -2],
          fontSize: 19
        },
        ...colSpaces
      ])
    }
    body.push(
      headers.map((header) => {
        return {
          text: header,
          fillColor: '#2196F3',
          color: '#FFFFFF',
          bold: true,
          fontSize: fontSize || 11,
          alignment: 'center'
        }
      }),
      ...rows
    )
    return {
      headerRows: topTitle ? 2 : 1,
      widths,
      body: body
    }
  },
  /**
   * @param result Valor a mostrar, puede ser numérico o string
   * @param mRight Cantidad de margen a la derecha, solo aplicable para casos necesarios, donde el centrado por alignment no es opción
   * @param condition Condición para validar mostrar el valor o string por defecto, aplicable a casos condicionales
   * @param decimals Numero de caracteres a mostrar por defecto 2
   * @return Objet
   */
  colResul (result: any, mRight?: undefined|number, condition?: boolean, decimals?: undefined|number) {
    return {
      text: condition === undefined || condition ? this.round(result, decimals, '--') : '--',
      ...(mRight ? { alignment: 'right', margin: [0, 5, mRight, 0] } : { alignment: 'center', margin: [0, 5, 0, 0] }),
      border: [true, true, true, true]
    }
  },
  round (value: any, decimals?: undefined| number, df?: undefined|string|number) {
    if (isNaN(value)) return df !== undefined ? df : value
    decimals = decimals === undefined || isNaN(decimals) ? 2 : decimals
    return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`).toFixed(decimals)
  }
}
