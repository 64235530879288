
<template>
  <v-stepper-content step="4">
    <x-technical-requirements></x-technical-requirements>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-0">
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-card-details</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.poll_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ currentCulture.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-card-details-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.external_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ currentCulture.displayName || currentCulture.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-star</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.date_delivery') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(currentCulture.releaseAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.poll_valid_until') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(currentCulture.deadlineAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-camera-timer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.time_zone') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ currentCulture.timeZone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.send_reminders') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ currentCulture.switchDate ? $t('cultureReports.yes') : $t('cultureReports.no') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-file-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.questionnaire') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ getQuestionnaireName() }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col xs="12" md="6" class="mb-3 my-2">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>fa-coins</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ `${currentCulture.totalPrice } ${$t('requiredCulture.token_unit')}` }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text subheading">
                        {{ computedPaymentMsg }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-email-open-outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('currentCulture.personalization') }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <v-col lg="10" sm="9">
                    <v-tabs v-model="currentCulture.active" fixed-tabs>
                      <v-tab :href="`#tab-1`" @click="tabSelected = 1">
                        {{$t('currentCulture.poll_invitation')}}
                      </v-tab>
                      <v-tab :href="`#tab-2`" @click="tabSelected = 2">
                        {{$t('currentCulture.reminder_mail')}}
                      </v-tab>
                      <!--
                      <v-tab :href="`#tab-3`" @click="tabSelected = 3">
                        {{$t('currentCulture.tk_message')}}
                      </v-tab>
                      -->
                    </v-tabs>
                  </v-col>
                  <v-col xs="12" class="mt-1 text-center" v-if="tabSelected != 3">
                    <v-btn color="primary" style="letter-spacing: 0; font-size: .9em;" @click="displayPreview"> {{ $t('input.preview') }} </v-btn>
                  </v-col>
                </v-row>

                <v-container grid-list-md text-xs-center>
                  <v-tabs-items v-model="currentCulture.active">

                    <v-tab-item :value="`tab-1`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <x-inputs-input
                            :input="currentCulture.pollInvitation.subject"
                            @updateInput="($event) => currentCulture.pollInvitation.subject = $event"
                            light
                            :label="$t('currentCulture.message_subject')"
                            name="message_subject"
                            :data-vv-as="$t('currentCulture.message_subject') | lowerCase"
                            :append-outer-icon="$t('help.icon')"
                            :help-message="$t('help.culture.create.subject')"
                          ></x-inputs-input>
                        </v-col>

                        <v-col cols="12">
                           <quill-editor ref="pollInvitationBody"
                              v-model="currentCulture.pollInvitation.body"
                              :options="editorOption">
                          </quill-editor>
                          <!--
                          <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile"
                            v-model="pollInvitationVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput"
                            accept="video/*"
                            @change="onFilePicked"
                          >
                          <v-row v-if="currentCulture.pollInvitation.attachment">
                            <v-flex xs12>
                              <b>{{ $t('currentCulture.fileExistNote') }}</b>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                              <v-card color="yellow lighten-4" class="mt-2">
                                <v-card-title>
                                  <v-row justify="space-between">
                                    <span class="ml-2">{{ $t('currentCulture.fileExist') }}</span>
                                    <v-tooltip right color="red">
                                      <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="deleteInvitationFile">
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('currentCulture.deleteFile') }}</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-card-title>
                                <v-card-text>
                                  <span>
                                    <b>{{ currentCulture.pollInvitation.attachment }}</b>
                                  </span>
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-row>
                          -->
                        </v-col>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item :value="`tab-2`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <x-inputs-input
                            :input="currentCulture.reminderMail.subject"
                            @updateInput="($event) => currentCulture.reminderMail.subject = $event"
                            light
                            :label="$t('currentCulture.message_subject')"
                            name="message_subject"
                            :data-vv-as="$t('currentCulture.message_subject') | lowerCase"
                            :append-outer-icon="$t('help.icon')"
                            :help-message="$t('help.culture.create.subject')"
                          ></x-inputs-input>
                        </v-col>

                        <v-col cols="12">
                           <quill-editor ref="pollReminderBody"
                            v-model="currentCulture.reminderMail.body"
                            :options="editorOption">
                          </quill-editor>
                          <!--
                          <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile2"
                            v-model="pollReminderVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor2"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput2"
                            accept="video/*"
                            @change="onFilePicked2"
                          >
                          <v-row v-if="currentCulture.reminderMail.attachment">
                            <v-flex xs12>
                              <b>{{ $t('currentCulture.fileExistNote') }}</b>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                              <v-card color="yellow lighten-4" class="mt-2">
                                <v-card-title>
                                  <v-row justify="space-between">
                                    <span class="ml-2">{{ $t('currentCulture.fileExist') }}</span>
                                    <v-tooltip right color="red">
                                      <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="deleteReminderFile">
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('currentCulture.deleteFile') }}</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-card-title>
                                <v-card-text>
                                  <span>
                                    <b>{{ currentCulture.reminderMail.attachment }}</b>
                                  </span>
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-row>
                          -->
                        </v-col>
                      </v-container>
                    </v-tab-item>
                    <!--
                    <v-tab-item :value="`tab-3`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <v-textarea
                            v-model="currentCulture.thankMessage"
                            light
                            :label="$t('currentCulture.tk_message')"
                            name="thankMessage"
                            :data-vv-as="$t('currentCulture.tk_message') | lowerCase"
                           :append-icon="$t('help.icon')"
                            @click:append="$store.dispatch('help/display', $t('help.culture.create.thankMessage'))"
                          ></v-textarea>
                        </v-col>

                      </v-container>
                    </v-tab-item>
                    -->
                  </v-tabs-items>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog
        v-model="display"
        max-width="58.8rem"
      >
        <div>
          <div style="background: #f3f3f3 wi">
            <p style="padding-left: 1em margin-bottom: 0"> {{$t('currentCulture.message_subject') }}: <strong> {{message.title}} </strong></p>
          </div>
          <div>
            <title>OCC Solutions - Cultura</title>
          </div>

          <div style="background: #f3f3f3">
            <table width="100%">
              <tr>
                <td align="center" bgcolor="#ffffff" style="border-bottom: 3px solid #f3f3f3">
                  <a href="#" target="_blank" style="color: #2E6B9C font-weight: 900 text-decoration: none line-height: 0.8">
                    <h1 style="font-size: 3rem margin: 0 padding: 30px 0">OCC Solutions - Cultura</h1>
                  </a>
                </td>
              </tr>
              <tr style="font-family: Arial, sans-serif">
                <td bgcolor="#FFF" style="padding: 40px 30px 40px 30px">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td style="color: #333333 font-size: 16px padding: 20px 0 30px 0">
                        <div style="padding-left: 1em margin-bottom: 0"  class="ql-editor" v-html="message.body">  </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" bgcolor="#FFF" style="padding: 40px 0color: #2E6B9C font-family: Arial, sans-serif font-size: 12px border-top: 3px solid #f3f3f3">
                  <p>
                    {{$t('currentCulture.all_right')}} OCC Solutions - Cultura <br/>
                    info@occsolutions.org | +57 (1) 642 1300
                  </p>
                  <div> {{ $t('currentCulture.automatic_message') }} <a href="#"> OCC Cultura </a></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-dialog>
    </v-row>
    <v-form data-vv-scope="form-4" class="mt-6">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(+step - 1)"
            >{{ $t('input.back') }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              large
              @click="changeStep(+step + 1)"
            >
              {{ isEdit ? $t('input.update') : $t('input.confirm_save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  props: {
    isEdit: Boolean,
    currentCulture: Object,
    step: String,
    price: Number,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      tabSelected: 1,
      display: false,
      message: {
        title: '',
        body: ''
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link']
            // ['link', 'image', 'video']
          ]
        },
        placeholder: ''
      },
      pollInvitationVideo: {
        src: '',
        name: ''
      },
      videoColor: 'primary',
      pollReminderVideo: {
        src: '',
        name: ''
      },
      videoColor2: 'primary',
      invitationFileFlag: true,
      reminderFileFlag: true
    }
  },
  computed: {
    computedPaymentMsg () {
      if (this.currentCulture.isEdit) {
        if (!this.currentCulture.population || !this.currentCulture.population.length) {
          return this.$t('currentCulture.poll_paid')
        } else {
          const newParticipants = this.currentCulture.population.length
          return `${this.$t('currentCulture.cost')} ${newParticipants} ${this.$t('currentCulture.participants')}`
        }
      } else {
        const active = this.currentCulture.population
          ? this.currentCulture.population.filter(employee => employee.active === true).length
          : 0
        return `${this.$t('currentCulture.cost')} ${active} ${this.$t('currentCulture.participants')}`
      }
    },
    pollReminderEditor () {
      return this.$refs.pollReminderBody.quill
    },
    pollInvitationEditor () {
      return this.$refs.pollInvitationBody.quill
    }
  },
  methods: {
    getQuestionnaireName () {
      if (this.currentCulture.questionnaires) {
        const questionnaire = this.currentCulture.questionnaires.find(questionnaire => questionnaire.slug === this.currentCulture.questionnaire)
        if (questionnaire) {
          return questionnaire.name
        }
      }
      return ''
    },
    changeStep (step) {
      if (this.currentCulture.pollInvitation.body === '<p class="ql-align-justify"><br></p>') {
        this.currentCulture.pollInvitation.body = this.$t('currentCulture.default_body')
      }
      if (this.currentCulture.reminderMail.body === '<p class="ql-align-justify"><br></p>') {
        this.currentCulture.reminderMail.body = this.$t('currentCulture.default_body_r')
      }
      this.$emit('changeStep', this.currentCulture, step)
    },
    deleteInvitationFile () {
      this.currentCulture.pollInvitation.attachment = undefined
      this.$emit('delete-invitation-file')
    },
    deleteReminderFile () {
      this.currentCulture.reminderMail.attachment = undefined
      this.$emit('delete-reminder-file')
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} ${this.$t('currentCulture.formatDateAt')} ${data.hour}:00`
    },
    displayPreview () {
      if (this.tabSelected === 1) {
        this.message.title = this.currentCulture.pollInvitation.subject
        this.message.body = this.currentCulture.pollInvitation.body
      } else {
        this.message.title = this.currentCulture.reminderMail.subject
        this.message.body = this.currentCulture.reminderMail.body
      }
      this.display = true
    },
    pickFile () {
      this.$refs.videoInput.click()
    },
    onFilePicked ($event) {
      const files = $event.target.files

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'))
          this.videoColor = 'error'
        } else {
          this.videoColor = 'primary'
          this.pollInvitationVideo.name = files[0].name
          return this.pollInvitationVideo.name.lastIndexOf('.') > 0 ? this.readFile(files) : this.resetVideo()
        }
      } else this.resetVideo()
    },
    resetVideo () {
      this.pollInvitationVideo.src = ''
      this.pollInvitationVideo.name = ''
      this.currentCulture.pollInvitation.file = ''
    },
    readFile (files) {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.pollInvitationVideo.src = fr.result
        this.currentCulture.pollInvitation.file = files[0]
      })
    },
    pickFile2 () {
      this.$refs.videoInput2.click()
    },
    onFilePicked2 ($event) {
      const files = $event.target.files

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'))
          this.videoColor2 = 'error'
        } else {
          this.videoColor2 = 'primary'
          this.pollReminderVideo.name = files[0].name
          return this.pollReminderVideo.name.lastIndexOf('.') > 0 ? this.readFile2(files) : this.resetVideo2()
        }
      } else this.resetVideo2()
    },
    resetVideo2 () {
      this.pollReminderVideo.src = ''
      this.pollReminderVideo.name = ''
      this.currentCulture.reminderMail.file = ''
    },
    readFile2 (files) {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.pollReminderVideo.src = fr.result
        this.currentCulture.reminderMail.file = files[0]
      })
    }
  },
  created () {
    this.editorOption.placeholder = this.$t('currentCulture.body_message')
    this.currentCulture.pollInvitation.body = this.$t('currentCulture.default_body')
    this.currentCulture.reminderMail.body = this.$t('currentCulture.default_body_r')
  }
}
</script>

<style lang="css">
.ql-container {
  min-height: 170px !important
}
</style>
