
export default {
  title: '¡Operación Exitosa!',
  get_more_tokens: 'Adquirir más OCC Tokens',
  or: 'Ó',
  go_to_required_list: 'Ir al Listado Cultura Requerida',
  go_to_actual_list: 'Ir al Listado Cultura Actual',
  go_to_pulse_list: 'Ir al Listado de Pulsos en Cultura',
  information: 'Si desea adquirir más OCC Tokens diríjase a Operaciones de Suite'
}
