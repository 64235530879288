
export default {
  continue: 'Continue',
  general_title: 'Dashboard',
  init: 'Start:',
  end: 'Ending',
  ended: 'Finished on:',
  finished: 'Finalized',
  progress: 'In progress',
  poll: 'Poll:',
  start: 'Start',
  no_pending_activities: 'It has no pending activities.',
  completed: 'Completed',
  reply: 'Reply',
  name: 'Name',
  dates: 'Dates',
  status: 'Status',
  enterprise: 'Enterprise',
  customer: 'Customer',
  actions: 'Actions',
  population: 'Population',
  to: 'to',
  resume_current: 'Summary of latest measurements current culture',
  resume_pulse: 'Summary of latest Culture Pulses',
  resume_required: 'Summary of latest culture required measurements'
}
