
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('required-culture')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList, role: string) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    if (role === 'admin') {
      return service.get('list', params)
    } else {
      return service.get('list-by-enterprise', params)
    }
  },
  listCompleted: () => {
    return service.get('list-completed')
  },
  create: (data: any) => {
    data.questionnaires = ''
    return service.post('create', data)
  },
  getOneById (id: string) {
    return service.get(`${id}`)
  },
  update: (id: number, data: any) => {
    data.questionnaires = ''
    return service.post(`edit/${id}`, { data })
  },
  getPrevious (id: string) {
    return service.get(`get-previous/${id}`)
  },
  getOneByTokenId (tokenId: string) {
    return service.get(`find-by-token-id/${tokenId}`)
  },
  updateScore (tokenId: string, data: object) {
    return service.put('update-score', { tokenId, data })
  },
  updateEndScore (tokenId: string, data: object) {
    return service.put('update-end-score', { tokenId, data })
  },
  updateAttribute (tokenId: string, data: object) {
    return service.put('update-attribute', { tokenId, data })
  },
  close: (id: number, data: any) => {
    return service.post(`close/${id}`)
  }
}
