
<template>
  <v-stepper-content step="4">
    <x-technical-requirements></x-technical-requirements>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-0">
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-card-details</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('requiredCulture.workshop_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ requiredCulture.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2" v-if="!requiredCulture.tokenId">
                <v-list-item-title>
                  <v-list-item-action>
                    <v-icon>fa-coins</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ `${price} ${$t('requiredCulture.token_unit')}` }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ $t('requiredCulture.workshop_cost') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item-title>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-star</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('requiredCulture.start_date') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(requiredCulture.releaseAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item-title>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('requiredCulture.deadline_at') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(requiredCulture.deadlineAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item-title>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-camera-timer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('requiredCulture.time_zone') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ requiredCulture.timeZone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item-title>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-file-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('requiredCulture.questionnaire') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ getQuestionnaireName() }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item-title>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-form data-vv-scope="form-4">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(+step - 1)"
            >{{ $t('input.back') }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <x-async-btn
                class="white--text"
                :action="save"
                large
                block
              >{{ $t('input.confirm_save') }}
            </x-async-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>

export default {
  props: {
    requiredCulture: Object,
    step: String,
    price: Number,
    nextAction: String,
    prevAction: String
  },
  methods: {
    getQuestionnaireName () {
      if (this.requiredCulture.questionnaires) {
        const questionnaire = this.requiredCulture.questionnaires.find(questionnaire => questionnaire.slug === this.requiredCulture.questionnaire)
        if (questionnaire) {
          return questionnaire.name
        }
      }
      return ''
    },
    changeStep (step) {
      this.$emit('changeStep', this.requiredCulture, step)
    },
    save () {
      return Promise.resolve(this.changeStep(+this.step + 1))
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} ${this.$t('requiredCulture.at')} ${data.hour}:00`
    }
  }
}
</script>
