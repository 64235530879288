
import pdfUtil from '../utils/pdf'

const fontSizeBylength = [
  { min: 0, max: 30, fontSize: 20 },
  { min: 31, max: 34, fontSize: 18 },
  { min: 35, max: 38, fontSize: 16 },
  { min: 39, max: 42, fontSize: 14 },
  { min: 43, max: 57, fontSize: 12 },
  { min: 57, max: 70, fontSize: 10 },
  { min: 70, max: 9999999, fontSize: 8 }
]

const getFontSizeBylength = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize

export default {
  data () {
    return {
      today: new Date()
    }
  },
  methods: {
    $generateCover () {
      const fontSize = getFontSizeBylength((this.currentPoll.name || '').length)
      return [
        {
          image: this.cultureCoverBase64,
          width: 678,
          height: 765,
          aligment: 'center',
          absolutePosition: { x: 0, y: 0 }
        },
        {
          margin: [0, 450, 0, 0],
          columns: pdfUtil.generateCenteredText(this.$t('cultureReports.current_culture'), 22, true, false, false)
        },
        {
          margin: [0, 10, 0, 20],
          columns: pdfUtil.generateCenteredText(this.$t(`cultureReports.cover.general`), 20, false)
        },
        {
          columns: pdfUtil.generateCenteredText(this.currentPoll.name, fontSize, false)
        },
        {
          margin: [0, 45, 0, 0],
          columns: pdfUtil.generateCenteredText(this.user.enterprise.name, 20, true)
        },
        {
          columns: pdfUtil.generateCenteredText(`${this.$t(`cultureReports.months[${this.today.getMonth()}]`)} - ${this.today.getFullYear()}`, 15, true),
          pageBreak: 'after'
        }
      ]
    }
  }
}
