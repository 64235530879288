
export default {
  enterprises_list: 'List of Companies',
  add_enterprise: 'Add company',
  edit_enterprise: 'Edit company',
  filter_by: 'Filter by....',
  look_for: 'Find a company',
  no_data: 'There are no companies to show',
  id: 'ID',
  enterprise_name: 'Company name',
  group: 'Group',
  license: 'License',
  expiration_date: 'Expiry date',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the company?',
  modal_disable: 'Do you want to disable the company?',
  modal_enable_des: 'Once enabled, the company will be able to access the system again',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Once it is disabled, the company will not be able to access the system until it is enabled again',
  action: 'Take actionn',
  accept: 'Accept',
  close: 'Close',
  enterprise_information: 'Company information',
  contact_employee: 'Contact employee',
  licensing: 'Licensing',
  demographic_cuts: 'Demographic Cuts',
  tool_customization: 'Tool customization',
  primary_color: 'Primary color',
  secondary_color: 'Secondary color',
  // tslint:disable-next-line:max-line-length
  informative_cuts: 'The following data is for information and does not represent actions in the system. This data is needed to identify employees.',
  select_demographic_cuts: 'Please select the demographic cut categories you want to measure.',
  obligatories_demographic_cuts: 'The {obligatories} categories are mandatory.',
  created_enterprise: 'The company was successfully created.',
  section_edited: 'The section was successfully edited.',
  enterprise_edited: 'The company was successfully edited.',
  profile_title: 'Company {size} in the {sector} sector. ',
  profile_title_addres: 'Located in: {country} - {address}.',
  extend_plan: 'Extend plan',
  extend_plan_requests: 'Plan extension requests',
  info_extend: 'After submitting your plan extension request, you will receive a notification of your acceptance.',
  extend_request_table: [
    { text: 'Engagement requested', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulse requested', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan completion', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  no_extend_requests: 'This company has no pending extension requests.',
  extend_request_done: 'Plan extension carried out',
  extend_request_sent: 'Plan extension request sent',
  extend_request_accepted: 'Request accepted',
  extend_request_rejected: 'Request rejected',
  extend_request_cancelled: 'Application canceled',
  active_plan: 'Active plan',
  plan_expire_date: 'Plan expiry date',
  active_engagements: 'Remaining Engagements',
  active_pulses: 'Remaining Pulses',
  info_update: 'Warning: The Plan Update is received as a request. This must be accepted by the system administrator, and you will therefore receive a notification indicating the status of your request. Only one update request can be submitted at a time.',
  update_plan_request: 'Plan update request',
  info_request_extend: 'Warning: The Plan Extension is received as a request. This must be accepted by the system administrator, and you will therefore receive a notification indicating the status of your request. Only one extension request can be submitted at a time.',
  no_update_requests: 'This company has no pending update requests.',
  active_license: 'Active plan',
  license_expire_at: 'Expiry date',
  active_plan_pulses: 'Currently active Pulses',
  active_plan_engagements: 'Currently active Engagements ',
  new_plan_pulses: 'Pulse of your new plan',
  new_plan_engagements: 'Engagement of your new plan',
  new_plan_requested: 'New plan requested:',
  new_plan_duration: 'Duration of plan requested:',
  old_plan_end: 'Current plan end date:',
  old_plan_duration: 'Current plan duration:',
  want_other_start: 'Do you want your plan to starton a date other than the expiry date of your current plan?',
  other_start_warning: 'Warning: Selecting a date other than the expiry date of your plan has conditions.',
  before_start_warning: 'If you request that your plan be updated on a date prior to the expiry date of your current plan and your request is automatically accepted, you will lose all the benefits of your current plan and the one requested will be activated.',
  after_start_warning: 'If you request that your plan be updated on a date after the expiration date of your current plan and your request is accepted, you will not have permission to enter the system on the dates between the expiry date of your current plan and the start date of the new plan.',
  new_plan_start: 'Start of the requested plan',
  update_plan: 'Update plan.',
  update_type: 'Update',
  extend_type: 'Extension',
  enterprise_extend_request_table: [
    { text: 'Type of request', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Engagements requested', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulses requested', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan completion', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  extensions_history_header: [
    { text: 'Type of request', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Engagement', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulse', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Start of request', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Duration', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Status', value: '', sortable: false, class: 'text-xs-center' }
  ],
  status_pending: 'Pending',
  status_accepted: 'Accepted',
  status_rejected: 'Rejected',
  status_cancelled: 'Cancelled',
  no_demo_requests: 'No pending demo requests',
  demo_plan_request: 'Demo plan requests',
  demo_requests_table: [
    { text: 'Company', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Address', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Request date', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  // tslint:disable-next-line:max-line-length
  created_demo_enterprise: 'Your company has been registered. You will now receive an email with the instructions to follow for activating your account.',
  profile_warning: 'Remember to set your demographic cuts in order to be able to use the system correctly.',
  first_time_title: 'Welcome to OCC - Pulse',
  // tslint:disable-next-line:max-line-length
  first_time_text: 'To have a better experience when interacting with the platform, it is advisable to follow the following steps:',
  first_time_step: 'Step',
  // tslint:disable-next-line:max-line-length
  first_time_step_1: 'Verify the company information, edit or upload only the demographic cuts that you will use to generate the reports.',
  // tslint:disable-next-line:max-line-length
  first_time_step_2: 'Enter employees section to upload the company payroll before carrying out any survey.',
  // tslint:disable-next-line:max-line-length
  first_time_step_3: 'Create your first engagement survey or click according to the plan you have contracted.',
  register_date: 'Registration date',
  balance: 'Current balance OCC Tokens',
  resumen: 'Company Summary',
  admin: 'Administrator',
  available_tokens: 'Tokens available',
  company_name: 'Company name',
  profile_picture: 'Profile photo',
  customer: 'Customer',
  requirements_title: 'Technical requirements',
  // tslint:disable-next-line:max-line-length
  requirements_subtitle: 'Review and implement these requirements with the area in charge, before the launch of the measurement. With this we prevent emails from reaching spam or blocking access to the platform for respondents.',
  // tslint:disable-next-line:max-line-length
  requirements_text: 'OCC Suite requires several technical conditions that must be guaranteed in your company\'s network and equipment for an adequate level of service, operation and maintenance to be offered.',
  requirements_title_1: 'Access to URLs',
  requirements_title_list_1: 'Allow the organization\'s computers to access to the following  URLs:',
  requirements_title_listitems_1: [
    'https://occ-solutions.com/',
    'http://suite.occ-solutions.com/',
    'https://suite.occ-solutions.com/',
    'https://cultura.occ-solutions.com/',
    'https://cultura.occ-solutions.com/'
  ],
  requirements_title_2: 'Email domains',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_2: 'Enable entry to your company\'s email server for emails sent with the respective domains and subdomains:',
  requirements_title_listitems_2: [
    'sendgrid.info',
    'sendgrid.com',
    'sendgrid.net'
  ],
  requirements_title_3: 'Mail domains',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_3: 'Enable entry to your company\'s mail server emails sent with the respective domains and subdomains:',
  requirements_title_listitems_3: [
    '@occ-solutions.com',
    '@occsolutions.org',
    '@suite.occ-solutions.com',
    '@suite.occsolutions.org'
  ],
  requirements_title_4: 'Compatible browsers:',
  requirements_title_list_4: 'The web application works properly in the following browsers:',
  requirements_title_listitems_4: [
    'Google Chrome (recommended).',
    'Mozilla Firefox.',
    'Opera.',
    'Microsoft Edge.'
    // 'Internet Explorer (version 11 onwards).'
  ]
}
