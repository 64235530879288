
export default {
  poll_title: 'Cultura Requerida',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Lea cada una de las afirmaciones presentadas abajo y seleccione la opción que mejor representa su opinión según la siguiente escala: Totalmente en desacuerdo, Parcialmente en desacuerdo, Ni en desacuerdo ni de acuerdo, Parcialmente de acuerdo, Totalmente de acuerdo.',
  totally_disagree: 'Totalmente<br/> en desacuerdo',
  parcially_disagree: 'Parcialmente<br/> en desacuerdo',
  nor_agree_disagree: 'Ni en desacuerdo<br/> ni de acuerdo',
  parcially_agree: 'Parcialmente<br/> de acuerdo',
  totally_agree: 'Totalmente<br/> de acuerdo',
  entry_title: 'Bienvenido a su encuesta Engagement',
  // tslint:disable-next-line:max-line-length
  entry_text: 'Te invitamos a responder la encuesta de Engagement, seguros que con tus respuestas construiremos un mejor ambiente laboral. Cuentas con un enlace propio para tu encuesta, así que puedes responder con total tranquilidad que tus respuestas son confidenciales.',
  questions_65: '65 Preguntas',
  minutes_n: '{n} Minutos',
  complete_anonymity: 'Anonimato total',
  finish_title: 'Muchas gracias por haber completado la encuesta',
  finish_text: 'Su percepción es muy importante para nosotros.',
  new: 'Nueva medición Cultura Requerida',
  overview: 'Nombre del taller',
  receptors: 'Encuestados',
  date: 'Fecha',
  questions: 'Preguntas',
  questionnaire: 'Cuestionario',
  leaders_selection: 'Selección de líderes',
  revition: 'Revisión',
  workshop_name: 'Nombre de la medición',
  want_external_name: '¿Desea colocar un nombre externo para los encuestados?',
  workshop_cost: 'Costo del taller',
  report_cost: 'Costo del Reporte',
  balance_after: 'Saldo luego de crear el taller:',
  balance_after_generate_report: 'Saldo luego de generar el repote',
  token_unit: 'OCC Tokens',
  non_active_employees: 'Debe tener al menos un colaborador activo para esta encuesta.',
  operation_failed: 'Operación no exitosa, se recomienda:',
  acquire_tokens: 'Adquirir más OCC Tokens',
  no_balance: 'Sin balance suficiente',
  no_balance_msg: 'Empresa sin saldo suficiente, si continua el proceso, el sistema intentará consumir saldos autorizados.',
  want_to_send: '¿A quién desea enviar esta encuesta?',
  hours: 'Hora de envío',
  hours_valid_until: 'Hora de cierre',
  minutes: 'Minuto',
  period_validity: 'Periodo de vigencia',
  start_date: 'Fecha de inicio',
  workshop_valid_until: 'Medición válida hasta',
  time_zone: 'Zona horaria',
  want_send_reminders: '¿Desea enviar recordatorios?',
  reminders: 'Enviar recordatorio',
  want_open_question: '¿Desea añadir preguntas abiertas adicionales?',
  open_question: 'Preguntas abiertas',
  add_open_question: 'Añadir otra pregunta abierta',
  remove_open_question: 'Eliminar última pregunta abierta',
  min_open_question: 'Debe tener al menos una pregunta abierta disponible',
  max_open_question: 'Solamente puede {n} preguntas abiertas por encuesta',
  insert_question: 'Insertar contenido de la pregunta {n}',
  insert_question_option: 'Insertar opción {o} para pregunta {n}',
  add_question_option: 'Añadir otra opción ',
  remove_question_option: 'Eliminar opción ',
  min_question_option: 'Debe tener al menos {n} opciones disponibles',
  max_question_option: 'Sólo puede indicar {n} opciones para una pregunta abierta',
  total_receptors: 'Total de encuestados: {n}',
  porcent_total: 'Porcentaje del total a tomar',
  general_revition: 'Revisión general',
  settings: 'Ajustes',
  status: 'Estado',
  inter_name: 'Nombre interno',
  send_reminders: 'Envio de recordatorios',
  personalization: 'Personalización de correos electrónicos',
  poll_invitation: 'invitación a la encuesta',
  reminder_mail: 'Correo de recordatorio',
  tk_message: 'Mensaje de agradecimiento',
  message_subject: 'Asunto del mensaje',
  body_message: 'Cuerpo del mensaje',
  message_from_the_company: 'Mensaje por la empresa',
  created_workshop: '¡Medición creada exitosamente! En breve será redireccionado al resumen de la operación',
  id: 'Id',
  name: 'Nombre',
  participants: 'Participantes',
  responses: 'Respuestas',
  leaders: 'Líderes',
  period: 'Período',
  action: 'Acciones',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una encuesta',
  no_data: 'No hay mediciones para mostrar',
  engagement_realized: 'Engagement realizados',
  create_required_culture: 'Crear',
  pending: 'Pendiente',
  completed: 'Completada',
  in_progress: 'En progreso',
  cancelled: 'Cancelada',
  progress: 'En progreso',
  building: 'En construcción',
  at: 'a las',
  download: 'Descargar',
  aprogress: 'Progreso',
  edit: '¡Medición editada exitosamente!',
  tooltip_name: 'Nombre interno de la encuesta',
  tooltip_display_name: 'Nombre de la encuesta para los encuestados',
  summary: 'Resumen de la Medición',
  to_edit: 'Editar medición',
  total_progress: 'Progreso total',
  deadline_at: 'Fecha cierre',
  poll_completed: 'Encuestas completadas',
  poll_no_completed: 'Encuestas no completadas',
  resend: 'Reenviar encuestas no completadas',
  resend_success: '¡Encuestas reenviadas exitosamente!',
  question_n: '{n} Preguntas',
  no_actions: 'No disponibles',
  questionnaire_e: 'Cuestionario Engagement 3',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'En este archivo se puden observar las preguntas pertenecientes al cuestionario "{name}" agrupadas por dimensión y atributo.',
  open_question_info: 'Puede indicar preguntas personalizadas adicionales, indicando las posibles respuestas.',
  // tslint:disable-next-line:max-line-length
  congratulations: '¡Felicitaciones, completaste el <span class="headline" style="color: #44C156">{progress}%</span> de la encuesta!',
  incompleted: 'Incompleta',
  no_questionnaries_title: 'No puede continuar en la creación de esta encuesta',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'No existen cuestionarios activos para su empresa, por esta razón no puede continuar con la creación de encuestas de este tipo Por favor pongase en contacto con el administrador del sistema para solventar este problema.',
  to_the: 'al',
  link: 'Copiar enlace',
  dimensional_scores: 'Asignación de puntajes para dimensiones',
  workshop_calibration: 'Calibración de la Medición: ',
  done: 'Realizada',
  unrealized: 'No realizada',
  attributes_relevance: 'Valoración de nivel de requerimiento de los atributos',
  confirm_create_title: 'Confirme la creación del taller',
  confirm_generate_report: 'Confirme la generación del reporte',
  your_balance: 'Su saldo:',
  close_workshop: 'Cerrar medición',
  msg_close_workshop: 'Al cerrar la Medición ninguno de los colaboradores faltantes podrá responder la encuesta y esta acción no podrá ser modificada de ninguna manera. Toda la información para reportes se guardará hasta este punto.',
  copy_invitation_url: 'Copia el enlace y envíaselo a los participantes del taller previamente o al inicio del mismo.',
  link_copied: 'Enlace copiado.',
  no_required_culture: 'No posee registros de cultura requerida',
  go_create: 'Ir a creación de cultura requerida',
  resume: 'Resumen de cultura requerida',
  not_enough_population: 'Poblacion insuficiente.',
  population_more_than_five: 'La poblacion debe ser mayor a uno',
  no_population_msg: 'Para garantizar el anonimato de nuestras mediciones, no podemos permitir filtrar poblaciones menores a dos resultados, si desea conocer más de nuestras politicas',
  please_contact_us: 'por favor comuniquese con nosotros.',
  date_selection_subtitle: 'Con el fin de tener el link habilitado, le recomendamos establecer la fecha y hora de inicio mínimo 24 horas antes del taller.',
  questionnaire_selection_subtitle: 'Selecciona el mismo cuestionario en la medición de cultura actual, para que las respuestas sean 100% comparables.'
}
