
import dataObj from '../utils/data-obj'

export default {
  methods: {
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    $generateResults () {
      let totalAverage = this.cultureAverage[0] + this.cultureAverage[1] + this.cultureAverage[2]
      let totalPreviousAverage = ''
      if (this.hasPreviousRequired && this.previousRequired.attributeRelevance.length > 0) {
        totalPreviousAverage = 0
        totalPreviousAverage = this.previousCultureAverage[0] + this.previousCultureAverage[1] + this.previousCultureAverage[2]
        totalPreviousAverage = Math.round(((totalPreviousAverage / 3) + Number.EPSILON) * 100) / 100
      }
      totalAverage = Math.round(((totalAverage / 3) + Number.EPSILON) * 100) / 100
      const result = [
        // 4
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4. ${this.$t('requiredReport.results')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 20],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.1 ${this.$t('requiredReport.results_per_culture')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 0]
        },
        {
          text: this.$t('requiredReport.results_per_culture_text'),
          alignment: 'justify',
          margin: [0, 5, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#0889D6' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.dimension').toUpperCase(), margin: [0, 3, 10, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('requiredReport.values'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.values'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.sustainability'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.sustainability'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.customers'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.customers'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('requiredReport.innovation'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.innovation'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.achievement'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.achievement'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.leadership'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.leadership'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('requiredReport.talent'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.talent'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.relationship'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.relationship'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('requiredReport.communication'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.communication'), color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#aaaaaa' : (rowIndex === 1 && columnIndex === 0)
                ? '#0889D6' : (rowIndex === 2 && columnIndex === 0)
                  ? '#EB493C' : (rowIndex === 3 && columnIndex === 0)
                    ? '#48BBA1' : (rowIndex === 4 && columnIndex === 0)
                      ? '#aaaaaa' : ''
            }
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: this.$t('requiredReport.culture').toUpperCase(), margin: [80, 3, 80, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.required').toUpperCase(), margin: [30, 3, 20, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.last_score').toUpperCase(), margin: [30, 3, 20, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t('requiredReport.responsible'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 13, margin: [65, 5, 0, 0] },
                { text: (this.cultureAverage[0] ? this.cultureAverage[0].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] },
                { text: (this.previousCultureAverage[0] ? this.previousCultureAverage[0].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] }
              ],
              [{ text: this.$t('requiredReport.competitive'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 13, margin: [65, 5, 0, 0] },
                { text: (this.cultureAverage[1] ? this.cultureAverage[1].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] },
                { text: (this.previousCultureAverage[1] ? this.previousCultureAverage[1].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] }
              ],
              [{ text: this.$t('requiredReport.humanist'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 13, margin: [65, 5, 0, 0] },
                { text: (this.cultureAverage[2] ? this.cultureAverage[2].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] },
                { text: (this.previousCultureAverage[2] ? this.previousCultureAverage[2].toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] }
              ],
              [{ text: 'Total', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 13, margin: [65, 5, 0, 0] },
                { text: (totalAverage ? totalAverage.toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] },
                { text: (totalPreviousAverage ? totalPreviousAverage.toFixed(2) : '--'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#6d7a7a', alignment: 'center', margin: [0, 5, 0, 0] }
              ]
            ]
          },
          margin: [0, 0, 0, 0]
        },
        {
          image: this.barChart,
          width: 520,
          height: 340
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.2 ${this.$t('requiredReport.results_per_dimension')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: '',
          alignment: 'justify',
          margin: [0, 10, 22, 20],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#ddd' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.dimension').toUpperCase(), margin: [10, 5, 55, 5], bold: true }],
                  [
                    {
                      text: this.$t('requiredReport.required_culture'),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [8, 10, 0, 10]
                    }
                  ],
                  [
                    {
                      text: this.$t('requiredReport.last_score'),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [8, 10, 0, 10]
                    }
                  ]
                ]
              },
              margin: [0, 0, 14, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#0889D6' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    {
                      text: this.$t('requiredReport.initials.values'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      bold: true,
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.sustainability'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.customers'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      style: { color: '#fff' }
                    }
                  ],
                  [
                    {
                      text: (this.dimensionAverage[0].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[1].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[2].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ],
                  [
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[0], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[1], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[2], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    {
                      text: this.$t('requiredReport.initials.innovation'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      bold: true,
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.achievement'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.leadership'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      style: { color: '#fff' }
                    }
                  ],
                  [
                    {
                      text: (this.dimensionAverage[3].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[4].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[5].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ],
                  [
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[3], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[4], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[5], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    {
                      text: this.$t('requiredReport.initials.talent'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      bold: true,
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.relationship'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      borderColor: ['', '', '#fff', ''],
                      border: [false, false, true, false],
                      style: { color: '#fff' }
                    },
                    {
                      text: this.$t('requiredReport.initials.communication'),
                      alignment: 'center',
                      margin: [0, 5, 0, 5],
                      bold: true,
                      style: { color: '#fff' }
                    }
                  ],
                  [
                    {
                      text: (this.dimensionAverage[6].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[7].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (this.dimensionAverage[8].score).toFixed(2),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ],
                  [
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[6], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[7], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    },
                    {
                      text: (dataObj.propertieOrDefault(this.previousDimensionAverage[8], 'score')),
                      color: '#6d7a7a',
                      borderColor: ['', '', '', '#ddd'],
                      border: [false, false, false, true],
                      margin: [0, 10, 0, 10],
                      alignment: 'center'
                    }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          image: this.chartPie,
          width: 520,
          height: 370
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredReport.required_culture') }
          ],
          margin: [0, 40, 0, 4]
        }
      ]
      if (this.hasPreviousRequired) {
        result.push({
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: 'black' },
            { width: '96%', text: this.$t('requiredReport.last_score') }
          ]
        })
      }
      return result
    }
  }
}
