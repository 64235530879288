
export default {
  copyright: 'OCC - All rights reserved',
  model: 'The model',
  ranking: 'Ranking',
  occ_tool: 'OCC tool',
  current_culture: 'Current Culture',
  required_culture: 'Required Culture',
  current_culture_organization: 'Current organizational culture',
  current_culture_abr: 'Organization\'s Current Culture',
  current_culture_cut_abr: 'Current Cut Culture',
  open_questions: 'Open questions',
  commitment_belonging: 'Commitment + Belonging',
  index: 'Index',
  topic: 'Topic',
  page: 'Page',
  humanist: 'Humanist',
  competitive: 'Competitive',
  responsible: 'Responsible',
  our_recommendations: 'Our recommendations',
  introduction: 'Introduction',
  tool_desc: 'Tool description',
  understanding_results: 'Understanding the results',
  result_analysis: 'Results analysis',
  results: 'Results',
  results_per_dimensions: 'Results by dimensions',
  results_per_dimensions_actual_culture: 'Results by current culture dimension',
  results_per_dimensions_actual_culture_text: 'Total number of answers per value assigned for each dimension.',
  results_per_culture: 'Results by Types of Culture',
  results_per_culture_text: 'Each dimension uses the first two letters as its abbreviation',
  info_negative_answers: 'Consisting of the number and composed of the number and percentage of negative answers',
  info_neutral_answers: 'Consisting of the number and composed of the number and percentage of people who scored zero',
  info_positive_answers: 'Consisting of the number and composed of the number and percentage of people who answered positively',
  index_stack1: [
    '1.1 What is Organizational Culture?\n',
    '1.2 Why measure Organizational Culture?\n'
  ],
  index_stack2: [
    '2.1 What does the Organizational Culture Compass (OCC) tool measure?\n',
    '2.2 Types of Culture\n',
    '2.3 Dimensions of Culture\n'
  ],
  index_stack3: [
    '3.1 Response rate',
    '3.2 Current Context of the Organization\n',
    '3.3 Results analysis\n',
    '3.4 What to do with the Results?\n'
  ],
  questionaire_result: 'In this section you will find the results of the survey',
  index_stack4: [
    '4.1 Response Rate\n',
    '4.2 Results by Types of Culture\n',
    '4.3 Results by Dimensions\n',
    '4.4 Results by Attributes\n',
    '4.5 Ranking of Current Culture vs. Required Culture Dimensions\n',
    '4.6 Ranking of Current Culture vs. Required Culture Attributes\n',
    '4.7 Analysis of Gaps in Current Culture vs. Required Culture Dimensions',
    '4.8 Analysis of Gaps in Current Culture vs. Required Culture Attributes',
    '4.9 Ranking of Dispersion in Dimensions and Attributes',
    '4.10 Ranking of Trends\n',
    '4.11 Summary of Open Questions\n'
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    '1. Make sure strategic importance is given ', { text: 'Engagement', italics: true }, ' as a lever to achieve outstanding and sustainable results.\n\n',
    // tslint:disable-next-line:max-line-length
    '2. Remember that ', { text: 'Engagement', italics: true }, ' and Organizational Culture complement each other; managing both is vital to attracting and retaining talent.\n\n',
    // tslint:disable-next-line:max-line-length
    '3. Empower each leader with responsibility for managing ', { text: 'Engagement', italics: true }, ' and Culture. Human management areas facilitate and support.\n\n',
    // tslint:disable-next-line:max-line-length
    '4. When defining plans to bridge gaps, be inclusive and participative. Mobilizing collective intelligence generates a higher level of commitment.\n\n',
    // tslint:disable-next-line:max-line-length
    '5. Tracking the process in short cycles is vital. Look for creative ways to close the gaps and measure frequently. Our Pulse tool is designed for this.\n\n',
    // tslint:disable-next-line:max-line-length
    '6. Always disclose results openly and transparently and include them in the leadership performance management process.\n\n',
    // tslint:disable-next-line:max-line-length
    '7. Finally, remember the importance of maintaining focus. Fewer high-impact actions are better than many actions without positive effects.'
  ],
  // Introduction
  what_is_organization_culture: 'What is Organizational Culture?',
  // tslint:disable-next-line:max-line-length
  organization_culture_definition: 'Organizational Culture is viewed as the way of thinking, feeling and acting…..  reflection of the deepest beliefs, of the values, standards and traditions that define the organization\'s personality. \n\n',
  organization_culture_definition2: 'Organizational Culture is the last frontier of competitive advantages and which generates a sustainable differentiation that is difficult to imitate. The culture of an organization is reflected in the daily habits of its members, in its observable features and the way in which decisions are made . \n\n',
  organization_culture_definition3: 'Organizational Culture is directly related to executing strategy and achieving results; many organizations fail to execute their strategy because they do not have an adequate and coherent culture.\n\n',
  // Para qué definir la cultura organizacional
  for_what: 'Why measure organizational culture?',
  for_what1: 'In view of the complexity of today\'s world and the challenges that arise in attracting clients, talent or investors, cultural capital has become the new priority in terms of an organization\'s competitive advantages\n\n',
  for_what2: 'The objective of diagnosing culture is to know the current state of the organization\'s culture and compare it with the required or already defined culture. From this information, positive and limiting aspects of the culture and priorities to work can be defined, internal comparisons between different populations and trends. \n\n',
  for_what3: 'A culture is measurable, dynamic, transformable and unique. To this extent, no culture is better than others, simply more or less adequate; it is not about comparing organizations, it is about knowing the reality and contrasting it with their needs and desires.\n\n',
  // Descripción de la herramienta occ
  what_the_tool_measure: 'What does the organizational culture compass (OCC) tool measure?',
  what_the_tool_measure_text: 'OCC\'s questionnaire is based on observable behaviors associated with values ​​that define the Organizational Culture. The results presented in this report are based on the perceptions of the people who answered the questionnaire. The 72 Attributes (behaviors) form nine dimensions organized in three types of culture. \n\n',
  what_the_tool_measure_text2: 'By analyzing the data, the elements of the culture that are predominant and those that are less obvious can be identified.\n\n',
  // Tipos de cultura
  culture_types: 'Types of culture',
  responsible_culture: 'Responsible culture',
  competitive_culture: 'Competitive culture',
  humanist_culture: 'Humanistic culture',
  responsible_text: 'An organization admired by society and its customers, a "good citizen", oriented towards its stakeholders with ethical and impeccable management. It comprises the following dimensions:',
  competitive_text: 'An efficient, vibrant results organization. Balances innovation and the effectiveness of its current processes through the management of world-class leaders. It comprises the following dimensions:',
  humanist_text: 'An organization that understands the need for competent and committed talent if it is to achieve its results.  A company that attracts, develops and retains the best talent, promotes excellent relationships and effective and transparent communication. It comprises the following dimensions:',
  what_is_engagement_text: ['At OCC Solutions we understand ', { text: 'Engagement', italics: true }, ' as a high level of emotional commitment, a sense of belonging combined with enthusiasm and passion for work and for the Organization that manifests itself in a permanent desire to always give the best.'],
  // Dimensiones de cultura
  culture_dimensions: 'Culture Dimensions',
  culture_dimensions_next: 'The nine Dimensions of Culture are described below:',
  yes: 'Yes',
  no: 'No',
  // Principios
  values_positive: 'Leadership and a principle-centered culture define and determine guidelines for human behavior that become the moral compass of the organization. Principles can be viewed as values, and today it is clear that experiencing them is essential for ensuring the survival of organizations.',
  values_negative: ['For organizations that ', { text: 'do not', decoration: 'underline' }, ' focus on principles, the end justifies the means. Values either do not exist or are not experienced. The associated behaviors are not an example of good behavior, morals or ethics. They usually have a bad reputation and do not build trust with their stakeholders.'],
  // Sostenibilidad
  sustainability_positive: 'A culture of sustainability promotes the efficient use of current resources to ensure the future sustainability of the organization. It gives the same importance to all interest groups and seeks balanced results in financial, human, social and planet issues',
  sustainability_negative: ['A culture ', { text: 'that does not', decoration: 'underline' }, ' focus on sustainability aims to get short-term results by prioritizing achievements for shareholders over other stakeholders. Social responsibility, the planet and future generations ', { text: 'are not', decoration: 'underline' }, ' they are priorities.'],
  // Clientes
  customers_positive: 'A customer-centric culture focuses its efforts on understanding, meeting and exceeding customer needs and expectations. When processes and systems, structure, and people\'s competences are defined, current and future customer realities are taken into account.',
  customers_negative: ['A non-customer centric culture defines the customer as a means to obtain results and shows no interest in their satisfaction or loyalty. The vision is more inward, towards processes, control and short-term financial results. Requests, complaints, claims and suggestions are seen as a nuisance and customers, at best, are only important to those who are in direct contact with them.'],
  // Innovación
  innovation_positive: 'Innovation is understood as the ability to create new realities, it is a vital aspect for the success of organizations in today\'s world. A culture of innovation fosters creativity, initiative and the ability to reinvent yourself to prosper and compete over time, while allowing you to take risks and learn from mistakes.',
  innovation_negative: ['In organizations where innovation ', { text: 'is not', decoration: 'underline' }, ' is not valued, they seek to maintain and preserve what is known. Most people are paid to “do their job and obey” and the ability to innovate is, at best, the privilege of a few. There is inflexibility, difficulty in adapting and changing, fear of taking risks, and mistakes are often punished.'],
  // Logro
  achievement_positive: 'Organizations must obtain positive results to progress and thus be able to achieve their objectives. A culture of effectiveness and good results promotes achievement-oriented behaviors and processes with a high level of performance. A direct line is created between the contribution of individual and team performance to the performance of the organization. Positive results are the consequence of a culture of high performance and commitment.',
  achievement_negative: ['In organizations that ', { text: 'do not', decoration: 'underline' }, ' value results, such results are not important and when they are not achieved there are usually “excuses or culprits”. There is generally a lack of commitment and a sense of belonging. Compliance with processes and controls are more important than achieving defined goals.'],
  // Liderazgo
  leadership_positive: 'The quality and capacity of leaders is essential to understanding the success of organizations; understanding leadership as the art of mobilizing and guiding people to ensure the achievement of common objectives and purposes. In this dimension, we analyze how leaders achieve commitment, generate trust, become interested in developing people, give autonomy, create environments that empower and accompany their teams in their development.',
  leadership_negative: ['In organizations where transformative leadership ', { text: 'is not', decoration: 'underline' }, ' valued, people are simply one more resource and are required only to implement the goals, objectives and tasks defined by the bosses. Managers assign activities and control results. Management is based on imposition, authoritarianism, distrust and there is usually a high level of "micro-management".'],
  // Talento
  talent_positive: 'A people-centered culture seeks to attract, develop and retain the best talent in the organization as the main lever for achieving its goals. The aim is to have competent people with a high commitment and performance level, who feel proud of belonging to the organization. These organizations strive to deserve the enthusiasm and intelligence of all through their vision, their coherence with values, their leadership and their achievements.',
  talent_negative: ['For companies that ', { text: 'do not', decoration: 'underline' }, ' value people, people are resources that are used to achieve their objectives, regardless of their individual objectives and goals. People\'s emotions, skills and development are not important or, at most, they are for Human Resources areas.'],
  // Relaciones
  relationship_positive: 'In these organizations, the connection between relationship quality and results is clear. They promote teamwork, solidarity and the ability to join forces, in order to achieve common goals. Topics such as shared goals, trust, coordination, interdependence and collaboration are common in these types of organizations.',
  relationship_negative: 'Organizations that value only individual work, internal competition, and the win-lose game describe the everyday way of acting and leading. Relationships are strictly professional, cooperation is viewed as a favor, and division between areas reflects a clear sense of independence. Energy is wasted on bad relationships and mistrust.',
  // Comunicación
  communication_positive: 'The way people communicate and the stories that are told are part of, and reflect and define, the culture and leadership styles. In an organization with a strong communication culture, information is shared transparently, is listened to carefully, questions are encouraged, and relationships of trust and mutual respect are created and maintained. Vision and goals are clearly communicated.',
  communication_negative: ['In organizations where communication ', { text: 'is not', decoration: 'underline' }, ' valued, "he who has information has power." Information does not flow in all directions, nor is the importance of timeliness and transparency valued. Strategies and objectives are not clearly communicated. There is often no “time” to listen to people, and questions are perceived as inappropriate and unnecessary. The complexity of communications reflects mistrust, disrespect, and hierarchical leadership styles.'],
  // Entendimiento de los resultados
  current_context: 'Current Context of the Organization',
  current_context_text: 'When interpreting results, it is crucial to take into account the current context of the organization and the population being analyzed. The following questions can provide some guidelines:\n\n',
  current_context_questions: [
    'What are our current challenges?',
    'Where are we going?',
    'What do we want to achieve?',
    'What are our opportunities and threats?',
    'What are our strengths and opportunities for improvement?\n\n'
  ],
  results_analysis: [
    'If the gap between Dimensions, Attributes and Subcultures is greater than 1, the symbol (+) will appear in the gap column and on the graphs, denoting misalignment or differences between Cultures.',
    'If the gap between Dimensions, Attributes and Subcultures is less than 1, the symbol (-) will appear in the gap column and on the graphs denoting alignment or cultural similarities.',
    'If the average of an Attribute is greater than or equal to 2 in the Current Culture, it indicates a positive Culture.',
    'If the average of an Attribute is less than or equal to 0 in the Current Culture, it indicates a potentially limiting Culture.',
    'In this case, more analysis is required to understand the situation.',
    'The highest gaps between the Required Culture and the Current Culture indicate an area of ​​opportunity and should generate strategies to close gaps.',
    'Attributes with greater dispersion indicate less consensus and require more detailed analysis.',
    'If it improved by more than 0.5 when compared to the previous evaluation,, the value will be highlighted in bold.\n\n'
  ],
  what_is_organizational_culture: 'What is Organizational culture?',
  // tslint:disable-next-line:max-line-length
  what_is_organizational_culture_text: [
    'Organization Culture is viewed as the way of thinking, feeling and acting that distinguishes the members of an organization. The deepest beliefs, of values, standards and traditions that define the personality of the organization.\n\n',
    'Organizational Culture is the last frontier of competitive advantages which generates a sustainable differentiation that is difficult to imitate. The culture of an organization is reflected in the daily habits of its members, in its observable features and the way in which decisions are made . \n\n',
    'Organizational Culture is directly related to executing strategy and achieving results; many organizations fail to execute their strategy because they do not have an adequate and coherent culture. \n\n'
  ],
  what_is_engagement_positive: [
    'Do your best every day and be passionate about your job.\n\n',
    'Emotional commitment to the purpose, objectives and values of the Organization.\n\n',
    'Motivation to contribute to the success of the Organization with a greater sense of well-being.\n\n',
    'Trust, integrity, two-way commitment and communication between the Organization and its members.\n\n',
    'High level of enthusiasm and dedication that a person feels towards their work.'
  ],
  answers_rate_items: [
    'How was the survey process communicated?\n',
    'Was the importance of this process clearly expressed?\n',
    'What does the fact that not everyone answered indicate?\n\n'
  ],
  how_does_our_model_works: 'How does our model work?',
  what_do_with_results: 'What to do with the Results?',
  what_do_with_results_text: 'After understanding these results, you should take these steps:\n\n',
  results_steps: [
    'Analyze the results by identifying similarities and differences between Actual and Required Culture.\n\n',
    'Identify the strengths of the organization for leveraging a Culture Transformation process.\n\n',
    'Identify the main potentially limiting factors in Culture and define a plan of action that can most affect the development of your strategy.\n\n',
    'Identify the main gaps and define a plan of action for those that can most affect the development of your strategy.\n\n',
    'Analyze the aspects in which there is greater dispersion. This will indicate those aspects where there may be greater differences between the perceptions of people / areas.\n\n',
    'Make sure you communicate the results properly to the people who need them.'
  ],
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text1: 'Our model allows us to measure six dimensions, which indicate people\'s level of commitment and sense of belonging to the Organization.. Each dimension in turn has 3 attributes and each attribute has 3 questions, called variables. The six dimensions are:',
  how_does_our_model_works_text2: 'Each of the 6 dimensions has three attributes:',
  my_inspiration: 'My inspiration',
  sense_of_purpose: 'Sense of purpose',
  transcendental_work: 'Significant work',
  they_know_me: 'They know me',
  my_job: 'My job',
  reputation_of_the_organization: 'Reputation of the Organization',
  reputation_of_my_area: 'Reputation of my area',
  work_enviroment: 'Work environment',
  positive_work_enviroment: 'Positive work environment',
  relationships_quality: 'Quality of relationships',
  peoples_treat: 'Treatment of people',
  equity_and_transparency: 'Fairness and transparency',
  my_team: 'My team',
  team_dynamics: 'Team dynamics',
  communication_and_coordination: 'Communication and coordination',
  agility_and_decision_making: 'Agility and decision making',
  my_development_and_learning: 'My development and learning',
  opportunities_in_my_role: 'Growth opportunities in my role',
  opportunities_in_the_organization: 'Opportunities for growth in the Organization',
  learning_culture: 'Learning culture',
  the_leaders: 'The leaders',
  my_leader: 'My leader',
  support: 'Support ',
  our_leaders: 'Our leaders',
  methodology: 'Methodology',
  // tslint:disable-next-line:max-line-length
  completed_questionaires_persons: 'This table shows the number of people who were included in the process and which group completed the questionnaire.',
  methodology_conclusion: 'In addition, in the final part of the survey, three open questions are asked, and these are shown graphically in the report',
  results_text: 'In this section you will find the results of the survey.',
  total_obtained: 'Total answered: ',
  total_sent: 'Total expected: ',
  answers_rate: 'Response rate',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'It is important to understand the results in relation to the response rate. If the response rate was not what you expected, you should ask yourself the following:\n\n',
  general_score: 'Results',
  results_by_dimension: 'Results by dimension',
  results_by_attribute: 'Results by attribute',
  results_by_dim_attr: 'Results by dimension - attribute',
  total: 'Total',
  attribute: 'Attribute',
  limitant_attribute: 'Limiting Attribute',
  positive_attribute: 'Positive Attribute',
  act: 'ACT',
  disp: 'DISP',
  tend: 'TEND',
  current_population: 'Current Score',
  preview_population: 'Previous Score',
  rate: 'Trend',
  actual_organization: 'Organization Score',
  gap: 'Gap',
  culture_alignment: 'Alignment with culture',
  purpose: 'Purpose',
  future_vision: 'Future vision',
  autonomy: 'Autonomy',
  strengths_and_talents: 'Strengths and talents',
  my_contribution: 'My contribution',
  motivation: 'Motivation',
  they_value_me: 'They value me',
  change_value: 'Change value',
  performance: 'Execution',
  recognized_organization: 'Recognized organization',
  organization_performance: 'Performance of the Organization',
  pride_of_belonging_organization: 'Pride in belonging to the Organization',
  recognized_area: 'Recognized area',
  area_performance: 'Performance of the area',
  pride_of_being_part_area: 'Pride in being part of the area',
  materials_and_equipment: 'Materials and equipment',
  offices_and_dress_code: 'Offices and dress codes',
  balance_personal_professional: 'Balance between personal and professional life',
  being_myself: 'Be myself',
  freedom_of_expression: 'Freedom of expression',
  interpersonal_relationships: 'Interpersonal relationships',
  care_for_people: 'Care for people',
  inclusive_workplace: 'Inclusive workplace',
  respectful_treatment: 'Respectful treatment',
  equal_opportunities: 'Equal opportunity',
  salary_and_benefits: 'Salary and benefits',
  recognition_culture: 'Recognition culture',
  trust_quality_relationships: 'Trust and quality in relationships',
  responsibility_meeting_goals: 'Responsibility for meeting targets',
  workload_balance: 'Balance in workload',
  team_network: 'Team network',
  communication_team: 'Communication in my team',
  diversity: 'Diversity',
  agility_processes: 'Agility in processes',
  innovation: 'Innovation',
  achievement: 'Achievement',
  leadership: 'Leadership',
  talent: 'Talent',
  relationship: 'Relationships',
  communication: 'Communication',
  values: 'Principles',
  sustainability: 'Sustainability',
  customers: 'Customers',
  access_transparency_information: 'Access to and transparency of information',
  autonomous_learning: 'Autonomous Learning',
  development_potential: 'Development of my potential',
  expectations_role: 'Expectations of my role',
  career_plan: 'Career plan',
  future_organization: 'Future in the Organizationn',
  horizontal_mobility: 'Horizontal mobility',
  learning_tracking: 'Learning tracking',
  genuine_interest: 'Genuine interest',
  admiration: 'Admiration',
  transparency_honesty: 'Transparency and honesty',
  clear_transparent_objectives: 'Clear and transparent objectives',
  coaching_feedback: 'Coaching and feedback',
  leader_access: 'Access to the leader',
  systematic_thinking: 'Systematic thinking',
  strategic_planning: 'Strategic planning',
  social_intelligence_collaboration: 'Social intellilgence and cooperation',
  ranking_of_dimensions: 'Ranking of dimensions for current culture',
  dimension: 'Dimension',
  actual: 'Current',
  required: 'Required',
  current_organization: 'Current organization',
  dimensions_with_higher_score: 'Highest Scored Dimensions',
  dimensions_with_lower_score: 'Lowest Scored Dimensions',
  dimensions_with_higher_dispersion: 'Dimensions with highest dispersion',
  dimensions_with_lower_dispersion: 'Dimensions with lowest dispersion',
  ranking_of_the_attributes: 'Ranking of attributes for current culturel',
  ranking_of_the_attributes_dispersion: 'Ranking of the attributes with the highest / lowest current culture dispersion',
  attributes_with_higher_score: 'Highest scoring attributes',
  attributes_with_lower_score: 'Lowest scoring attributes',
  attributes_with_higher_gap_positive: 'Attributes with highest positive gaps',
  attributes_with_higher_gap_negative: 'Attributes with highest negative gaps',
  higher_dispersion_ranking: 'Ranking of Attributes with the highest current culture dispersion',
  lower_dispersion_ranking: 'Ranking of Attributes with the lowest current culture dispersion',
  ranking_of_the_variables: 'Ranking of variables',
  ranking_of_rates: 'Ranking of current culture trends',
  variable: 'Variable',
  variables_with_higher_score: 'Variables with the highest current score',
  variables_with_lower_score: 'Variables with the lowest current score',
  dispersion: 'Dispersion',
  dispersion_by_attributes: 'Dispersion by attibutes',
  dispersion_by_variables: 'Dispersion by variables',
  attributes_with_higher_dispersion: 'Attributes with higher dispersion',
  attributes_with_lower_dispersion: 'Attributes with lower dispersion',
  preview_dispersion: 'Previous dispersion',
  current_dispersion: 'Current dispersion',
  variables_with_higher_dispersion: 'Variables with higher dispersion',
  variables_with_lower_dispersion: 'Variables with lower dispersion',
  rates_by_dimension: 'Trends by dimension',
  variation: 'Variation',
  rates: 'Trends',
  highest_positive_rate_attribute: 'Highest positive trends',
  highest_negative_rate_attribute: 'Highest negative trends',
  highest_positive_rate_variable: 'Highest positive trends by variable',
  highest_negative_rate_variable: 'Highest negative trends by variablee',
  rates_by_variables: 'Trends by variables',
  rates_by_attributes: 'Trends by attributes',
  open_questions_summary: 'Closed question graphs',
  word_questions_summary: 'Summary of open questions',
  the_leaders_text: 'THE LEADERS: The leaders\nin this Organization\ninspire me',
  my_development_text: 'MY DEVELOPMENT\nAND LEARNING: I see myself growing\nin this Organization\nin the future',
  my_team_text: 'MY TEAM: I am part\nof a team committed\nto the Organization',
  // tslint:disable-next-line:max-line-length
  work_enviroment_text: 'POSITIVE\nWORK ENVIRONMENT: I ​​would recommend a\nfriend or family member to work\nin this Organization',
  my_job_text: 'MY WORK: I\nfeel committed\nand proud to work\nin this company',
  // tslint:disable-next-line:max-line-length
  my_inspiration_text: 'MY INSPIRATION: If I were offered a similar job in\nanother company, I would\nstay where I am',
  reports: 'Reports',
  general_title: 'General',
  general_desc: 'This report has the statistics of the entire population included in the survey.',
  demographic_title: 'By demographic cuts',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'This report features population statistics filtered through certain demographic cuts; these cuts can be selected below:',
  demographic_button: 'Generate Engagement report by demographic cuts',
  display_demographic: 'Show demographic cuts',
  population: 'Population',
  sent: 'Sent',
  obtained: 'Obtained',
  AcademicDegree: 'Academic title',
  Age: 'Age',
  Antiquity: 'Seniority',
  Charge: 'Job title',
  Country: 'Country',
  Department: 'Area / Department',
  Gender: 'Gender',
  JobType: 'Employment type',
  years_range: 'Between {min} and {max} years',
  my_inspiration_chart: 'My\ninspiration',
  my_job_chart: 'My\njob',
  work_enviroment_chart: 'Positive work\nenvironment',
  my_team_chart: 'My\nteam',
  my_development_and_learning_chart: 'My development\nand learning',
  the_leaders_chart: 'The\nleaders',
  cover: {
    byItems: 'Report by population',
    general: 'General Report'
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  active_employees: 'Active employees',
  average: 'Sector average',
  people: 'People',
  note: 'Notes:',
  colors: 'Colors',
  dim_color: 'Dimension color',
  meaning: 'Meaning',
  previous_measurement: 'Previous measurement',
  strengths: 'Strengths',
  opportunities: 'Opportunities for improvements',
  feelings: 'Feelings',
  what_is_it: 'What is it?',
  what_is_not: 'What is it not?',
  critical_situation: 'Critical situation',
  requires_attention: 'Requires urgent attention',
  insufficient: 'Insufficient',
  aceptable: 'Acceptable',
  very_good: 'Very good',
  remarkable: 'Outstanding',
  how_to_read: 'How to read the report',
  title: 'OCC - Engagement Report',
  att_var: 'Attribute - Variable',
  dispersion_by_dimensions: 'Dispersion by dimensions',
  open_questions_button: 'Additional question graphs',
  open_question_select_label: 'Select a question',
  org_dispersion: 'Organization dispersion',
  positive_attribute_footer: 'The following attributes have a high score exactly equal to the attribute ',
  negative_attribute_footer: 'The following attributes have a low score exactly equal to the attribute ',
  positive_variable_footer: 'The following variables have a high score exactly equal to the variable ',
  negative_variable_footer: 'The following variables have a low score exactly equal to the variable ',
  no_answers_modal_msg: 'The current survey has no answers, so a report cannot be generated.',
  positive: 'positive',
  limiting: 'limiting',
  initial: 'Init',
  no_tend_msg: 'Available from the second completed survey'
}
