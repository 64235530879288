
import pdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      keys: {
        jobTypes: 'jobTypes',
        headquarter: 'headquarter',
        gender: 'gender',
        departments: 'departments',
        country: 'country',
        charge: 'charge',
        antiquity: 'antiquity',
        age: 'age',
        academicDegree: 'academicDegree',
        additionalDemographic1: 'optionalDemo1',
        additionalDemographic2: 'optionalDemo2'
      }
    }
  },
  methods: {
    buildParticipationByDemographicTable (label, rows = []) {
      const table = [{
        margin: [5, 17, 5, 0],
        table: pdfUtil.generateTable(
          [ '60%', '20%', '20%' ],
          [ ...rows ],
          [
            label,
            this.$t('summaryReport.total_expected'),
            this.$t('summaryReport.total_obtained')
          ]
        ),
        layout: pdfUtil.setLayout()
      }]

      return table
    },
    $generateParticipationTables () {
      const tables = []
      const count = this.threadData.countsByDemographicsCuts
      Object.keys(count).forEach(key => {
        const found = this.enterpriseItems.find(x => x.code === this.keys[key])
        if (found) {
          const translation = found.translations.find(x => x.lang === this.user.lang)
          const label = translation ? translation.label : '--'
          const rows = []
          Object.keys(count[key]).forEach(id => {
            let name
            if (['age', 'antiquity'].includes(key)) {
              name = this.$t(`followupReport.${key}.${id}`)
            } else {
              if (key === 'headquarter') {
                const hq = this.totalItems[key].find(h => parseInt(h.id) === parseInt(id))
                name = hq ? hq.name : '--'
              } else {
                const t = count[key][id].translations.find(x => x.lang === this.user.lang)
                name = t ? t.label : '--'
              }
            }
            if (count[key][id].expected > 0) {
              rows.push([
                { text: name },
                { text: count[key][id].expected, alignment: 'center' },
                { text: count[key][id].obtained, alignment: 'center' }
              ])
            }
          })
          tables.push(this.buildParticipationByDemographicTable(label, rows))
        }
      })

      return [
        pdfUtil.generateTitle(`${this.$t('cultureReports.results')}`, 'before', 20, [0, 5, 0, 0]),
        {
          text: `${pdfUtil.round((this.totalObtained * 100) / this.totalReceivers)}%`,
          color: '#2196F3',
          fontSize: 44,
          absolutePosition: { x: 400, y: 50 }
        },
        pdfUtil.generateTitle(`${this.$t('cultureReports.answers_rate')}`, '', 20, [0, 20, 0, -10]),
        this.buildParticipationTable([5, 20, 5, 0]),
        pdfUtil.generateTitle(`${this.$t('summaryReport.demographic_answers_rate')}`, '', 20, [0, 25, 0, -10]),
        ...tables
      ]
    }
  }
}
