
<template>
  <v-container fluid>
    <div>
      <v-card style="margin-bottom:10px;">
        <v-row>
          <v-col>
            <v-select
              class="styled-select mr-3"
              :items="languages"
              v-model="language"
              dense
              @change="changeLocale"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-title class="d-none d-md-flex" style="background-color:#9E9E9E;">
          <v-row wrap row align="center">
            <v-col cols="12" sm="6">
              <v-card style="border-radius:10px;" min-height="">
                <div class="d-flex flex-no-wrap justify-space-around align-center">
                  <img :src="enterprise.logo  || baseUrlImage" alt="Enterprise Logo" height="130" width="260" style="margin-top: 10px;"/>
                  <div style="">
                    <v-card-title
                      class="headline"
                      style="white-space: nowrap;"
                    >{{ enterprise.name || '' }}</v-card-title>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <h2
                class="text-capitalize ml-5 white--text"
                style="font-size: 40px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 60px;"
              >
                {{ $t('evaluations.required_title') }}
              </h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-stepper :value="step">
          <v-stepper-items>
            <v-card-text>
              <v-stepper-content v-for="n in (dimensions.length + 1)" :key="`${n}-content`" :step="n">
                <v-row wrap row>
                  <v-col xs12>
                    <v-card outlined
                      v-if="step === 1" elevation="2"
                      class="pa-8"
                    >
                      <p
                        v-html="$t('evaluations.required_information')"
                        class="mb-0"
                      ></p>
                    </v-card>
                    <v-card v-if="step > 1" outlined elevation=2>
                      <v-card-title style="padding:30px;">
                        <v-btn fab v-if="step > 2" small color="warning" class="mr-5" @click="stepBack">
                          <v-icon>fa-chevron-left</v-icon>
                        </v-btn>
                        <p style="font-size: 40px;"> {{ $t(`evaluations.dim_${dimensions[step-2]['name']}`) }}</p>
                        <br/>
                      </v-card-title>
                      <v-card-subtitle style="padding-left: 30px; margin-top: 1px;">
                        <span style="font-size: 26px; color: #0D47A1;">{{ $t(`evaluations.${dimensions[step-2]['type']}`) }}</span>
                      </v-card-subtitle>
                      <p style="padding: 30px;">
                        {{ $t('evaluations.dimensions_information') }}
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <v-layout v-if="step === 1" wrap row pa-3 mb-3>
                  <v-flex xs12>
                    <v-expansion-panels v-model="panel" :disabled="Boolean(conectionAttempt)">
                      <v-flex v-for="(item, i) in dimensions" :key="i" xs12>
                        <v-expansion-panel style="border-bottom: 1px solid #aeb2b5;">
                          <v-col>
                            <v-expansion-panel-header style="margin-bottom: 10px;">
                              <h4>{{ $t(`evaluations.${item.name}`) }}</h4>
                              <v-avatar v-if="score[i]" :color="i < 3 ? '#309DE0' : i >=3 && i <= 5 ? '#EC5F50' : '#4ACAAE'" size="45" style="flex: none;">
                                <span class="white--text">{{score[i]}}%</span>
                              </v-avatar>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="border-top:1px solid rgb(56, 153, 218);">
                              <p style="margin-top:5px; margin-bottom: 0">
                                {{ $t(`evaluations.dimension_text.${item.name}`) }}
                              </p>
                              <v-row row wrap justify="end">
                                <v-col cols="4" sm="4" md="2" lg="2" xs="4">
                                  <v-text-field
                                    v-model="score[i]"
                                    type="number"
                                    append-icon="fa-percentage"
                                    min="0"
                                    max="100"
                                    autofocus
                                    @keypress.enter="nextItem(i) | validate(score[i], i)"
                                    @focusout="validate(score[i], i)"
                                    :rules="[rules.validate]"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-col>
                        </v-expansion-panel>
                      </v-flex>
                    </v-expansion-panels>
                  </v-flex>
                  <v-flex>
                    <v-fab-transition style="bottom: 10px;">
                      <v-btn
                        :color="progress === 100 ? 'green accent-4' : 'red darken-1'"
                        dark
                        fixed
                        bottom
                        right
                        fab
                        x-large
                      >
                        <span>{{progress}}%</span>
                      </v-btn>
                    </v-fab-transition>
                  </v-flex>
                </v-layout>
                <template v-if="step > 1">
                  <div v-for="(question, k) in dimensions[step-2].questions" :key="k">
                    <v-row pa-3 wrap row>
                      <v-col cols="12" >
                        <v-card raised elevation="5">
                          <v-row justify="space-between" align="center">
                            <v-col cols="12" sm="8" style="padding-left: 20px;">
                              <span>{{question.positive[language] }}</span>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-btn
                                :text="!dimensions[step-2].questions[k].more_important"
                                style="color: #51C7AF"
                                :disabled="dimensions[step-2].questions[k].disabled || Boolean(conectionAttempt)"
                                :color="!dimensions[step-2].questions[k].more_important ? '#51C7AF' : 'rgba(81, 199, 175, 0.3)'"
                                @click="evaluateQuestion(k, step-2, 'positive')"
                              >
                                <v-icon left small>fa-plus</v-icon>
                                <span>{{ $t('evaluations.required') }}</span>
                              </v-btn>
                              <v-btn
                                :text="!dimensions[step-2].questions[k].less_important"
                                style="color: #ff5252"
                                :disabled="dimensions[step-2].questions[k].disabled || Boolean(conectionAttempt)"
                                :color="!dimensions[step-2].questions[k].less_important ? 'error' : 'rgba(199, 93, 81, 0.3)' "
                                @click="evaluateQuestion(k, step-2, 'negative')"
                              >
                                <v-icon left small>fa-minus</v-icon>
                                <span>{{ $t('evaluations.required') }}</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </v-stepper-content>
            </v-card-text>
          </v-stepper-items>
        </v-stepper>
        <v-spacer/>
      </v-card>
      <v-row row wrap class="mt-5" justify="center">
        <v-col cols="6">
          <v-row class="text-right">
            <v-col v-if="this.step <= 10 && this.step > 1" cols="12" class="my-0" style="font-size: 18px; color: rgb(56, 153, 218);">
              <h2 v-if="progressComplete[step-1]">{{ $t('evaluations.progress') + ' ' + (step * 10) + '%' }}</h2>
              <h2 v-else>{{ $t('evaluations.progress') + ' ' + ((step - 1) * 10) + '%' }}</h2>
            </v-col>
            <v-col cols="12" class="my-0 py-0" v-if="this.step <= 10 && this.step > 1">
              <v-progress-linear
                :value="progressComplete[step-1] ? ((step) * 10) : (step-1) * 10"
                buffer-value="100"
                height="10"
                color="rgb(56, 153, 218)"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="this.step === 1" cols="12" xs="6" md="6" sm="6" class="mt-4 ml-5">
          <v-btn
            color="rgb(56, 153, 218)"
            class="white--text"
            :disabled="!progressComplete[step-1] || Boolean(conectionAttempt)"
            @click="firstStep"
            width="100%"
            min-width="100%">{{ $t('input.next') }}</v-btn>
        </v-col>
        <v-col cols="12" xs="4" md="4" sm="6" v-if="this.step < 10 && this.step > 1" class="mt-4 ml-5">
          <v-btn
            :disabled="!progressComplete[step-1] || Boolean(conectionAttempt)"
            color="rgb(56, 153, 218)"
            class="white--text"
            @click="nextStep(step)"
            width="100%"
            min-width="100%">{{ $t('input.next') }}</v-btn>
        </v-col>
        <v-col v-if="this.step === 10" cols="12" xs="6" md="4" sm="6" class="mt-4 ml-5">
          <v-btn
            color="rgb(56, 153, 218)"
            class="white--text"
            :disabled="!progressComplete[step-1] || Boolean(conectionAttempt)"
            @click="saveAttributes()"
            width="100%"
            min-width="100%">{{ $t('input.save') }}</v-btn>
        </v-col>
        <v-col cols="12" v-if="conectionTry">
          <v-alert type="info" :value="conectionTry">{{ $t('actualEvaluation.connection_try_save') }}</v-alert>
          <v-alert type="info" :value="conectionAttempt >= 10">{{ $t('actualEvaluation.connection_interrupted_out') }}</v-alert>
          <v-btn
            v-if="conectionAttempt >= 10"
            @click="retryConection"
            class="mt-4"
            color="primary"
            large
          >{{ $t('actualEvaluation.connection_try_connect_now') }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="checkDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-row row wrap justify="center" class="text-center">
            <v-col cols="12" xl="12" lg="12" xs="12" md="6" sm="12">
              <v-icon x-large color="red darken-4" style="margin-top: 5%; font-size: 1400%">fa-ban</v-icon>
            </v-col>
          </v-row>
          <v-row row wrap justify="center" class="text-center">
            <v-col cols="12" xl="12" lg="12" xs="12" md="12" sm="12" style="margin-top: 4%;">
              <span style="font-size: 30px;">{{ $t('evaluations.dont_close') }}</span>
              <br><br>
              <span style="font-size: 30px;">{{ $t('evaluations.please_wait') }}</span>
              <!-- <br/>
              <span style="font-size: 30px;">{{ $t('evaluations.if_accepted') }}</span> -->
              <br/>
              <span style="font-size: 20px; color: red">{{ dialogError }}</span>
            </v-col>
            <v-col cols="12" xl="3" lg="3" xs="3" md="4" sm="6" style="margin-top: 5%; border-bottom: 6px solid #B71C1C">
              <v-btn v-if="!continueButton" color="primary" outlined width="100%" @click="nextStep(1)">{{ $t('input.next') }}</v-btn>
              <v-btn v-else color="primary" outlined width="100%" @click="checkDialog = false">{{ $t('input.continue_anyway') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('input.confirm_continue') }}</v-card-title>
        <v-card-actions style="text-align: center;">
          <v-spacer />
          <v-btn
            color="error"
            icon
            @click="confirmDialog = !confirmDialog"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn
            color="success"
            icon
            @click="nextStep(1)"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <x-out-interval-dialog :dialog="outIntervalDialog" :icon="dialogIcon" :text="dialogText"></x-out-interval-dialog>

    <x-dialog-finish
      :logo="displayEnterpriseLogo"
      :display.sync="finishDialog"
    ></x-dialog-finish>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import requiredCultureService from '../../services/required-culture'
import XDialogFinish from './culture-pulse/dialogs/finish.vue'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  components: {
    XDialogFinish
  },
  data () {
    return {
      score: [],
      requiredCulture: {},
      dimensions: [],
      cultureTypes: {},
      dimensionScore: {
        responsible: [],
        competitive: [],
        humanist: []
      },
      dimensionsScores: [],
      dimensionsEndScore: {},
      baseUrlImage: '',
      progressComplete: [],
      attributeRelevance: {
        responsible: [],
        humanist: [],
        competitive: []
      },
      enterprise: { logo: null, name: '' },
      panel: 0,
      step: 1,
      checkDialog: false,
      confirmDialog: false,
      outIntervalDialog: false,
      finishDialog: false,
      dialogIcon: '',
      dialogText: '',
      calibrated: false,
      rules: {
        validate: value => {
          const pattern = /^[0-9][0-9]?$|^100$/
          return pattern.test(value) || 'Dato inválido'
        }
      },
      dialogError: '',
      continueButton: false,
      saving: false,
      languages: [],
      language: 'es',
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionCallBack: undefined,
      conectionCallBackResponse: undefined,
      conectionTry: false,
      intervalConection: undefined
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      required: state => state.required.required
    }),
    displayEnterpriseLogo () {
      return !this.enterprise || !this.enterprise.logo
        ? '/img/20200301_occ_solution_logo.png'
        : this.enterprise.logo
    },
    requiredPromise: {
      get () {
        return this.required
      },
      set (newValue) {
        return newValue
      }
    },
    progress () {
      let progress = 0
      let total = 0
      let count = 0
      let self = this
      let endScore = {}
      self.dimensionScore = {
        responsible: [],
        competitive: [],
        humanist: []
      }
      self.progressComplete = []
      for (let len = 0; len < this.dimensions.length + 1; len++) {
        self.progressComplete.push(false)
      }
      for (let i = 0; i < this.score.length; i++) {
        if (this.score[i] !== undefined) {
          let number = parseInt(self.score[i])
          if (isNaN(number)) {
            total = parseInt(total) + 0
            count = count - 1 + 1
          } else {
            total = parseInt(total) + parseInt(this.score[i])
            count++
          }
        } else {
          self.score[i] = undefined
        }
        if (this.dimensions[i]) {
          endScore = {
            name: this.dimensions[i].name,
            score: parseInt(this.score[i])
          }
          self.dimensionScore[this.dimensions[i].type].push(endScore)
        }
        progress = total
      }
      if (total === 100 && count === this.score.length) {
        self.progressComplete[0] = true
      } else {
        self.progressComplete[0] = false
      }
      return progress
    }
  },
  created () {
    const languages = this.$t('languages')
    for (let key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this._i18n.locale = this.language
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.baseUrlImage = `${res}/img/20200301_occ_solution_logo.png`
        }
      })
    this.$store.dispatch('loading/show')
    if (this.requiredPromise.length > 0 || this.requiredPromise !== undefined) {
      if (this.requiredPromise.hasOwnProperty(this.$route.params.id)) {
        if (this.requiredPromise[this.$route.params.id].score.length === 0 || this.requiredPromise[this.$route.params.id].score === undefined) {
          this.getRequiredCulture()
        } else {
          this.score = this.requiredPromise[this.$route.params.id].score
          if (this.requiredPromise[this.$route.params.id].dimensionsScoresSaved) {
            this.step = this.step + 1
          }
          if (this.requiredPromise[this.$route.params.id].required.length === 0 || this.requiredPromise[this.$route.params.id].required === undefined) {
            this.getRequiredCulture()
          } else {
            this.dimensions = this.requiredPromise[this.$route.params.id].required
            if (this.requiredPromise[this.$route.params.id].attributes === undefined) {
              this.attributeRelevance = {}
            } else {
              this.attributeRelevance = this.requiredPromise[this.$route.params.id].attributes
            }
            setTimeout(this.validateLocal, 500)
          }
        }
      } else {
        this.getRequiredCulture()
      }
    } else {
      this.getRequiredCulture()
    }
  },
  watch: {
    checkDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    confirmDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    outIntervalDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (let key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this._i18n.locale = this.language
      }
    }
  },
  methods: {
    changeLocale () {
      this._i18n.locale = this.language
    },
    retryConection () {
      if (this.intervalConection) {
        clearInterval(this.intervalConection)
      }
      this.conectionAttempt = 0
      this.tryConection()
    },
    async tryConection (callBack) {
      this.conectionAttempt++
      if (callBack) {
        this.conectionCallBack = callBack
      }
      return this.conectionCallBack()
        .then((res) => {
          this.conectionTry = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return this.conectionCallBackResponse(res)
        })
        .catch(async (err) => {
          this.$store.dispatch('loading/hide')
          if (err.msg !== 'required-culture/not-found') {
            this.conectionTry = this.conectionAttempt > 3
            if (this.conectionAttempt < 10) {
              const time = this.timeConectionAttempt = this.conectionAttempt <= 3 ? 2 : (this.conectionAttempt <= 6 ? 5 : 10)
              this.intervalConection = setInterval(() => {
                this.timeConectionAttempt--
                if (this.timeConectionAttempt <= 0) {
                  clearInterval(this.intervalConection)
                }
              }, 1000)
              await sleep(time * 1000)
              const res = await this.tryConection()
              return res
            }
          } else {
            this.conectionAttempt = 0
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          throw err
        })
    },
    getRequiredCulture () {
      this.$store.dispatch('loading/show')
      requiredCultureService.getOneByTokenId(this.$route.params.id)
        .then((res) => {
          if (res.executed) {
            if (res.data.status === 'completed') {
              this.$store.dispatch('loading/hide')
              this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
            } else {
              this.enterprise = res.data.enterprise || this.enterprise
              let timezone = res.data.timeZone.split('UTC')[1].split(')')[0]
              let releasedAtParsed = Date.parse(res.data.releaseAt.split('Z')[0] + timezone) / 1000
              let deadLineAtParsed = Date.parse(res.data.deadlineAt.split('Z')[0] + timezone) / 1000
              if (releasedAtParsed > parseInt(Date.now() / 1000)) {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.before_date'))
              } else if (deadLineAtParsed < parseInt(Date.now() / 1000)) {
                this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
              } else if (res.data.status === 'pending') {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.pending'))
              } else {
                this.getDimensions(res.data.questionnaire.cultureTypes)
                this.responseParse(res.data)
                this.$store.dispatch('loading/hide')
              }
            }
          } else {
            this.$store.dispatch('loading/hide')
            this.showDialog('/img/alerta.png', this.$i18n.t('evaluations.invalid_token'))
          }
        })
    },
    responseParse (res) {
      this.dimensionsEndScore = res.dimensionsEndScore
    },
    getDimensions (cultureTypes) {
      let count = 0
      this.dimensions = []
      const orderedCultures = ['responsible', 'competitive', 'humanist']
      for (let i = 0; i < orderedCultures.length; i++) {
        for (let dimension in cultureTypes[orderedCultures[i]]) {
          this.dimensions.push(cultureTypes[orderedCultures[i]][dimension])
          this.dimensions[count]['type'] = orderedCultures[i]
          this.dimensions[count]['completed'] = false
          for (let question in this.dimensions[count]['questions']) {
            this.dimensions[count]['questions'][question]['more_important'] = false
            this.dimensions[count]['questions'][question]['less_important'] = false
            this.dimensions[count]['questions'][question]['disabled'] = false
          }
          count++
        }
      }
    },
    showDialog (icon, text) {
      this.outIntervalDialog = true
      this.dialogIcon = icon
      this.dialogText = text
    },
    // Avanza al siguiente paso
    nextStep (step) {
      if (step === 1) {
        this.getRequiredCulture()
        setTimeout(this.validateCalibratedNext, 1000)
      }
      this.step = step + 1
    },
    firstStep () {
      this.dimensionsScores.push(this.dimensionScore)
      this.saveScore()
    },
    // Función on key:enter para avanzar al siguiente panel del expansion.
    nextItem (item) {
      this.panel = item + 1
    },
    // Retrocede al paso anterior
    stepBack () {
      this.step = this.step - 1
    },
    validateCalibratedNext () {
      if (this.dimensionsEndScore === undefined) {
        this.dialogError = this.$i18n.t('errors.not_calibrated')
        this.continueButton = true
      } else {
        this.checkDialog = false
        this.confirmDialog = false
      }
    },
    validate (value, index) {
      const pattern = /^[0-9][0-9]?$|^100$/
      if (!pattern.test(value)) {
        if (this.score[index] < 0) {
          this.score[index] = 1
        } else {
          this.score[index] = 1
        }
      }
      let required = this.requiredPromise
      this.$store.dispatch('required/getRefreshState')
        .then(state => {
          if (state.required === null || state.required === undefined) {
            if (required[this.$route.params.id]) {
              required[this.$route.params.id].score = this.score
            } else {
              required[this.$route.params.id] = {
                score: this.score,
                dimensionsScoresSaved: false,
                attributes: {},
                required: []
              }
            }
          } else {
            required = JSON.parse(state.required)
            if (required[this.$route.params.id]) {
              required[this.$route.params.id].score = this.score
            } else {
              required[this.$route.params.id] = {
                score: this.score,
                dimensionsScoresSaved: false,
                attributes: {},
                required: []
              }
            }
          }
          this.$store.dispatch('required/saveAttributes', { required: required })
        })
    },
    evaluateQuestion (index, dimension, type) {
      if (type === 'positive') {
        if (this.dimensions[dimension]['questions'][index].less_important !== this.dimensions[dimension]['questions'][index].more_important) {
          this.dimensions[dimension]['questions'][index].less_important = false
        }
        this.dimensions[dimension]['questions'][index].more_important = !this.dimensions[dimension]['questions'][index].more_important
      } else {
        if (this.dimensions[dimension]['questions'][index].less_important !== this.dimensions[dimension]['questions'][index].more_important) {
          this.dimensions[dimension]['questions'][index].more_important = false
        }
        this.dimensions[dimension]['questions'][index].less_important = !this.dimensions[dimension]['questions'][index].less_important
      }
      let flag = []
      flag = this.dimensions
      this.dimensions = []
      this.dimensions = flag
      let count = 0
      let countPositive = 0
      let countNegative = 0
      let i = 0
      for (i; i < this.dimensions[dimension]['questions'].length; i++) {
        if (this.dimensions[dimension]['questions'][i].more_important || this.dimensions[dimension]['questions'][i].less_important) {
          count++
          this.dimensions[dimension]['questions'][i].disabled = false
        }
        if (this.dimensions[dimension]['questions'][i].more_important) {
          countPositive++
        }
        if (this.dimensions[dimension]['questions'][i].less_important) {
          countNegative++
        }
        if (countPositive > 3) {
          this.dimensions[dimension]['questions'][i].more_important = !this.dimensions[dimension]['questions'][i].more_important
          countPositive--
          count--
        } else if (countNegative > 3) {
          this.dimensions[dimension]['questions'][i].less_important = !this.dimensions[dimension]['questions'][i].less_important
          countNegative--
          count--
        }
        if (count >= 6) {
          // Aqui modificar index
          for (const question of this.dimensions[dimension]['questions']) {
            if (!question.more_important && !question.less_important) {
              question.disabled = true
            }
          }
          this.dimensions[dimension]['completed'] = true
          this.progressComplete[dimension + 1] = true
        } else {
          for (const question of this.dimensions[dimension]['questions']) {
            question.disabled = false
          }
          this.dimensions[dimension]['completed'] = false
          this.progressComplete[dimension + 1] = false
        }
      }
      // Save Attributes in object
      let iterator = this.step - 2
      this.attributeRelevance = {
        responsible: [],
        competitive: [],
        humanist: []
      }
      for (let dimension of this.dimensions) {
        let attributes = []
        let score = 0
        let dimensionObj = {}
        for (let question of dimension.questions) {
          if (question.more_important) {
            score = 3
          } else if (question.less_important) {
            score = 1
          } else {
            score = 2
          }
          attributes.push({
            name: question.name,
            positive: question.positive,
            limiting: question.limiting,
            score: score
          })
        }
        dimensionObj = {
          name: dimension.name,
          questions: attributes
        }
        this.attributeRelevance[dimension.type].push(dimensionObj)
      }
      this.$store.dispatch('required/getRefreshState')
        .then(state => {
          const required = JSON.parse(state.required)
          required[this.$route.params.id].attributes = this.attributeRelevance
          required[this.$route.params.id].required = this.dimensions
          this.$store.dispatch('required/saveAttributes', { required: required })
        })
    },
    validateLocal () {
      let i = 1
      let count = 1
      for (let dimension of this.dimensions) {
        if (dimension.completed) {
          this.progressComplete[i] = true
          count++
        } else {
          this.progressComplete[i] = false
        }
        i++
        if (count === 10) {
          this.step = count
        } else {
          this.step = count + 1
        }
      }
      this.$store.dispatch('loading/hide')
    },
    saveScore () {
      this.conectionCallBackResponse = () => {
        this.$store.dispatch('loading/hide')
        this.checkDialog = true
        this.$store.dispatch('required/getRefreshState')
          .then(state => {
            this.$store.dispatch('loading/hide')
            const required = JSON.parse(state.required)
            required[this.$route.params.id].dimensionsScoresSaved = true
            this.$store.dispatch('required/saveAttributes', { required: required })
          })
      }
      return this.tryConection(this.runSaveScore)
    },
    runSaveScore () {
      this.$store.dispatch('loading/show')
      return requiredCultureService.updateScore(this.$route.params.id, this.dimensionsScores[0])
    },
    saveAttributes () {
      this.conectionCallBackResponse = () => {
        this.$store.dispatch('loading/hide')
        this.finishDialog = true
        this.$store.dispatch('required/getRefreshState')
          .then(state => {
            const required = JSON.parse(state.required)
            delete required[this.$route.params.id]
            delete this.required[this.$route.params.id]
            if (Object.keys(required).length === 0) {
              this.$store.dispatch('required/clearAttributes')
              this.requiredPromise = {}
            } else {
              this.$store.dispatch('required/saveAttributes', { required: required })
            }
          })
      }
      return this.tryConection(this.runSaveAttributes)
    },
    runSaveAttributes () {
      this.$store.dispatch('loading/show')
      return requiredCultureService.updateAttribute(this.$route.params.id, this.attributeRelevance)
    }
  }
}
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 100px;
  font-size: 10pt;
  float: right;
}

</style>
