
export default {
  methods: {
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    $generateDiffRanking () {
      let gapsPerDimension = []
      for (let i = 0; i < this.dimensionAverage.length; i++) {
        gapsPerDimension[i] = {
          dimension: this.averagePerDimensions[i].name,
          actual: this.averagePerDimensions[i].average,
          required: this.dimensionAverage.find(dimension => dimension.name === this.averagePerDimensions[i].name).score,
          gap: this.averagePerDimensions[i].average - this.dimensionAverage.find(dimension => dimension.name === this.averagePerDimensions[i].name).score
        }
      }
      gapsPerDimension.sort((a, b) => a.gap - b.gap)

      let gapsPerAttribute = []
      for (let i = 0; i < this.attributesRanking.length; i++) {
        const dataByAttributes = this.questionDataByAttributes.find(attribute => this.attributesRanking[i].attrIndex === attribute.idx)
        gapsPerAttribute[i] = {
          limiting: dataByAttributes.limiting[this.user.lang],
          positive: dataByAttributes.positive[this.user.lang],
          dimension: this.attributesRanking[i].dimension,
          actual: dataByAttributes.actScore,
          required: this.attributesRanking[i].score,
          gap: dataByAttributes.actScore - this.attributesRanking[i].score,
          rate: dataByAttributes.tendencies
        }
      }
      gapsPerAttribute.sort((a, b) => a.gap - b.gap)
      let highestGapsPerAttributeTable = []
      let lowestGapsPerAttributeTable = []
      // Get Text By Condition
      const gtbc = (num, text, negative) => (negative ? num < 0 : num > 0) ? text : '--'
      for (let i = 0; i < 8; i++) {
        // Generate table for the 8 highest gaps per attribute
        const gapH = gapsPerAttribute[gapsPerAttribute.length - (i + 1)].gap
        const gapL = gapsPerAttribute[i].gap
        highestGapsPerAttributeTable.push([
          {
            text: gapH > 0 ? i + 1 : '',
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: gtbc(gapH, gapsPerAttribute[gapsPerAttribute.length - (i + 1)].limiting),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0],
            alignment: gapH < 0 ? 'center' : 'left'
          },
          {
            text: gtbc(gapH, gapsPerAttribute[gapsPerAttribute.length - (i + 1)].positive),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0],
            alignment: gapH < 0 ? 'center' : 'left'
          },
          {
            text: gtbc(gapH, this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - (i + 1)].dimension}`)),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapH, gapsPerAttribute[gapsPerAttribute.length - (i + 1)].actual.toFixed(2)),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapH, gapsPerAttribute[gapsPerAttribute.length - (i + 1)].required.toFixed(2)),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapH, this.round(gapsPerAttribute[gapsPerAttribute.length - (i + 1)].gap, 2)),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: !this.hasPrevious ? '--' : gtbc(gapH, gapsPerAttribute[gapsPerAttribute.length - (i + 1)].rate.toFixed(2)),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
        // Generate table for the 8 lowest gaps per attribute
        lowestGapsPerAttributeTable.push([
          {
            text: gapL < 0 ? i + 1 : '--',
            margin: [0, 6, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: gtbc(gapL, gapsPerAttribute[i].limiting, true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0],
            alignment: gapL > 0 ? 'center' : 'left'
          },
          {
            text: gtbc(gapL, gapsPerAttribute[i].positive, true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 5, 0, 0],
            alignment: gapL > 0 ? 'center' : 'left'
          },
          {
            text: gtbc(gapL, this.$t(`requiredReport.initials.${gapsPerAttribute[i].dimension}`), true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 10, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapL, gapsPerAttribute[i].actual.toFixed(2), true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapL, gapsPerAttribute[i].required.toFixed(2), true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: gtbc(gapL, this.round(gapsPerAttribute[i].gap, 2), true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          },
          {
            text: !this.hasPrevious ? '--' : gtbc(gapL, gapsPerAttribute[i].rate.toFixed(2), true),
            color: '#6d7a7a',
            borderColor: ['', '', '', '#78909C'],
            border: [false, false, false, true],
            margin: [0, 15, 0, 0],
            alignment: 'center'
          }
        ])
      }
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'portrait'
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.7 ${this.$t('cultureReports.dimensions_diff_analysis')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 0]
        },
        // Atributos con puntaje mas alto
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.dimensions_higher_pos_diff'), margin: [50, 8, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['1%', '39%', '20%', '20%', '20%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [50, 5, 50, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 15, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...[1, 2, 3].map((num) => {
                const gap = gapsPerDimension[gapsPerDimension.length - num].gap
                return [
                  {
                    text: gap > 0 ? num : '',
                    margin: [0, 15, 0, 10],
                    bold: true,
                    style: { color: '#6d7a7a' }
                  },
                  {
                    text: gtbc(gap, this.$t(`requiredReport.${gapsPerDimension[gapsPerDimension.length - num].dimension}`)),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 10],
                    alignment: gap > 0 ? 'left' : 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gapsPerDimension[gapsPerDimension.length - num].actual, 2)),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gapsPerDimension[gapsPerDimension.length - num].required, 2)),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gap, 2)),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  }
                ]
              })
            ]
          },
          margin: [20, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.dimensions_higher_neg_diff'), margin: [50, 8, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [36, 15, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['1%', '39%', '20%', '20%', '20%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [50, 5, 50, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 15, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...[0, 1, 2].map((num) => {
                const gap = gapsPerDimension[num].gap
                return [
                  {
                    text: gap < 0 ? num + 1 : '',
                    margin: [0, 15, 0, 10],
                    bold: true,
                    style: { color: '#6d7a7a' }
                  },
                  {
                    text: gtbc(gap, this.$t(`requiredReport.${gapsPerDimension[num].dimension}`), true),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 10],
                    alignment: gap < 0 ? 'left' : 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gapsPerDimension[num].actual, 2), true),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gapsPerDimension[num].required, 2), true),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: gtbc(gap, this.round(gapsPerDimension[num].gap, 2), true),
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  }
                ]
              })
            ]
          },
          margin: [20, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.8 ${this.$t('cultureReports.attributes_diff_analysis')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('requiredActualReport.attributes_with_higher_gap_positive'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...highestGapsPerAttributeTable
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 11
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              table: {
                widths: [479],
                body: [
                  [{ text: `4.8 ${this.$t('cultureReports.attributes_diff_analysis')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 0, 0]
            },
            // Atributos con puntaje mas alto
            {
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [243],
                body: [
                  [{ text: this.$t('requiredActualReport.attributes_with_higher_gap_negative'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [50, -15, -40, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.limitant_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.positive_attribute'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.actual'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.required'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.gap'),
                  margin: [5, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.rate'),
                  margin: [0, 5, 5, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...lowestGapsPerAttributeTable
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 10
        }
      ]
    }
  }
}
