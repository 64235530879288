
export default {
  methods: {
    $generateTrendRanking () {
      const hasHTBA = this.highestTendenciesByAttributesByItems.length > 0 ? this.highestTendenciesByAttributesByItems : false
      const hasLTBA = this.lowestTendenciesByAttributesByItems.length > 0 ? this.lowestTendenciesByAttributesByItems : false
      const baseTableCell = { color: '#6d7a7a',
        borderColor: ['', '', '', '#78909C'],
        border: [false, false, false, true]
      }
      this.highestTendenciesByAttributesByItems.reverse()
      let highestTendenciesByAttributesByItemsTable = []
      let lowestTendenciesByAttributesByItemsTable = []
      for (let i = 0; i < 6; i++) {
        highestTendenciesByAttributesByItemsTable.push([
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? i + 1 : '',
            margin: [0, 4, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? this.highestTendenciesByAttributesByItems[i].positive[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 3, 0, 0],
            alignment: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? (hasHTBA ? this.$t(`requiredReport.initials.${this.highestTendenciesByAttributesByItems[i].dimensionsName}`) : '') : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? this.round(this.highestTendenciesByAttributesByItems[i].actScore, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? this.round(this.highestTendenciesByAttributesByItems[i].previousAvg, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.highestTendenciesByAttributesByItems[i] ? this.round(this.highestTendenciesByAttributesByItems[i].tendencies, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          }
        ])

        lowestTendenciesByAttributesByItemsTable.push([
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? i + 1 : '',
            margin: [0, 4, 0, 0],
            bold: true,
            style: { color: '#6d7a7a' }
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? this.lowestTendenciesByAttributesByItems[i].positive[this.user.lang] : '--',
            ...baseTableCell,
            margin: [0, 3, 0, 0],
            alignment: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? 'left' : 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? (hasLTBA ? this.$t(`requiredReport.initials.${this.lowestTendenciesByAttributesByItems[i].dimensionsName}`) : '') : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? this.round(this.lowestTendenciesByAttributesByItems[i].actScore, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? this.round(this.lowestTendenciesByAttributesByItems[i].previousAvg, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          {
            text: this.hasPrevious && this.lowestTendenciesByAttributesByItems[i] ? this.round(this.lowestTendenciesByAttributesByItems[i].tendencies, 2) : '--',
            ...baseTableCell,
            margin: [0, 5, 0, 0],
            alignment: 'center'
          }
        ])
      }
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'portrait'
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `4.11 ${this.$t('cultureReports.ranking_of_rates')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8]
        },
        // Tendencias positivas mayores
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.highest_positive_rate_attribute'), margin: [50, 8, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [36, 0, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '54%', '13%', '9%', '10%', '11%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.attribute'),
                  margin: [0, 10, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 10, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.current_population'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.preview_population'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.change_value'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...highestTendenciesByAttributesByItemsTable
            ]
          },
          margin: [-10, 0, 5, 10],
          border: [false, false, false, true],
          fontSize: 12
        },
        // Tendencias negativas mayores
        {
          layout: {
            defaultBorder: '',
            fillColor: '#BFBFBF'
          },
          table: {
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.highest_negative_rate_attribute'), margin: [50, 8, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [36, 0, 0, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '54%', '13%', '9%', '10%', '11%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('requiredActualReport.attribute'),
                  margin: [0, 10, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.dimension'),
                  margin: [0, 10, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.current_population'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.preview_population'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('requiredActualReport.change_value'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...lowestTendenciesByAttributesByItemsTable
            ]
          },
          margin: [-10, 0, 5, 10],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          table: {
            body: [
              [{ text: (hasHTBA ? '' : this.$t('requiredActualReport.no_tend_msg')).toUpperCase(), bold: true, margin: [50, 8, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, -15, 0, 0]
        }
      ]
    }
  }
}
