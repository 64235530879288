
export default {
  required_information: 'Welcome to the OCC tool to define the Required Culture for the achievement of your organization\'s strategy.<br>To start, we ask you to allocate 100 points among the nine dimensions of Culture. Each dimension must have a minimum score of 1 point.<br>For this, take into account the importance that each topic has to execute the Organization\'s strategy, achieve results and make this a successful organization.<br>Keep in mind that we are defining the culture that the organization requires, but not the one that it currently has.<br>Remember that strategy implies choice.',
  dimensions_information: 'For each of the 9 dimensions noted before, you will find 8 Attributes. Next, select the 3 most required attributes and the 3 least required for the organization to execute the strategy, achieve the results and be a successful organization.',
  required_title: 'Assessment of culture\nrequired',
  values: 'Principles',
  sustainability: 'Sustainability',
  customers: 'Customers',
  achievement: 'Achievement',
  leadership: 'Leadership',
  innovation: 'Innovation',
  talent: 'Talent',
  relationship: 'Relationships',
  communication: 'Communication',
  dim_values: 'Dimension',
  dim_sustainability: 'Sustainability Dimension',
  dim_customers: 'Customer Dimension ',
  dim_achievement: 'Achievement dimension',
  dim_leadership: 'Leadership Dimension',
  dim_innovation: 'Innovation Dimension',
  dim_talent: 'Talent Dimension',
  dim_relationship: 'Relationship Dimension',
  dim_communication: 'Communication Dimension',
  cul_responsible: 'Responsible culture',
  cul_competitive: 'Competitive culture',
  cul_humanist: 'Humanistic culture',
  responsible: 'Responsible',
  competitive: 'Competitive',
  humanist: 'Humanist',
  dont_close: 'Important: Do not close this window.',
  please_wait: 'Please wait for confirmation from your Facilitator to proceed.',
  if_accepted: 'Once accepted you will not be able to return to dimension calibration.',
  end: 'Thank you very much for your participation. You have successfully completed the measurement.',
  invalid_token: 'There is no measurement for this access, please enter a valid one',
  before_date: 'The measurement is not available for the current date',
  pending: 'The measurement is not available at this time',
  expiration_date: 'The measurement you are trying to enter has already finished',
  calibrate: 'Calibrate',
  calibrated: 'The required culture has been successfully calibrated.',
  progress: 'Progress',
  actual_point: 'Current average',
  dispersion: 'Dispersion',
  final_point: 'Final score',
  current_total: 'Current total',
  final_total: 'Final total',
  important: 'important',
  required: 'required',
  dimension_text: {
    values: 'Leadership and a principle-centered culture define and determine human conduct guidelines that become the organization\'s moral compass of the organization. Principles can be understood as values ​​and today it is clear that their experiencing them is essential to ensuring the survival of organizations.',
    sustainability: 'A culture of sustainability promotes the efficient use of current resources to ensure the future sustainability of the organization. It gives the same importance to all stakeholders and seeks balanced results in financial, human, social and planet aspects.',
    customers: 'A customer-centric culture focuses its efforts on understanding, meeting and exceeding customer needs and expectations. When defining processes and systems, the structure and people\'s competences, current and future customer realities are taken into account.',
    achievement: 'Organizations must deliver positive results if they are to make progress and achieve their goals. A culture of effectiveness and obtaining results promotes achievement-oriented behaviors and high perfoemance-level processes. A direct line is created between the contribution of individual and team performance and the performance of the organization. Positive results are the consequence of a culture of high performance and commitment.',
    leadership: 'The quality and capability of leaders is essential to understanding the success of organizations; understanding leadership as the art of mobilizing and guiding people to ensure the achievement of common objectives and purposes. In this dimension, we analyze how leaders achieve commitment, build trust, become interested in developing people, give autonomy and create environments that empower and accompany their teams in their development.',
    innovation: 'Innovation is understood as the ability to create new realities, it is a vital aspect for the success of organizations in today\'s world. A culture of innovation encourages creativity, initiative and the ability to reinvent yourself to prosper and compete over time, while allowing you to take risks and learn from mistakes.',
    talent: 'A people-centered culture seeks to attract, develop and keep the best talent in the organization as the main lever for achieving its goals. The purpose is to have competent people with a high level of commitment and performance, who feel proud of belonging to the organization. These organizations strive to deserve the enthusiasm and intelligence of all, with their vision, their coherence with values, their leadership and their achievements.',
    relationship: 'In these organizations, the connection between the quality of relationships and results is clear. These companies promote teamwork, solidarity and the ability to join forces to achieve common goals. Topics such as shared goals, trust, coordination, interdependence and collaboration are common in these types of organizations.',
    communication: 'The way people communicate and the stories that are told are part of, and reflect and define, the culture and leadership styles. In an organization with a strong communication culture, information is shared transparently, is listened to carefully, questions are encouraged, and relationships of trust and mutual respect are created and maintained. Vision and goals are clearly communicated.'
  },
  creating_status: 'Creating',
  in_progress_status: 'In progress',
  progress_status: 'In progress',
  pending_status: 'Pending',
  closed_status: 'Closed',
  completed_status: 'Finished'

}
