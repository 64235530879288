
export default {
  methods: {
    $generateDiffRankingReq () {
      let gapsPerAttribute = []
      for (let i = 0; i < this.sortedquestionDataByAttributesByItems.length; i++) {
        const requiredAttribute = this.hasRequired ? this.attributesArray.find(attribute => this.sortedquestionDataByAttributesByItems[i].idx === attribute.attrIndex).score : '--'
        gapsPerAttribute[i] = this.hasRequired ? {
          limiting: this.sortedquestionDataByAttributesByItems[i].limiting[this.user.lang],
          positive: this.sortedquestionDataByAttributesByItems[i].positive[this.user.lang],
          dimension: this.sortedquestionDataByAttributesByItems[i].dimensionsName,
          actualCut: this.sortedquestionDataByAttributesByItems[i].actScore,
          requiredAttribute,
          gap: Number(this.sortedquestionDataByAttributesByItems[i].actScore) - Number(requiredAttribute)
        } : {
          limiting: '--',
          positive: '--',
          dimension: '--',
          actualCut: '--',
          requiredAttribute,
          gap: i
        }
      }
      gapsPerAttribute.sort((a, b) => a.gap - b.gap)
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.9 ${this.$t('cultureReports.analysis_difference_section_cut_req_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 20, 0]
            },
            // Atributos con puntaje mas alto
            {
              width: '35%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.diff_attributes_with_higher_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [10, -15, 0, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '33%', '33%', '9%', '7%', '8%', '8%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [5, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.required'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.difference_cut_req_2'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...[1, 2, 3, 4, 5, 6, 7, 8].map((num) => {
                return [
                  {
                    text: gapsPerAttribute[gapsPerAttribute.length - num].limiting === '--' ? '' : num,
                    margin: [0, 6, 0, 0],
                    bold: true,
                    style: { color: '#6d7a7a' }
                  },
                  {
                    text: gapsPerAttribute[gapsPerAttribute.length - num].limiting,
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 0],
                    alignment: gapsPerAttribute[gapsPerAttribute.length - num].limiting === '--' ? 'center' : 'left'
                  },
                  {
                    text: gapsPerAttribute[gapsPerAttribute.length - num].positive,
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 0],
                    alignment: gapsPerAttribute[gapsPerAttribute.length - num].positive === '--' ? 'center' : 'left'
                  },
                  {
                    text: this.hasRequired ? this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - num].dimension}`) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[gapsPerAttribute.length - num].actualCut, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[gapsPerAttribute.length - num].requiredAttribute, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[gapsPerAttribute.length - num].gap, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  }
                ]
              })
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.9 ${this.$t('cultureReports.analysis_difference_section_cut_req_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 20, 0]
            },
            // Atributos con puntaje mas bajo
            {
              width: '35%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.diff_attributes_with_lower_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [10, -15, 0, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '33%', '33%', '9%', '7%', '8%', '8%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.required'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.difference_cut_req_2'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              ...[0, 1, 2, 3, 4, 5, 6, 7].map((num) => {
                return [
                  {
                    text: gapsPerAttribute[num].limiting === '--' ? '' : num + 1,
                    margin: [0, 6, 0, 0],
                    bold: true,
                    style: { color: '#6d7a7a' }
                  },
                  {
                    text: gapsPerAttribute[num].limiting,
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 0],
                    alignment: gapsPerAttribute[num].limiting === '--' ? 'center' : 'left'
                  },
                  {
                    text: gapsPerAttribute[num].positive,
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 0],
                    alignment: gapsPerAttribute[num].positive === '--' ? 'center' : 'left'
                  },
                  {
                    text: this.hasRequired ? this.$t(`requiredReport.initials.${gapsPerAttribute[num].dimension}`) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[num].actualCut, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[num].requiredAttribute, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  },
                  {
                    text: this.hasRequired ? this.round(gapsPerAttribute[num].gap, 2) : '--',
                    color: '#6d7a7a',
                    borderColor: ['', '', '', '#78909C'],
                    border: [false, false, false, true],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                  }
                ]
              })
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        }
      ]
    }
  }
}
