import pdf from '../utils/pdf'

export default {
  methods: {
    roundM (val) {
      return Math.round(val * 100) / 100
    },
    buildRowsByCultureTypes (i) {
      const baseStyle = { borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], alignment: 'center' }
      let actual = ''
      let required = ''
      let previousScore = ''
      let gap = ''
      let trend = ''
      if (i === 'total') {
        actual = this.roundM(this.averagePerCultureTypesTotal / 3)
        required = this.roundM((this.cultureAverage[0] + this.cultureAverage[1] + this.cultureAverage[2]) / 3)
        gap = this.roundM(actual - required)
        if (this.hasPrevious) {
          previousScore = this.roundM((this.previousAveragePerCultureTypes[0].average + this.previousAveragePerCultureTypes[1].average + this.previousAveragePerCultureTypes[2].average) / 3)
          trend = this.roundM(actual - previousScore)
        }
      } else {
        actual = this.roundM(this.averagePerCultureTypes[i].average)
        required = this.roundM(this.cultureAverage[i])
        gap = this.roundM(actual - required)
        if (this.hasPrevious) {
          previousScore = this.roundM(this.previousAveragePerCultureTypes[i].average)
          trend = this.roundM(actual - previousScore)
        }
      }
      return [
        { text: actual !== '' && !isNaN(actual) ? actual.toFixed(2) : '--', ...baseStyle },
        { text: required !== '' && !isNaN(required) ? required.toFixed(2) : '--', ...baseStyle },
        { text: previousScore !== '' && !isNaN(previousScore) ? previousScore.toFixed(2) : '--', ...baseStyle },
        { text: gap !== '' && !isNaN(gap) ? gap.toFixed(2) : '--', ...baseStyle },
        { text: trend !== '' && !isNaN(trend) ? trend.toFixed(2) : '--', ...baseStyle, ...pdf.styleTendency(trend) }
      ]
    },
    barChartDimesions () {
      const prActualAvg = this.averagePerDimensionsObj.values
      const soActualAvg = this.averagePerDimensionsObj.sustainability
      const clActualAvg = this.averagePerDimensionsObj.customers
      const inActualAvg = this.averagePerDimensionsObj.innovation
      const loActualAvg = this.averagePerDimensionsObj.achievement
      const liActualAvg = this.averagePerDimensionsObj.leadership
      const taActualAvg = this.averagePerDimensionsObj.talent
      const reActualAvg = this.averagePerDimensionsObj.relationship
      const coActualAvg = this.averagePerDimensionsObj.communication
      const prTrend = this.hasPrevious ? this.round(prActualAvg - this.previousAveragePerDimensions[0].average, 2) : 0
      const soTrend = this.hasPrevious ? this.round(soActualAvg - this.previousAveragePerDimensions[1].average, 2) : 0
      const clTrend = this.hasPrevious ? this.round(clActualAvg - this.previousAveragePerDimensions[2].average, 2) : 0
      const inTrend = this.hasPrevious ? this.round(inActualAvg - this.previousAveragePerDimensions[3].average, 2) : 0
      const loTrend = this.hasPrevious ? this.round(loActualAvg - this.previousAveragePerDimensions[4].average, 2) : 0
      const liTrend = this.hasPrevious ? this.round(liActualAvg - this.previousAveragePerDimensions[5].average, 2) : 0
      const taTrend = this.hasPrevious ? this.round(taActualAvg - this.previousAveragePerDimensions[6].average, 2) : 0
      const reTrend = this.hasPrevious ? this.round(reActualAvg - this.previousAveragePerDimensions[7].average, 2) : 0
      const coTrend = this.hasPrevious ? this.round(coActualAvg - this.previousAveragePerDimensions[8].average, 2) : 0
      const prTrendStyle = this.hasPrevious ? pdf.styleTendency(+prTrend) : 0
      const soTrendStyle = this.hasPrevious ? pdf.styleTendency(+soTrend) : 0
      const clTrendStyle = this.hasPrevious ? pdf.styleTendency(+clTrend) : 0
      const inTrendStyle = this.hasPrevious ? pdf.styleTendency(+inTrend) : 0
      const loTrendStyle = this.hasPrevious ? pdf.styleTendency(+loTrend) : 0
      const liTrendStyle = this.hasPrevious ? pdf.styleTendency(+liTrend) : 0
      const taTrendStyle = this.hasPrevious ? pdf.styleTendency(+taTrend) : 0
      const reTrendStyle = this.hasPrevious ? pdf.styleTendency(+reTrend) : 0
      const coTrendStyle = this.hasPrevious ? pdf.styleTendency(+coTrend) : 0
      let test = [
        // 4 Resultados
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4. ${this.$t('cultureReports.results')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 20],
          pageBreak: 'before'
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.questionaire_result'),
          margin: [0, 0, 22, 0],
          lineHeight: 1.5,
          alignment: 'justify'
        },
        {
          columns: [
            {
              text: `${this.$t('cultureReports.total_obtained')} ${this.totalObtained}`,
              width: '*',
              alignment: 'center',
              margin: [0, 50],
              color: '#839291'
            },
            {
              image: this.chartCake,
              width: 200,
              height: 150
            },
            {
              text: `${this.$t('cultureReports.total_sent')} ${this.totalReceived}`,
              width: '*',
              alignment: 'center',
              margin: [0, 50],
              color: '#839291'
            }
          ]
        },
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.1 ${this.$t('cultureReports.answers_rate')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 0, 0, 0]
        },
        {
          width: 'auto',
          color: '#839291',
          text: this.$t('cultureReports.completed_questionaires_persons'),
          margin: [0, 10, 15, 5],
          alignment: 'justify'
        },
        ...this.answersRateTables,
        // 4.2
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.2 ${this.$t('cultureReports.results_per_culture')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 15, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#0889D6' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredActualReport.dimension').toUpperCase(), margin: [0, 3, 10, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredActualReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.values'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.values'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.sustainability'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.sustainability'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.customers'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.customers'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredActualReport.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredActualReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.innovation'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.innovation'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.achievement'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.achievement'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.leadership'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.leadership'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredActualReport.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('requiredActualReport.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.$t('cultureReports.talent'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.talent'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.relationship'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.relationship'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [{ text: this.$t('cultureReports.communication'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('requiredReport.initials.communication'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : (rowIndex === 1 && columnIndex === 0)
                ? '#0889D6' : (rowIndex === 2 && columnIndex === 0)
                  ? '#EB493C' : (rowIndex === 3 && columnIndex === 0)
                    ? '#48BBA1' : (rowIndex === 4 && columnIndex === 0)
                      ? '#BFBFBF' : ''
            }
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: this.$t('requiredReport.culture').toUpperCase(), alignment: 'center', margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.current').toUpperCase(), alignment: 'center', margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required').toUpperCase(), alignment: 'center', margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('requiredReport.last_score_2').toUpperCase(), alignment: 'center', margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.gap').toUpperCase(), alignment: 'center', margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.rate').toUpperCase(), alignment: 'center', margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t('cultureReports.responsible'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [50, 5, 0, 0] },
                ...this.buildRowsByCultureTypes(0)
              ],
              [{ text: this.$t('cultureReports.competitive'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [50, 5, 0, 0] },
                ...this.buildRowsByCultureTypes(1)
              ],
              [{ text: this.$t('cultureReports.humanist'), borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [50, 5, 0, 0] },
                ...this.buildRowsByCultureTypes(2)
              ],
              [{ text: 'Total', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [50, 5, 0, 0] },
                ...this.buildRowsByCultureTypes('total')
              ]
            ]
          },
          margin: [0, 0, 0, 0]
        },
        {
          image: this.chartPie,
          width: 500,
          height: 370
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.required_culture') }
          ],
          margin: [0, 0, 0, 4]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#BFBFBF' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ]
        },
        // 4.3
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredActualReport.dimension').toUpperCase(), margin: [8, 6, 50, 6], bold: true, style: { color: '#000' } }],
                  [{ text: this.$t('requiredActualReport.current_culture').toUpperCase(), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }],
                  [{ text: this.$t('requiredActualReport.required_culture').toUpperCase(), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }],
                  [{ text: this.$t('requiredActualReport.gap').toUpperCase(), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }],
                  [{ text: this.$t('requiredActualReport.rate').toUpperCase(), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }]
                ]
              },
              margin: [-15, 15, 5, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#2196F3' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.initials.values'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.sustainability'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.customers'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: prActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: soActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: clActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.dimensionAverageObj.values, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: this.dimensionAverageObj.sustainability, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: this.dimensionAverageObj.customers, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: (prActualAvg - this.dimensionAverageObj.values).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: (soActualAvg - this.dimensionAverageObj.sustainability).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: (clActualAvg - this.dimensionAverageObj.customers).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.hasPrevious ? prTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...prTrendStyle },
                    { text: this.hasPrevious ? soTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...soTrendStyle },
                    { text: this.hasPrevious ? clTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...clTrendStyle }
                  ]
                ]
              },
              margin: [0, 15, 5, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.initials.innovation'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.achievement'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.leadership'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: inActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: loActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: liActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.dimensionAverageObj.innovation, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: this.dimensionAverageObj.achievement, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: this.dimensionAverageObj.leadership, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: (inActualAvg - this.dimensionAverageObj.innovation).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: (loActualAvg - this.dimensionAverageObj.achievement).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: (liActualAvg - this.dimensionAverageObj.leadership).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.hasPrevious ? inTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...inTrendStyle },
                    { text: this.hasPrevious ? loTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...loTrendStyle },
                    { text: this.hasPrevious ? liTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...liTrendStyle }
                  ]
                ]
              },
              margin: [0, 15, 5, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('requiredReport.initials.talent'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.relationship'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('requiredReport.initials.communication'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: taActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] },
                    { text: reActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] },
                    { text: coActualAvg, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.dimensionAverageObj.talent, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'cenetr' },
                    { text: this.dimensionAverageObj.relationship, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'cenetr' },
                    { text: this.dimensionAverageObj.communication, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'cenetr' }
                  ],
                  [{ text: (taActualAvg - this.dimensionAverageObj.talent).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] },
                    { text: (reActualAvg - this.dimensionAverageObj.relationship).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] },
                    { text: (coActualAvg - this.dimensionAverageObj.communication).toFixed(2), color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.hasPrevious ? taTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0], ...taTrendStyle },
                    { text: this.hasPrevious ? reTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0], ...reTrendStyle },
                    { text: this.hasPrevious ? coTrend : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'cenetr', border: [false, false, false, true], margin: [4, 0], ...coTrendStyle }
                  ]
                ]
              },
              margin: [0, 15, 73, 20],
              border: [false, false, false, true]
            }
          ]
        },
        {
          image: this.flowerChart,
          width: 520,
          height: 370,
          margin: [0, 25, 0, 0]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.required_culture') }
          ],
          margin: [0, 40, 0, 4]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: 'black' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ]
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions_actual_culture')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 30, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_dimensions_actual_culture_text'),
          alignment: 'justify',
          margin: [50, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `${this.$t('cultureReports.culture_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 5, 0, 0]
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '-', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_negative_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [36, 8, 0, 10],
              alignment: 'center'
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '0', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_neutral_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [10, 8, 0, 0],
              alignment: 'center'
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: '+', margin: [-2, 0, -10, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                  [{ text: this.$t('requiredActualReport.info_positive_answers'), fontSize: 10, color: '#839291', border: [false, false, false, false] }]
                ],
                alignment: 'center'
              },
              margin: [10, 8, 12, 10],
              alignment: 'center'
            }
          ]
        }
      ]
      let array = []
      let column1 = []
      let column2 = []
      let column3 = []
      for (let index = 0; index < this.barCharts.length; index++) {
        if (index <= 2) {
          column1.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        } else if (index > 2 && index <= 5) {
          column2.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        } else {
          column3.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 130,
              margin: [0, 25, 20, 15]
            }
          )
        }
      }
      array = [
        {
          columns: column1
        },
        {
          columns: column2
        },
        {
          columns: column3
        }
      ]
      test = test.concat(array)
      return test
    },
    $generateResults () {
      return this.barChartDimesions()
    }
  }
}
