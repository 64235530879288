
<template>
  <div style="display:inline;">
    <v-row no-gutters>
      <v-col cols="12" class="mx-auto">
        <v-alert text dense
          type="error"
          width="348px"
        >
          {{ $t('requiredCulture.population_more_than_five') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="7" md="5" class="mx-auto">
        <v-btn large
          color="primary"
          @click="showModal = true"
        >
          <v-icon>mdi-file-pdf</v-icon>
          {{ this.buttonText }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showModal" width="55em" persistent>
      <v-card>
        <v-toolbar flat color="error" class="white--text" light>
          <v-toolbar-title>
            <span class="headline">
              <b class="headline">{{ $t('input.error') }}: </b>  {{ $t('requiredCulture.not_enough_population') }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-10">
            <v-col align="center" class="pt-4">
                <span class="headline">
                  {{ $t('requiredCulture.no_population_msg')}}
                </span>
                <a class="headline" href="https://occ-solutions.com" target="_blank">
                  {{ $t('requiredCulture.please_contact_us') }}
                </a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="pa-2 ma-0">
            <v-col cols="12">
              <v-btn block large @click="showModal = false">{{ $t('input.close') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'current-culture-report-by-items',
  props: {
    buttonText: String,
    population: Number
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
  }
}
</script>
