
export default {
  dashboard: 'Tablero',
  questionnaires: 'Cuestionarios',
  open_questions: 'Preguntas Abiertas',
  required_culture: 'Cultura Requerida',
  current_culture: 'Cultura Actual',
  culture_pulses: 'Pulsos en Cultura',
  suite: 'Suite'
}
