<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="display-1">{{ $t('requiredCulture.to_edit') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >

                <x-poll-steps-overview
                  :requiredCulture="requiredCulture"
                  step="1"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-overview>

                <x-poll-steps-date
                  :requiredCulture="requiredCulture"
                  step="2"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-poll-steps-date>

                <x-step-question
                  :requiredCulture="requiredCulture"
                  step="3"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-step-question>

                <x-step-revition
                  :requiredCulture="requiredCulture"
                  step="4"
                  nextAction="input.next"
                  prevAction="input.cancel"
                  @changeStep="verifyStepChanged"
                ></x-step-revition>
              </x-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <x-loading v-if="loading" :display="loading"/>
  </v-container>
</template>

<script>

import requiredCultureService from '../../services/required-culture'

import XStepQuestion from './steps/question.vue'
import XStepRevition from './steps/revition.vue'

export default {
  components: {
    XStepQuestion,
    XStepRevition
  },
  data () {
    return {
      stepperHeaders: [
        'requiredCulture.overview',
        'requiredCulture.date',
        'requiredCulture.questions',
        'requiredCulture.revition'
      ],
      requiredCulture: {
        name: '',
        status: '',
        timeZone: '',
        tokenId: '',
        questionnaire: '',
        releaseAt: {
          value: '',
          hour: ''
        },
        deadlineAt: {
          value: '',
          hour: ''
        },
        questionnaires: []
      },
      loading: false,
      step: 1
    }
  },
  methods: {
    getRequiredCulture () {
      requiredCultureService.getOneById(this.$route.params.id)
        .then((res) => {
          this.reponseParse(res)
          const status = res.status
          if (status === 'completed' || status === 'cancelled') { this.$router.go(-1) }
        })
    },
    reponseParse (res) {
      this.requiredCulture.name = res.name
      this.requiredCulture.status = res.status
      this.requiredCulture.timeZone = res.timeZone
      this.requiredCulture.tokenId = res.tokenId
      this.requiredCulture.questionnaire = res.questionnaire.slug
      this.requiredCulture.releaseAt = this.getDateRequiredCulture(res.releaseAt)
      this.requiredCulture.deadlineAt = this.getDateRequiredCulture(res.deadlineAt)
    },
    getDateRequiredCulture (ref) {
      if (!ref) {
        return {
          value: '',
          hour: '23:00'
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0] + ':00'
      }
    },
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/required-culture')
        case 5: return this.update()
        default: this.step = step
      }
    },
    update () {
      this.loading = true

      return requiredCultureService.update(this.$route.params.id, this.requiredCulture)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('requiredCulture.edit'))
          this.loading = false
          this.$router.push('/required-culture')
        })
        .catch((err) => {
          this.loading = false
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getRequiredCulture()
  }
}
</script>
