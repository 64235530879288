
import auth from './auth'
import customers from './customers'
import culturePulses from './culture-pulses'
import currentCulture from './current_culture'
import cultureReports from './culture-reports'
import dashboard from './dashboard'
import employees from './employees'
import enterprises from './enterprises'
import errors from './errors'
import followupReport from './followup-report'
import groups from './groups'
import help from './help'
import input from './input'
import instructive from './instructive'
import languages from './languages'
import language from './language'
import navigation from './navigation'
import notifications from './notifications'
import operationSummary from './operation-summary'
import outside from './outside'
import pluralization from './pluralization'
import settings from './settings'
import tokens from './tokens'
import services from './services'
import pricing from './pricing'
import calculatorView from './calculator_view'
import questionnaires from './questionnaires'
import evaluations from './evaluations'
import actualEvaluation from './actual_evaluation'
import requiredCulture from './required-culture'
import questions from './questions'
import reports from './reports'
import requiredReport from './required_report'
import requiredActualReport from './required_actual_report'
import summaryReport from './summary_report'

export default {
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Activo',
  inactive: 'Inactivo',
  no_data: 'No hay registros',
  registration_updated_successfully: 'Registro actualizado con éxito',
  record_saved_successfully: 'Registro guardado exitosamente',
  error_trying_update: 'Hubo un error al intentar actualizar',
  error_trying_save: 'Se produjo un error al intentar guardar',
  copyright: 'Todos los derechos reservados 2019',
  go_back: 'Volver',
  results_per_pages: 'Resultados por páginas',
  manual: 'Manuales de ayuda',
  go_to_manual: 'Haga click aquí para ir a la sección de manuales',
  page_all: 'Todos',
  pending: 'Pendiente',
  in_progress: 'En progreso',
  completed: 'Completada',
  /* Models */
  models: {
    enterprises: 'Empresas',
    groups: 'Grupos Empresariales',
    pulse: 'Pulse'
  },
  waiting: 'Por favor espere',
  maintenance: 'Página en construcción',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'Trabajamos cada día para lograr los objetivos<br/>y darle mejores resultados y experiencias.',
  /* Labels grouped by modules or type */
  auth,
  customers,
  culturePulses,
  currentCulture,
  cultureReports,
  dashboard,
  employees,
  enterprises,
  errors,
  followupReport,
  help,
  input,
  instructive,
  navigation,
  notifications,
  outside,
  pluralization,
  languages,
  language,
  questionnaires,
  questions,
  requiredCulture,
  settings,
  calculatorView,
  tokens,
  services,
  pricing,
  groups,
  page_text: 'de',
  evaluations,
  actualEvaluation,
  reports,
  requiredReport,
  requiredActualReport,
  operationSummary,
  summaryReport
}
