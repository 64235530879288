
import Vue from 'vue'
import Router from 'vue-router'

import AppLayout from '../views/app-layout.vue'
import AuthLayout from '../views/auth/auth-layout.vue'
import CulturePulses from '../views/culture-pulses/list.vue'
import CulturePulseCreate from '../views/culture-pulses/create.vue'
import CulturePulseEdit from '../views/culture-pulses/edit.vue'
import CulturePulseShow from '../views/culture-pulses/show.vue'
import CulturePulseReport from '../views/culture-pulses/report.vue'
import CurrentCultureAssessments from '../views/current-culture-assessments/list.vue'
import CurrentCultureAssessmentsCreate from '../views/current-culture-assessments/create.vue'
import CurrentCultureAssessmentsEdit from '../views/current-culture-assessments/edit.vue'
import CurrentCultureAssessmentsShow from '../views/current-culture-assessments/show.vue'
import CurrentCultureAssessmentsReport from '../views/current-culture-assessments/report.vue'
import CurrentCultureAssessmentsPowerBi from '../views/current-culture-assessments/power-bi.vue'
import Dashboard from '../views/dashboard.vue'
import Forbidden from '../views/errors/forbidden.vue'
import FollowUps from '../views/follow-ups/index.vue'
import NotFound from '../views/errors/not-found.vue'
import InvalidEnterprise from '../views/errors/invalid-enterprise.vue'
import RequireProductView from '../views/errors/require-product.vue'
import Home from '../views/home.vue'
import Maintenance from '../views/maintenance.vue'
import ListQuestionnaires from '../views/questionnaires/list.vue'
import CreateQuestionnaires from '../views/questionnaires/create.vue'
import EditQuestionnaires from '../views/questionnaires/edit.vue'
import EditQuestionnaireInfo from '../views/questionnaires/edit-questionnaire.vue'
import ListOpenQuestions from '../views/open-questions/list.vue'
import RequireAdmin from './guards/require-admin'
import RequireAuthentication from './guards/require-authentication'
import RequiredCultures from '../views/required-culture/list.vue'
import RequiredCultureCreate from '../views/required-culture/create.vue'
import RequiredCultureEdit from '../views/required-culture/edit.vue'
import RequiredCultureShow from '../views/required-culture/show.vue'
import RequiredCultureReports from '../views/required-culture/reports.vue'
import OperationSummary from '../views/operations/summary.vue'
import RequireCustomer from './guards/require-customer'
import RequireEnterprise from './guards/require-enterprise'
import SsoAuthentication from './guards/sso-authentication'
import RequireProduct from './guards/require-product'
import RequiredCultureLeader from '../views/evaluations/required-leader.vue'
import RequiredCultureAdmin from '../views/evaluations/required-admin.vue'
import ActualEvaluation from '../views/evaluations/actual-evaluation.vue'
import PulseEvaluation from '../views/evaluations/culture-pulse/fill.vue'
import EvaluationLayout from '../views/evaluations/evaluation-layout.vue'

Vue.use(Router)

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'sso',
        beforeEnter: SsoAuthentication
      },
      {
        path: 'sign-in',
        beforeEnter: RequireAuthentication
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Home,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/evaluation/required/:id',
        component: RequiredCultureAdmin,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/questionnaires',
        component: ListQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/create',
        component: CreateQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/:slug/edit',
        component: EditQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/:slug/edit-questionnaire',
        component: EditQuestionnaireInfo,
        beforeEnter: RequireAdmin
      },
      {
        path: '/open-questions',
        component: ListOpenQuestions,
        beforeEnter: RequireAdmin
      },
      {
        path: '/required-culture',
        component: RequiredCultures,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/required-culture/create',
        component: RequiredCultureCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/required-culture/:id/edit',
        component: RequiredCultureEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/required-culture/:id/show',
        component: RequiredCultureShow,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/required-culture/reports/:id',
        component: RequiredCultureReports,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/culture-pulses/list',
        component: CulturePulses,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/culture-pulse/create',
        component: CulturePulseCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/culture-pulse/:id/show',
        component: CulturePulseShow,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/culture-pulse/:id/edit',
        component: CulturePulseEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/culture-pulse/:id/report',
        component: CulturePulseReport,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/current-culture-assessments/list',
        component: CurrentCultureAssessments,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/current-culture-assessments/create',
        component: CurrentCultureAssessmentsCreate,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/current-culture-assessments/:id/show',
        component: CurrentCultureAssessmentsShow,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/current-culture-assessments/:id/edit',
        component: CurrentCultureAssessmentsEdit,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/:type/:pollId/followup',
        component: FollowUps,
        beforeEnter: RequireEnterprise
      },
      {
        path: '/current-culture-assessments/reports/:id',
        component: CurrentCultureAssessmentsReport,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/current-culture-assessments/power-bi/:id',
        component: CurrentCultureAssessmentsPowerBi,
        beforeEnter: RequireAuthentication
      }
    ]
  },
  {
    path: '/evaluations',
    component: EvaluationLayout,
    children: [
      {
        path: 'pulse/:id',
        component: PulseEvaluation
      },
      {
        path: 'actual/:id',
        component: ActualEvaluation
      },
      {
        name: 'required',
        path: 'required/:id',
        component: RequiredCultureLeader
      }
    ]
  },
  {
    path: '/forbidden',
    component: Forbidden
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/invalid-enterprise',
    component: InvalidEnterprise,
    beforeEnter: RequireCustomer
  },
  {
    path: '/ask-product',
    component: RequireProductView,
    beforeEnter: RequireProduct
  },
  {
    path: '/operation-summary/:type/:pollId?',
    component: OperationSummary
  }
]

export default new Router({
  mode: 'history',
  routes
})
