
import pdfUtil from '../utils/pdf'

const fontSizeBylength = [
  { min: 0, max: 30, fontSize: 20 },
  { min: 31, max: 34, fontSize: 18 },
  { min: 35, max: 38, fontSize: 16 },
  { min: 39, max: 42, fontSize: 14 },
  { min: 43, max: 57, fontSize: 12 },
  { min: 57, max: 70, fontSize: 10 },
  { min: 70, max: 9999999, fontSize: 8 }
]

const getFontSizeBylength = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize

export default {
  data () {
    return {
      today: new Date()
    }
  },
  methods: {
    buildParticipationTable (margin = [40, 15, 40, 0]) {
      const table = [{
        margin,
        table: pdfUtil.generateTable(
          [ '60%', '20%', '20%' ],
          [
            [
              {
                text: this.$t('summaryReport.participants'),
                alignment: 'center'
              },
              {
                text: this.totalReceivers,
                alignment: 'center'
              },
              {
                text: this.totalObtained,
                alignment: 'center'
              }
            ]
          ],
          [
            this.$t('summaryReport.population'),
            this.$t('summaryReport.total_expected'),
            this.$t('summaryReport.total_obtained')
          ]
        ),
        layout: pdfUtil.setLayout()
      }]

      return table
    },
    $generateCover () {
      const fontSize = getFontSizeBylength((this.currentPoll.name || '').length)
      return [
        {
          image: this.cultureCoverBase64,
          width: 678,
          height: 765,
          aligment: 'center',
          absolutePosition: { x: 0, y: 0 }
        },
        {
          margin: [0, 470, 0, 0],
          columns: pdfUtil.generateCenteredText(this.$t('cultureReports.current_culture'), 22, true, false, false)
        },
        {
          margin: [0, 10, 0, 20],
          columns: pdfUtil.generateCenteredText(this.$t('summaryReport.title'), 20, false)
        },
        {
          columns: pdfUtil.generateCenteredText(this.currentPoll.name, fontSize, false)
        },
        this.buildParticipationTable(),
        {
          columns: pdfUtil.generateCenteredText(this.user.enterprise.name, 20, true),
          absolutePosition: { x: 40, y: 747 }
        },
        {
          columns: pdfUtil.generateCenteredText(`${this.$t(`cultureReports.months[${this.today.getMonth()}]`)} - ${this.today.getFullYear()}`, 15, true),
          absolutePosition: { x: 40, y: 777 }
        }
      ]
    }
  }
}
