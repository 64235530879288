
export default {
  follow_up: 'Follow Up',
  followup_report: 'Follow Up Report',
  followup_report_desc: 'Select up to two demographics to be able to generate the tracking report.',
  total_participants: 'Total Participants',
  total_completed: 'Total Completed',
  total_remaining: 'Total Remaining',
  totals: 'Totals',
  generate: 'Generate',
  export: 'Export',
  age: {
    '1': 'Less than 25 years',
    '2': 'From 25 to 35 years',
    '3': 'From 35 to 45 years',
    '4': 'From 45 to 50 years',
    '5': 'More than 50 years'
  },
  antiquity: {
    '1': 'Less than 6 months',
    '2': 'From 6 months to 1 year',
    '3': 'From 1 to 3 years',
    '4': 'From 3 to 5 years',
    '5': 'From 5 to 10 years',
    '6': 'From 10 to 20 years',
    '7': 'More than 20 years'
  }
}
