
<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-col cols="12" class="pa-3 headline">
        {{ $t('requiredCulture.overview') }}
        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-col>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <x-inputs-input
                  :input="requiredCulture.name"
                  @updateInput="($event) => requiredCulture.name = $event"
                  :append-outer-icon="$t('help.icon')"
                  :label="$t('requiredCulture.overview')"
                  rules="required"
                  autofocus
                  light
                  :counter="counter"
                  :disabled="(requiredCulture.status==='cancelled'||requiredCulture.status==='completed')"
                  name="overview"
                  :help-message="$t('help.requiredCulture.create.name')"
                  :data-vv-as="$t('requiredCulture.overview') | lowerCase"
                  @click:append-outer="$store.dispatch('help/display', $t('help.requiredCulture.create.name'))"
                ></x-inputs-input>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  large
                  @click="changeStep(+step - 1)"
                >{{ $t(prevAction) }}</v-btn>
              </v-col>

              <v-col cols="12" sm="6">
                <v-btn
                  color="primary"
                  class="white--text"
                  type="submit"
                  large
                  block
                >{{ $t(nextAction) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-stepper-content>
</template>

<script>

export default {
  props: {
    requiredCulture: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      counter: 75
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('changeStep', this.requiredCulture, step)
    },
    onSubmit () {
      this.validateCounter()
        .then(() => this.changeStep(+this.step + 1))
    },
    validateCounter () {
      return new Promise((resolve, reject) => {
        if (this.requiredCulture.name.length > this.counter) {
          this.$store.dispatch('alert/error', this.$t('errors.steps-overview/max-counter'))
          return reject(new Error('counter'))
        }
        return resolve(true)
      })
    }
  }
}
</script>
