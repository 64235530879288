
export default {
  methods: {
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    $generateAtrributesTables () {
      let headersDimensions = [
        'values',
        'sustainability',
        'customers',
        'innovation',
        'achievement',
        'leadership',
        'talent',
        'relationship',
        'communication'
      ]
      let tables = []
      for (let index = 0; index < 9; index++) {
        let color = ''
        if (index < 3) {
          color = '#0889D6'
        } else if (index > 2 && index < 6) {
          color = '#EB493C'
        } else {
          color = '#48BBA1'
        }
        tables.push(
          {
            text: '',
            pageBreak: 'before',
            pageOrientation: 'landscape'
          },
          {
            columns: [
              {
                table: {
                  color: 'white',
                  widths: [479],
                  body: [
                    [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                  ]
                },
                layout: {
                  fillColor: '#BFBFBF',
                  defaultBorder: ''
                },
                alignment: 'left',
                bold: true,
                margin: [-40, -30, 0, 0]
              },
              {
                table: {
                  color: 'white',
                  widths: [260],
                  body: [
                    [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                  ]
                },
                layout: {
                  fillColor: color,
                  defaultBorder: ''
                },
                alignment: 'left',
                bold: true,
                margin: [36, -30, 0, 0]
              }
            ]
          },
          {
            table: {
              color: '#BFBFBF',
              fontSize: 8,
              body: [
                [{ text: this.$t('cultureReports.limitant_attribute').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 6], alignment: 'center', border: [false, false, false, false] },
                  { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 6], width: 125, height: 30, alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.positive_attribute').toUpperCase(), color: '#5c5655', margin: [0, 6, 0, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.act').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.req').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.diff').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.disp').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                  { text: this.$t('cultureReports.tend').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] }
                ],
                [{ text: this.questionDataByAttributes[0 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][0], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[0 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[0 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][0].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[0 + (8 * index)].actScore - this.dataAttributes[index][0].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[0 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[0 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[1 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][1], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[1 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[1 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][1].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[1 + (8 * index)].actScore - this.dataAttributes[index][1].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[1 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[1 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[2 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][2], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[2 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[2 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][2].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[2 + (8 * index)].actScore - this.dataAttributes[index][2].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[2 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[2 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[3 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][3], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[3 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[3 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][3].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[3 + (8 * index)].actScore - this.dataAttributes[index][3].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[3 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[3 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[4 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][4], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[4 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[4 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][4].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[4 + (8 * index)].actScore - this.dataAttributes[index][4].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[4 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[4 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[5 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][5], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[5 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[5 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][5].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[5 + (8 * index)].actScore - this.dataAttributes[index][5].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[5 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[5 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[6 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][6], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[6 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[6 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][6].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[6 + (8 * index)].actScore - this.dataAttributes[index][6].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[6 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[6 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ],
                [{ text: this.questionDataByAttributes[7 + (8 * index)].limiting[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { image: this.horizontalBarChartsAttributes[headersDimensions[index]][7], color: '#5c5655', width: 120, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[7 + (8 * index)].positive[this.user.lang], color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'left', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.questionDataByAttributes[7 + (8 * index)].actScore.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.dataAttributes[index][7].score.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[7 + (8 * index)].actScore - this.dataAttributes[index][7].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: this.round(this.questionDataByAttributes[7 + (8 * index)].dispersion, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] },
                  { text: !this.hasPrevious ? '--' : this.questionDataByAttributes[7 + (8 * index)].tendencies.toFixed(2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#d1d1d1' : null
              },
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            fontSize: 10,
            margin: [-40, 8, 10, 6]
          },
          {
            columns: [
              {
                width: '78%',
                table: {
                  color: 'white',
                  body: [
                    [{ text: this.$t('cultureReports.dimension').toUpperCase(), color: '#5c5655', margin: [10, 6, 10, 6], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.actual').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                      { text: this.$t('cultureReports.required').toUpperCase(), color: '#5c5655', margin: [10, 6, 10, 6], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.difference').toUpperCase(), color: '#5c5655', margin: [10, 6, 10, 6], border: [false, false, false, false] },
                      { text: this.$t('cultureReports.trend').toUpperCase(), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                      { image: this.actualCultureSliderBase64, color: '#5c5655', margin: [0, 6, 0, 6], width: 150, height: 25, alignment: 'center', border: [false, false, false, false] }
                    ],
                    [{ text: this.$t(`cultureReports.${headersDimensions[index]}`), color: '#5c5655', margin: [10, 6, 0, 6], alignment: 'center', border: [false, false, false, false] },
                      { text: this.round(this.averagePerDimensions[index].average, 2), color: '#5c5655', margin: [10, 4, 5, 4], alignment: 'center', border: [false, false, false, false] },
                      { text: this.round(this.dimensionAverage[index].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                      { text: this.round(this.averagePerDimensions[index].average - this.dimensionAverage[index].score, 2), color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                      { text: `${this.hasPrevious ? this.round(this.averagePerDimensions[index].average - this.previousAveragePerDimensions[index].average, 2) : '--'}`, color: '#5c5655', margin: [5, 6, 5, 6], alignment: 'center', border: [false, false, false, false] },
                      { image: this.horizontalBarChartsDimensions[index], color: '#5c5655', width: 130, height: 30, alignment: 'center', border: [false, false, false, true], borderColor: ['', '', '', '#ddd'] }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0) ? '#d1d1d1' : null
                  }
                },
                alignment: 'center',
                bold: true,
                margin: [0, 0, 0, 0]
              },
              [
                {
                  columns: [
                    { width: '4%', text: ' \u0020 ', background: color },
                    { width: '96%', text: this.$t('requiredActualReport.required_culture') }
                  ],
                  margin: [0, 20, 0, 0]
                },
                {
                  columns: [
                    { width: '4%', text: ' \u0020 ', background: '#BFBFBF' },
                    { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
                  ],
                  margin: [0, 5, 0, 0]
                }
              ]
            ]
          })
        let array = []
        let column1 = []
        let column2 = []
        let column3 = []
        for (let j = 0; j < this.barChartsAttributes[headersDimensions[index]].length; j++) {
          if (j <= 2) {
            column1.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else if (j > 2 && j <= 5) {
            column2.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          } else {
            column3.push(
              {
                image: this.barChartsAttributes[headersDimensions[index]][j],
                width: 160,
                height: 130,
                margin: [0, 25, 20, 0]
              }
            )
          }
        }
        array = [
          {
            text: '',
            pageBreak: 'before',
            pageOrientation: 'portrait'
          },
          {
            table: {
              color: 'white',
              widths: [479],
              body: [
                [{ text: `4.4 ${this.$t('cultureReports.results_by_attribute')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: '#BFBFBF',
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [-40, 40, 0, 8]
          },
          {
            table: {
              color: 'white',
              widths: [459],
              body: [
                [{ text: this.$t(`cultureReports.${headersDimensions[index]}`).toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
              ]
            },
            layout: {
              fillColor: color,
              defaultBorder: ''
            },
            alignment: 'left',
            bold: true,
            margin: [36, 15, 0, 0]
          },
          {
            columns: [
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '-', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_negative_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [36, 15, 0, 10],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '0', margin: [0, 0, 0, 0], padding: [0, 0, 0, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_neutral_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 0, 0],
                alignment: 'center'
              },
              {
                width: 'auto',
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0 || rowIndex === 1) ? '#E4E4E4' : null
                  }
                },
                table: {
                  headerRows: 1,
                  body: [
                    [{ text: '+', margin: [-2, 0, -10, 0], bold: true, fontSize: 15, style: { color: '#2196F3' }, border: [false, false, false, false] }],
                    [{ text: this.$t('requiredActualReport.info_positive_answers'), color: '#839291', fontSize: 10, border: [false, false, false, false] }]
                  ],
                  alignment: 'center'
                },
                margin: [10, 15, 12, 10],
                alignment: 'center'
              }
            ]
          },
          {
            columns: column1
          },
          {
            columns: column2
          },
          {
            columns: column3
          }
        ]
        tables.push(...array)
      }
      return tables
    }
  }
}
