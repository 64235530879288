
const echarts = require('echarts')
require('echarts-wordcloud')

export default {
  methods: {
    $generateWordsCloud () {
      for (let index = 0; index < this.answersOpenQuestionsPopulation.length; index++) {
        const canvas = document.createElement('canvas')
        canvas.width = 960
        canvas.height = 960
        const chartWordsLocal = echarts.init(canvas)

        chartWordsLocal.setOption({
          series: [{
            type: 'wordCloud',
            shape: 'circle',
            gridSize: 4,
            sizeRange: [14, 44],
            rotationRange: [-90, 90],
            rotationStep: 90,
            width: '56%',
            height: '94%',
            drawOutOfBound: true,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                color: function () {
                  // Random color
                  return 'rgb(' + [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                  ].join(',') + ')'
                }
              }
            },
            data: this.answersOpenQuestionsPopulation[index]
            // data: this.answersOpenQuestionsPopulation[index].slice(0, 100).map(question => ({
            //   name: question.name,
            //   value: question.value
            // }))
          }]
        })
        let timer
        chartWordsLocal.on('finished', () => {
          if (timer) clearTimeout(timer)
          timer = setTimeout(() => {
            this.wordsCharts[index] = chartWordsLocal.getDataURL()
            this.renderPart[`wordsCloud${index + 1}`] = true
          }, 1500)
        })
      }
    },
    $generateWordQuestions () {
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'portrait'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.11 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            body: [
              [{ text: this.openQuestions[0][this.user.lang].split('A su juicio ').length <= 1 ? this.openQuestions[0][this.user.lang].toUpperCase() + ':' : this.openQuestions[0][this.user.lang].split('A su juicio ')[1].toUpperCase() + ':', color: '#fff', margin: [5, 5, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [25, 30, 13, 10]
        },
        {
          image: this.wordsCharts[0],
          width: 500,
          height: 500
        },
        {
          pageBreak: 'before',
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.11 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            body: [
              [{ text: this.openQuestions[1][this.user.lang].split('A su juicio ').length <= 1 ? this.openQuestions[1][this.user.lang].toUpperCase() + ':' : this.openQuestions[1][this.user.lang].split('A su juicio ')[1].toUpperCase() + ':', color: '#fff', margin: [5, 5, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [25, 30, 13, 0]
        },
        {
          image: this.wordsCharts[1],
          width: 500,
          height: 500
        },
        {
          pageBreak: 'before',
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.11 ${this.$t('cultureReports.word_questions_summary')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            body: [
              [{ text: this.openQuestions[2][this.user.lang].split('A su juicio ').length <= 1 ? this.openQuestions[2][this.user.lang].toUpperCase() + ':' : this.openQuestions[2][this.user.lang].split('A su juicio ')[1].toUpperCase() + ':', color: '#fff', margin: [5, 5, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [25, 30, 13, 0]
        },
        {
          image: this.wordsCharts[2],
          width: 500,
          height: 500
        }
      ]
    }
  }
}
