
import pdfUtil from '../utils/pdf'

export default {
  methods: {
    $generateIntroduction () {
      return [
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `${this.$t('cultureReports.index')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 20]
        },
        {
          text: `1. ${this.$t('cultureReports.introduction')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#839291'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('cultureReports.index_stack1_req'),
              margin: [15, 0, 0, 0],
              color: '#839291',
              lineHeight: 1.7
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '3\n3',
              color: '#839291',
              lineHeight: 1.7
            }
          ]
        },
        {
          text: `2. ${this.$t('cultureReports.tool_desc')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#839291'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('cultureReports.index_stack2'),
              margin: [15, 0, 0, 0],
              color: '#839291',
              lineHeight: 1.7
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '4\n4\n5',
              color: '#839291',
              lineHeight: 1.7
            }
          ]
        },
        {
          text: `3. ${this.$t('cultureReports.understanding_results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#839291'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('cultureReports.index_stack3'),
              margin: [15, 0, 0, 0],
              color: '#839291',
              lineHeight: 1.7
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '8\n8\n8\n9\n',
              color: '#839291',
              lineHeight: 1.7
            }
          ]
        },
        {
          text: `4. ${this.$t('cultureReports.results')}`.toUpperCase(),
          fontSize: 15,
          alignment: 'left',
          margin: [0, 15],
          color: '#839291'
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('cultureReports.index_stack4_req'),
              margin: [15, 0, 0, 0],
              color: '#839291',
              lineHeight: 1.7
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: `10\n${11 + (this.extraPage + this.fitsExtraPage)}\n${12 + (this.extraPage + this.fitsExtraPage)}\n${14 + (this.extraPage + this.fitsExtraPage)}\n${32 + (this.extraPage + this.fitsExtraPage)}\n${33 + (this.extraPage + this.fitsExtraPage)}\n${37 + (this.extraPage + this.fitsExtraPage)}\n${38 + (this.extraPage + this.fitsExtraPage)}\n${40 + (this.extraPage + this.fitsExtraPage)}\n${42 + (this.extraPage + this.fitsExtraPage)}\n${44 + (this.extraPage + this.fitsExtraPage)}\n${45 + (this.extraPage + this.fitsExtraPage)}\n`,
              color: '#839291',
              lineHeight: 1.7
            }
          ]
        },
        // 1
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `1. ${this.$t('cultureReports.introduction')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [459],
            body: [
              [{ text: `1.1 ${this.$t('cultureReports.what_is_organization_culture')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('cultureReports.organization_culture_definition'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          text: this.$t('cultureReports.organization_culture_definition2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          text: this.$t('cultureReports.organization_culture_definition3'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `1.2 ${this.$t('cultureReports.for_what_cuts')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('cultureReports.for_what1_cuts'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          text: this.$t('cultureReports.for_what2_cuts'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          text: this.$t('cultureReports.for_what3_cuts'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        // 2
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `2. ${this.$t('cultureReports.tool_desc')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 35, 0, 8],
          pageBreak: 'before'
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.1 ${this.$t('cultureReports.what_the_tool_measure')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        {
          text: this.$t('cultureReports.what_the_tool_measure_text'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          text: this.$t('cultureReports.what_the_tool_measure_text2'),
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        // 2.2
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: `2.2 ${this.$t('cultureReports.culture_types')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [36, 8, 0, 15]
        },
        // 2.2 Cultura responsable
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('cultureReports.responsible_culture'),
                  color: '#0889D6',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('cultureReports.responsible_text'),
                  color: '#839291',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('cultureReports.values'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('cultureReports.sustainability'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' },
                { text: this.$t('cultureReports.customers'), alignment: 'center', margin: [0, 5, 0, 0], italics: true, bold: true, fontSize: 12, color: '#0889D6' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura competitiva
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('cultureReports.competitive_culture'),
                  color: '#EB493C',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('cultureReports.competitive_text'),
                  color: '#839291',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('cultureReports.innovation'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('cultureReports.achievement'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' },
                { text: this.$t('cultureReports.leadership'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#EB493C' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        },
        // 2.2 Cultura humanista
        {
          margin: [0, 0, 22, 0],
          table: {
            widths: ['*', '*', '*'],
            headersRow: 1,
            body: [
              [
                {
                  text: this.$t('cultureReports.humanist_culture'),
                  color: '#36ad92',
                  fontSize: 14,
                  margin: [15, 4, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  text: this.$t('cultureReports.humanist_text'),
                  color: '#839291',
                  alignment: 'justify',
                  lineHeight: 1.5,
                  margin: [0, 0, 0, 0],
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                { text: this.$t('cultureReports.talent'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('cultureReports.relationship'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' },
                { text: this.$t('cultureReports.communication'), alignment: 'center', margin: [0, 0, 0, 0], italics: true, bold: true, fontSize: 12, color: '#36ad92' }
              ]
            ]
          },
          layout: {
            defaultBorder: false
          }
        }
      ]
    }
  }
}
