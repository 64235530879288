
<template>
  <div style="display:inline;">
    <v-btn
      @click="openReportOneById"
      color="primary"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      large
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ buttonText }}
    </v-btn>
    <x-confirm-spend-dialog
      v-if="itemsReport"
      :confirmText="$t('requiredCulture.confirm_generate_report')"
      :costText="$t('requiredCulture.report_cost')"
      :balanceAfterText="$t('requiredCulture.balance_after_generate_report')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noBalanceResponse="noBalanceResponse"
      :disableButtonModal="disableButton"
      @result="verifySpend"
      @update="checkBalance">
    </x-confirm-spend-dialog>

    <!-- Dialog already Generated Report -->
    <x-dialog-already-generated-report
      :show.sync="alreadyGeneratedModal"
      @close="alreadyGeneratedModal = false"
    ></x-dialog-already-generated-report>
  </div>
</template>

<script>
import Vue from 'vue'

import CurrentCultureService from '../../services/current-culture-assessment'

import XDialogAlreadyGeneratedReport from '@/components/dialogs/dialog-already-generated-report'

export default Vue.extend({
  name: 'thread-current-culture-report',
  components: {
    XDialogAlreadyGeneratedReport
  },
  props: {
    pollId: String,
    checkGenerated: Function,
    disableButton: Boolean,
    itemsReport: Boolean,
    demographicsSelected: [Array, Object],
    listOfDemographics: Object,
    buttonText: String,
    filters: null
  },
  data () {
    return {
      alreadyGeneratedModal: false,
      lockPdfButton: false,
      showModalConfirm: false,
      disableButtonModal: true,
      noBalanceResponse: false,
      balance: 0,
      price: 0
    }
  },
  watch: {
    disableButton () {
      this.disableButtonModal = this.disableButton
    }
  },
  methods: {
    openReportOneById () {
      const alreadyGenerated = this.checkGenerated(this.itemsReport, this.demographicsSelected)
      if (alreadyGenerated) {
        this.alreadyGeneratedModal = true
        return
      }
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      if (this.itemsReport) {
        return this.checkBalance()
      }
      return this.runOpenReport()
    },
    runOpenReport () {
      this.$store.dispatch('loading/show')
      return CurrentCultureService.completeCounter(this.pollId)
        .then(() => CurrentCultureService.openReportOneById(this.pollId, this.itemsReport, this.demographicsSelected, this.listOfDemographics))
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('cultureReports.opetaion_init'))
          this.$store.dispatch('loading/hide')
          // this.lockPdfButton = false
          this.showModalConfirm = false
          setTimeout(() => window.location.reload(), 3000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/current-culture/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
    },
    checkBalance () {
      return CurrentCultureService.checkBalance()
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.showModalConfirm = true
          this.$store.dispatch('loading/hide')
        })
    },
    verifySpend ($event) {
      if ($event) {
        return this.runOpenReport()
      } else {
        this.showModalConfirm = false
        if (this.lockPdfButton) {
          setTimeout(() => {
            this.lockPdfButton = false
          }, 200)
        }
      }
    }
  }
})
</script>
