<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="300" top absolute>
    <template v-slot:activator="{ on }">
      <v-list-item class="text-center" v-if="(user.customer || {}).type === 'commercial'" v-on="on">
        <v-list-item-title>
          <v-icon small>fa-briefcase</v-icon>
            {{ $t('input.change_enterprise') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-toolbar dense flat color="primary" class="white--text">
      <v-toolbar-title>
          {{ $t('input.changing_enterprise') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="white--text">
        <v-btn icon light @click="menu = !menu" class="white--text">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-list dense>
      <v-list-item>
        <v-list-item-title>
          <h2 style="margin-bottom: .7em">{{ $t('input.fast_search') }}</h2>
          <v-spacer></v-spacer>
          <v-text-field
            outlined
            dense
            rounded
            v-model="search"
            @keypress.enter="searchEnterprise"
          >

          </v-text-field>
        </v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar dense flat color="primary" class="white--text" style="margin-left: 1em; margin-right: 1em; margin-bottom: 1em" >
        <v-toolbar-title>
          {{ $t('input.enterprises') }}
        </v-toolbar-title>
      </v-toolbar>
      <template v-for="(enterprise, key) in enterprises">
        <v-list-item :key="key" style="padding-left: 2em; padding-right: 2em; margin-top: 0.6em" @click="loginAsEnterprise(enterprise.id)">
          <v-list-item-title>
            <p>
              <span class="text-capitalize">
                {{ enterprise.name }}
              </span><br>
              <span style="font-size: .9em">{{enterprise.headquarter.name}}</span>
            </p>
          </v-list-item-title>
        </v-list-item>
        <v-divider :key="key" v-if="key < enterprises.length - 1"></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>

import Vue from 'vue'
import authService from '../../services/auth'

export default Vue.extend({
  props: {
    menu: Boolean,
    user: Object,
    enterprises: Array,
    closeBack: Function
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    searchEnterprise () {
      this.$emit('search-enterprises', this.search)
    },
    loginAsEnterprise (enterpriseId) {
      return authService.loginAsEnterprise(enterpriseId, this.user)
        .then((res) => {
          return this.$store
            .dispatch('session/loginAs', res)
            .then((res) => {
              this.$router.push('/dashboard')
              window.location.reload(false)
            })
            .catch(err => (this.$store.dispatch('alert/error', err)))
        }).catch(err => (this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))))
    }
  }
})
</script>
