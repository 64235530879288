
export default {
  methods: {
    $generateDiffRanking () {
      let gapsPerAttribute = []
      for (let i = 0; i < this.sortedquestionDataByAttributesByItems.length; i++) {
        const actualOrg = this.sortedquestionDataByAttributes.find(attribute => this.sortedquestionDataByAttributesByItems[i].idx === attribute.idx).actScore
        gapsPerAttribute[i] = {
          limiting: this.sortedquestionDataByAttributesByItems[i].limiting[this.user.lang],
          positive: this.sortedquestionDataByAttributesByItems[i].positive[this.user.lang],
          dimension: this.sortedquestionDataByAttributesByItems[i].dimensionsName,
          actualCut: this.sortedquestionDataByAttributesByItems[i].actScore,
          actualOrg,
          gap: Number(this.sortedquestionDataByAttributesByItems[i].actScore) - Number(actualOrg)
        }
      }
      gapsPerAttribute.sort((a, b) => a.gap - b.gap)
      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.8 ${this.$t('cultureReports.analysis_difference_section_cut_org_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 20, 0]
            },
            // Atributos con puntaje mas alto
            {
              width: '35%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.diff_attributes_with_higher_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [10, -15, 0, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '33%', '33%', '9%', '7%', '8%', '8%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_org'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.difference_cut_org_2'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 1].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 1].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 1].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 1].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 1].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 1].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 2].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 2].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 2].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 2].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 2].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 2].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 3].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 3].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 3].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 3].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 3].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 3].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 4].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 4].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 4].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 4].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 4].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 4].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 5].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 5].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 5].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 5].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 5].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 5].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 6].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 6].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 6].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 6].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 6].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 6].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 7].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 7].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 7].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 7].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 7].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 7].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 8].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[gapsPerAttribute.length - 8].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[gapsPerAttribute.length - 8].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 8].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 8].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[gapsPerAttribute.length - 8].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        },
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        {
          columns: [
            {
              width: '65%',
              table: {
                widths: [479],
                body: [
                  [{ text: `4.8 ${this.$t('cultureReports.analysis_difference_section_cut_org_report')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
                ]
              },
              layout: {
                fillColor: '#aaaaaa',
                defaultBorder: ''
              },
              alignment: 'left',
              margin: [-40, -15, 20, 0]
            },
            // Atributos con puntaje mas bajo
            {
              width: '35%',
              layout: {
                defaultBorder: '',
                fillColor: '#BFBFBF'
              },
              table: {
                widths: [244],
                body: [
                  [{ text: this.$t('cultureReports.diff_attributes_with_lower_score'), margin: [30, 8, 0, 5], bold: true, color: '#fff' }]
                ]
              },
              margin: [10, -15, 0, 10]
            }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['2%', '33%', '33%', '9%', '7%', '8%', '8%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 0, 0, 0]
                },
                {
                  text: this.$t('cultureReports.limitant_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.positive_attribute'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.dimension'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_cut'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.actual_org'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                },
                {
                  text: this.$t('cultureReports.difference_cut_org_2'),
                  margin: [0, 5, 0, 0],
                  bold: true,
                  style: { color: '#fff' },
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '1',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[0].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[0].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[0].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[0].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[0].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[0].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '2',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[1].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[1].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[1].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[1].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[1].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[1].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '3',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[2].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[2].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[2].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[2].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[2].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[2].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '4',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[3].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[3].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[3].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[3].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[3].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[3].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '5',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[4].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[4].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[4].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[4].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[4].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[4].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '6',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[5].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[5].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[5].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[5].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[5].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[5].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '7',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[6].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[6].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[6].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[6].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[6].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[6].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: '8',
                  margin: [0, 6, 0, 0],
                  bold: true,
                  style: { color: '#6d7a7a' }
                },
                {
                  text: gapsPerAttribute[7].limiting,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: gapsPerAttribute[7].positive,
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 0]
                },
                {
                  text: this.$t(`requiredReport.initials.${gapsPerAttribute[7].dimension}`),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[7].actualCut, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[7].actualOrg, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                },
                {
                  text: this.round(gapsPerAttribute[7].gap, 2),
                  color: '#6d7a7a',
                  borderColor: ['', '', '', '#78909C'],
                  border: [false, false, false, true],
                  margin: [0, 15, 0, 0],
                  alignment: 'center'
                }
              ]
            ]
          },
          margin: [-10, 0, 10, 20],
          border: [false, false, false, true],
          fontSize: 12
        }
      ]
    }
  }
}
