
<template>
  <v-container fluid>
    <v-row wrap row class="my-5">
      <v-col cols="12" sm="12" md="6">
        <v-row justify="center" align="center">
          <section class="mini-logo text-right">
            <v-img aspect-ratio="1" src="/img/20200301_occ_solution_logo.png" alt="Company Logo"/>
          </section>
          <h1 class="ml-5 mr-5" style="font-size: 27px; color: rgb(56, 153, 218);">{{ currentCultureName }}</h1>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card
          class="mx-auto"
        >
          <v-row>
            <v-col cols="12" sm="11">
              <v-card-text>
                <span>
                  {{ $t('actualEvaluation.information') }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="1" sm="1" class="d-none d-sm-flex">
              <v-avatar color="indigo">
                <v-icon dark>mdi-information-outline</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-for="(n, i) in dimensions" :key="`${i}-content`" justify="center">
      <template v-if="step === i">
        <v-col cols="11" class="mt-6 mb-1 py-0" v-for="(item, j) in n.questions" :key="j">
          <v-row>
            <v-col cols="1" class="d-none d-md-flex pt-2 pb-0">
              <v-avatar class="ml-8" :color="n.questions[j].selected ? 'rgb(94,175,169)' : 'blue-grey'" size="36">
                <span class="white--text headline">{{ n.questions[j].cnt }}</span>
              </v-avatar>
            </v-col>
            <v-col cols="12" md="11" class="py-0 pr-md-12">
              <v-card style="border-radius: 5px;" min-height="50">
                <v-card-text class="pa-0">
                  <v-row wrap row justify="space-between">
                    <v-col class="text-left ml-5">
                      <p style="font-size: 15px;" class="my-0"> {{ n.questions[j].limiting[language] }} </p>
                    </v-col>
                    <v-col class="text-right mr-5">
                      <p style="font-size: 15px;" class="my-0">{{ n.questions[j].positive[language] }} </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-8 mb-2">
            <x-slider-custom
              :model="item"
              :value="item.value"
              :ticks-labels='ticksLabels'
              :positions="{ dimension: i, attribute: j }"
              class="ml-md-12"
              @change-value="changeValue"
            />
          </v-row>
        </v-col>
      </template>
    </v-row>
    <v-row justify="center" class="mt-8">
      <template v-if="step === dimensions.length">
        <v-col cols="12" sm="10" class="text-center">
          <p style="font-size: 25px; font-weigth: bold; color: rgb(94,175,169);">{{ $t('actualEvaluation.open_questions') }}</p>
        </v-col>
        <v-col cols="9" class="py-0" v-for="(n, i) in openQuestions" :key="i">
          <v-row wrap row justify="center">
            <v-col cols="12" sm="10" class="py-0">
              <h4 style="font-size: 20px;">{{ n.translation[language] }}</h4>
            </v-col>
            <v-col cols="12" class="mt-4 mb-12 py-0">
              <v-row justify="space-between">
                <v-col cols="3" v-for="(item, j) in n.answer" :key="j">
                  <v-text-field
                    v-model="openQuestions[i].answer[j]"
                    :rules="rules"
                    :label="$t('input.answer')"
                    class="pt-4"
                    counter
                    :maxlength="18"
                    @change="answerOpenQuestions"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <v-row wrap row justify="center"
      :class="step === 0 ? 'ml-5' : step === dimensions.length ? 'ml-0' : 'ml-8'"
    >
      <v-col cols="12" v-if="conectionTry">
        <v-alert type="info" :value="conectionTry">{{ $t('actualEvaluation.connection_try_save') }}</v-alert>
      </v-col>
      <v-col
        v-if="step != 0"
        class="mr-3 pt-4 pb-5 text-right"
      >
        <v-btn large
          color="warning"
          class="mt-4"
          :class="step === dimensions.length ? 'px-12' : ''"
          :disabled="Boolean(conectionAttempt)"
          @click="backPageView"
        >
          {{ $t('input.back') }}
        </v-btn>
      </v-col>
      <v-col
        v-if="!multiDialog"
        class="ml-5 pt-4 pb-5"
        :class="step == 0 ? 'text-center' : ''"
      >
        <v-btn :disabled="!stepCompleted[step]" :loading="Boolean(conectionAttempt)" class="mt-4" @click="nextPageView" large color="primary">{{ step === dimensions.length ? $t('input.finish_send') : $t('input.next') }}</v-btn>
      </v-col>
    </v-row>
    <v-fab-transition style="bottom: 10px;">
      <v-btn
        color="rgb(56, 153, 218)"
        dark fixed bottom right fab x-large
      >
        <span>{{ parseInt(progress) }}%</span>
      </v-btn>
    </v-fab-transition>

    <v-dialog
      v-model="startDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-card-text>
        <v-container fluid class="px-0">
          <v-layout row wrap>
            <v-flex
              class="offset-md2 text-center grey--text text--darken-1"
              style="margin: 30px auto!important;"
            >
              <v-row justify="end">
                <v-select
                  class="styled-select mr-3"
                  :items="languages"
                  v-model="language"
                  dense
                  @change="changeLocale"
                ></v-select>
              </v-row>
              <v-row justify="center">
                <img src="/img/20200301_occ_solution_logo.png" width="220"/>
                <img v-if="enterprise && enterprise.logo" :src="enterprise.logo" alt="Enterprise Logo" height="130" width="260"/>
              </v-row>
              <br>
              <h2 class="display-3 mt-5 mt-5 mx-3" style="color: #42A5F5;">{{ $t('actualEvaluation.start') }}</h2>

              <p class="headline mt-5"></p>
              <p class="mt-5 mx-3" v-html="$t('actualEvaluation.instructions', {deadline: this.deadLine})"></p>
              <p class="mt-5 mx-3" v-html="$t('actualEvaluation.instructions_2')"></p>
              <v-col cols="12" class="px-0 text-center">
                <img src="/img/ejemplo-cultura-actual.gif" width="100%"/>
              </v-col>
              <v-row justify="center">
                <v-col cols="8">
                  <v-row align="center" justify="space-between">
                    <v-col cols="12">
                      <v-btn
                        @click="startDialog = false"
                        class="mt-4"
                        color="primary"
                        large
                      >{{ $t('input.continue_questionnaire') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="multiDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex
              class="offset-md2 text-center grey--text text--darken-1"
              style="margin: 110px auto!important;"
            >
              <img :src="multiDialogInfo.src" width="220"/>
              <h2
                class="display-3"
                :class="[multiDialogInfo.colorClass, multiDialogInfo.mtClass]"
                :style="[multiDialogInfo.colorStyle]"
              >{{ $t(multiDialogInfo.text) }}</h2>
              <p class="headline mt-5"></p>
              <v-btn
                v-if="multiDialogInfo.close"
                @click="multiDialog = false"
                class="mt-4"
                color="primary"
                large
              >{{ $t('input.continue') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="conectionModal"
      fullscreen
      hide-overlay
      persistent
      no-click-animation
    >
      <v-card>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex
              class="offset-md2 text-center grey--text text--darken-1"
              style="margin: 110px auto!important;"
            >
              <img src="/img/actual-culture-img5.png" width="220"/>
              <template v-if="conectionAttempt <= 9">
                <h2 v-if="suiteConnFailed" class="display-3 error--text mt-10">
                  {{ $t(multiDialogInfo.text) }}
                </h2>
                <h2 v-else  class="display-3 error--text mt-10">
                  {{ $t('actualEvaluation.connection_interrupted') }}
                </h2>
                <p class="headline mt-5">
                  <span v-if="timeConectionAttempt > 0">
                    {{ $t('actualEvaluation.connection_interrupted_trying', {seconds: timeConectionAttempt}) }}
                  </span>
                  <span v-else>
                    {{ $t('actualEvaluation.connection_interrupted_connecting') }}
                  </span>
                </p>
              </template>
              <h2 class="display-3 error--text mt-10" v-else>{{ $t('actualEvaluation.connection_interrupted_out') }}</h2>
              <v-btn
                v-if="!suiteConnFailed && (timeConectionAttempt > 0 || conectionAttempt >= 10)"
                @click="retryConection"
                class="mt-4"
                color="primary"
                large
              >{{ $t('actualEvaluation.connection_try_connect_now') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-overlay :value="Boolean(conectionAttempt)"></v-overlay>
    <x-alert></x-alert>

    <x-dialog-finish
      :logo="displayEnterpriseLogo"
      :display.sync="finishDialog"
    ></x-dialog-finish>
  </v-container>
</template>

<script>

import currentCultureAssessment from '../../services/current-culture-assessment'
// import authService from '../../services/auth'
import XDialogFinish from './culture-pulse/dialogs/finish.vue'
import DevFillForm from '../../utils/dev-fill-form.js'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  components: {
    XDialogFinish
  },
  data () {
    return {
      step: 0,
      value: 0,
      startDialog: false,
      multiDialog: false,
      multiDialogInfo: {},
      finishDialog: false,
      answer: {},
      dimensions: [],
      deadLine: '',
      dateFinal: '',
      openQuestions: [],
      employee: {},
      currentCultureName: '',
      rules: [
        value => !!value || this.$i18n.t('input.required_field')
      ],
      stepChange: [false, false, false, false, false, false, false, false, false, false],
      stepCompleted: [false, false, false, false, false, false, false, false, false, false],
      positions: {
        dimension: 0,
        attribute: 0
      },
      temporary: [],
      ticksLabels: ['-4', '-3', '-2', '-1', '0', '+1', '+2', '+3', '+4'],
      scoreAssessment: [],
      enterprise: null,
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionCallBack: null,
      conectionTry: false,
      conectionModal: false,
      suiteConnFailed: false,
      progress: 0,
      progressDisplayed: true,
      intervalConection: undefined,
      languages: [],
      language: 'es'
    }
  },
  created () {
    const languages = this.$t('languages')
    for (let key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this._i18n.locale = this.language
    this.getActualCulture()
    window.fillForm = (options) => { DevFillForm(this, options) }
  },
  computed: {
    displayEnterpriseLogo () {
      return !this.enterprise || !this.enterprise.logo
        ? '/img/20200301_occ_solution_logo.png'
        : this.enterprise.logo
    }
  },
  watch: {
    startDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    multiDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    conectionModal (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        this.suiteConnFailed = false
      }
    },
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (let key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this._i18n.locale = this.language
        const options = { year: 'numeric', month: 'short', day: 'numeric' }
        let d = this.dateFinal.toLocaleDateString(this._i18n.locale, options)
        let t = this.dateFinal.toLocaleTimeString()
        t = t.replace(/\u200E/g, '')
        t = t.replace(/^([^\d]*\d{1,2}:\d{1,2}):\d{1,2}([^\d]*)$/, '$1$2')
        let result = d + ' ' + t
        this.deadLine = result
      }
    }
  },
  methods: {
    changeLocale () {
      this._i18n.locale = this.language
    },
    retryConection () {
      if (this.intervalConection) {
        clearInterval(this.intervalConection)
      }
      this.conectionAttempt = 0
      this.tryConection()
    },
    async tryConection (callBack) {
      this.conectionAttempt++
      if (callBack) {
        this.conectionCallBack = callBack
      }
      return this.conectionCallBack()
        .then((res) => {
          this.conectionModal = false
          this.conectionTry = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return res
        })
        .catch(async (err) => {
          if (!this.errorHandler(err)) {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            this.conectionTry = this.conectionAttempt > 3
            this.conectionModal = this.conectionAttempt > 6
            if (this.conectionAttempt < 10) {
              const time = this.timeConectionAttempt = this.conectionAttempt <= 3 ? 2 : (this.conectionAttempt <= 6 ? 5 : 10)
              this.intervalConection = setInterval(() => {
                this.timeConectionAttempt--
                if (this.timeConectionAttempt <= 0) {
                  clearInterval(this.intervalConection)
                }
              }, 1000)
              await sleep(time * 1000)
              const res = await this.tryConection()
              return res
            }
          }
          throw err
        })
    },
    errorHandler (err, initial = false) {
      const errors = [
        'evaluation_resend_completed',
        'current-culture/not-found',
        'current-culture/status-fail',
        'current-culture/user-status-fail',
        'current-culture/user-disabled',
        'current-culture/user-not-found',
        'current-culture/invalid-token',
        'current-culture/idx-fail',
        'current-culture/check-process-fail'
      ]
      if (err && err.code) {
        if (initial && err.code === 'suite-fail/not-found-retry') {
          this.multiDialogInfo.text = `errors.${err.code}`
          this.conectionModal = true
          this.suiteConnFailed = true
          this.timeConectionAttempt = 10
          setTimeout(() => { location.reload() }, 10000)
        }
        if (errors.includes(err.code)) {
          if (err.code === 'current-culture/user-status-fail') {
            this.finishDialog = true
          } else {
            this.setMultiDialogByCase('errorHandlerDialog')
            this.multiDialogInfo.text = `errors.${err.code}`
          }
          return true
        }
      }
    },
    setMultiDialogByCase (idxCase) {
      const cases = {
        middleDialog: {
          src: '/img/actual-culture-img4.png',
          colorClass: 'warning--text',
          text: 'actualEvaluation.middle',
          close: true
        },
        invalidTokenDialog: {
          src: '/img/alerta.png',
          mtClass: 'mt-10',
          colorClass: 'error--text',
          text: 'actualEvaluation.invalid_token'
        },
        beforeDialog: {
          src: '/img/reloj.png',
          colorStyle: { color: '#42A5F5;' },
          text: 'actualEvaluation.before_date'
        },
        expiratedDialog: {
          src: '/img/expiracion.png',
          colorClass: 'error--text',
          text: 'actualEvaluation.expiration_date'
        },
        errorHandlerDialog: {
          src: '/img/alerta.png',
          mtClass: 'mt-10',
          colorClass: 'error--text',
          text: 'errors.apology'
        }
      }
      this.multiDialogInfo = JSON.parse(JSON.stringify(cases[idxCase]))
      this.multiDialogInfo.colorStyle = this.multiDialogInfo.colorStyle || {}
      this.multiDialogInfo.colorStyle = this.multiDialogInfo.mtClass ? this.multiDialogInfo.mtClass : 'mt-5'
      this.multiDialog = true
    },
    setInitialDimensions (object) {
      this.currentCultureName = object.name
      let progressAcum = 0
      let cultureTypes = object.questionnaire.cultureTypes
      let temporal = object.population.temp.dimension
      let flag = false
      let employeeEnterprise = object.population.employee.employeeEnterprise
      this.employee = {
        countryId: employeeEnterprise.countryId,
        headquarterId: employeeEnterprise.headquarterId,
        departmentId: employeeEnterprise.departmentId,
        enterpriseId: employeeEnterprise.enterpriseId,
        genderId: employeeEnterprise.genderId,
        jobTypeId: employeeEnterprise.jobTypeId,
        academicDegreeId: employeeEnterprise.academicDegreeId,
        chargeId: employeeEnterprise.chargeId,
        identifyTypeId: employeeEnterprise.identifyTypeId,
        additionalDemographic1Id: employeeEnterprise.additionalDemographic1Id,
        additionalDemographic2Id: employeeEnterprise.additionalDemographic2Id,
        birthdate: employeeEnterprise.birthdate,
        admission: employeeEnterprise.admission,
        employeeEnterpriseId: employeeEnterprise.id
      }
      this.openQuestions = object.openQuestions
      for (let i = 0; i < this.openQuestions.length; i++) {
        this.openQuestions[i].answer = ['', '', '']
      }
      if (temporal.length === 0) {
        flag = true
      }
      for (let obj in cultureTypes) {
        for (let tc in cultureTypes[obj]) {
          this.dimensions.push(cultureTypes[obj][tc])
        }
      }
      let cnt = 1
      for (let i = 0; i < this.dimensions.length; i++) {
        for (let j = 0; j < this.dimensions[i].questions.length; j++) {
          this.dimensions[i].questions[j].cnt = cnt
          if (temporal[i] !== undefined && temporal[i].attributes[j] !== undefined) {
            if (flag === false && temporal[i].attributes[j]._id !== undefined) {
              this.dimensions[i].questions[j].value = temporal[i].attributes[j].score
              this.dimensions[i].questions[j].selected = true
            }
          } else {
            this.dimensions[i].questions[j].value = 0
            this.dimensions[i].questions[j].selected = false
          }
          if (this.dimensions[i].questions[j].selected === true) {
            progressAcum += 1
            if (progressAcum === 8) {
              progressAcum = 0
              this.stepCompleted[i] = true
            }
          }
          cnt++
        }
      }
      this.setTemporalValue()
      // this.checkActivity()
      this.setProgress()
    },
    getActualCulture () {
      currentCultureAssessment.getOneByToken(this.$route.params.id.toString())
        .then((res) => {
          if (res.executed) {
            this.enterprise = res.data.enterprise
            if (res.data.status === 'completed') {
              this.setMultiDialogByCase('expiratedDialog')
            } else {
              if (res.data.population.status === 'finished') {
                this.finishDialog = true
              } else {
                let timezone = res.data.timeZone.split('UTC')[1].split(')')[0]
                let releasedAtParsed = Date.parse(res.data.releaseAt.split('Z')[0] + timezone) / 1000
                let deadLineAtParsed = Date.parse(res.data.deadlineAt.split('Z')[0] + timezone) / 1000
                if (releasedAtParsed > parseInt(Date.now() / 1000)) {
                  this.setMultiDialogByCase('beforeDialog')
                } else if (deadLineAtParsed < parseInt(Date.now() / 1000)) {
                  this.setMultiDialogByCase('expiratedDialog')
                } else {
                  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                  let maxDateTime = new Date(deadLineAtParsed * 1000)
                  let testDate = maxDateTime.toLocaleDateString(undefined, options)
                  let months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
                  let ampm = maxDateTime.getHours() >= 12 ? ' pm' : ' am'
                  let hour = (maxDateTime.getHours() % 12) || 12
                  // prepend zero
                  const pz = (num) => num >= 0 && num < 10 ? `0${num}` : Number(num).toString()
                  this.deadLine = pz(maxDateTime.getDate()) + ' ' + months[maxDateTime.getMonth()] + ' de ' + maxDateTime.getFullYear() + ', ' + pz(hour) + ':' + pz(maxDateTime.getMinutes()) + ':' + pz(maxDateTime.getSeconds()) + ampm
                  this.startDialog = true
                  this.dateFinal = maxDateTime
                  this.setInitialDimensions(res.data)
                }
              }
            }
          } else {
            this.setMultiDialogByCase('invalidTokenDialog')
          }
        })
        .catch((err) => {
          this.errorHandler(err, true)
        })
    },
    changeValue (value, i, j, sl) {
      // Getting the values with a change
      this.dimensions[i].questions[j].value = value
      this.dimensions[i].questions[j].selected = sl
      this.positions = { dimension: i, attribute: j, value }

      let progressAcum = 0
      for (let k = 0; k < this.dimensions[i].questions.length; k++) {
        if (this.dimensions[i].questions[k].selected) {
          progressAcum += 1
        }
      }
      this.stepChange[i] = true
      this.stepCompleted[i] = progressAcum === 8
      this.setProgress()
      return Promise.resolve(true)
    },
    updateTemporal () {
      return currentCultureAssessment.updateTemporal(this.$route.params.id, this.temporary)
    },
    setTemporalValue () {
      // Set values in temporal
      let temporaryDimension = []
      for (let index = 0; index < this.dimensions.length; index++) {
        const dimension = this.dimensions[index]
        const temporaryAttributes = []
        temporaryDimension.push({
          name: dimension.name,
          score: 0
        })
        for (let l = 0; l < dimension.questions.length; l++) {
          if (dimension.questions[l].selected) {
            temporaryAttributes.push({
              score: dimension.questions[l].value
            })
          }
        }
        temporaryDimension[index].attributes = temporaryAttributes
      }

      this.temporary = {
        name: '',
        score: 0,
        dimension: temporaryDimension
      }
    },
    nextPageView () {
      if (this.step === this.dimensions.length) {
        this.setTemporalValue()
        const getBaseCulture = (name) => ({ name, score: 0, dimension: [] })
        const responsible = getBaseCulture('responsible')
        const humanist = getBaseCulture('humanist')
        const competitive = getBaseCulture('competitive')
        for (let index = 0; index < this.temporary.dimension.length; index++) {
          let name = this.temporary.dimension[index].name
          if (name === 'values' || name === 'sustainability' || name === 'customers') {
            responsible.dimension.push(this.temporary.dimension[index])
          } else if (name === 'talent' || name === 'relationship' || name === 'communication') {
            humanist.dimension.push(this.temporary.dimension[index])
          } else {
            competitive.dimension.push(this.temporary.dimension[index])
          }
        }
        let openQuestionsFormatted = []
        for (let j = 0; j < this.openQuestions.length; j++) {
          openQuestionsFormatted.push({
            question: {
              name: this.openQuestions[j].name,
              translation: this.openQuestions[j].translation
            },
            answer: this.openQuestions[j].answer
          })
        }
        this.answer = {
          demographicItems: this.employee,
          cultureTypes: [
            responsible,
            humanist,
            competitive
          ],
          openQuestions: openQuestionsFormatted
        }
        return this.tryConection(this.completeValuation)
          .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
      } else {
        this.setTemporalValue()
        if (this.stepChange[this.step]) {
          this.stepChange[this.step] = false
          this.tryConection(this.updateTemporal)
            .then((res) => this.changeStep())
        } else {
          this.changeStep()
        }
      }
    },
    completeValuation () {
      return currentCultureAssessment.saveAnswer(this.$route.params.id, this.answer)
        .then(() => {
          this.finishDialog = true
        })
    },
    backPageView () {
      this.setTemporalValue()
      if (this.stepChange[this.step]) {
        this.stepChange[this.step] = false
        this.tryConection(this.updateTemporal)
          .then((res) => this.changeStep(true))
      } else {
        this.changeStep(true)
      }
    },
    changeStep (reduce) {
      if (reduce) {
        this.step--
      } else {
        this.step++
        if (!this.stepCompleted[this.step]) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }
    },
    answerOpenQuestions () {
      let count = 0
      let openQuestions = 0
      for (let index = 0; index < this.openQuestions.length; index++) {
        openQuestions += this.openQuestions[index].answer.length
        for (let j = 0; j < this.openQuestions[index].answer.length; j++) {
          if (this.openQuestions[index].answer[j] !== '') {
            count++
          }
        }
      }
      this.stepCompleted[this.dimensions.length] = count === openQuestions
      this.setProgress()
    },
    setProgress () {
      let acumQuestions = 0
      let acumAnswer = 0
      for (let i = 0; i <= this.dimensions.length - 1; i++) {
        acumQuestions += this.dimensions[i].questions.length
        for (let j = 0; j < this.dimensions[i].questions.length; j++) {
          if (this.dimensions[i].questions[j].selected) {
            acumAnswer += 1
          }
        }
      }
      for (let index = 0; index < this.openQuestions.length; index++) {
        acumQuestions += this.openQuestions[index].answer.length
        for (let j = 0; j < this.openQuestions[index].answer.length; j++) {
          if (this.openQuestions[index].answer[j] !== '') {
            acumAnswer++
          }
        }
      }
      this.progress = acumAnswer / acumQuestions * 100
      if (this.step === 5 && this.progress >= 50 && !this.progressDisplayed) {
        this.setMultiDialogByCase('middleDialog')
        this.progressDisplayed = true
      }
      if (this.progress < 50 && this.progressDisplayed) {
        this.progressDisplayed = false
      }
    }
  }
}
</script>
<style scoped>
  .v-slider__tick--filled {
    background-color: none;
  }
  .styled-select,
  .styled-select label[for] {
    max-width: 100px;
    font-size: 10pt;
    float: right;
  }
</style>
