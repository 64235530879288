
import mapParameters from '../utils/map-parameters'
import resolver from '../utils/resolver'

import Services from './base-services'
import academicDegreeService from './academic-degrees'
import chargeService from './charges'
import countryService from './countries'
import demographicItemsService from './demographic-items'
import departmentService from './departments'
import genderService from './genders'
import headquarterService from './headquarters'
import jobTypeService from './job-types'
import additionalDemographics1Service from './additional-demographics1'
import additionalDemographics2Service from './additional-demographics2'

const service = new Services('culture-pulse')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList, role: string) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    if (role === 'admin') {
      return service.get('list', params)
    } else {
      return service.get('list-by-enterprise', params)
    }
  },
  create: (data: any) => {
    return service.post('create', data)
  },
  sendInvitationFiles: (id: string, file: object) => {
    return service.form(`upload-invitation-file/${id}`, file)
  },
  sendReminderFiles: (id: string, file: object) => {
    return service.form(`upload-reminder-file/${id}`, file)
  },
  getOneById: (id: number) => {
    return service.get(String(id))
  },
  culturePulseTheads: (id: number) => {
    return service.get(`culture-pulse-theads/${id}`)
  },
  checkBalance: () => {
    return service.get('check-balance')
  },
  completeCounter: (id: string) => {
    return service.get(`complete-counter/${id}`)
  },
  openReportOneById: (
    id: number,
    itemsReport: boolean,
    demographicsSelected: Array<string>|{[key: string]: Array<number>},
    listOfDemographics: any
  ) => {
    return service.post(`open-culture-pulse-report/${id}`, { itemsReport, demographicsSelected, listOfDemographics })
  },
  getPopulationByAnswers: (id: number, demographicsSelected: {[key: string]: Array<number>}) => {
    return service.post(`population-by-answers/${id}`, { demographicsSelected })
  },
  getOneReportByThreadId: (id: number) => {
    return service.post(`open-thread-culture-pulse-report/${id}`)
  },
  getOneToShow: (id: number) => {
    return service.get(`get-one-to-show/${id}`)
  },
  getOneToEdit: (id: number) => {
    return service.get(`get-one-to-edit/${id}`)
  },
  update: (id: number, data: any) => {
    return service.post(`edit/${id}`, { data })
  },
  getOneByToken (token: string) {
    return service.get(`find-by-token/${token}`)
  },
  getPrevious (id: string) {
    return service.get(`get-previous/${id}`)
  },
  updateTemporal (token: string, data: object) {
    return service.put(`update-temporal`, { token, data })
  },
  saveAnswer (token: string, data: object) {
    return service.put(`save-answer`, { token, data })
  },
  close: (id: number) => {
    return service.post(`close/${id}`)
  },
  associate: (id: number, data: any) => {
    return service.post(`associate/${id}`, { data })
  },
  getDemographicsItems (lang: string) {
    return demographicItemsService.list(lang)
  },
  updateTokensAvailableByReport (id: any, enterpriseId: any) {
    return service.post('update-report-tokens', { id, enterpriseId })
  },
  resend (id: string) {
    return service.get(`resend-invitation/${id}`)
  },
  getAdditionalQuestionAnswers: (pollId: string, question: string) => {
    return service.get(`get-additional-question-answers/${pollId}/${question}`)
  },
  getDemographicsItemsByEntreprise (lang: string) {
    let demographicItemsData: any = {
      jobTypes: [],
      headquarter: [],
      gender: [],
      departments: [],
      country: [],
      charge: [],
      antiquity: [],
      age: [],
      academicDegree: [],
      optionalDemo1: [],
      optionalDemo2: []
    }
    let demographicItem: any[] = []

    return resolver.all({
      academicDegreesAll: academicDegreeService.listAll(),
      charges: chargeService.fetchByEnterprise(),
      countries: countryService.listByEnterprise(),
      demographicItem: this.getDemographicsItems(lang),
      departmentsAll: departmentService.listAll(),
      gendersAll: genderService.listAll(),
      headquarters: headquarterService.fetchByEnterprise(),
      jobTypesAll: jobTypeService.listAll(),
      additinalDemographics1All: additionalDemographics1Service.listAll(),
      additinalDemographics2All: additionalDemographics2Service.listAll()
    })
      .then((res) => {
        // TODO: falta lista de items demograficos
        demographicItem.push(...res.demographicItem.items)
        // LISTO grados academicos
        demographicItemsData.academicDegree = res.academicDegreesAll.items.map((academicDegrees: any) => ({
          ...academicDegrees,
          translate: { label: lang === 'es' ? academicDegrees.translations[0].label : academicDegrees.translations[1].label }
        }))
        // LISTO Departamentos
        demographicItemsData.departments = res.departmentsAll.items.map((departments: any) => ({
          ...departments,
          translate: { label: lang === 'es' ? departments.translations[0].label : departments.translations[1].label }
        }))
        // CHARGES
        demographicItemsData.charge = res.charges.items.map((charge: any) => ({
          ...charge,
          translate: { label: lang === 'es' ? charge.translations[0].label : charge.translations[1].label }
        }))
        // TODO: falta lista de countrys
        demographicItemsData.country = res.countries.items
        // GENDER
        demographicItemsData.gender = res.gendersAll.items.map((gender: any) => ({
          ...gender,
          translate: { label: lang === 'es' ? gender.translations[0].label : gender.translations[1].label }
        }))
        // HEADQUARTERS NO TIENE TRADUCCION EN BASE DE DATOS
        demographicItemsData.headquarter = res.headquarters
        // JOBTYPES
        demographicItemsData.jobTypes = res.jobTypesAll.items.map((jobTypes: any) => ({
          ...jobTypes,
          translate: { label: lang === 'es' ? jobTypes.translations[0].label : jobTypes.translations[1].label }
        }))

        // Additional Demographic 1
        demographicItemsData.optionalDemo1 = res.additinalDemographics1All.items.map((item: any) => ({
          ...item,
          translate: { label: lang === 'es' ? item.translations[0].label : item.translations[1].label }
        }))

        // Additional Demographic 2
        demographicItemsData.optionalDemo2 = res.additinalDemographics2All.items.map((item: any) => ({
          ...item,
          translate: { label: lang === 'es' ? item.translations[0].label : item.translations[1].label }
        }))

        // Filter optionals with no items
        demographicItem = demographicItem.filter(it => !it.optional || Boolean(demographicItemsData[it.code].length))

        return { demographicItem: demographicItem, data: demographicItemsData }
      })
  }
}
