
export default {
  copyright: 'OCC - Todos los derechos reservados',
  model: 'El Modelo',
  ranking: 'Ranking',
  occ_tool: 'Herramienta OCC',
  current_culture: 'Cultura Actual',
  required_culture: 'Cultura Requerida',
  current_culture_organization: 'Cultura actual organizacional',
  current_culture_abr: 'Cultura Actual Organización',
  current_culture_cut_abr: 'Cultura Actual Corte',
  open_questions: 'Preguntas abiertas',
  commitment_belonging: 'Compromiso+Pertenencia',
  index: 'Índice',
  topic: 'Tema',
  page: 'Página',
  humanist: 'Humanista',
  competitive: 'Competitiva',
  responsible: 'Responsable',
  our_recommendations: 'Nuestras recomendaciones',
  introduction: 'Introducción',
  tool_desc: 'Descripción de la herramienta',
  understanding_results: 'Entendimiento de los resultados',
  result_analysis: 'Interpretación de los resultados',
  results: 'Resultados',
  results_per_dimensions: 'Resultados por dimensiones',
  results_per_dimensions_actual_culture: 'Resultados por dimensión cultura actual',
  results_per_dimensions_actual_culture_text: 'Número total de respuestas por valor otorgado para cada dimensión.',
  results_per_culture: 'Resultados por Tipos de Cultura',
  results_per_culture_text: 'Las abreviaturas para cada dimensión son las dos primeras letras.',
  info_negative_answers: 'Comprende el número y porcentaje de respuestas negativas',
  info_neutral_answers: 'Comprende el número y porcentaje de personas que calificaron en cero',
  info_positive_answers: 'Comprende el número y porcentaje de personas que respondieron positivamente',
  index_stack1: [
    '1.1 ¿Qué es Cultura Organizacional?\n',
    '1.2 ¿Para qué medir la Cultura Organizacional?\n'
  ],
  index_stack2: [
    '2.1 ¿Qué mide la herramienta Organizational Culture Compass (OCC)?\n',
    '2.2 Tipos de Cultura\n',
    '2.3 Dimensiones de Cultura\n'
  ],
  index_stack3: [
    '3.1 Tasa de respuestas',
    '3.2 Contexto actual de la Organización\n',
    '3.3 Interpretación de los Resultados\n',
    '3.4 ¿Qué hacer con los Resultados?\n'
  ],
  questionaire_result: 'En esta sección se encuentran los resultados de la encuesta',
  index_stack4: [
    '4.1  Tasa de Respuesta\n',
    '4.2  Resultados por Tipos de Cultura\n',
    '4.3  Resultados por Dimensiones\n',
    '4.4  Resultados por Atributos\n',
    '4.5  Ranking de las Dimensiones para la Cultura Actual vs. Requerida\n',
    '4.6  Ranking de los Atributos para la Cultura Actual vs. Requerida\n',
    '4.7  Análisis de las Brechas en las Dimensiones Cultura Actual vs. Requerida',
    '4.8  Análisis de las Brechas en los Atributos Cultura Actual vs. Requerida',
    '4.9  Ranking de Dispersión en las Dimensiones y Atributos',
    '4.10 Ranking de Tendencias\n',
    '4.11 Resumen de la Preguntas Abiertas\n'
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    '1. Asegúrese que se da la importancia estratégica al', { text: 'Engagement', italics: true }, 'como una palanca para alcanzar resultados sobresalientes y sostenibles.\n\n',
    // tslint:disable-next-line:max-line-length
    '2. Recuerde que el ', { text: 'Engagement', italics: true }, ' y la Cultura Organizacional se complementan; gestionar ambos es vital para atraer y retener al talento.\n\n',
    // tslint:disable-next-line:max-line-length
    '3. Empodere a cada líder con la responsabilidad de gestionar el ', { text: 'Engagement', italics: true }, ' y la Cultura. Las áreas de gestión humana facilitan y apoyan.\n\n',
    // tslint:disable-next-line:max-line-length
    '4. A la hora de definir los planes para cerrar brechas, sea inclusivo y participativo. Movilizar la inteligencia colectiva genera mayor nivel de compromiso.\n\n',
    // tslint:disable-next-line:max-line-length
    '5. Es vital el seguimiento al proceso en ciclos cortos. Busque formas creativas de cerrar las brechas y mida con frecuencia. Nuestra herramienta Pulse está diseñada para esto.\n\n',
    // tslint:disable-next-line:max-line-length
    '6. Divulgue siempre los resultados con apertura y transparencia e inclúyalos en el proceso de gestión del desempeño de los líderes.\n\n',
    // tslint:disable-next-line:max-line-length
    '7. Finalmente, recuerde la importancia de mantener foco. Es mejor menos acciones de alto impacto que muchas acciones sin efectos positivos.'
  ],
  // Introduction
  what_is_organization_culture: '¿Qué es Cultura Organizacional?',
  // tslint:disable-next-line:max-line-length
  organization_culture_definition: 'Se entiende por Cultura Organizacional, la forma de pensar, sentir y actuar que distingue a los miembros de una organización. La cultura es el reflejo de las creencias más profundas, de los valores y de las normas y tradiciones que definen la personalidad de la organización.\n\n',
  organization_culture_definition2: 'La Cultura Organizacional es la última frontera de las ventajas competitivas y genera una diferenciación sostenible y difícil de imitar. La cultura de una organización se refleja en los hábitos cotidianos de sus miembros, en sus rasgos observables y la forma en que se toman las decisiones.\n\n',
  organization_culture_definition3: 'La Cultura Organizacional está directamente relacionada con la ejecución de la estrategia y el logro de los resultados; muchas organizaciones fracasan al ejecutar su estrategia por no tener una cultura adecuada y coherente.\n\n',
  // Para qué definir la cultura organizacional
  for_what: '¿Para qué medir la cultura organizacional?',
  for_what1: 'Con la complejidad del mundo actual y los retos que se presentan en atraer clientes, talento o inversionistas, el capital cultural se ha convertido en la nueva prioridad en lo relacionado con las ventajas competitivas de una organización. La cultura y los valores que representa son tan importantes como la calidad de los productos y servicios que ofrece una organización.\n\n',
  for_what2: 'El objetivo de diagnosticar cultura es conocer el estado actual de la cultura de la organización y compararlo con la cultura requerida o ya definida. A partir de esta información, se pueden definir aspectos positivos y limitantes de la cultura y prioridades a trabajar, comparaciones internas entre diferentes poblaciones y tendencias.\n\n',
  for_what3: 'Una cultura es medible, dinámica, transformable y única. En esta medida, no hay culturas mejores que otras, simplemente más o menos adecuadas; no se trata de comparar organizaciones, se trata de saber la realidad y contrastarla con su necesidad y deseos.\n\n',
  // Descripción de la herramienta occ
  what_the_tool_measure: '¿Qué mide la herramienta organizational culture compass (OCC)?',
  what_the_tool_measure_text: 'El cuestionario de OCC está basado en conductas observables asociadas a unos valores que definen la Cultura Organizacional. Los resultados presentados en este reporte están basados en las percepciones de las personas que respondieron el cuestionario. Los 72 Atributos (conductas) forman nueve dimensiones organizadas en tres tipos de cultura.\n\n',
  what_the_tool_measure_text2: 'Al analizar los datos, se pueden identificar los elementos de la cultura que predominan y los que son menos evidentes. Recuerde, no hay culturas buenas o malas, solo diferentes.\n\n',
  // Tipos de cultura
  culture_types: 'Tipos de culturas',
  responsible_culture: 'Cultura responsable',
  competitive_culture: 'Cultura competitiva',
  humanist_culture: 'Cultura humanista',
  responsible_text: 'Una organización admirada por la sociedad y sus clientes, un “buen ciudadano”, orientada hacia sus grupos de interés con una gestión ética e impecable. Comprende las siguientes dimensiones:',
  competitive_text: 'Una organización de resultados, eficiente y vibrante. Mantiene en equilibrio la innovación y la efectividad de sus procesos actuales a través de la gestión de líderes de clase mundial. Comprende las siguientes dimensiones:',
  humanist_text: 'Una organización que entiende que necesita talento competente y comprometido para lograr sus resultados. Una empresa que atrae, desarrolla y mantiene el mejor talento, promueve excelentes relaciones y una comunicación efectiva y trasparente. Comprende las siguientes dimensiones:',
  what_is_engagement_text: ['En OCC Solutions entendemos ', { text: 'Engagement', italics: true }, ' como alto nivel de compromiso emocional, sentido de pertenencia combinado con entusiasmo y pasión por el trabajo y por la Organización, que se manifiesta en un permanente deseo de dar siempre lo mejor.'],
  // Dimensiones de cultura
  culture_dimensions: 'Dimensiones de Cultura',
  culture_dimensions_next: 'A continuación se describen las nueve Dimensiones de Cultura:',
  yes: 'Si',
  no: 'No',
  // Principios
  values_positive: 'El liderazgo y la cultura centrada en principios definen y defienden pautas de conducta humana que se convierten en la brújula moral de la organización. Los principios se pueden entender como los valores y hoy es claro que su vivencia es fundamental para asegurar la supervivencia de las organizaciones.',
  values_negative: ['Para las organizaciones ', { text: 'no', decoration: 'underline' }, ' enfocadas en principios, el fin justifica los medios. No existen o se viven valores y las conductas asociadas ', { text: 'no', decoration: 'underline' }, ' son ejemplo de comportamiento, de moral o de ética. Usualmente tienen mala reputación y no generan confianza en sus grupos de interés.'],
  // Sostenibilidad
  sustainability_positive: 'Una cultura de sostenibilidad promueve el uso eficiente de los recursos actuales para asegurar la sostenibilidad futura de la organización. Da la misma importancia a todos los grupos de interés y busca resultados equilibrados en lo  financiero, humano, social y para el planeta.',
  sustainability_negative: ['Una cultura ', { text: 'no', decoration: 'underline' }, ' enfocada en la sostenibilidad se orienta a obtener resultados de corto plazo, priorizando los logros para los accionistas sobre los demás grupos de interés. La responsabilidad social, el planeta y las generaciones futuras ', { text: 'no', decoration: 'underline' }, ' son prioridades.'],
  // Clientes
  customers_positive: 'Una cultura centrada en los clientes enfoca sus esfuerzos en entender, satisfacer y superar las necesidades y expectativas de los clientes. La definición de los procesos y sistemas, la estructura y las competencias de las personas se definen teniendo en cuenta las realidades actuales y futuras de los clientes.',
  customers_negative: ['Una cultura ', { text: 'no', decoration: 'underline' }, ' enfocada en el cliente, define el cliente como un medio para obtener los resultados y ', { text: 'no', decoration: 'underline' }, ' se demuestra interés por su satisfacción o lealtad. La visión es más hacia adentro, hacia los procesos, el control y los resultados financieros de corto plazo. Las peticiones, quejas, reclamos y sugerencias son vistas como una molestia y los clientes, en el mejor de los casos, solo son importantes para los que están en contacto directo con ellos.'],
  // Innovación
  innovation_positive: 'La innovación es entendida como la capacidad de crear nuevas realidades, es un aspecto vital para el éxito de las organizaciones en el mundo de hoy. Una cultura de innovación fomenta la creatividad, la iniciativa y la capacidad de reinventarse para prosperar y competir en el tiempo, a la vez que permite asumir riesgos y aprender de los errores.',
  innovation_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran la innovación se busca mantener y preservar lo conocido. A la mayoría de las personas se les paga por “hacer su trabajo y obedecer” y la posibilidad de innovar es, en el mejor de los casos, privilegio de unos pocos. Hay inflexibilidad, dificultad de adaptarse y cambiar, temor a asumir riesgos y se suele castigar el error.'],
  // Logro
  achievement_positive: 'Las organizaciones deben dar resultados positivos para progresar y lograr sus objetivos. Una cultura de efectividad y obtención de resultados promueve conductas y procesos orientados al logro con alto nivel de desempeño. Se crea un lineamiento directo entre la contribución del desempeño individual y de equipo con el desempeño de la organización. Los resultados positivos son la consecuencia de una cultura de alto desempeño y compromiso.',
  achievement_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran los resultados, estos no son importantes y cuando no se logran suele haber “excusas o culpables”. Suele faltar compromiso y sentido de pertenencia. El cumplimiento de los procesos y los controles son más importantes que el logro de las metas definidas.'],
  // Liderazgo
  leadership_positive: 'La calidad y capacidad de los líderes es fundamental para entender el éxito de las organizaciones; entendiendo el liderazgo como el arte de movilizar y orientar personas para asegurar el logro de objetivos y propósitos comunes. En esta dimensión se analiza cómo los líderes logran compromiso, generan confianza, se interesan por desarrollar personas, dan autonomía, crean ambientes que empoderan y acompañan a sus equipos en su desarrollo.',
  leadership_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' se valora el liderazgo transformador, las personas son simplemente un recurso más y están dedicadas solamente a implementar las metas, objetivos y tareas definidas por los jefes. Los jefes asignan actividades y controlan los resultados. Se gestiona desde la imposición, el autoritarismo, la desconfianza y suele haber un alto nivel de “micro-gerencia”.'],
  // Talento
  talent_positive: 'Una cultura centrada en las personas, busca atraer, desarrollar y mantener el mejor talento en la organización como principal palanca para el logro de sus metas. El propósito es tener gente competente y con alto nivel de compromiso y desempeño, que se sienta orgullosa de pertenecer a la organización. Estas organizaciones se esfuerzan por merecer el entusiasmo y la inteligencia de todos, con su visión, su coherencia con valores, su liderazgo y sus logros.',
  talent_negative: ['Para las empresas que ', { text: 'no', decoration: 'underline' }, ' valoran la gente, las personas son recursos que se utilizan para lograr sus objetivos, sin tener en cuenta sus propósitos y metas individuales. Las emociones, competencias y desarrollo de las personas no son importantes o, a lo sumo, lo son para las áreas de Recursos Humanos.'],
  // Relaciones
  relationship_positive: 'En estas organizaciones es clara la conexión entre la calidad de las relaciones y los resultados. Estas compañías promueven el trabajo en equipo, la solidaridad y la capacidad de unir esfuerzos para lograr objetivos comunes. Temas como metas compartidas, confianza, coordinación, interdependencia y colaboración son comunes en este tipo de organizaciones.',
  relationship_negative: 'Las organizaciones que valoran solamente el trabajo individual, la competencia interna y el juego de ganar – perder, describen la forma cotidiana de actuar y dirigir. Las relaciones son estrictamente profesionales, la colaboración se entiende como favor y la división entre áreas refleja un sentido claro de independencia. Se desperdicia energía en las malas relaciones y la desconfianza.',
  // Comunicación
  communication_positive: 'La forma en que las personas se comunican y las historias que se cuentan hacen parte, son reflejo y definen la cultura y los estilos de liderazgo. En una organización con una cultura fuerte de comunicación, se comparte información con transparencia, se escucha con atención, se promueven las preguntas y se crean y mantienen relaciones de confianza y respeto mutuo. La visión y los objetivos se comunican con claridad.',
  communication_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran la comunicación, “Quien tiene la información tiene el poder”. La información no fluye en todos los sentidos ni se valora la importancia de la oportunidad y transparencia de la misma. Las estrategias y los objetivos no son claramente comunicados. No suele “haber tiempo” para escuchar a las personas y las preguntas se perciben como inoportunas e innecesarias. La complejidad de las comunicaciones refleja la desconfianza, la falta de respeto y los estilos de liderazgo jerárquicos.'],
  // Entendimiento de los resultados
  current_context: 'Contexto Actual de la Organización',
  current_context_text: 'Al interpretar los resultados es fundamental tener en cuenta el contexto actual de la organización y de la población que se está analizando. Las siguientes preguntas pueden darle algunas pautas:\n\n',
  current_context_questions: [
    '¿Cuáles son nuestros retos actuales?',
    '¿Hacia dónde vamos?',
    '¿Qué queremos alcanzar?',
    '¿Cuáles son nuestras oportunidades y amenazas?',
    '¿Cuáles son nuestras fortalezas y oportunidades de mejora?\n\n'
  ],
  results_analysis: [
    'Si el promedio de un Atributo es mayor o igual a 2 en la Cultura Actual, indica una Cultura positiva.',
    'Si el promedio de un Atributo es menor o igual a 0 en la Cultura Actual, indica una Cultura potencialmente limitante.',
    'Si el promedio de un Atributo está entre 0 y + 2, indica una Cultura neutra. En este caso, se requiere más análisis para entender la situación.',
    'Las brechas más altas entre la Cultura Requerida y la Cultura Actual indican un área de oportunidad y deben generar estrategias para cerrar brechas.',
    'Los Atributos con mayor dispersión indican menor consenso y requieren análisis más detallado.',
    'La tendencia significa la evolución de resultado; si frente a la evaluación anterior mejoró más de 0.5 aparecerá el valor resaltado en negrita, si desmejoró en más de 0.5 aparecerá el valor subrayado, entre -0.5 y +0.5 quedará en blanco.\n\n'
  ],
  what_is_organizational_culture: '¿Qué es cultura Organizacional?',
  // tslint:disable-next-line:max-line-length
  what_is_organizational_culture_text: [
    'Se entiende por Cultura Organizacional, la forma de pensar, sentir y actuar que distingue a los miembros de una organización. La cultura es el reflejo de las creencias más profundas, de los valores y de las normas y tradiciones que definen la personalidad de la organización.\n\n',
    'La Cultura Organizacional es la última frontera de las ventajas competitivas y genera una diferenciación sostenible y difícil de imitar. La cultura de una organización se refleja en los hábitos cotidianos de sus miembros, en sus rasgos observables y la forma en que se toman las decisiones.\n\n',
    'La Cultura Organizacional está directamente relacionada con la ejecución de la estrategia y el logro de los resultados; muchas organizaciones fracasan al ejecutar su estrategia por no tener una cultura adecuada y coherente.\n\n'
  ],
  what_is_engagement_positive: [
    'Dar lo mejor de sí todos los días y tener pasión por el trabajo.\n\n',
    'Compromiso emocional con el propósito, los objetivos y valores de la Organización.\n\n',
    'Motivación para contribuir con el éxito de la Organización con una mayor sensación de bienestar propio.\n\n',
    'Confianza, integridad, compromiso bidireccional y comunicación entre la Organización y sus miembros.\n\n',
    'Alto nivel de entusiasmo y dedicación que una persona siente hacia su trabajo.'
  ],
  answers_rate_items: [
    '¿Cómo se comunicó el proceso de medición?\n',
    '¿Se dio a entender la importancia de este proceso?\n',
    '¿Qué dice el hecho de que no respondieron todos?\n\n'
  ],
  how_does_our_model_works: '¿Cómo funciona nuestro modelo?',
  what_do_with_results: '¿Qué hacer con los Resultados?',
  what_do_with_results_text: 'Después de entender estos resultados se le recomienda seguir los siguientes pasos:\n\n',
  results_steps: [
    'Analice los resultados identificando similitudes y diferencias entre la Cultura Actual y Requerida.\n\n',
    'Identifique las fortalezas de la organización para apalancar un proceso de Transformación de Cultura.\n\n',
    'Identifique los principales factores potencialmente limitantes en la Cultura y defina un plan de acción para los que más puedan afectar en el desarrollo de su estrategia.\n\n',
    'Identifique las principales brechas y defina un plan de acción para los rasgos de la cultura que más puedan afectar el desarrollo de su estrategia.\n\n',
    'Analice los aspectos en los cuales hay mayor dispersión. Esto le indicará aquellos aspectos en donde puede haber mayores diferencias entre las percepciones de las personas/áreas.\n\n',
    'Asegúrese de comunicar adecuadamente los resultados a las personas que los necesiten.'
  ],
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text1: 'Nuestro modelo permite medir seis dimensiones, que indican el nivel de compromiso y sentido de pertenencia de las personas con la Organización.\n\nCada dimensión a su vez tiene 3 atributos y cada atributo tiene 3 preguntas, llamadas variables.\n\nLas seis dimensiones son:',
  how_does_our_model_works_text2: 'Cada una de las 6 dimensiones tienen tres atributos:',
  my_inspiration: 'Mi inspiración',
  sense_of_purpose: 'Sentido de propósito',
  transcendental_work: 'Trabajo trascendental',
  they_know_me: 'Me conocen',
  my_job: 'Mi trabajo',
  reputation_of_the_organization: 'Reputación de la Organización',
  reputation_of_my_area: 'Reputación de mi área',
  work_enviroment: 'Entorno de trabajo',
  positive_work_enviroment: 'Ambiente laboral positivo',
  relationships_quality: 'Calidad de las relaciones',
  peoples_treat: 'Trato de la gente',
  equity_and_transparency: 'Equidad y transparencia',
  my_team: 'Mi equipo',
  team_dynamics: 'Dinámicas de equipo',
  communication_and_coordination: 'Comunicación y coordinación',
  agility_and_decision_making: 'Agilidad y toma de decisiones',
  my_development_and_learning: 'Mi desarrollo y aprendizaje',
  opportunities_in_my_role: 'Oportunidades de crecimiento en mi rol',
  opportunities_in_the_organization: 'Oportunidades de crecimiento en la Organización',
  learning_culture: 'Cultura de aprendizaje',
  the_leaders: 'Los líderes',
  my_leader: 'Mi líder',
  support: 'Apoyo',
  our_leaders: 'Nuestros líderes',
  methodology: 'Metodología',
  // tslint:disable-next-line:max-line-length
  completed_questionaires_persons: 'Esta tabla presenta la cantidad de personas que se incluyeron en el proceso y de ese grupo cuáles completaron el cuestionario.',
  methodology_conclusion: 'Adicionalmente en la parte final de la encuesta se hacen tres preguntas abiertas, las cuales se\npresentan en el reporte en forma gráfica',
  results_text: 'En esta sección se encuentran los resultados de la encuesta.',
  total_obtained: 'Total respondidas: ',
  total_sent: 'Total esperadas: ',
  answers_rate: 'Tasa de respuestas',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'Es importante entender los resultados en relación a la tasa de respuestas. Si la tasa de respuesta no fue la esperada, debe preguntarse lo siguiente:\n\n',
  general_score: 'Resultados',
  results_by_dimension: 'Resultados por dimensión',
  results_by_attribute: 'Resultados por atributo',
  results_by_dim_attr: 'Resultados por dimensión - atributo',
  total: 'Total',
  attribute: 'Atributo',
  limitant_attribute: 'Atributo Limitante',
  positive_attribute: 'Atributo Positivo',
  act: 'ACT',
  disp: 'DISP',
  tend: 'TEND',
  current_population: 'Puntaje Actual',
  preview_population: 'Puntaje Anterior',
  rate: 'Tendencia',
  actual_organization: 'Puntaje Organización',
  gap: 'Brecha',
  culture_alignment: 'Alineación con la cultura',
  purpose: 'Propósito',
  future_vision: 'Visión futuro',
  autonomy: 'Autonomía',
  strengths_and_talents: 'Fortalezas y talentos',
  my_contribution: 'Mi aporte',
  motivation: 'Motivación',
  they_value_me: 'Me valoran',
  change_value: 'Valor del cambio',
  performance: 'Realización',
  recognized_organization: 'Organización reconocida',
  organization_performance: 'Desempeño de la Organización',
  pride_of_belonging_organization: 'Orgullo de pertenecer a la Organización',
  recognized_area: 'Área reconocida',
  area_performance: 'Desempeño del área',
  pride_of_being_part_area: 'Orgullo de hacer parte del área',
  materials_and_equipment: 'Materiales y equipos',
  offices_and_dress_code: 'Oficinas y códigos de vestimenta',
  balance_personal_professional: 'Balance entre la vida personal y profesional',
  being_myself: 'Ser yo mismo',
  freedom_of_expression: 'Libertad de expresión',
  interpersonal_relationships: 'Relaciones interpersonales',
  care_for_people: 'Cuidado por las personas',
  inclusive_workplace: 'Lugar de trabajo inclusivo',
  respectful_treatment: 'Trato respetuoso',
  equal_opportunities: 'Igualidad de oportunidades',
  salary_and_benefits: 'Salario y beneficios',
  recognition_culture: 'Cultura de reconocimiento',
  trust_quality_relationships: 'Confianza y calidad en la relaciones',
  responsibility_meeting_goals: 'Responsabilidad por cumplir las metas',
  workload_balance: 'Balance en la carga de trabajo',
  team_network: 'Red de equipos',
  communication_team: 'Comunicación en mi equipo',
  diversity: 'Diversidad',
  agility_processes: 'Agilidad en los procesos',
  innovation: 'Innovación',
  achievement: 'Logro',
  leadership: 'Liderazgo',
  talent: 'Talento',
  relationship: 'Relaciones',
  communication: 'Comunicación',
  values: 'Principios',
  sustainability: 'Sostenibilidad',
  customers: 'Clientes',
  access_transparency_information: 'Acceso y transparencia de la información',
  autonomous_learning: 'Aprendizaje autónomo',
  development_potential: 'Desarrollo de mi potencial',
  expectations_role: 'Expectativas de mi rol',
  career_plan: 'Plan de carrera',
  future_organization: 'Futuro en la Organización',
  horizontal_mobility: 'Movilidad horizontal',
  learning_tracking: 'Seguimiento al aprendizaje',
  genuine_interest: 'Interés genuino',
  admiration: 'Admiración',
  transparency_honesty: 'Transparencia y honestidad',
  clear_transparent_objectives: 'Objetivos claros y transparentes',
  coaching_feedback: 'Coaching y retroalimentación',
  leader_access: 'Acceso al líder',
  systematic_thinking: 'Pensamiento sistemático',
  strategic_planning: 'Planeación estratégica',
  social_intelligence_collaboration: 'Inteligencia social y colaboración',
  ranking_of_dimensions: 'Ranking de las dimensiones para la cultura actual',
  dimension: 'Dimensión',
  actual: 'Actual',
  required: 'Requerida',
  current_organization: 'Organización actual',
  dimensions_with_higher_score: 'Dimensiones con puntaje más alto',
  dimensions_with_lower_score: 'Dimensiones con puntaje más bajo',
  dimensions_with_higher_dispersion: 'Dimensiones con dispersión más alta',
  dimensions_with_lower_dispersion: 'Dimensiones con dispersión más baja',
  ranking_of_the_attributes: 'Ranking de los atributos para la cultura actual',
  ranking_of_the_attributes_dispersion: 'Ranking de los atributos con mayor/menor dispersión cultura actual',
  attributes_with_higher_score: 'Atributos con puntaje más alto',
  attributes_with_lower_score: 'Atributos con puntaje más bajo',
  attributes_with_higher_gap_positive: 'Atributos con brechas más altas positivas',
  attributes_with_higher_gap_negative: 'Atributos con brechas más altas negativas',
  higher_dispersion_ranking: 'Ranking de los Atributos con mayor dispersión cultura actual',
  lower_dispersion_ranking: 'Ranking de los Atributos con menor dispersión cultura actual',
  ranking_of_the_variables: 'Ranking de las variables',
  ranking_of_rates: 'Ranking de tendencias cultura actual',
  variable: 'Variable',
  variables_with_higher_score: 'Variables con puntaje actual más alto',
  variables_with_lower_score: 'Variables con puntaje actual más bajo',
  dispersion: 'Dispersión',
  dispersion_by_attributes: 'Dispersión por atributos',
  dispersion_by_variables: 'Dispersión por variables',
  attributes_with_higher_dispersion: 'Atributos con dispersión más alta',
  attributes_with_lower_dispersion: 'Atributos con dispersión más baja',
  preview_dispersion: 'Dispersión anterior',
  current_dispersion: 'Dispersión actual',
  variables_with_higher_dispersion: 'Variables con dispersión más alta',
  variables_with_lower_dispersion: 'Variables con dispersión más baja',
  rates_by_dimension: 'Tendencias por dimensión',
  variation: 'Variación',
  rates: 'Tendencias',
  highest_positive_rate_attribute: 'Tendencias positivas mayores',
  highest_negative_rate_attribute: 'Tendencias negativas mayores',
  highest_positive_rate_variable: 'Tendencias positivas más altas por variable',
  highest_negative_rate_variable: 'Tendencias negativas más altas por variable',
  rates_by_variables: 'Tendencias por variables',
  rates_by_attributes: 'Tendencias por atributos',
  open_questions_summary: 'Gráficos de preguntas cerradas',
  word_questions_summary: 'Resumen de preguntas abiertas',
  the_leaders_text: 'LOS LÍDERES: Los líderes\nen esta Organización\nme inspiran',
  my_development_text: 'MI DESARROLLO Y\nAPRENDIZAJE: Me veo creciendo\nen esta Organización\nen el futuro',
  my_team_text: 'MI EQUIPO: Hago parte\nde un equipo comprometido\ncon la Organización',
  // tslint:disable-next-line:max-line-length
  work_enviroment_text: 'AMBIENTE LABORAL\nPOSITIVO: Le recomendaría a\namigo o familiar que trabaje\nen esta Organización',
  my_job_text: 'MI TRABAJO: Me\nsiento comprometido\ny orgulloso de trabajar\nen esta empresa',
  // tslint:disable-next-line:max-line-length
  my_inspiration_text: 'MI INSPIRACIÓN: Si me\nofrecieran un trabajo similar en\notra empresa, me quedaría\ndonde estoy',
  reports: 'Reportes',
  general_title: 'General',
  general_desc: 'Este reporte cuenta con las estadísticas de toda la población incluida en la encuesta.',
  demographic_title: 'Por cortes demográficos',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'Este reporte cuenta con las estadísticas de la población filtrada a través de ciertos cortes demográficos; dichos cortes pueden seleccionarse a continuación:',
  demographic_button: 'Generar reporte Engagement por cortes demográficos',
  display_demographic: 'Mostrar cortes demográficos',
  population: 'Población',
  sent: 'Enviadas',
  obtained: 'Obtenidas',
  AcademicDegree: 'Título académico',
  Age: 'Edad',
  Antiquity: 'Antigüedad',
  Charge: 'Cargo',
  Country: 'País',
  Department: 'Área / Departamento',
  Gender: 'Género',
  JobType: 'Tipo de contratación',
  years_range: 'Entre {min} y {max} años',
  my_inspiration_chart: 'Mi\ninspiración',
  my_job_chart: 'Mi\ntrabajo',
  work_enviroment_chart: 'Ambiente laboral\npositivo',
  my_team_chart: 'Mi\nequipo',
  my_development_and_learning_chart: 'Mi desarrollo\ny aprendizaje',
  the_leaders_chart: 'Los\nlíderes',
  cover: {
    byItems: 'Reporte por población',
    general: 'Reporte General'
  },
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  active_employees: 'Empleados activos',
  average: 'Promedio sector',
  people: 'personas',
  note: 'Notas:',
  colors: 'Colores',
  dim_color: 'Color dimensión',
  meaning: 'Significado',
  previous_measurement: 'Medición anterior',
  strengths: 'Fortalezas',
  opportunities: 'Oportunidades de mejoras',
  feelings: 'Sentimientos',
  what_is_it: '¿Qué es?',
  what_is_not: '¿Qué no es?',
  critical_situation: 'Situación crítica',
  requires_attention: 'Requiere atención urgente',
  insufficient: 'Insuficiente',
  aceptable: 'Aceptable',
  very_good: 'Muy bien',
  remarkable: 'Sobresaliente',
  how_to_read: 'Cómo leer el reporte',
  title: 'OCC - Reporte Engagement',
  att_var: 'Atributo - Variable',
  dispersion_by_dimensions: 'Dispersión por dimensiones',
  open_questions_button: 'Gráficas de preguntas adicionales',
  open_question_select_label: 'Seleccione una pregunta',
  org_dispersion: 'Dispersión Organización',
  positive_attribute_footer: 'Los siguientes atributos poseen un puntaje alto exactamente igual al atributo ',
  negative_attribute_footer: 'Los siguientes atributos poseen un puntaje bajo exactamente igual al atributo ',
  positive_variable_footer: 'Las siguientes variables poseen un puntaje alto exactamente igual a la variable ',
  negative_variable_footer: 'Las siguientes variables poseen un puntaje bajo exactamente igual a la variable ',
  no_answers_modal_msg: 'La encuesta actual no posee respuestas, por lo cual no será posible generar un reporte.',
  positive: 'positivas',
  limiting: 'limititantes',
  initial: 'Sigla',
  no_tend_msg: 'Disponible a partir de la segunda medición completada'
}
