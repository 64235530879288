
import { mapState } from 'vuex'
import culturePulseService from '../../../services/culture-pulse'
import XDialogEntry from './dialogs/entry.vue'
import XDialogRandom from './dialogs/random.vue'
import XDialogFinish from './dialogs/finish.vue'
import XDialogToken from './dialogs/token.vue'
import DevFillForm from '../../../utils/dev-fill-form-pulse.js'

export default {
  components: {
    XDialogEntry,
    XDialogRandom,
    XDialogFinish,
    XDialogToken
  },
  data () {
    return {
      progress: 0,
      selected: {
        dimension: [],
        // open: [],
        additional: []
      },
      currentPage: 0,
      scrollOptions: {
        duration: 800,
        easing: 'easeInOutCubic'
      },
      questions: [],
      pages: [],
      questionTotal: 0,
      loading: true,
      entryDialog: false,
      rndDialog: false,
      rndOnce: true,
      finishDialog: false,
      tokenDialog: false,
      tokenMessage: '',
      poll: {
        id: '',
        name: ''
      },
      colors: {
        1: 'angry',
        2: 'sad',
        3: 'neutral',
        4: 'happy',
        5: 'veryhappy'
      },
      dialogContent: {},
      ticksLabels: ['-4', '-3', '-2', '-1', '0', '+1', '+2', '+3', '+4'],
      langSelected: 'es',
      enterprise: {
        invitationCode: '',
        logo: ''
      },
      tempSelectedAnswers: {},
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      connectionCallBack: null,
      conectionModal: false,
      multiDialog: false,
      multiDialogInfo: {},
      // openQuestions: [],
      additionalQuestions: []
    }
  },
  created () {
    this.verifyPollToken()
    this.randomDialogContent()
    this.langSelected = localStorage.getItem('outsideLangSelected') || 'es'
    window.fillForm = (options) => { DevFillForm(this, options) }
  },
  watch: {
    loading (val) {
      if (val) {
        this.$store.dispatch('loading/show')
      } else {
        this.$store.dispatch('loading/hide')
      }
    },
    selected: {
      handler () {
        this.calcPercent()
      },
      deep: true
    },
    entryDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    rndDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    finishDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    tokenDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    multiDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    }
  },
  methods: {
    errorHandler (err) {
      if (err && err.code) {
        this.setMultiDialogByCase(`errors.${err.code}`)
        return true
      }
      return false
    },
    setMultiDialogByCase (msg = 'errors.apology') {
      this.multiDialogInfo = {
        image: '/img/alerta.png',
        text: msg
      }
      this.multiDialog = true
    },
    tryConection () {
      this.loading = true
      this.conectionAttempt++
      return this.connectionCallBack()
        .then((res) => {
          this.conectionModal = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return res
        })
        .catch((err) => {
          if (!this.errorHandler(err)) {
            this.conectionModal = true
            if (this.conectionAttempt < 4) {
              const time = this.timeConectionAttempt = this.conectionAttempt * 10
              setTimeout(() => this.tryConection(), time * 1000)
              const interval = setInterval(() => {
                this.timeConectionAttempt--
                if (this.timeConectionAttempt <= 0) {
                  clearInterval(interval)
                }
              }, 1000)
            }
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    calcPercent () {
      let answeredCount = 0
      for (const dm of this.selected.dimension) {
        const answeredDim = dm.attributes.filter(a => a.selected)
        answeredCount += answeredDim.length
      }
      for (const additional of this.selected.additional) {
        if (additional.answer[0] !== '') {
          answeredCount++
        }
      }
      this.progress = Math.ceil((answeredCount / this.questionTotal) * 100)
      if (this.progress >= 50 && !this.rndOnce) {
        this.rndDialog = true
        this.rndOnce = true
      } else if (this.progress < 50) {
        this.rndOnce = false
      }
    },
    verifyPollToken (onlyVerify) {
      this.loading = true
      if (!onlyVerify) {
        this.entryDialog = true
      }
      return culturePulseService.getOneByToken(this.$route.params.id.toString())
        .then((res) => {
          if (res.data.population.status !== 'pending') {
            this.entryDialog = false
          }
          return !onlyVerify ? this.afterVerifyPollToken(res) : Promise.resolve(true)
        })
        .catch((error) => {
          if (error && error.code) {
            if (typeof error.code === 'object') {
              this.tokenMessage = this.$t('errors.generic-error/retry')
            } else {
              this.tokenMessage = this.$t(`errors.${error.code}`)
            }
            this.tokenDialog = true
          }
          throw error
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    afterVerifyPollToken (res) {
      this.poll.id = res.data.id
      this.poll.name = res.data.name
      this.enterprise = res.data.enterprise

      const selDim = res.data.selectedDimensions
      const selDimTags = []
      for (const key of Object.keys(selDim)) {
        if (selDim[key].selected) {
          selDimTags.push(key)
        }
      }
      const qCultureTypes = res.data.questionnaire.cultureTypes
      let cnt = 0
      for (const key of Object.keys(qCultureTypes)) {
        for (const dim of qCultureTypes[key]) {
          if (selDimTags.includes(dim.name)) {
            const qAttributes = []
            for (const q of dim.questions) {
              cnt++
              q['dimension'] = key
              q['count'] = cnt
              qAttributes.push({ score: null, selected: false })
            }
            this.pages.push(dim.questions)
            this.selected.dimension.push({
              name: dim.name,
              attributes: qAttributes
            })
            this.questionTotal += dim.questions.length
          }
        }
      }

      // Additional Questions
      if (res.data.additionalQuestions) {
        this.additionalQuestions = res.data.additionalQuestions.map(a => {
          cnt++
          return {
            count: cnt,
            question: a.question,
            options: a.options
          }
        })
        this.pages.push(this.additionalQuestions)
        for (const additional of this.additionalQuestions) {
          this.selected.additional.push({
            question: additional.question,
            answer: ['']
          })
        }
        this.questionTotal += res.data.additionalQuestions.length
      }

      this.stopLoading()
      this.afterFetchEmployeeAnswers(res.data.population.temp)
    },
    afterFetchEmployeeAnswers (tmp) {
      if (tmp.dimension.length) {
        this.selected.dimension = tmp.dimension
      }
      if (tmp.additional.length) {
        this.selected.additional = tmp.additional
      }
    },
    handlepaginate (action) {
      if (action && this.currentPage !== this.pages.length - 1) {
        this.currentPage += 1
        if (!this.pageCompleted) {
          setTimeout(() => {
            this.$vuetify.goTo(0, this.scrollOptions)
          }, 40)
        }
      } else if (!action && this.currentPage !== 0) {
        this.currentPage -= 1
      }
    },
    updateAdditionalAnswers (q, opt) {
      this.tempSelectedAnswers = JSON.parse(JSON.stringify(this.selected))
      this.tempSelectedAnswers.additional[q].answer[0] = opt
      this.connectionCallBack = this.saveCurrentAnswers
      this.tryConection()
    },
    selectAnswer (score, dim, attr, selected) {
      this.tempSelectedAnswers = JSON.parse(JSON.stringify(this.selected))
      this.tempSelectedAnswers.dimension[dim].attributes[attr].score = score
      this.tempSelectedAnswers.dimension[dim].attributes[attr].selected = selected
      this.connectionCallBack = this.saveCurrentAnswers
      this.tryConection()
    },
    saveCurrentAnswers () {
      return culturePulseService.updateTemporal(this.$route.params.id, this.tempSelectedAnswers)
        .then(() => {
          this.setSavedAnswer()
        })
    },
    setSavedAnswer () {
      this.selected = JSON.parse(JSON.stringify(this.tempSelectedAnswers))
      this.tempSelectedAnswers = {}
    },
    randomDialogContent () {
      const index = Math.floor(Math.random() * 2)
      this.dialogContent.image = '/img/actual-culture-img2.png'
    },
    tryFinishPoll () {
      this.connectionCallBack = this.finishPoll
      return this.tryConection()
    },
    finishPoll () {
      return this.verifyPollToken(true)
        .then(() => {
          this.connectionCallBack = () => {
            return culturePulseService.saveAnswer(this.$route.params.id, this.selected)
              .then(() => {
                this.finishDialog = true
              })
              .catch((err) => {
                if (err.code === 'culture-pulse/empty-answers') {
                  this.$store.dispatch('alert/error', this.$t('errors.culture-pulse/missing-questions'))
                } else {
                  this.tokenMessage = this.$t(`errors.${err.code}`)
                  this.tokenDialog = true
                }
              })
          }
          return this.tryConection()
        })
    },
    stopLoading () {
      if (this.loading) {
        setTimeout(() => {
          this.loading = false
        }, 140)
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
      alert: (state) => state.alert
    }),
    dimensionsFiltered () {
      return this.pages[this.currentPage]
    },
    pageCompleted () {
      if (!this.pages[this.currentPage]) {
        return false
      }

      // Questionnaire Questions
      if (this.pages[this.currentPage][0].dimension) {
        const answered = this.selected.dimension[this.currentPage].attributes.filter(a => a.selected)
        return answered.length >= this.pages[this.currentPage].length
      }

      // Additional Questions
      if (this.pages[this.currentPage][0].options) {
        let additionalAnswered = 0
        this.selected.additional.map(x => {
          if (x.answer[0] !== '') {
            additionalAnswered++
          }
        })
        return this.pages[this.currentPage][0].options.length === additionalAnswered
      }
    },
    percentColor () {
      if (this.progress === 0) return 'grey'
      else if (this.progress <= 24) return '#BB3E3E'
      else if (this.progress <= 40) return '#B8663D'
      else if (this.progress <= 74) return '#C2B147'
      else if (this.progress <= 99) return '#B6C144'
      else if (this.progress === 100) return '#44C156'
      return '#44C156'
    },
    displayEnterpriseLogo () {
      return !this.enterprise || !this.enterprise.logo ? '/img/20200301_occ_solution_logo.png' : this.enterprise.logo
    }
  }
}
