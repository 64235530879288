
export default {
  continue: 'Continuar',
  general_title: 'Tablero',
  init: 'Inicio:',
  end: 'Finalización',
  ended: 'Finalizado el:',
  finished: 'Finalizado',
  progress: 'En progreso',
  poll: 'Encuesta:',
  start: 'Comenzar',
  no_pending_activities: 'No posee actividades pendientes.',
  completed: 'Completada',
  reply: 'Responder',
  name: 'Nombre',
  dates: 'Fechas',
  status: 'Estado',
  enterprise: 'Empresa',
  customer: 'Cliente',
  actions: 'Acciones',
  population: 'Poblacion',
  to: 'hasta',
  resume_current: 'Resumen de últimas mediciones cultura actual',
  resume_pulse: 'Resumen de últimos Pulsos en Cultura',
  resume_required: 'Resumen de últimas mediciones cultura requerida'
}
