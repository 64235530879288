
export default {
  dashboard: 'Board',
  questionnaires: 'Questionnaires',
  open_questions: 'Open questions',
  required_culture: 'Required Culture',
  current_culture: 'Current Culture',
  culture_pulses: 'Culture Pulses',
  suite: 'Suite'
}
