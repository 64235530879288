
const required = '<br/><br/><b>Este campo es obligatorio.</b>'

export default {
  icon: 'mdi-help-circle',
  culture: {
    create: {
      name: {
        title: 'Nombre de la encuesta',
        text: `Sirve como medio de identificación para la encuesta. ${required}`
      },
      displayName: {
        title: 'Nombre externo para los encuestados',
        // tslint:disable-next-line:max-line-length
        text: 'En caso de ser seleccionado, este será el nombre mostrado a los colaboradores que vayan a realizar la encuesta.'
      },
      releaseAt: {
        title: 'Fecha de lanzamiento',
        text: `Establece la fecha de inicio de la encuesta. A partir de esta fecha, se enviaran los correos de participación y los colaboradores podrán responder la medición. ${required}`
      },
      deadlineAt: {
        title: 'Encuesta válida hasta',
        text: `Establece la fecha de cierre de la medición. Después del cierre, no se podrán responder más encuestas de esta medición. ${required}`
      },
      timezone: {
        title: 'Zona horaria',
        text: `Las fechas de lanzamiento, cierre y recordatorios se basarán en la zona horaria elegida. ${required}`
      },
      reminders: {
        title: 'Recordatorios',
        // tslint:disable-next-line:max-line-length
        text: 'Los recordatorios se enviaran vía correo electrónico a aquellos colaboradores que no hayan respondido hasta el momento. Se pueden configurar máximo 5 recordatorios.'
      },
      openQuestion: {
        title: 'Añadir preguntas adicionales',
        // tslint:disable-next-line:max-line-length
        text: 'Se permite agregar hasta tres preguntas de opción múltiple con única respuesta. Por cada pregunta puedes configurar hasta cinco opciones de respuesta. El colaborador al momento de responder, selecciona una sola de las opciones.'
      },
      subject: {
        title: 'Asunto del mensaje',
        text: 'Asunto que se utilizará en el correo electrónico enviado.'
      },
      body: {
        title: 'Cuerpo del mensaje',
        text: 'Contenido del correo electrónico enviado.'
      },
      thankMessage: {
        title: 'Mensaje de agradecimiento',
        // tslint:disable-next-line:max-line-length
        text: 'Este mensaje será enviado al correo electrónico del receptor que finalice satisfactoriamente el llenado de la encuesta.'
      },
      remember_load_update_collaborators: 'Recuerda cargar o actualizar los colaboradores antes de crear la encuesta.'
    }
  },
  employee: {
    create: {
      identifyDocument: {
        title: 'Documento de identificación',
        text: `Documento de identificación del empleado. Es necesario indicar el tipo de documento.`,
        required
      },
      firstName: {
        title: 'Nombre',
        text: `Los nombres del empleado.`,
        required
      },
      lastName: {
        title: 'Apellido',
        text: `Los apellidos del empleado.`,
        required
      },
      gender: {
        title: 'Género',
        text: `Género del empleado.`,
        required
      },
      birthdate: {
        title: 'Fecha de nacimiento',
        text: `Fecha de nacimiento del empleado.`,
        required
      },
      academicDegree: {
        title: 'Título académico',
        text: `Título académico del empleado.`,
        required
      },
      country: {
        title: 'País',
        text: `País del empleado.`,
        required
      },
      headquarter: {
        title: 'Sede',
        text: `Sede donde trabaja el empleado.`,
        required
      },
      email: {
        title: 'Correo electrónico',
        text: `Correo electrónico del empleado.`,
        required
      },
      phoneNumber: {
        title: 'Número de teléfono',
        text: `Número de teléfono del empleado.`,
        required
      },
      admission: {
        title: 'Fecha de ingreso',
        text: `Fecha en la cual fue contratado el empleado.`,
        required
      },
      jobType: {
        title: 'Tipo de contratación',
        text: `Tipo de contratación del empleado.`,
        required
      },
      department: {
        title: 'Área o departamento',
        text: `Área o departamento al cual pertenece el empleado.`,
        required
      },
      charge: {
        title: 'Cargo',
        text: `Cargo que desempeña el empleado dentro de la Organización.`,
        required
      }
    }
  },
  pulse: {
    create: {
      committal: {
        title: 'Añadir preguntas de compromiso',
        // tslint:disable-next-line:max-line-length
        text: 'Permite añadir de una a seis preguntas de compromiso a la encuesta. Esta acción es obligatoria en caso de no incluir preguntas de un cuestionario en la encuesta.'
      },
      questionnaire: {
        title: 'Añadir preguntas de un cuestionario',
        // tslint:disable-next-line:max-line-length
        text: 'Permite añadir las preguntas de una o más dimensiones pertenecientes a un cuestionario. Esta acción es obligatoria en caso de no incluir preguntas de compromiso en la encuesta.'
      },
      open: {
        title: 'Añadir preguntas abiertas adicionales',
        // tslint:disable-next-line:max-line-length
        text: 'Permite agregar hasta tres preguntas abiertas extras a la encuesta con un máximo de cinco opciones cada una.'
      },
      selection: {
        title: '¿A quién desea enviar esta encuesta?',
        // tslint:disable-next-line:max-line-length
        text: `Define la población objetivo de la medición. Puedes seleccionar entre: toda la organización o filtrar por cortes demográficos. ${required}`
      },
      currentCultureQuestions: {
        title: 'Utilizar cuestionario de Cultura Actual',
        text: 'Seleccione una medición de Cultura Actual previamente realizada, para utilizar las mismas preguntas.'
      }
    }
  },
  questionnaire: {
    create: {
      name: {
        title: 'Nombre',
        text: `Nombre que será asignado al cuestionario. ${required}`
      },
      base: {
        title: 'Cuestionario base',
        text: `Cuestionario del cual se hará una copia para generar el nuevo cuestionario. ${required}`
      },
      language: {
        title: 'Idiomas',
        text: `Idiomas en los que estará disponible el cuestionario. ${required}`
      },
      assignate: {
        title: 'Asignar cuestionario',
        text: 'Permite asignar el cuestionario a un sector empresarial específico o a una organización en particular.'
      }
    }
  },
  requiredCulture: {
    create: {
      name: {
        title: 'Nombre de la encuesta',
        text: `Sirve como medio de identificación para la encuesta. ${required}`
      },
      dateStart: {
        title: 'Fecha de inicio',
        text: `Establece la fecha de inicio del taller. ${required}`
      },
      dateEnd: {
        title: 'Encuesta válida hasta',
        text: `Esta será la fecha final para participar del taller.  Después de esta fecha no podrá haber más participaciones. 
Recuerda que podrás cerrar la medición manualmente desde la columna de acciones. ${required}`
      },
      timezone: {
        title: 'Zona horaria',
        text: `Las fechas de inicio y cierre, se basarán en la zona horaria elegida. ${required}`
      }
    },
    plan: {
      pulse_quantity: {
        title: 'Cantidad de Pulse a agregar',
        text: `Pulse que se agregarán a la empresa en la extensión del plan. ${required}`
      },
      engagement_quantity: {
        title: 'Cantidad de Engagement a agregar',
        text: `Engagement que se agregarán a la empresa en la extensión del plan. ${required}`
      },
      new: {
        title: 'Nuevo plan',
        text: `Plan que será asignado a la empresa. ${required}`
      },
      duration: {
        title: 'Duración',
        text: `Duración en meses para el nuevo plan. ${required}`
      }
    },
    massive: {
      file_input: {
        title: 'Archivo a cargar',
        // tslint:disable-next-line:max-line-length
        text: `Cargue un archivo excel (.xls/.xlsx) o csv (.csv) que contenga los datos de sus colaboradores. Esta acción actualizará su base total de colaboradores, por lo que debe agregar la base completa de colaboradores disponibles.<br/><br/>Recuerde utilizar la plantilla descargable que puede obtener al hacer click en el botón "DESCARGAR PLANTILLA". ${required}`
      }
    },
    group: {
      name: {
        title: 'Nombre del grupo',
        text: `Nombre con el que se identificará al grupo de empresas. ${required}`
      },
      leader: {
        title: 'Empresa líder',
        // tslint:disable-next-line:max-line-length
        text: 'La empresa líder (líder del grupo) tiene la capacidad de gestionar los planes de las empresas partenecientes a su grupo.'
      }
    }
  }
}
