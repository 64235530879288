
export default {
  'apology': 'We are sorry!',
  'not_found': 'The page you are looking for has not been found.',
  'forbidden': 'You are not authorized to access this information.',
  'back_home': 'Back to top',
  'wait': 'Wait!',
  // tslint:disable-next-line:max-line-length
  'auth/user-is-disabled': 'The user entered is disabled.  Please contact the administrator.',
  'auth/employee-no-exist': 'Employee not found.',
  'auth/demo-request-exist': 'There is already a demo account request with the requested email.',
  'validator/verify_fields': 'Please check the form fields.',
  'validator/invalid-input': 'One or more fields are not valid.',
  'validator/academicDegreeId-invalid-input': 'The "academic level" field is mandatory due to a demographic cut.',
  'validator/countryId-invalid-input': 'The "country" field is mandatory due to a demographic cut.',
  'validator/headquarterId-invalid-input': 'The "office" field is mandatory due to a demographic cut.',
  'validator/chargeId-invalid-input': 'The "job title" field is mandatory due to a demographic cut.',
  'validator/departmentId-invalid-input': 'The "area / department" field is mandatory due to a demographic cut.',
  'validator/genderId-invalid-input': 'The "gender" field is mandatory due to a demographic cut.',
  'validator/jobTypeId-invalid-input': 'The "type of contract" field is mandatory due to a demographic cut.',
  'validator/birthdate-invalid-input': 'The "date of birth" field is mandatory due to a demographic cut.',
  'validator/admission-invalid-input': 'The "start date" field is mandatory due to a demographic cut.',
  // tslint:disable-next-line:max-line-length
  'email/unique:User,email,user_id': 'The specified email is already in use.',
  'validator/reminders': 'You can only select five reminders.',
  'validator/delete_reminder': 'You cannot delete this reminder.',
  'undefined': 'An error has occurred in the system.',
  'timezone': 'The selected time is not valid for that time zone.',
  'question_emply': 'The question cannot be empty.',
  'validator/select_one': 'Please select at least one language.',
  'validator/empty-field': 'The field cannot be empty.',
  'validator/no_receivers': 'The survey must have at least one respondent.',
  'image/size': 'Image size must be less than 2MB.',
  'employees_limit': 'You have already reached the limit of employees allowed by your license.',
  'modal_identify_type': 'Please select a valid identity type.',
  'modal_identify_document': 'Please enter a valid identification number.',
  'modal_first_name': 'Please enter a valid name.',
  'modal_last_name': 'Please enter a valid last name.',
  'modal_exists_email': 'The email entered already exists.',
  'modal_user_not_employee': 'The email entered exists as another type of user',
  'modal_exist_identification': 'The ID number entered already exists.',
  'modal_email': 'Enter a valid email address.',
  'modal_gender': 'Please select a valid gender.',
  'modal_antiquity_range': 'Please enter a valid start date.',
  'modal_department': 'Please select a valid department.',
  'modal_charge': 'Please select a valid job title.',
  'modal_job_type': 'Please select a valid contract type.',
  'modal_age_range': 'Please enter a valid date of birth.',
  'modal_country': 'Please select a valid country.',
  'modal_headquarter': 'Please select a valid office.',
  'modal_academic_degree': 'Please select a valid academic level.',
  'modal_validation_error': 'There are records still to be corrected.',
  'email_already_taken': 'The email entered is already in use.',
  'video/size': 'The size of the video must be less than 20MB.',
  'error_disable_questionnaires': 'Not all questionnaires can be deactivated',
  'error_select_questionnaires': 'You must select a questionnaire.',
  'error_select_pulse_dimensions': 'You must select at least one (1) dimension.',
  'error_select_dates': 'You must select a valid time range.',
  'error_select_timezones': 'You must select a valid time zone.',
  'auth/enterprise_disabled': 'The requested company is inactive',
  'auth/employee_disabled': 'The requested employee is inactive',
  'auth/password-not-match': 'The current password is incorrect',
  'auth/email-not-match': 'The current email is wrong',
  'auth/email-already-registered': 'The new email is already assigned to another user',
  'no_balance': 'Insufficient balance',
  'required_enterprise_p1': 'To continue, you must select a company.',
  'required_enterprise_p2': 'You will be redirected to Suite shortly.',
  'not_calibrated': 'The required culture has not yet been calibrated by the Facilitator',
  'no_permissions': 'You do not have permission to enter this product',
  'ask_permissions': 'If you want to request activation of this product or have already requested it and you still do not have it available, please contact our team here and we will contact you.',
  'evaluation_resend_pending': 'This survey has not started',
  'evaluation_resend_completed': 'This survey has finished already',
  'uploadReminderError': 'An error occurred uploading the video for the reminder email; however, the survey has been created.',
  'uploadInvitationError': 'An error occurred uploading the video for the invitation email; however, the survey has been created.',
  'current-culture/not-found': 'The survey does not exist.',
  'current-culture/status-fail': 'This survey is not available to answer.',
  'current-culture/user-status-fail': 'This employee has already answered the survey.',
  'current-culture/user-disabled': 'This employee is disabled.',
  'current-culture/user-not-found': 'The employee does not exist.',
  'current-culture/invalid-token': 'No surveys for this access, please enter a valid one',
  'current-culture/idx-fail': 'An error has occurred loading the answer, please reload the page and try again.',
  'current-culture/check-process-fail': 'An error has occurred saving your changes, please reload the page and try again, if error persists please contact OCC Solutions',
  'current-culture/closed': 'This assessment has been closed',
  'culture-pulse/not-found': 'The Pulse does not exist.',
  'culture-pulse/status-fail': 'This Pulse is not available to answer.',
  'culture-pulse/user-status-fail': 'This employee has already answered the survey.',
  'culture-pulse/user-disabled': 'This employee is disabled.',
  'culture-pulse/user-not-found': 'The employee does not exist.',
  'culture-pulse/invalid-token': 'No surveys for this access, please enter a valid one',
  'culture-pulse/idx-fail': 'An error has occurred loading the answer, please reload the page and try again.',
  'culture-pulse/check-process-fail': 'An error has occurred saving your changes, please reload the page and try again, if error persists please contact OCC Solutions',
  'culture-pulse/closed': 'This assessment has been closed',
  'steps-overview/max-counter': 'The name cannot exceed the maximum number of characters',
  'suite-fail/not-found': 'There was an error communicating with suite, please try again',
  'suite-fail/not-found-retry': 'There was an error communicating with suite! Trying again...',
  'suite-fail/create-required-culture/spend-fail': 'There was an error processing your payment',
  'suite-fail/create-current-culture/spend-fail': 'There was an error processing your payment'
}
