
export default {
  no_data: 'No data is available for this demographic cut.',
  // tslint:disable-next-line:max-line-length
  master_references_section: 'This section is reserved for setting up demographic cuts. We recommend selecting them according to your needs, since the generation of high-value reports depends on this.',
  // tslint:disable-next-line:max-line-length
  master_references_action: 'These actions are sensitive. You are responsible for any misuse of a master reference.',
  // tslint:disable-next-line:max-line-length
  master_references_enable_des: 'The cut will be available again in the selection lists. Information can be registered or updated with this cut.',
  // tslint:disable-next-line:max-line-length
  master_references_disable_des: 'This cut will not be available in the selection lists until it is activated again. Information previously registered with this cut will not undergo any changes.',
  // tslint:disable-next-line:max-line-length
  master_references_delete_des: 'This cut will not be available in the selection lists and cannot be accessed in any way. Information previously registered with this cut will not undergo any changes.',
  actions: 'Actions',
  label: 'Name or label',
  description: 'Description.',
  no_translation: 'There is no translation for this language.',
  academic_degree: 'Academic title settings',
  academic_degree_read: 'Academic levels configured',
  academic_degrees: 'Academic degrees',
  academic_degree_info: 'Specify the academic levels that will be assigned to the employees of all companies',
  academic_degree_add: 'Add academic levels',
  academic_degree_enable: 'Enable academic level',
  academic_degree_disable: 'Disable academic level',
  academic_degree_delete: 'Delete academic level',
  language: 'Language settings',
  languages: 'Languages',
  language_info: 'Specify the languages ​​that will be assigned to employees and companies',
  language_add: 'Add language',
  language_enable: 'Enable languagea',
  language_disable: 'Disable language',
  language_delete: 'Delete language',
  department: 'Configuration of areas or departments of the company',
  departments: 'Areas or departments of the company',
  department_info: 'Assign all areas or departments in the organization',
  department_add: 'Add area or department',
  department_enable: 'Enable area or department',
  department_disable: 'Disable area or department',
  department_delete: 'Delete area or department',
  sector: 'Configuration of business sectors',
  sectors: 'Business sectors',
  sector_info: 'Assign all the business sectors that the system will handle',
  sector_add: 'Add sector',
  sector_enable: 'Enable sector',
  sector_disable: 'Disable sector',
  sector_delete: 'Delete sector',
  gender: 'Company genders configuration',
  genders: 'Company genders',
  gender_info: 'Assign all the genders that make up the organization',
  gender_add: 'Add gender',
  gender_enable: 'Enable gender',
  gender_disable: 'Disable gender',
  gender_delete: 'Delete gender',
  job_type: 'Configuration of the company\'s contract types',
  job_types: 'Company\'s contract type  ',
  job_type_info: 'Assign the types of contract in the organization',
  job_type_add: 'Add contract types',
  job_type_enable: 'Enable contract types',
  job_type_disable: 'Disable contract types',
  job_type_delete: 'Delete contract types',
  country: 'Country configurations',
  country_read: 'Countries configured.',
  countries: 'Countries.',
  culture_pulses: 'Culture Pulses',
  current_culture: 'Current Culture',
  country_info: 'Assign all countries that will be available in the system',
  country_add: 'Add countries',
  country_enable: 'Enable countries',
  country_disable: 'Disable countries',
  country_delete: 'Delete countries',
  enterprise_size: 'Configuration of company sizes.',
  enterprise_sizes: 'Company sizes.',
  enterprise_size_info: 'Assign all company sizes that will be available in the system',
  enterprise_size_add: 'Add company sizes',
  enterprise_size_enable: 'Enable company sizes',
  enterprise_size_disable: 'Disable company sizes',
  enterprise_size_delete: 'Delete company sizes',
  headquarters_title: 'Office configuration',
  headquarters_country: 'Country',
  headquarters_head: 'Office',
  headquarters_countries: 'Countries',
  headquarters_heads: 'Offices',
  headquarters_create: 'Create offices',
  headquarters_actions: 'Actions',
  headquarters_enable: 'Enable',
  headquarters_disable: 'Disable',
  headquarters_delete: 'Delete',
  headquarters_updated: 'Office updated successfully!',
  headquarters_deleted: 'Office removed!',
  headquarters_delete_title: 'Delete office',
  headquarters_toggle_true_title: 'Disable office',
  headquarters_toggle_false_title: 'Enable office',
  headquarters_add: 'Add new office',
  headquarters_created: 'Office created successfully!',
  charges_title: 'Job title level configuration ',
  charges_department: 'Department',
  charges_head: 'Job title',
  charges_departments: 'Departments',
  charges_heads: 'Job title',
  charges_create: 'Create job title',
  charges_actions: 'Actions',
  charges_enable: 'Enable',
  charges_disable: 'Disable',
  charges_delete: 'Delete',
  charges_updated: 'Job title updated successfully!',
  charges_deleted: 'Job title removed!',
  charges_delete_title: 'Remove job title',
  charges_toggle_true_title: 'Disable job title',
  charges_toggle_false_title: 'Enable job title',
  charges_add: 'Add new job title',
  charges_created: 'Job title created successfully!',
  antiquity_title: 'Seniority ranges',
  antiquity_subtitle: 'Available age ranges',
  ages_title: 'Age ranges',
  ages_subtitle: 'Available age ranges',
  tooltip_edit: 'You must press ENTER to edit',
  terms_and_conditions_settings: 'Settings of Terms and Conditions',
  terms_and_conditions: 'Terms and Conditions',
  terms_and_conditions_for_personal: 'Single Company customers',
  terms_and_conditions_for_commercial: 'Multi-company customers',
  products_title: 'Product Configuration',
  products_logo: 'Product Logo',
  products_conf: 'Product Configuration',
  products_description: 'Description: ',
  products_status: 'status',
  products_edit: 'edit product',
  products_modal_enable: 'Do you want to enable the product?',
  products_modal_disable: 'Do you want to disable the product?',
  products_modal_enable_des: 'Once enabled, the product will be available in the system',
  products_modal_disable_des: 'Once disabled, the product cannot be used in the system',
  products_edited: 'The product has been edited correctly',
  products_description_max_limit: '230 characters maximum',
  tokens_title: 'Token Price Settings',
  tokens_edit_price: 'edit price',
  tokens_edit_minrange: 'Minimum Range',
  tokens_edit_maxrange: 'Maximum Range',
  tokens_edit_price_amount: 'Price in USD',
  tokens_price_edited: 'The price has been edited correctly',
  service_edit: 'edit service'
}
