
export default {
  title: 'Instructions for OCC Solutions - Pulse: Bulk loading module.',
  title_body: 'The purpose of this instruction manual is to guide the end user, in the correct way to enter the bulk upload format in order to prevent errors in user data uploading.',
  demographic_items: 'Demographic cuts: ',
  identifyType: 'Type of identification document.',
  matchCaseValue: 'One of the values ​​stored in the application must be specified for this demographic cut, respecting uppercase and lowercase  letters.',
  example: 'Example:',
  identifyDocument: 'Identification document',
  matchCaseNumber: 'You must enter only numbers, without letters, spaces or special characters.',
  firstName: 'Name',
  matchCaseText: 'Only letters or spaces must be entered, without numbers or special characters.',
  lastName: 'Last name',
  email: 'Email',
  matchCaseEmail: 'Valid email must be specified, cannot be repeated. Or you can leave this field empty.',
  gender: 'Gender',
  age: 'Age range (Date of birth)',
  antiquity: 'Seniority Range (Entry Date)',
  matchCaseDate: 'A valid date must be entered in yyyy / mm / dd or yyyy-mm-dd format, without spaces or letters',
  departments: 'Department / Area',
  charge: 'Job title',
  charge_dependent_info: 'The positions with departments identified in parentheses "()", can only be assigned to employees who belong to said department.',
  jobTypes: 'Contract Type',
  academicDegree: 'Academic Degree',
  country: 'Country',
  headquarter: 'Office location',
  headquarter_dependent_info: 'The offices with countries that are identified in parentheses "()", can only be assigned to employees that belong to said country.',
  importDataToExcelTitle: 'Import data from CSV file to Excel Document.',
  step1: 'Step 1',
  step1_desc: 'Open Excel.',
  step2: 'Step 2',
  step2_desc: 'Select the Blank Book option',
  step3: 'Step 3',
  step3_desc: 'Go to the Data tab and in the Obtain external data section, select the option From a Text File.',
  step4: 'Step 4',
  step4_desc: 'Select the CSV data file you want to import. and then press the Import button.',
  step5: 'Step 5',
  step5_desc: 'At this point the configuration that will be used to import the data file is specified.  It must be indicated that the values ​​are Delimited, and that the Unicode encoding uft- 8, will be used as shown in the image below. This for the correct conversion of words with a written accent. Then the button Next must be pressed.',
  step6: 'Step 6',
  step6_desc: 'At this point you must indicate which character will be used for the separation of values.  You must indicate the comma as shown in the following image. Then you must press the Finish button.',
  step7: 'Step 7',
  step7_desc: 'You will then be asked if this data will be imported into the existing sheet or into a new sheet. By default, import into the existing sheet will be selected,  if not, select this option and press OK.',
  step8_desc: 'Finally the data will appear correctly separated and the words with a written accent will be imported correctly.',
  availableItems: 'Available values:'
}
