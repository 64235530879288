
<template>
  <div style="display:inline;">
    <v-btn large
      color="primary"
      :disabled="disableBtn"
      :loading="loading"
      @click="getThreadData"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('reports.download_report') }}
    </v-btn>

    <img
      src="/img/20200302_x2_occ_cultura_logo.png"
      id="occCultureLogo"
      class="d-none"
    />
    <img
      src="/img/portada-cultura.jpg"
      id="occCultureCover"
      class="d-none"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import is from 'is_js'
import CurrentCultureService from '../../services/current-culture-assessment'

import pdfUtil from './utils/pdf'

import cover from './mixins-summary/01-cover'
import participation from './mixins-summary/02-participation'
import dimensions from './mixins-summary/03-dimensions'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  name: 'current-culture-summary-report',
  mixins: [
    cover,
    participation,
    dimensions
  ],
  props: {
    disableBtn: Boolean,
    pollId: String,
    threadId: String
  },
  data () {
    return {
      downloadPdf: true,
      loading: false,
      threadData: {},
      currentPoll: {},
      // Images
      cultureLogoSrc: null,
      cultureCoverSrc: null,
      cultureLogoBase64: null,
      cultureCoverBase64: null
    }
  },
  mounted () {
    this.cultureLogoSrc = document.getElementById('occCultureLogo').src
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  watch: {
    cultureLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureLogoSrc, (dataURL) => {
          this.cultureLogoBase64 = dataURL
        })
      }
    },
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    }
  },
  methods: {
    getThreadData () {
      this.$store.dispatch('loading/show')
      this.loading = true
      CurrentCultureService.getOneReportByThreadId(this.threadId)
        .then((res) => {
          this.threadData = res.data
          this.currentPoll = res.data.current
          this.totalReceivers = res.data.population
          this.totalObtained = res.data.answers
          this.totalItems = res.data.totalItems
          this.enterpriseItems = res.data.enterpriseItems
          this.renderPdf()
        })
        .catch((err) => {
          console.log(err)
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.$store.dispatch('loading/hide')
          this.loading = false
        })
    },
    renderPdf () {
      this.$store.dispatch('loading/show')
      this.loading = true
      // this.$emit('render-pdf')

      const configuration = {
        pageSize: 'A4',
        pageMargins: [40, 70, 40, 40],
        info: {
          title: this.$t('summaryReport.title'),
          author: 'OCC',
          subject: this.$t('summaryReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: (currentPage, pageCount, pageSize) => {
          const resultObj = {
            image: this.cultureLogoBase64,
            fit: [110, 200],
            margin: [17, 14, 0, 0]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage, pageCount) => {
          if (currentPage === 1) return [{}]
          return [
            {
              margin: [0, 5, 0, 0],
              columns: pdfUtil.generateCenteredText(this.$t('cultureReports.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, -10, 37, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage, pageSize) => {
          //
        },
        content: [
          // Cover
          this.$generateCover(),
          // Participation by Demographic Cut
          this.$generateParticipationTables(),
          // Dimensions Results by Demographic Cut
          this.$generateDimensionsTables()
        ]
      }

      if (this.downloadPdf) {
        const reportName = `${this.$t('summaryReport.title')} - ${this.currentPoll.name}.pdf`
        if (is.edge() || is.ie()) {
          const pdfDocGenerator = pdfMake.createPdf(configuration)
          pdfDocGenerator.getBlob((blob) => {
            window.navigator.msSaveBlob(blob, reportName)
            this.finishPdfRender()
          })
        } else {
          pdfmake.createPdf(configuration).download(reportName, () => {
            this.finishPdfRender()
          })
        }
      } else {
        this.finishPdfRender()
      }
    },
    finishPdfRender () {
      this.$store.dispatch('loading/hide')
      this.loading = false
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    }
  }
}
</script>
