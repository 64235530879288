
import pdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      //
    }
  },
  methods: {
    buildDimensionsByDemographicTable (label, rows = []) {
      const table = [{
        margin: [5, 14, 5, 0],
        table: pdfUtil.generateTable(
          [ '24%', '7%', '10%', '7%', '7%', '7%', '10%', '7%', '7%', '7%', '7%' ],
          [ ...rows ],
          [
            label,
            this.$t('questionnaires.dimensions.values'),
            this.$t('questionnaires.dimensions.sustainability'),
            this.$t('questionnaires.dimensions.customers'),
            this.$t('questionnaires.dimensions.talent'),
            this.$t('questionnaires.dimensions.relationship'),
            this.$t('questionnaires.dimensions.communication'),
            this.$t('questionnaires.dimensions.innovation'),
            this.$t('questionnaires.dimensions.achievement'),
            this.$t('questionnaires.dimensions.leadership'),
            'TOTAL'
          ],
          label,
          9// Headers fontSize
        ),
        layout: pdfUtil.setLayout()
      }]

      return table
    },
    $generateDimensionsTables () {
      const tables = []
      const averages = this.threadData.dimensionsAverages
      Object.keys(averages).forEach(key => {
        const found = this.enterpriseItems.find(x => x.code === this.keys[key])
        if (found) {
          const translation = found.translations.find(x => x.lang === this.user.lang)
          const label = translation ? translation.label : '--'
          const rows = []
          Object.keys(averages[key]).forEach(id => {
            let name
            if (['age', 'antiquity'].includes(key)) {
              name = this.$t(`followupReport.${key}.${id}`)
            } else {
              if (key === 'headquarter') {
                const hq = this.totalItems[key].find(h => parseInt(h.id) === parseInt(id))
                name = hq ? hq.name : '--'
              } else {
                const t = averages[key][id].translations.find(x => x.lang === this.user.lang)
                name = t ? t.label : '--'
              }
            }
            const columns = [{ text: name, fontSize: 9 }]
            const dimAvgs = Object.values(averages[key][id].dimensions)
            dimAvgs.forEach(dim => {
              columns.push({
                text: pdfUtil.round(dim),
                alignment: 'center',
                fontSize: 10
              })
            })
            columns.push({
              text: pdfUtil.round(dimAvgs.reduce((a, b) => a + b, 0) / dimAvgs.length),
              alignment: 'center',
              fontSize: 10,
              bold: true
            })
            rows.push(columns)
          })
          tables.push(this.buildDimensionsByDemographicTable(label, rows))
        }
      })

      return [
        {
          text: '',
          pageBreak: 'before',
          pageOrientation: 'landscape'
        },
        pdfUtil.generateTitle(`${this.$t('cultureReports.results')}`, '', 20, [0, 5, 0, 0]),
        ...tables
      ]
    }
  }
}
