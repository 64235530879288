
export default {
  groups: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    getOneByLeader: ['customer', 'enterprise_admin'],
    edit: ['admin'],
    profile: ['customer', 'enterprise_admin'],
    applyUpdatePlan: ['admin'],
    rejectUpdatePlan: ['admin'],
    applyExtendPlan: ['admin'],
    rejectExtendPlan: ['admin']
  },
  enterprises: {
    list: ['admin', 'customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    profile: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin', 'customer', 'enterprise_admin']
  },
  employees: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin.customer', 'customer', 'enterprise_admin'],
    massiveUpload: ['admin.customer', 'customer', 'enterprise_admin']
  },
  questionnairesCulture: {
    list: ['admin'],
    create: ['admin'],
    edit: ['admin']
  },
  openQuestionsCulture: {
    list: ['admin'],
    create: ['admin'],
    edit: ['admin']
  },
  currentCulture: {
    create: ['customer', 'enterprise_admin'],
    list: ['customer', 'enterprise_admin'],
    fill: ['employee']
  },
  requiredCulture: {
    create: ['customer', 'enterprise_admin'],
    list: ['customer', 'enterprise_admin'],
    fill: ['employee']
  },
  culturePulses: {
    create: ['customer', 'enterprise_admin'],
    list: ['customer', 'enterprise_admin'],
    fill: ['employee']
  },
  genders: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  headquarters: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  jobTypes: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  countries: {
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin']
  },
  departments: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  charges: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin']
  },
  academicDegrees: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin']
  },
  sectors: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  languages: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  enterpriseSizes: {
    list: ['admin'],
    settings: ['admin']
  },
  committalQuestions: {
    list: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin'],
    settings: ['admin']
  },
  wordQuestions: {
    list: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  identifyTypes: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin']
  },
  products: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  productServices: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAccounts: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAccountDetails: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    buy: ['customer', 'enterprise_admin'],
    move: ['admin', 'customer', 'enterprise_admin'],
    spend: ['customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  tokenAuthorization: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin', 'customer', 'enterprise_admin']
  },
  tokenPrice: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  },
  terms: {
    settings: ['admin'],
    getOne: ['admin'],
    create: ['admin']
  },
  customers: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin', 'customer', 'enterprise_admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin']
  }
}
