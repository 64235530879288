
import CurrentCultureService from '../../../services/current-culture-assessment'
import ResponseRateUtils from '../utils/response-rate'

export default {
  methods: {
    // OBTENCIÓN DE DATOS INICIALES
    $getCalcData (lang) {
      return CurrentCultureService.getOneReportByThreadId(this.threadId)
        .then((res) => {
          this.currentPoll = res.data.current
          this.answerRates = res.data.structures.answerRates
          this.listOfDemographics = res.data.listOfDemographics
          this.previousPoll = res.data.previous
          this.fitsExtraPage = res.data.fitsExtraPage || 0
          this.answersRateTables = ResponseRateUtils(this.answerRates, this, lang, true)
          this.$getTotalParticipants(res.data.answers, res.data.population)
          this.currentRequired = res.data.requiredStructure
          this.$getPollResults(res.data.structures)
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.disableButton = true
          this.lockPdfButton = false
        })
    },
    // OBTENCIÓN DE PARTICIPANTES EN TOTAL
    $getTotalParticipants (answers, population) {
      this.totalObtained = answers
      this.totalReceived = population
      this.totalParticipantsPercent = this.$round((this.totalObtained / this.totalReceived) * 100)
    },
    $getPollResults (data) {
      this.questionDataByAttributes = data.questionDataByAttributes
      this.openQuestions = data.openQuestions
      this.answersOpenQuestionsPopulation = data.answersOpenQuestionsPopulation
      this.averagePerCultureTypes = data.averagePerCultureTypes
      this.averagePerDimensions = data.averagePerDimensions
      this.averagePerCultureTypesTotal = data.averagePerCultureTypesTotal
      this.answerValuesByDimensions = data.answerValuesByDimensions
      this.answerValuesByAtributtes = data.answerValuesByAtributtes
      this.lowestScoresByDimensions = data.lowestScoresByDimensions
      this.highestScoresByAttributes = data.highestScoresByAttributes
      this.hasPrevious = Boolean(this.previousPoll)
      this.previousAveragePerCultureTypes = data.previousAveragePerCultureTypes
      this.previousAveragePerDimensions = data.previousAveragePerDimensions
      this.previousAveragePerCultureTypesTotal = data.previousAveragePerCultureTypesTotal
      this.highestScoresByDimensions = data.highestScoresByDimensions
      this.lowestScoresByAttributes = data.lowestScoresByAttributes
      this.lowestDispersionByAttributes = data.lowestDispersionByAttributes
      this.highestDispersionByAttributes = data.highestDispersionByAttributes
      this.lowestTendenciesByAttributes = data.lowestTendenciesByAttributes
      this.highestTendenciesByAttributes = data.highestTendenciesByAttributes
      this.sortedquestionDataByAttributes = data.sortedquestionDataByAttributes
      this.sortedAveragePerDimensions = data.sortedAveragePerDimensions
      this.hasRequired = data.hasRequired
    },
    // REDONDEADOR
    $round (num) {
      let result = (Math.round(num * 10) / 10).toFixed(1)
      if (result === '0.0') result = '0'
      if (result === '100.0') result = '100'
      return result
    },
    round (value, decimals) {
      if (isNaN(Number(value))) return ''
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    setAverageArrayToObject () {
      const forEachElement = (obj, fieldNum) => {
        fieldNum = fieldNum || 'average'
        return element => {
          obj[element.name] = this.round(element[fieldNum], 2)
        }
      }

      this.averagePerDimensions.forEach(forEachElement(this.averagePerDimensionsObj))
      if (this.hasPrevious) {
        this.previousAveragePerDimensions.forEach(forEachElement(this.previousAveragePerDimensionsObj))
      }
      if (this.hasRequired) {
        this.dimensionAverage.forEach(forEachElement(this.dimensionAverageObj, 'score'))
      }
    }
  }
}
