
export default {
  list_title: 'Cuestionarios',
  list_add_questionnaire: 'Añadir Cuestionario',
  list_questionnaires: 'Lista de Cuestionarios',
  edit_title: 'Edición de cuestionario',
  edit_questions: 'Editar preguntas',
  edit_open_dimension: 'Abre cada una de las dimensiones para editar las preguntas correspondientes',
  create_title: 'Añadir cuestionario',
  dimensions: {
    values: 'Principios',
    sustainability: 'Sostenibilidad',
    customers: 'Clientes',
    talent: 'Talento',
    relationship: 'Relaciones',
    communication: 'Comunicación',
    innovation: 'Innovación',
    achievement: 'Logro',
    leadership: 'Liderazgo'
  },
  name: 'Nombre',
  last_modification: 'Ultima modificación',
  status: 'Estado',
  actions: 'Acciones',
  limiting: 'limitante',
  positive: 'positivo',
  questionnaires: 'Cuestionarios',
  create: 'Crear cuestionario',
  edit: 'Editar cuestionario',
  no_data: 'No hay cuestionarios para mostrar',
  as_person: 'Como persona',
  management: 'Desarrollo',
  mobilization: 'Movilización',
  achievement: 'Logro',
  tooltip_edit: 'Debe presionar ENTER para editar',
  customer: 'Cliente',
  enterprise: 'Empresa',
  sector: 'Sector',
  assignation_type: 'Tipo de asignación',
  assignation_to: 'Asignar a',
  base: 'Cuestionario base',
  question_questionnaire_edit: 'Editar preguntas del cuestionario',
  list_create: 'Crear',
  list_modal_enable: '¿Desea habilitar el cuestionario?',
  list_modal_enable_des: 'Una vez habilitado, el cuestionario nuevamente podrá ser seleccionado para la creación de mediciones por los clientes que lo disponen',
  list_modal_disable: '¿Desea inhabilitar el cuestionario?',
  list_modal_disable_des: 'Una vez inhabilitado, los clientes que disponen de este cuestionario no podrán elegir el cuestionario para crear una medición',
  list_toggle_status_error_disabled: 'Error al desactivar el cuestionario',
  list_toggle_status_error_enable: 'Error al activar el cuestionario',
  list_toggle_status_error_by_limit: 'No pueden desactivarse todos los cuestionarios',
  edit_questionnaire_success: 'Cuestionario editado correctamente',
  edit_questionnaire_error: 'Error en la edición del cuestionario'
}
