
import dataObj from '../utils/data-obj'
import pdf from '../utils/pdf'
export default {
  methods: {
    $generateRankings () {
      const noTendMsg = (this.hasPrevious ? '' : this.$t('requiredActualReport.no_tend_msg'))
      return [
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.5 ${this.$t('cultureReports.ranking_of_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 6], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.dimensions_with_higher_score').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 15, 0, 0]
        },
        {
          table: {
            color: 'white',
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t(`cultureReports.dimension`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.current_population`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.preview_population`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.rate`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[2].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.highestScoresByDimensions[2].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.highestScoresByDimensions[2].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.highestScoresByDimensions[2].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByDimensions[0].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).average).toFixed(2) : 0) }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[1].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.highestScoresByDimensions[1].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.highestScoresByDimensions[1].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.highestScoresByDimensions[1].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByDimensions[1].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[1].name).average).toFixed(2) : 0) }
              ],
              [{ text: this.$t(`cultureReports.${this.highestScoresByDimensions[0].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.highestScoresByDimensions[0].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.highestScoresByDimensions[0].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.highestScoresByDimensions[0].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[0].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByDimensions[2].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.highestScoresByDimensions[2].name).average).toFixed(2) : 0) }
              ]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [0, 35, 25, 0]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.dimensions_with_lower_score').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          table: {
            color: 'white',
            widths: ['40%', '20%', '20%', '20%'],
            body: [
              [{ text: this.$t(`cultureReports.dimension`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.current_population`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.preview_population`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.$t(`cultureReports.rate`).toUpperCase(), color: '#fff', margin: [0, 10, 0, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[0].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.lowestScoresByDimensions[0].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.lowestScoresByDimensions[0].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.lowestScoresByDimensions[0].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByDimensions[0].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[0].name).average).toFixed(2) : 0) }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[1].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.lowestScoresByDimensions[1].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.lowestScoresByDimensions[1].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.lowestScoresByDimensions[1].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByDimensions[1].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[1].name).average).toFixed(2) : 0) }
              ],
              [{ text: this.$t(`cultureReports.${this.lowestScoresByDimensions[2].name}`), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'left' },
                { text: this.lowestScoresByDimensions[2].average.toFixed(2), color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: this.hasPrevious ? dataObj.arrayFindByNameFixed(this.previousAveragePerDimensions, this.lowestScoresByDimensions[2].name, 'average', '--') : '--', color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center' },
                { text: `${this.hasPrevious ? (this.lowestScoresByDimensions[2].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).average).toFixed(2) : '--'}`, color: '#839291', margin: [10, 10, 10, 10], border: [false, false, false, true], borderColor: ['', '', '', '#ddd'], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByDimensions[2].average - this.previousAveragePerDimensions.find(dimension => dimension.name === this.lowestScoresByDimensions[2].name).average).toFixed(2) : 0) }
              ]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [0, 35, 25, 0],
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.attributes_with_higher_score').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.limitant_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.positive_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.rate').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } }
              ],
              [{ text: '1', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[7] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[7] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[7] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[7] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[7] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[7] || {}).tendencies : 0) }
              ],
              [{ text: '2', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[6] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[6] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[6] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[6] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[6] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[6] || {}).tendencies : 0) }
              ],
              [{ text: '3', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[5] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[5] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[5] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[5] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[5] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[5] || {}).tendencies : 0) }
              ],
              [{ text: '4', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[4] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[4] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[4] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[4] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[4] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[4] || {}).tendencies : 0) }
              ],
              [{ text: '5', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[3] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[3] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[3] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[3] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[3] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[3] || {}).tendencies : 0) }
              ],
              [{ text: '6', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[2] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[2] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[2] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[2] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[2] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[2] || {}).tendencies : 0) }
              ],
              [{ text: '7', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[1] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[1] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[1] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[1] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[1] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[1] || {}).tendencies : 0) }
              ],
              [{ text: '8', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestScoresByAttributes[0] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestScoresByAttributes[0] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestScoresByAttributes[0] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestScoresByAttributes[0] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.highestScoresByAttributes[0] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.highestScoresByAttributes[0] || {}).tendencies : 0) }
              ]
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.6 ${this.$t('cultureReports.ranking_of_the_attributes')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.attributes_with_lower_score').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.limitant_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.positive_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.rate').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } }
              ],
              [{ text: '1', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[0] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[0] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[0] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[0] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[0] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[0] || {}).tendencies : 0) }
              ],
              [{ text: '2', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[1] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[1] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[1] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[1] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[1] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[1] || {}).tendencies : 0) }
              ],
              [{ text: '3', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[2] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[2] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[2] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[2] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[2] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[2] || {}).tendencies : 0) }
              ],
              [{ text: '4', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[3] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[3] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[3] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[3] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[3] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[3] || {}).tendencies : 0) }
              ],
              [{ text: '5', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[4] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[4] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[4] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[4] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[4] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[4] || {}).tendencies : 0) }
              ],
              [{ text: '6', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[5] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[5] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[5] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[5] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[5] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[5] || {}).tendencies : 0) }
              ],
              [{ text: '7', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[6] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[6] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[6] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[6] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[6] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[6] || {}).tendencies : 0) }
              ],
              [{ text: '8', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestScoresByAttributes[7] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestScoresByAttributes[7] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestScoresByAttributes[7] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestScoresByAttributes[7] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.hasPrevious ? this.round((this.lowestScoresByAttributes[7] || {}).tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (this.lowestScoresByAttributes[7] || {}).tendencies : 0) }
              ]
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [485],
            body: [
              [{ text: `4.7 ${this.$t('cultureReports.ranking_of_the_attributes_dispersion')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.attributes_with_higher_dispersion').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.limitant_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.positive_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dispersion').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: '1', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[7] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[7] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[7] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[7] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[7] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '2', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[6] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[6] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[6] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[6] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[6] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '3', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[5] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[5] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[5] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[5] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[5] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '4', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[4] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[4] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[4] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[4] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[4] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '5', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[3] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[3] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[3] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[3] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[3] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '6', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[2] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[2] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[2] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[2] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[2] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '7', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[1] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[1] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[1] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[1] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[1] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '8', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.highestDispersionByAttributes[0] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.highestDispersionByAttributes[0] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.highestDispersionByAttributes[0] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.highestDispersionByAttributes[0] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.highestDispersionByAttributes[0] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ]
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [485],
            body: [
              [{ text: `4.7 ${this.$t('cultureReports.ranking_of_the_attributes_dispersion')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.attributes_with_lower_dispersion').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.limitant_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.positive_attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dispersion').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } }
              ],
              [{ text: '1', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[0] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[0] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[0] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[0] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[0] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '2', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[1] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[1] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[1] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[1] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[1] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '3', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[2] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[2] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[2] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[2] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[2] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '4', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[3] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[3] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[3] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[3] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[3] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '5', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[4] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[4] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[4] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[4] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[4] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '6', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[5] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[5] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[5] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[5] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[5] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '7', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[6] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[6] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[6] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[6] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[6] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ],
              [{ text: '8', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                { text: (this.lowestDispersionByAttributes[7] || {}).limiting[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: (this.lowestDispersionByAttributes[7] || {}).positive[this.user.lang], color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 5, 5, 8] },
                { text: this.$t(`cultureReports.${(this.lowestDispersionByAttributes[7] || {}).dimensionsName}_code`), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                { text: this.round((this.lowestDispersionByAttributes[7] || {}).actScore, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                { text: this.round((this.lowestDispersionByAttributes[7] || {}).dispersion, 2), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' }
              ]
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10,
          pageBreak: 'after'
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.8 ${this.$t('cultureReports.ranking_of_rates')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.highest_positive_rate_attribute').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.current_population').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.preview_population').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.change_value').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } }
              ],
              ...[5, 4, 3, 2, 1, 0].map((num) => {
                return [
                  { text: this.highestTendenciesByAttributes[num] ? (6 - num) : '', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                  { text: this.highestTendenciesByAttributes[num] ? this.highestTendenciesByAttributes[num].positive[this.user.lang] : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 0, 5], alignment: this.highestTendenciesByAttributes[num] ? 'left' : 'center' },
                  { text: this.highestTendenciesByAttributes[num] ? this.$t(`cultureReports.${this.highestTendenciesByAttributes[num].dimensionsName}_code`) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                  { text: this.highestTendenciesByAttributes[num] ? this.round(this.highestTendenciesByAttributes[num].actScore, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                  { text: this.highestTendenciesByAttributes[num] ? this.round(this.highestTendenciesByAttributes[num].previousAvg, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                  { text: this.highestTendenciesByAttributes[num] ? this.round(this.highestTendenciesByAttributes[num].tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency((this.highestTendenciesByAttributes[num] || {}).tendencies) }
                ]
              })
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10
        },
        {
          table: {
            body: [
              [{ text: noTendMsg.toUpperCase(), bold: true, margin: [50, 8, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, -15, 0, 0]
        },
        {
          pageBreak: 'before',
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.8 ${this.$t('cultureReports.ranking_of_rates')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 35, 0, 8]
        },
        {
          table: {
            color: 'white',
            widths: [459],
            body: [
              [{ text: this.$t('cultureReports.highest_negative_rate_attribute').toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [36, 10, 0, 0]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#a6a6a6' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [{ text: '', margin: [0, 0, 0, 0] },
                { text: this.$t('cultureReports.attribute').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.dimension').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.current_population').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.preview_population').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.change_value').toUpperCase(), alignment: 'center', margin: [0, 5, 0, 5], bold: true, style: { color: '#fff' } }
              ],
              ...[0, 1, 2, 3, 4, 5].map((num) => {
                return [
                  { text: this.lowestTendenciesByAttributes[num] ? (num + 1) : '', margin: [0, 15, 0, 0], bold: true, style: { color: '#839291' } },
                  { text: this.lowestTendenciesByAttributes[num] ? this.lowestTendenciesByAttributes[num].positive[this.user.lang] : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 0, 5], alignment: this.lowestTendenciesByAttributes[num] ? 'left' : 'center' },
                  { text: this.lowestTendenciesByAttributes[num] ? this.$t(`cultureReports.${this.lowestTendenciesByAttributes[num].dimensionsName}_code`) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], alignment: 'center', margin: [0, 5, 0, 5] },
                  { text: this.lowestTendenciesByAttributes[num] ? this.round(this.lowestTendenciesByAttributes[num].actScore, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                  { text: this.lowestTendenciesByAttributes[num] ? this.round(this.lowestTendenciesByAttributes[num].previousAvg, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center' },
                  { text: this.lowestTendenciesByAttributes[num] ? this.round(this.lowestTendenciesByAttributes[num].tendencies, 2) : '--', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 5, 5, 5], alignment: 'center', ...pdf.styleTendency((this.lowestTendenciesByAttributes[num] || {}).tendencies) }
                ]
              })
            ]
          },
          margin: [0, 15, 20, 25],
          border: [false, false, false, true],
          fontSize: 10
        },
        {
          table: {
            body: [
              [{ text: noTendMsg.toUpperCase(), bold: true, margin: [50, 8, 20, 5], alignment: 'left' }]
            ]
          },
          layout: {
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, -15, 0, 0],
          pageBreak: 'after'
        }
      ]
    }
  }
}
