<template>
  <v-container fluid class="technical-requirements">
    <v-row>
      <v-col>
        <h5 class="headline grey--text">{{ $t('enterprises.requirements_title') }}</h5>
        <p class="mt-4 mb-0 body-2 grey--text text--darken-3">
          <v-icon class="mb-1" color="warning">mdi-alert</v-icon>
          {{ $t('enterprises.requirements_subtitle') }}
        </p>
      </v-col>
    </v-row>
    <v-divider class="mt-1 mb-3"></v-divider>
    <v-row justify="center">
      <v-col v-for="i in [1,2,3]" :key="i" cols="12" sm="6" md="4">
        <v-card outlined :class="['red', `lighten-${3 + (i - 1)}`]" class="info-card">
          <v-card-text>
            <h5 class="mb-3">{{ $t(`enterprises.requirements_title_${i}`) }}</h5>
            <p style="line-height: 18px;font-family: Helvetica, Arial, sans-serif;font-size: 14px;">
              {{ $t(`enterprises.requirements_title_list_${i}`) }}
            </p>
            <ul style="list-style: none;line-height: 20px;" class="blue--text pl-3">
              <li v-for="(text, j) in $t(`enterprises.requirements_title_listitems_${i}`)" :key="j">
                {{ text }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" >
        <v-card outlined class="grey--text" style="border: 1px solid darkgrey;">
          <v-card-text class="align-center">
            <h5>{{ $t('enterprises.requirements_title_4') }}</h5>
          </v-card-text>
          <v-row>
            <v-col
              v-for="(navigator, k) in $t('enterprises.requirements_title_listitems_4')"
              :key="k"
              class="text-center"
            >
              <img :src="`/img/${iconIsNavigator(navigator)}`"
                alt="navigator"
                style="width: 40px;"><br/>
                {{ navigator }}
              <!-- <small class="m-0" v-if="navigator.includes('Explorer')">IE11+</small> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    iconIsNavigator (text) {
      let navigator = ''
      if (text.includes('Chrome')) {
        navigator = 'chrome.png'
      } else if (text.includes('Firefox')) {
        navigator = 'firefox.png'
      } else if (text.includes('Opera')) {
        navigator = 'opera.png'
      // } else if (text.includes('Explorer')) {
      //   navigator = 'ie.png'
      } else if (text.includes('Edge')) {
        navigator = 'edge.png'
      }
      return navigator
    }
  }
}
</script>
<style>
.technical-requirements .info-card {
  min-height: 15rem;
}
.technical-requirements .v-card__title {
  word-break: break-word;
}
@media screen and (max-width: 1470px) {
  .technical-requirements .info-card {
    min-height: 18rem;
  }
}
@media screen and (max-width: 500px) {
  .technical-requirements .info-card {
    min-height: 19rem;
  }
}
@media screen and (max-width: 330px) {
  .technical-requirements .info-card {
    min-height: 17rem;
  }
}
</style>
