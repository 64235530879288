
export default {
  insert_document: 'Enter your identification document number',
  verify: 'Check surveys',
  polls_list: 'Available surveys',
  pending_polls_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Ending', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  fill_poll: 'Fill out survey',
  back_to_verify_polls: 'Return to survey verification'
}
