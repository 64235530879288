<template>
  <v-row justify="center">
    <template v-if="info.length">
      <v-col align="center" xs="12" sm="4" v-for="(activity, k) in info" :key="k">
        <v-card>
          <v-toolbar dense class="mb-3">
            <v-toolbar-title class="text-capitalize center--text">{{activity.evaluation.displayName || activity.evaluation.name}}</v-toolbar-title>
          </v-toolbar>
          <v-hover
            v-slot:default="{ hover }"
          >
            <v-row>
              <v-col cols="12" align="center">
                <v-progress-circular
                  :rotate="360"
                  :size="120"
                  :width="15"
                  :value="Math.floor(activity.score.percent)"
                  color="primary"
                  class="around"
                  @click="$router.push(`/evaluations/actual/${activity.token}`)"
                >
                  <span class="justify-center minipercentage" v-if="!hover"> {{ Math.floor(activity.score.percent) }}% </span>
                  <p v-else>
                    <small color="rgb(56, 153, 218)" v-if="activity.score.percent > 0 && activity.score.percent < 100"> {{$t('dashboard.continue')}} <br> </small>
                    <small color="rgb(56, 153, 218)" v-else-if="activity.score.percent === 0"> {{$t('dashboard.start')}} <br> </small>
                    <small color="rgb(56, 153, 218)" v-else> {{$t('dashboard.completed')}} <br> </small>
                    <v-icon size="35" color="rgb(56, 153, 218)" v-if="activity.score.percent < 100">play_arrow</v-icon>
                    <v-icon size="35" color="rgb(56, 153, 218)" v-else>fa-check</v-icon>
                  </p>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-hover>
          <v-row>
            <v-col class="px-8">
              <v-btn
                color="primary"
                @click="$router.push(`/evaluations/actual/${activity.token}`)"
                block
              >
                {{ $t('dashboard.reply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
    <v-col cols="12" align="centered" class="py-10" v-else>
      <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
        {{ $t('dashboard.no_pending_activities') }}
      </h1>
    </v-col>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {}
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}
.no-decoration{
  text-decoration: none;
}
.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(56, 153, 218);
    box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
