
const required = '<br/><br/><b>This field is required.</b>'

export default {
  icon: 'mdi-help-circle',
  culture: {
    create: {
      name: {
        title: 'Survey Name',
        text: `It serves as a means of identification for the survey. ${required}`
      },
      displayName: {
        title: 'External name for respondents',
        // tslint:disable-next-line:max-line-length
        text: 'In case of being selected, this will be the name shown to the collaborators who are going to carry out the survey.'
      },
      releaseAt: {
        title: 'Release date',
        text: `It shows the date from which you can participate in the survey.  ${required}`
      },
      deadlineAt: {
        title: 'Survey valid until',
        text: `It shows the date until which you can participate in the survey. ${required}`
      },
      timezone: {
        title: 'Time zone',
        text: `Release, validity and reminder dates will be based on the time zone selected. ${required}`
      },
      reminders: {
        title: 'Reminders',
        // tslint:disable-next-line:max-line-length
        text: 'Each reminder sends an email to all survey respondents on the specified date. In addition, the delivery date of the reminder must be included between the survey release and validity dates.'
      },
      openQuestion: {
        title: 'Add additional open questions',
        // tslint:disable-next-line:max-line-length
        text: 'It allows up to three extra open questions to be added to the survey, with a maximum of five options each.'
      },
      subject: {
        title: 'Message Subject',
        text: 'Subject to be used in the email sent.'
      },
      body: {
        title: 'Body of message',
        text: 'Content of the email sent. '
      },
      thankMessage: {
        title: 'Thank you message',
        // tslint:disable-next-line:max-line-length
        text: 'This message will be sent to the email address of the recipient who successfully completes the survey.'
      },
      remember_load_update_collaborators: 'Remember to upload or update collaborators before creating the assessment.'
    }
  },
  employee: {
    create: {
      identifyDocument: {
        title: 'Identification document',
        text: `Employee identification document. It is necessary to indicate the type of document should be stated.`,
        required
      },
      firstName: {
        title: 'Name',
        text: `Employee's names.`,
        required
      },
      lastName: {
        title: 'Last name',
        text: `Employee's last names.`,
        required
      },
      gender: {
        title: 'Gender',
        text: `Employee's gender.`,
        required
      },
      birthdate: {
        title: 'Date of Birth',
        text: `Employee's date of birth.`,
        required
      },
      academicDegree: {
        title: 'Academic title',
        text: `Employee's academic title`,
        required
      },
      country: {
        title: 'Country',
        text: `Employee's country.`,
        required
      },
      headquarter: {
        title: 'Office',
        text: `Office where the employee works.`,
        required
      },
      email: {
        title: 'Email address',
        text: `Employee's email address.`,
        required
      },
      phoneNumber: {
        title: 'Phone number',
        text: `Employee's phone number.`,
        required
      },
      admission: {
        title: 'Date of admission',
        text: `Date on which the employee was hired.`,
        required
      },
      jobType: {
        title: 'Contract type',
        text: `Employee's contract type`,
        required
      },
      department: {
        title: 'Area or department',
        text: `Area or department the employee belongs to.`,
        required
      },
      charge: {
        title: 'Job title',
        text: `Post held by the employeee in the Organization `,
        required
      }
    }
  },
  pulse: {
    create: {
      committal: {
        title: 'Add commitment questions',
        // tslint:disable-next-line:max-line-length
        text: 'It allows between one and six commitment questions to be added to the survey.'
      },
      questionnaire: {
        title: 'Add questions from a questionnaire',
        // tslint:disable-next-line:max-line-length
        text: 'It allows questions to be added from one or more dimensions on a questionanaire. This action is mandatory, if you do not include engagement questions in the survey.'
      },
      open: {
        title: 'Add additional open questions',
        // tslint:disable-next-line:max-line-length
        text: 'It allows up to three extra open questions to be added to the survey, with a maximum of five options each.'
      },
      selection: {
        title: 'Who do you want to send this survey to?',
        // tslint:disable-next-line:max-line-length
        text: `Defines the target population of the assessment. You can select between: the entire organization or filter by demographic cuts. ${required}`
      },
      currentCultureQuestions: {
        title: 'Use Current Culture questionnaire',
        text: 'Select a previously conducted Current Culture assessment to use the same questions.'
      }
    }
  },
  questionnaire: {
    create: {
      name: {
        title: 'Name',
        text: `Name that will be assigned to the questionnaire. ${required}`
      },
      base: {
        title: 'Base questionnaire',
        text: `Questionnaire from which a copy will be made to generate the new questionnaire. ${required}`
      },
      language: {
        title: 'Languages',
        text: `Languages in which the questionnaire will be available. ${required}`
      },
      assignate: {
        title: 'Assign questionnaire',
        text: 'It allows you to assign the questionnaire to a specific business sector or a particular organization.'
      }
    }
  },
  requiredCulture: {
    create: {
      name: {
        title: 'Overview',
        text: `Survey name. ${required}`
      },
      dateStart: {
        title: 'Date',
        text: `Survey start date. ${required}`
      },
      dateEnd: {
        title: 'Date',
        text: `This will be the final date to participate in the workshop. After this date there will be no more entries. Remember that you can close the measurement manually from the actions column. ${required}`
      },
      timezone: {
        title: 'Date',
        text: `Time zone in which the survey will be conducted. ${required}`
      }
    },
    plan: {
      pulse_quantity: {
        title: 'Number of Pulses to add',
        text: `Pulses that will be added to the company in the plan extension ${required}`
      },
      engagement_quantity: {
        title: 'Amount of Engagement to add',
        text: `Engagement to be added to the company in the plan extension. ${required}`
      },
      new: {
        title: 'New plan',
        text: `Plan to be assigned to the company. ${required}`
      },
      duration: {
        title: 'Duration',
        text: `Duration of the new plan in months. ${required}`
      }
    },
    massive: {
      file_input: {
        title: 'File to be uploaded',
        // tslint:disable-next-line:max-line-length
        text: `Upload an excel file (.xls / .xlsx) or csv (.csv) containing your employees' data. This action will update your total employee base, so you should add the full base of employees available. Remember to use the downloadable template that you can obtain by clicking on the "DOWNLOAD TEMPLATE" button. ${required}`
      }
    },
    group: {
      name: {
        title: 'Group name',
        text: `Name that will identify the group of companies. ${required}`
      },
      leader: {
        title: 'Lead company',
        // tslint:disable-next-line:max-line-length
        text: 'The lead company (group leader) can manage the plans of companies in its group.'
      }
    }
  }
}
