import pdf from '../utils/pdf'
export default {
  methods: {
    barChartDimesions () {
      const respActualCut = this.round(this.averagePerCultureTypesByItems[0].average, 2)
      const respActualOrg = this.round(this.averagePerCultureTypes[0].average, 2)
      const respReq = this.hasRequired ? this.round(this.cultureAverage[0], 2) : '--'
      const compActualCut = this.round(this.averagePerCultureTypesByItems[1].average, 2)
      const compActualOrg = this.round(this.averagePerCultureTypes[1].average, 2)
      const compReq = this.hasRequired ? this.round(this.cultureAverage[1], 2) : '--'
      const humaActualCut = this.round(this.averagePerCultureTypesByItems[2].average, 2)
      const humaActualOrg = this.round(this.averagePerCultureTypes[2].average, 2)
      const humaReq = this.hasRequired ? this.round(this.cultureAverage[2], 2) : '--'
      const totalActualCut = this.round(((Number(respActualCut) + Number(humaActualCut) + Number(compActualCut)) / 3), 2)
      const totalActualOrg = this.round(((Number(respActualOrg) + Number(humaActualOrg) + Number(compActualOrg)) / 3), 2)
      const totalReq = this.hasRequired ? this.round(((this.cultureAverage[0] + this.cultureAverage[1] + this.cultureAverage[2]) / 3), 2) : '--'
      const respTrend = this.hasPrevious ? this.round((respActualCut - this.previousAveragePerCultureTypes[0].average), 2) : 0
      const compTrend = this.hasPrevious ? this.round((compActualCut - this.previousAveragePerCultureTypes[1].average), 2) : 0
      const humaTrend = this.hasPrevious ? this.round((humaActualCut - this.previousAveragePerCultureTypes[2].average), 2) : 0
      const totalTrend = this.hasPrevious ? this.round((totalActualCut - (this.previousAveragePerCultureTypesTotal / 3)), 2) : 0
      let test = [
        // 4.2
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `4.2 ${this.$t('cultureReports.results_per_culture')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#0889D6' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    { text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 10, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [
                    { text: this.$t('cultureReports.values'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.values_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.sustainability'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.sustainability_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.customers'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.customers_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    { text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [
                    { text: this.$t('cultureReports.innovation'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.innovation_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.achievement'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.achievement_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.leadership'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.leadership_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    { text: this.$t('cultureReports.dimension').toUpperCase(), margin: [0, 3, 5, 0], bold: true, style: { color: '#fff' } },
                    { text: this.$t('cultureReports.initial').toUpperCase(), margin: [20, 3, 15, 0], bold: true, style: { color: '#fff' } }
                  ],
                  [
                    { text: this.$t('cultureReports.talent'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.talent_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.relationship'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.relationship_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ],
                  [
                    { text: this.$t('cultureReports.communication'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 0, 0, 0] },
                    { text: this.$t('cultureReports.communication_code'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [30, 0, 0, 0] }
                  ]
                ]
              },
              margin: [0, 0, 10, 20],
              border: [false, false, false, true]
            }
          ]
        },
        // table
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : (rowIndex === 1 && columnIndex === 0)
                ? '#0889D6' : (rowIndex === 2 && columnIndex === 0)
                  ? '#EB493C' : (rowIndex === 3 && columnIndex === 0)
                    ? '#48BBA1' : (rowIndex === 4 && columnIndex === 0)
                      ? '#BFBFBF' : ''
            }
          },
          table: {
            headerRows: 1,
            widths: ['24%', '12%', '12%', '12%', '14%', '12%', '14%'],
            body: [
              [{ text: this.$t('cultureReports.culture').toUpperCase(), alignment: 'center', margin: [0, 15, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.act_cut').toUpperCase(), alignment: 'center', margin: [0, 10, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.actual_org').toUpperCase(), alignment: 'center', margin: [0, 3, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_org').toUpperCase(), alignment: 'center', margin: [0, 3, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.required').toUpperCase(), alignment: 'center', margin: [0, 15, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.difference_cut_req').toUpperCase(), alignment: 'center', margin: [0, 3, 0, 0], bold: true, style: { color: '#fff' } },
                { text: this.$t('cultureReports.rate').toUpperCase(), alignment: 'center', margin: [0, 15, 0, 0], bold: true, style: { color: '#fff' } }
              ],
              [{ text: this.$t('cultureReports.responsible'), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 10, 0, 0] },
                { text: respActualCut, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0] },
                { text: respActualOrg, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0] },
                { text: this.round((respActualCut - respActualOrg), 2), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0] },
                { text: respReq, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0] },
                { text: respReq !== '--' ? this.round((respActualCut - respReq), 2) : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0] },
                { text: this.hasPrevious ? respTrend : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 10, 0, 0], ...pdf.styleTendency(respTrend) }
              ],
              [{ text: this.$t('cultureReports.competitive'), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: compActualCut, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: compActualOrg, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.round((compActualCut - compActualOrg), 2), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: compReq, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: compReq !== '--' ? this.round((compActualCut - compReq), 2) : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.hasPrevious ? compTrend : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], ...pdf.styleTendency(compTrend) }
              ],
              [{ text: this.$t('cultureReports.humanist'), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: humaActualCut, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: humaActualOrg, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.round((humaActualCut - humaActualOrg), 2), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: humaReq, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: humaReq !== '--' ? this.round((humaActualCut - humaReq), 2) : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.hasPrevious ? humaTrend : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], ...pdf.styleTendency(humaTrend) }
              ],
              [{ text: 'Total', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#fff', fontSize: 10, margin: [0, 5, 0, 0] },
                { text: totalActualCut, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: totalActualOrg, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.round(totalActualCut - totalActualOrg, 2), alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: totalReq, alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: totalReq !== '--' ? this.round((totalActualCut - totalReq), 2) : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0] },
                { text: this.hasPrevious ? totalTrend : '--', alignment: 'center', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], color: '#839291', margin: [0, 5, 0, 0], ...pdf.styleTendency(totalTrend) }
              ]
            ]
          },
          margin: [0, 0, 20, -20]
        },
        {
          image: this.chartPie,
          width: 500,
          height: 370
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#000' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_cut_abr') }
          ],
          margin: [0, -15, 0, 4]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ],
          margin: [0, 0, 0, 4]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#BFBFBF' },
            { width: '96%', text: this.$t('requiredActualReport.required_culture') }
          ]
        },
        // 4.3
        {
          table: {
            color: 'white',
            widths: [461],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_culture_text'),
          alignment: 'justify',
          margin: [50, 15, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          columns: [
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#E4E4E4' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.dimension').toUpperCase(), margin: [30, 6, 30, 6], bold: true, style: { color: '#fff' } }],
                  [{ text: this.$t('cultureReports.actual_cut'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }],
                  [{ text: this.$t('cultureReports.actual_organizational'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }],
                  [{ text: this.$t('cultureReports.difference_cut_org'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }],
                  [{ text: this.$t('cultureReports.required'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }],
                  [{ text: this.$t('cultureReports.difference_cut_required'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }],
                  [{ text: this.$t('cultureReports.rate'), color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [0, 0] }]
                ]
              },
              margin: [-15, 15, 5, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#2196F3' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.values_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.sustainability_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.customers_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  // Actual Corte
                  [{ text: this.averagePerDimensionsByItemsObj.values, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.sustainability, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.customers, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  // Actual Organización
                  [{ text: this.averagePerDimensionsObj.values, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.sustainability, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.customers, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  // Brecha Corte-Org
                  [{ text: `${this.round(+this.averagePerDimensionsByItemsObj.values - +this.averagePerDimensionsObj.values, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.sustainability - +this.averagePerDimensionsObj.sustainability, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.customers - +this.averagePerDimensionsObj.customers, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  // Requerida
                  [{ text: `${this.hasRequired ? this.dimensionAverageObj.values : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.sustainability : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.customers : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  // Brecha Corte-Requerida
                  [{ text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.values - +this.dimensionAverageObj.values, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.sustainability - +this.dimensionAverageObj.sustainability, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.customers - +this.dimensionAverageObj.customers, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  // Tendencia
                  [{ text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.values - +this.previousAveragePerDimensionsObj.values).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.values - +this.previousAveragePerDimensionsObj.values) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.sustainability - +this.previousAveragePerDimensionsObj.sustainability).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.sustainability - +this.previousAveragePerDimensionsObj.sustainability) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.customers - +this.previousAveragePerDimensionsObj.customers).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.customers - +this.previousAveragePerDimensionsObj.customers) : 0) }
                  ]
                ]
              },
              margin: [0, 15, 3, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EB493C' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.innovation_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.achievement_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.leadership_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.averagePerDimensionsByItemsObj.innovation, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.achievement, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.leadership, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.averagePerDimensionsObj.innovation, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.achievement, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.leadership, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.round(+this.averagePerDimensionsByItemsObj.innovation - +this.averagePerDimensionsObj.innovation, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.achievement - +this.averagePerDimensionsObj.achievement, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.leadership - +this.averagePerDimensionsObj.leadership, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.hasRequired ? this.dimensionAverageObj.innovation : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.achievement : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.leadership : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.innovation - +this.dimensionAverageObj.innovation, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.achievement - +this.dimensionAverageObj.achievement, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.leadership - +this.dimensionAverageObj.leadership, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.innovation - +this.previousAveragePerDimensionsObj.innovation).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.innovation - +this.previousAveragePerDimensionsObj.innovation) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.achievement - +this.previousAveragePerDimensionsObj.achievement).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.achievement - +this.previousAveragePerDimensionsObj.achievement) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.leadership - +this.previousAveragePerDimensionsObj.leadership).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], alignment: 'center', border: [false, false, false, true], margin: [4, 0], ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.leadership - +this.previousAveragePerDimensionsObj.leadership) : 0) }
                  ]
                ]
              },
              margin: [0, 15, 3, 20],
              border: [false, false, false, true]
            },
            {
              width: 'auto',
              layout: {
                defaultBorder: '',
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#48BBA1' : null
                },
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                body: [
                  [{ text: this.$t('cultureReports.talent_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.relationship_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' }, border: [false, false, true, false], borderColor: ['', '', '#fff', ''] },
                    { text: this.$t('cultureReports.communication_code'), alignment: 'center', margin: [5, 6], bold: true, style: { color: '#fff' } }
                  ],
                  [{ text: this.averagePerDimensionsByItemsObj.talent, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.relationship, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsByItemsObj.communication, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: this.averagePerDimensionsObj.talent, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.relationship, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: this.averagePerDimensionsObj.communication, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.round(+this.averagePerDimensionsByItemsObj.talent - +this.averagePerDimensionsObj.talent, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.relationship - +this.averagePerDimensionsObj.relationship, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] },
                    { text: `${this.round(+this.averagePerDimensionsByItemsObj.communication - +this.averagePerDimensionsObj.communication, 2)}`, alignment: 'center', color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0] }
                  ],
                  [{ text: `${this.hasRequired ? this.dimensionAverageObj.talent : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.relationship : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasRequired ? this.dimensionAverageObj.communication : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.talent - +this.dimensionAverageObj.talent, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.relationship - +this.dimensionAverageObj.relationship, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' },
                    { text: `${this.hasRequired ? this.round(+this.averagePerDimensionsByItemsObj.communication - +this.dimensionAverageObj.communication, 2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center' }
                  ],
                  [{ text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.talent - +this.previousAveragePerDimensionsObj.talent).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.talent - +this.previousAveragePerDimensionsObj.talent) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.relationship - +this.previousAveragePerDimensionsObj.relationship).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.relationship - +this.previousAveragePerDimensionsObj.relationship) : 0) },
                    { text: `${this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.communication - +this.previousAveragePerDimensionsObj.communication).toFixed(2) : '--'}`, color: '#839291', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [4, 0], alignment: 'center', ...pdf.styleTendency(this.hasPrevious ? (+this.averagePerDimensionsByItemsObj.communication - +this.previousAveragePerDimensionsObj.communication) : 0) }
                  ]
                ]
              },
              margin: [0, 15, 45, 0],
              border: [false, false, false, true]
            }
          ]
        },
        {
          image: this.flowerChart,
          width: 520,
          height: 370,
          margin: [0, 5, 0, 0]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: 'black' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_cut_abr') }
          ],
          margin: [0, 20, 0, 4]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0889D6' },
            { width: '*', text: ' \u0020 ', background: '#EB493C' },
            { width: '*', text: ' \u0020 ', background: '#48BBA1' },
            { width: '96%', text: this.$t('requiredActualReport.current_culture_abr') }
          ],
          margin: [0, 0, 0, 4]
        },
        {
          columns: [
            { width: '3%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020 ', background: '#BFBFBF' },
            { width: '96%', text: this.$t('requiredActualReport.required_culture') }
          ]
        },
        {
          table: {
            color: 'white',
            widths: [479],
            body: [
              [{ text: `4.3 ${this.$t('cultureReports.results_per_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [-40, 30, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('cultureReports.results_per_dimensions_actual_culture_text'),
          alignment: 'justify',
          margin: [50, 0, 22, 0],
          color: '#839291',
          lineHeight: 1.5
        },
        {
          table: {
            color: 'white',
            widths: [460],
            body: [
              [{ text: `${this.$t('cultureReports.culture_dimensions')}`.toUpperCase(), color: '#fff', margin: [50, 8, 0, 5], alignment: 'left' }]
            ]
          },
          layout: {
            fillColor: '#BFBFBF',
            defaultBorder: ''
          },
          alignment: 'left',
          bold: true,
          margin: [35, 30, 0, 0]
        }
      ]
      let array = []
      let column1 = []
      let column2 = []
      let column3 = []
      for (let index = 0; index < this.barCharts.length; index++) {
        if (index <= 2) {
          column1.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 150,
              margin: [0, 25, 20, 15]
            }
          )
        } else if (index > 2 && index <= 5) {
          column2.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 150,
              margin: [0, 25, 20, 15]
            }
          )
        } else {
          column3.push(
            {
              image: this.barCharts[index],
              width: 160,
              height: 150,
              margin: [0, 25, 20, 15]
            }
          )
        }
      }
      array = [
        {
          columns: column1
        },
        {
          columns: column2
        },
        {
          columns: column3
        }
      ]
      test = test.concat(array)
      return test
    },
    $generateResults () {
      return this.barChartDimesions()
    }
  }
}
