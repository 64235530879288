
export default {
  copyright: 'OCC - Todos los derechos reservados',
  introduction: 'Introducción',
  description: 'Descripción de la herramienta OCC',
  results: 'Resultados',
  ranking: 'Ranking',
  index: 'Índice',
  topic: 'Tema',
  page: 'Página',
  humanist: 'Humanista',
  competitive: 'Competitiva',
  responsible: 'Responsable',
  responsible_culture: 'Cultura Responsable',
  competitive_culture: 'Cultura Competitiva',
  humanist_culture: 'Cultura Humanista',
  culture: 'Cultura',
  required_culture: 'Cultura Requerida',
  tool_desc: 'Descripción de la herramienta',
  understanding_results: 'Entendimiento de los Resultados',
  current_culture_organization: 'Cultura Actual Organizacional',
  occ_tool: 'Herramienta OCC',
  index_stack1: [
    '1.1 ¿Qué es Cultura Organizacional?\n',
    '1.2 ¿Para qué definir la Cultura Requerida?\n'
  ],
  index_stack2: [
    '2.1 ¿Qué mide la herramienta Organizational Culture Compass (OCC)?\n',
    '2.2 Tipos de Cultura\n',
    '2.3 Dimensiones de Cultura\n'
  ],
  index_stack3: [
    '3.1 Contexto actual de la Organización\n',
    '3.2 Interpretación de los Resultados\n'
  ],
  index_stack4: [
    '4.1 Resultados por Tipos de Cultura\n',
    '4.2 Resultados por Dimensiones\n',
    '4.3 Resultados por Atributos\n',
    '4.4  Ranking de las Dimensiones para la Cultura Requerida\n',
    '4.5 Ranking de los Atributos para la Cultura Requerida\n'
  ],
  initials: {
    values: 'Pr',
    sustainability: 'So',
    customers: 'Cl',
    innovation: 'In',
    achievement: 'Lo',
    leadership: 'Li',
    talent: 'Ta',
    relationship: 'Re',
    communication: 'Co'
  },
  // Introduction
  what_is_organization_culture: '¿Qué es Cultura Organizacional?',
  // tslint:disable-next-line:max-line-length
  organization_culture_definition: 'Se entiende por Cultura Organizacional, la forma de pensar, sentir y actuar que distingue a los miembros de una organización. La cultura es el reflejo de las creencias más profundas, de los valores y de las normas y tradiciones que definen la personalidad de la organización.\n\n',
  organization_culture_definition2: 'La Cultura Organizacional es la última frontera de las ventajas competitivas y genera una diferenciación sostenible y difícil de imitar. La cultura de una organización se refleja en los hábitos cotidianos de sus miembros, en sus rasgos observables y la forma en que se toman las decisiones.\n\n',
  organization_culture_definition3: 'La Cultura Organizacional está directamente relacionada con la ejecución de la estrategia y el logro de los resultados; muchas organizaciones fracasan al ejecutar su estrategia por no tener una cultura adecuada y coherente.\n\n',
  // Para qué definir la cultura requerida
  for_what: '¿Para qué definir la cultura requerida?',
  for_what1: 'Con la complejidad del mundo actual y los retos que se presentan en atraer clientes, talento o inversionistas, el capital cultural se ha convertido en la nueva prioridad en lo relacionado con las ventajas competitivas de una organización. La cultura y los valores que representa son tan importantes como la calidad de los productos y servicios que ofrece una organización.\n\n',
  for_what2: 'El objetivo de definir la Cultura Requerida, es identificar la cultura que debe desarrollar la Organización para implementar su estrategia con éxito. La define el equipo directivo revisando la realidad actual y futura de la Organización, su direccionamiento estratégico, el entorno competitivo y los principales retos.\n\n',
  for_what3: 'Una cultura es medible, dinámica, transformable y única. En esta medida, no hay culturas mejores que otras, simplemente más o menos adecuadas; creemos que es importante contrastar esta definición con un diagnóstico de la Cultura.\n\n',
  // Descripción de la herramienta occ
  what_the_tool_measure: '¿Qué mide la herramienta organizational culture compass (OCC)?',
  what_the_tool_measure_text: 'El cuestionario de OCC está basado en conductas observables asociadas a unos valores que definen la Cultura Organizacional. Los resultados presentados en este reporte están basados en las percepciones de las personas que respondieron el cuestionario. Los 72 Atributos (conductas) forman nueve dimensiones organizadas en tres tipos de cultura.\n\n',
  what_the_tool_measure_text2: 'Al analizar los datos, se pueden identificar los elementos de la cultura que predominan y los que son menos evidentes. Recuerde, no hay culturas buenas o malas, solo diferentes.\n\n',
  // Tipos de cultura
  culture_types: 'Tipos de Cultura',
  responsible_text: 'Una organización admirada por la sociedad y sus clientes, un “buen ciudadano”, orientada hacia sus grupos de interés con una gestión ética e impecable. Comprende las siguientes dimensiones:',
  competitive_text: 'Una organización de resultados, eficiente y vibrante. Mantiene en equilibrio la innovación y la efectividad de sus procesos actuales a través de la gestión de líderes de clase mundial. Comprende las siguientes dimensiones:',
  humanist_text: 'Una organización que entiende que necesita talento competente y comprometido para lograr sus resultados. Una empresa que atrae, desarrolla y mantiene el mejor talento, promueve excelentes relaciones y una comunicación efectiva y trasparente. Comprende las siguientes dimensiones:',
  what_is_engagement_text: ['En OCC Solutions entendemos ', { text: 'Engagement', italics: true }, ' como alto nivel de compromiso emocional, sentido de pertenencia combinado con entusiasmo y pasión por el trabajo y por la Organización, que se manifiesta en un permanente deseo de dar siempre lo mejor.'],
  // Dimensiones de cultura
  culture_dimensions: 'Dimensiones de Cultura',
  culture_dimensions_next: 'A continuación se describen las nueve Dimensiones de Cultura:',
  yes: 'Si',
  no: 'No',
  // Principios
  values_positive: 'El liderazgo y la cultura centrada en principios definen y defienden pautas de conducta humana que se convierten en la brújula moral de la organización. Los principios se pueden entender como los valores y hoy es claro que su vivencia es fundamental para asegurar la supervivencia de las organizaciones.',
  values_negative: ['Para las organizaciones ', { text: 'no', decoration: 'underline' }, ' enfocadas en principios, el fin justifica los medios. No existen o se viven valores y las conductas asociadas ', { text: 'no', decoration: 'underline' }, ' son ejemplo de comportamiento, de moral o de ética. Usualmente tienen mala reputación y no generan confianza en sus grupos de interés.'],
  // Sostenibilidad
  sustainability_positive: 'Una cultura de sostenibilidad promueve el uso eficiente de los recursos actuales para asegurar la sostenibilidad futura de la organización. Da la misma importancia a todos los grupos de interés y busca resultados equilibrados en lo  financiero, humano, social y para el planeta.',
  sustainability_negative: ['Una cultura ', { text: 'no', decoration: 'underline' }, ' enfocada en la sostenibilidad se orienta a obtener resultados de corto plazo, priorizando los logros para los accionistas sobre los demás grupos de interés. La responsabilidad social, el planeta y las generaciones futuras ', { text: 'no', decoration: 'underline' }, ' son prioridades.'],
  // Clientes
  customers_positive: 'Una cultura centrada en los clientes enfoca sus esfuerzos en entender, satisfacer y superar las necesidades y expectativas de los clientes. La definición de los procesos y sistemas, la estructura y las competencias de las personas se definen teniendo en cuenta las realidades actuales y futuras de los clientes.',
  customers_negative: ['Una cultura ', { text: 'no', decoration: 'underline' }, ' enfocada en el cliente, define el cliente como un medio para obtener los resultados y ', { text: 'no', decoration: 'underline' }, ' se demuestra interés por su satisfacción o lealtad. La visión es más hacia adentro, hacia los procesos, el control y los resultados financieros de corto plazo. Las peticiones, quejas, reclamos y sugerencias son vistas como una molestia y los clientes, en el mejor de los casos, solo son importantes para los que están en contacto directo con ellos.'],
  // Innovación
  innovation_positive: 'La innovación es entendida como la capacidad de crear nuevas realidades, es un aspecto vital para el éxito de las organizaciones en el mundo de hoy. Una cultura de innovación fomenta la creatividad, la iniciativa y la capacidad de reinventarse para prosperar y competir en el tiempo, a la vez que permite asumir riesgos y aprender de los errores.',
  innovation_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran la innovación se busca mantener y preservar lo conocido. A la mayoría de las personas se les paga por “hacer su trabajo y obedecer” y la posibilidad de innovar es, en el mejor de los casos, privilegio de unos pocos. Hay inflexibilidad, dificultad de adaptarse y cambiar, temor a asumir riesgos y se suele castigar el error.'],
  // Logro
  achievement_positive: 'Las organizaciones deben dar resultados positivos para progresar y lograr sus objetivos. Una cultura de efectividad y obtención de resultados promueve conductas y procesos orientados al logro con alto nivel de desempeño. Se crea un lineamiento directo entre la contribución del desempeño individual y de equipo con el desempeño de la organización. Los resultados positivos son la consecuencia de una cultura de alto desempeño y compromiso.',
  achievement_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran los resultados, estos no son importantes y cuando no se logran suele haber “excusas o culpables”. Suele faltar compromiso y sentido de pertenencia. El cumplimiento de los procesos y los controles son más importantes que el logro de las metas definidas.'],
  // Liderazgo
  leadership_positive: 'La calidad y capacidad de los líderes es fundamental para entender el éxito de las organizaciones; entendiendo el liderazgo como el arte de movilizar y orientar personas para asegurar el logro de objetivos y propósitos comunes. En esta dimensión se analiza cómo los líderes logran compromiso, generan confianza, se interesan por desarrollar personas, dan autonomía, crean ambientes que empoderan y acompañan a sus equipos en su desarrollo.',
  leadership_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' se valora el liderazgo transformador, las personas son simplemente un recurso más y están dedicadas solamente a implementar las metas, objetivos y tareas definidas por los jefes. Los jefes asignan actividades y controlan los resultados. Se gestiona desde la imposición, el autoritarismo, la desconfianza y suele haber un alto nivel de “micro-gerencia”.'],
  // Talento
  talent_positive: 'Una cultura centrada en las personas, busca atraer, desarrollar y mantener el mejor talento en la organización como principal palanca para el logro de sus metas. El propósito es tener gente competente y con alto nivel de compromiso y desempeño, que se sienta orgullosa de pertenecer a la organización. Estas organizaciones se esfuerzan por merecer el entusiasmo y la inteligencia de todos, con su visión, su coherencia con valores, su liderazgo y sus logros.',
  talent_negative: ['Para las empresas que ', { text: 'no', decoration: 'underline' }, ' valoran la gente, las personas son recursos que se utilizan para lograr sus objetivos, sin tener en cuenta sus propósitos y metas individuales. Las emociones, competencias y desarrollo de las personas no son importantes o, a lo sumo, lo son para las áreas de Recursos Humanos.'],
  // Relaciones
  relationship_positive: 'En estas organizaciones es clara la conexión entre la calidad de las relaciones y los resultados. Estas compañías promueven el trabajo en equipo, la solidaridad y la capacidad de unir esfuerzos para lograr objetivos comunes. Temas como metas compartidas, confianza, coordinación, interdependencia y colaboración son comunes en este tipo de organizaciones.',
  relationship_negative: 'Las organizaciones que valoran solamente el trabajo individual, la competencia interna y el juego de ganar – perder, describen la forma cotidiana de actuar y dirigir. Las relaciones son estrictamente profesionales, la colaboración se entiende como favor y la división entre áreas refleja un sentido claro de independencia. Se desperdicia energía en las malas relaciones y la desconfianza.',
  // Comunicación
  communication_positive: 'La forma en que las personas se comunican y las historias que se cuentan hacen parte, son reflejo y definen la cultura y los estilos de liderazgo. En una organización con una cultura fuerte de comunicación, se comparte información con transparencia, se escucha con atención, se promueven las preguntas y se crean y mantienen relaciones de confianza y respeto mutuo. La visión y los objetivos se comunican con claridad.',
  communication_negative: ['En las organizaciones que ', { text: 'no', decoration: 'underline' }, ' valoran la comunicación, “Quien tiene la información tiene el poder”. La información no fluye en todos los sentidos ni se valora la importancia de la oportunidad y transparencia de la misma. Las estrategias y los objetivos no son claramente comunicados. No suele “haber tiempo” para escuchar a las personas y las preguntas se perciben como inoportunas e innecesarias. La complejidad de las comunicaciones refleja la desconfianza, la falta de respeto y los estilos de liderazgo jerárquicos.'],
  // Entendimiento de los resultados
  current_context: 'Contexto Actual de la Organización',
  current_context_text: 'Al interpretar los resultados es fundamental tener en cuenta el contexto actual de la organización y de la población que se está analizando. Las siguientes preguntas pueden darle algunas pautas:',
  current_context_questions: [
    '  ¿Cuáles son nuestros retos actuales?',
    '  ¿Hacia dónde vamos?',
    '  ¿Qué queremos alcanzar?',
    '  ¿Cuáles son nuestras oportunidades y amenazas?',
    '  ¿Cuáles son nuestras fortalezas y oportunidades de mejora?'
  ],
  results_interpretation: 'Interpretación de los resultados',
  results_analysis: [
    'La Cultura Requerida se muestra en una escala 2 a 4.',
    'Los resultados obtenidos permiten definir los rasgos que debe tener la Cultura. Insistimos que es importante hacer esta definición teniendo en cuenta los retos actuales y futuros de la Organización.',
    'Analizando la información podrán encontrar la dimensión y los atributos con mayor puntaje. De acá se pueden deducir los valores.',
    'Igualmente es importante revisar las dimensiones y los atributos con menor puntaje. Esto puede darle información acerca de lo que menos se valora por parte de los líderes y generar una reflexión de alto valor.',
    'Una dimensión o atributo bajo puede significar que es un “tema resuelto”. Hay que verificar la información. Aunque sea un “tema resuelto”, puede ser necesario seguir trabajándolo en la Cultura.',
    'Recomendamos diagnosticar la Cultura Actual, pues la identificación de brechas es fundamental para definir planes de acción enfocados y pertinentes.',
    'Cuando se ha medido por segunda vez, el análisis de tendencias también aporta información valiosa.'
  ],
  results_per_culture: 'Resultados por Tipos de Cultura',
  results_per_culture_text: 'Las abreviaturas para cada dimensión son las dos primeras iniciales de la siguiente forma:',
  results_per_dimension: 'Resultados por dimensiones',
  results_per_attribute: 'Resultados por atributos',
  innovation: 'Innovación',
  achievement: 'Logro',
  leadership: 'Liderazgo',
  talent: 'Talento',
  relationship: 'Relaciones',
  communication: 'Comunicación',
  values: 'Principios',
  sustainability: 'Sostenibilidad',
  customers: 'Clientes',
  dimension: 'Dimensión',
  initial: 'Siglas',
  required: 'Requerida',
  required_abbreviation: 'Req.',
  previous_score_abbreviation: 'Punt. Ant.',
  last_score: 'Puntaje anterior',
  last_score_2: 'Puntaje\nanterior',
  current_organization: 'Organización actual',
  ranking_dimensions: 'Ranking de las dimensiones para la cultura requerida',
  dimensions_with_higher_score: 'Dimensiones con puntaje más alto según Cultura Requerida',
  dimensions_with_lower_score: 'Dimensiones con puntaje más bajo según Cultura Requerida',
  dimensions_with_higher_dispersion: 'Dimensiones con dispersión más alta',
  dimensions_with_lower_dispersion: 'Dimensiones con dispersión más baja',
  ranking_attributes: 'Ranking de los atributos para la cultura requerida',
  attributes_with_higher_score: 'Atributos con puntaje más alto',
  attributes_with_lower_score: 'Atributos con puntaje más bajo',
  ranking_rates: 'Ranking de tendencias cultura requerida',
  ranking_of_the_variables: 'Ranking de las variables',
  rates_by_dimension: 'Tendencias por dimensión',
  variation: 'Variación',
  rates: 'Tendencias',
  highest_positive_rate_attribute: 'Tendencias positivas mayores',
  highest_negative_rate_attribute: 'Tendencias negativas mayores',
  highest_positive_rate_variable: 'Tendencias positivas más altas por variable',
  highest_negative_rate_variable: 'Tendencias negativas más altas por variable',
  rates_by_variables: 'Tendencias por variables',
  rates_by_attributes: 'Tendencias por atributos',
  // tslint:disable-next-line:max-line-length
  reports: 'Reportes',
  general_title: 'General',
  general_desc: 'Este reporte cuenta con las estadísticas de toda la población incluida en la encuesta.',
  demographic_title: 'Por cortes demográficos',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'Este reporte cuenta con las estadísticas de la población filtrada a través de ciertos cortes demográficos; dichos cortes pueden seleccionarse a continuación:',
  demographic_button: 'Generar reporte Engagement por cortes demográficos',
  display_demographic: 'Mostrar cortes demográficos',
  population: 'Población',
  sent: 'Enviadas',
  obtained: 'Obtenidas',
  AcademicDegree: 'Título académico',
  Age: 'Edad',
  Antiquity: 'Antigüedad',
  Charge: 'Cargo',
  Country: 'País',
  Department: 'Área / Departamento',
  Gender: 'Género',
  JobType: 'Tipo de contratación',
  years_range: 'Entre {min} y {max} años',
  my_inspiration_chart: 'Mi\ninspiración',
  my_job_chart: 'Mi\ntrabajo',
  work_enviroment_chart: 'Ambiente laboral\npositivo',
  my_team_chart: 'Mi\nequipo',
  my_development_and_learning_chart: 'Mi desarrollo\ny aprendizaje',
  the_leaders_chart: 'Los\nlíderes',
  current_plus_req: 'Cultura Actual vs. Requerida',
  cover: {
    ByItems: 'Reporte por población',
    general: 'Reporte General'
  },
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  title: 'OCC - Reporte Cultura Requerida',
  // tslint:disable-next-line:max-line-length
  positive_attribute_footer: 'Los siguientes atributos poseen un puntaje alto exactamente igual al atributo ',
  negative_attribute_footer: 'Los siguientes atributos poseen un puntaje bajo exactamente igual al atributo ',
  positive_variable_footer: 'Las siguientes variables poseen un puntaje alto exactamente igual a la variable ',
  negative_variable_footer: 'Las siguientes variables poseen un puntaje bajo exactamente igual a la variable ',
  no_answers_modal_msg: 'La encuesta actual no posee respuestas, por lo cual no será posible generar un reporte.',
  positive: 'positivas',
  limiting: 'limititantes',
  attribute: 'Atributo'
}
