
export default {
  'apology': '¡Lo sentimos!',
  'not_found': 'La página que busca no ha sido encontrada.',
  'forbidden': 'Usted no está autorizado para acceder a esta información.',
  'back_home': 'Volver al inicio',
  'wait': '¡Espera!',
  // tslint:disable-next-line:max-line-length
  'auth/user-is-disabled': 'El usuario ingresado se encuentra deshabilitado.<br/>Por favor comunicarse con el administrador.',
  'auth/employee-no-exist': 'Empleado no encontrado.',
  'auth/demo-request-exist': 'Ya existe una solitud de cuenta demo con el correo solicitado.',
  'validator/verify_fields': 'Por favor, verifique los campos del formulario.',
  'validator/invalid-input': 'Uno o más campos no son válidos.',
  'validator/academicDegreeId-invalid-input': 'El campo nivel académico es obligatorio por corte demográfico.',
  'validator/countryId-invalid-input': 'El campo país es obligatorio por corte demográfico.',
  'validator/headquarterId-invalid-input': 'El campo sede es obligatorio por corte demográfico.',
  'validator/chargeId-invalid-input': 'El campo cargo es obligatorio por corte demográfico.',
  'validator/departmentId-invalid-input': 'El campo área/departamento es obligatorio por corte demográfico.',
  'validator/genderId-invalid-input': 'El campo género es obligatorio por corte demográfico.',
  'validator/jobTypeId-invalid-input': 'El campo tipo de contratación es obligatorio por corte demográfico.',
  'validator/birthdate-invalid-input': 'El campo fecha de nacimiento es obligatorio por corte demográfico.',
  'validator/admission-invalid-input': 'El campo fecha de ingreso es obligatorio por corte demográfico.',
  // tslint:disable-next-line:max-line-length
  'email/unique:User,email,user_id': 'El correo especificado ya se encuentra en uso.',
  'validator/reminders': 'Solo puede seleccionar cinco recordatorios.',
  'validator/delete_reminder': 'No puede eliminar este recordatorio.',
  'undefined': 'Ha ocurrido un error en el sistema.',
  'timezone': 'La hora seleccionada no es válida para esa zona horaria.',
  'question_emply': 'La pregunta no puede estar vacía.',
  'validator/select_one': 'Seleccione al menos un idioma.',
  'validator/empty-field': 'El campo no puede estar vacío.',
  'validator/no_receivers': 'La encuesta debe poseer al menos un encuestado.',
  'image/size': 'El tamaño de la imagen debe ser inferior a 2MB.',
  'modal_identify_type': 'Seleccione un tipo de identidad válida.',
  'modal_identify_document': 'Ingrese un número de identificación válido.',
  'modal_first_name': 'Ingrese un nombre válido.',
  'modal_last_name': 'Ingrese un apellido válido.',
  'modal_exists_email': 'El correo electrónico ingresado ya existe.',
  'modal_user_not_employee': 'El correo electrónico ingresado existe como otro tipo de usuario',
  'modal_exist_identification': 'El número de identificación ingresado ya existe.',
  'modal_email': 'Ingrese un correo electrónico válido.',
  'modal_gender': 'Seleccione un género válido.',
  'modal_antiquity_range': 'Ingrese una fecha de ingreso válida.',
  'modal_department': 'Seleccione un departamento válido.',
  'modal_charge': 'Seleccione un cargo válido.',
  'modal_job_type': 'Seleccione un tipo de contratación válida.',
  'modal_age_range': 'Ingrese una fecha de nacimiento válida.',
  'modal_country': 'Seleccione un país válido.',
  'modal_headquarter': 'Seleccione una sede válida.',
  'modal_academic_degree': 'Seleccione un nivel académico válido.',
  'modal_validation_error': 'Quedan registros por corregir.',
  'email_already_taken': 'El correo electrónico ingresado ya está en uso.',
  'video/size': 'El tamaño del video debe ser inferior a 20MB.',
  'error_disable_questionnaires': 'No se pueden desactivar todos los cuestionarios',
  'error_select_questionnaires': 'Debe seleccionar un cuestionario.',
  'error_select_pulse_dimensions': 'Debe seleccionar al menos una (1) dimension.',
  'error_select_dates': 'Debe seleccionar un rango de tiempo válido.',
  'error_select_timezones': 'Debe seleccionar una zona horaria válida.',
  'auth/enterprise_disabled': 'La empresa solicitada se encuentra inactiva',
  'auth/employee_disabled': 'El empleado solicitado se encuentra inactivo',
  'auth/password-not-match': 'La contraseña actual es incorrecta',
  'auth/email-not-match': 'El correo actual es incorrecto',
  'auth/email-already-registered': 'El nuevo correo electronico ya se encuentra asignado a otro usuario',
  'no_balance': 'Sin balance suficiente',
  'required_enterprise_p1': 'Para continuar debe seleccionar una empresa.',
  'required_enterprise_p2': 'En breves momentos será redirigido a Suite.',
  'not_calibrated': 'La cultura requerida todavía no ha sido calibrada por el Facilitador',
  'no_permissions': 'No posee los permisos para ingresar a este producto',
  'ask_permissions': 'Si desea solicitar la activación de este producto o ya la ha solicitado y aún no lo posee disponible' +
  ' póngase en contacto con nuestro equipo por esta vía y lo contactaremos.',
  'evaluation_resend_pending': 'Esta medición no ha iniciado',
  'evaluation_resend_completed': 'Esta medición ya ha finalizado',
  'uploadReminderError': 'Ha ocurrido un error cargando el video para el correo de recordatorio, sin embargo, la medición se ha creado.',
  'uploadInvitationError': 'Ha ocurrido un error cargando el video para el correo de invitación, sin embargo, la medición se ha creado.',
  'current-culture/not-found': 'La medición no existe.',
  'current-culture/status-fail': 'Esta medición no está disponible para responder.',
  'current-culture/user-status-fail': 'Este colaborador ya ha respondido la medición.',
  'current-culture/user-disabled': 'Este colaborador está deshabilitado.',
  'current-culture/user-not-found': 'El Colaborador no existe.',
  'current-culture/invalid-token': 'No existe encuesta para este acceso, por favor coloque uno valido.',
  'current-culture/idx-fail': 'Ha ocurrido un error cargando la respuesta, por favor recargue la página e intente nuevamente.',
  'current-culture/check-process-fail': 'Ha ocurrido un error guardando sus cambios, por favor recargue la página e intente nuevamente, si error persiste por favor comuníquese con OCC Solutions',
  'current-culture/closed': 'Esta medición ha sido cerrada',
  'culture-pulse/not-found': 'La medición no existe.',
  'culture-pulse/status-fail': 'Esta medición no está disponible para responder.',
  'culture-pulse/user-status-fail': 'Este colaborador ya ha respondido la medición.',
  'culture-pulse/user-disabled': 'Este colaborador está deshabilitado.',
  'culture-pulse/user-not-found': 'El Colaborador no existe.',
  'culture-pulse/invalid-token': 'No existe encuesta para este acceso, por favor coloque uno valido.',
  'culture-pulse/idx-fail': 'Ha ocurrido un error cargando la respuesta, por favor recargue la página e intente nuevamente.',
  'culture-pulse/check-process-fail': 'Ha ocurrido un error guardando sus cambios, por favor recargue la página e intente nuevamente, si error persiste por favor comuníquese con OCC Solutions',
  'culture-pulse/closed': 'Esta medición ha sido cerrada',
  'steps-overview/max-counter': 'El nombre no puede exceder el número máximo de caracteres',
  'suite-fail/not-found': 'Hubo un error al comunicarse con la central de datos, por favor intente nuevamente',
  'suite-fail/not-found-retry': 'Hubo un error al comunicarse con la central de datos! Intentando nuevamente...',
  'suite-fail/create-required-culture/spend-fail': 'Hubo un error al procesar su pago',
  'suite-fail/create-current-culture/spend-fail': 'Hubo un error al procesar su pago'
}
