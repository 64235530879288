
<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-col cols="12" class="pa-3 headline">
        {{ $t('currentCulture.overview') }}
        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-col>
      <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-tooltip bottom color='blue'>
                <template v-slot:activator="{ on }">
                  <x-inputs-input
                    :input="currentCulture.name"
                    @updateInput="($event) => currentCulture.name = $event"
                    v-on="on"
                    light
                    :counter="counter"
                    :label="$t('currentCulture.poll_name')"
                    autofocus
                    rules="required"
                    name="poll_name"
                    :data-vv-as="$t('currentCulture.poll_name') | lowerCase"
                    :append-outer-icon="$t('help.icon')"
                    :help-message="$t('help.culture.create.name')"
                  ></x-inputs-input>
                </template>
                <span>{{ $t('currentCulture.tooltip_name') }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="currentCulture.switchName"
                :label="$t('currentCulture.want_external_name')"
                color="primary"
              ></v-switch>
              <v-tooltip bottom color='blue'>
                <template v-slot:activator="{ on }">
                  <x-inputs-input
                    v-on="on"
                    :input="currentCulture.displayName"
                    @updateInput="($event) => currentCulture.displayName = $event"
                    v-show="currentCulture.switchName"
                    :rules="currentCulture.switchName ? 'required' : ''"
                    light
                    :counter="counter"
                    :label="$t('currentCulture.external_name')"
                    name="external_name"
                    :data-vv-as="$t('currentCulture.external_name') | lowerCase"
                    :append-outer-icon="$t('help.icon')"
                    :help-message="$t('help.culture.create.displayName')"
                  ></x-inputs-input>
                </template>
                <span>{{ $t('currentCulture.tooltip_display_name') }}</span>
              </v-tooltip>
            </v-col>

            <!-- Load/Update Participants Reminder -->
            <v-col cols="12" sm="8" class="pt-5 caption primary--text">
              {{ isEdit ? '' : $t('help.culture.create.remember_load_update_collaborators') }}
            </v-col>

            <!-- Assessment Participant Count -->
            <v-col cols="12" sm="4" v-if="totalReceptors" class="ml-auto text-right headline">
              {{ $t('currentCulture.total_receptors', {n: `${computedNew || totalReceptors}`}) }}
            </v-col>

            <!-- Assessment New Participant warning -->
            <v-col cols="12" v-if="isEdit && computedNew" class="pt-0 pb-0 px-3 caption text-right error--text">
              {{ newParticipants.length === 1
                  ? $t('currentCulture.population_diff_singular')
                  : $t('currentCulture.population_diff_plural', {n: `${newParticipants.length}`})
              }}
            </v-col>

            <v-col cols="12" sm="6" class="pt-1">
              <v-btn block large
                to="/current-culture-assessments/list"
              >
                {{ $t(prevAction) }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="pt-1">
              <v-btn large block
                color="primary"
                type="submit"
              >
                {{ $t('input.next') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      </ValidationObserver>
    </v-card>
  </v-stepper-content>
</template>

<script>

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    totalReceptors: Number,
    currentCulture: Object,
    newParticipants: {
      type: Array,
      default: () => []
    },
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      counter: 75
    }
  },
  computed: {
    computedNew () {
      return this.newParticipants.length
        ? this.newParticipants.length + this.totalReceptors
        : 0
    }
  },
  methods: {
    changeStep (step) {
      this.$emit('changeStep', this.currentCulture, step)
    },
    onSubmit () {
      this.validateCounter()
        .then(() => this.changeStep(+this.step + 1))
    },
    validateCounter () {
      return new Promise((resolve, reject) => {
        if (this.currentCulture.name.length > this.counter || this.currentCulture.displayName.length > this.counter) {
          this.$store.dispatch('alert/error', this.$t('errors.steps-overview/max-counter'))
          return reject(new Error('counter'))
        }
        return resolve(true)
      })
    }
  }
}
</script>
