
import Services from './base-services'

const service = new Services('power-bi')

export default {
  getEmbedData: (isCurrent: boolean = false) => {
    let url = 'get-embed-data'
    if (isCurrent) {
      url = url.concat('?isCurrent=1')
    }
    return service.get(url)
  }
}
