
<template>
  <v-stepper-content :step="step">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('requiredCulture.date') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <p class="subtitle-2 grey--text text--darken-1 text-justify">
        {{ $t('requiredCulture.date_selection_subtitle') }}
      </p>
    </v-col>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-container>
          <v-row>
            <v-col cols="12" sm="3">
              <x-date-picker
                v-model="requiredCulture.releaseAt.value"
                @date-picked="requiredCulture.releaseAt.value = $event"
                :label="$t('requiredCulture.start_date')"
                ref="delivered"
                :append-outer-icon="$t('help.icon')"
                :name="$t('requiredCulture.start_date') | lowerCase"
                vid="requiredCulture.start_date"
                rules="required"
                :readonly="requiredCulture.status!=='pending'"
                :help="$t('help.requiredCulture.create.dateStart')"
              ></x-date-picker>
            </v-col>

            <v-col cols="12" sm="3">
              <v-autocomplete
                :filled="requiredCulture.status!=='pending'"
                v-bind:style="requiredCulture.status!=='pending' ? 'cursor: not-allowed;' : ''"
                :items="hours"
                persistent-hint
                v-model="requiredCulture.releaseAt.hour"
                light
                :label="$t('requiredCulture.hours')"
                autofocus
                name="hours"
                :disabled="requiredCulture.status!=='pending'"
                :data-vv-as="$t('requiredCulture.hours') | lowerCase"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3">
              <x-date-picker
                v-model="requiredCulture.deadlineAt.value"
                @date-picked="requiredCulture.deadlineAt.value = $event"
                :label="$t('requiredCulture.workshop_valid_until')"
                :name="$t('requiredCulture.workshop_valid_until') | lowerCase"
                vid="requiredCulture.workshop_valid_until"
                rules="required"
                :help="$t('help.requiredCulture.create.dateEnd')"
              ></x-date-picker>
            </v-col>

            <v-col cols="12" sm="3">
              <v-autocomplete
                :items="hours"
                persistent-hint
                v-model="requiredCulture.deadlineAt.hour"
                light
                :label="$t('requiredCulture.hours_valid_until')"
                autofocus
                name="hours_valid_until"
                :data-vv-as="$t('requiredCulture.hours_valid_until') | lowerCase"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                :filled="requiredCulture.status!=='pending'"
                v-bind:style="requiredCulture.status!=='pending' ? 'cursor: not-allowed;' : ''"
                persistent-hint
                :items="timezones"
                v-model="requiredCulture.timeZone"
                light
                :label="$t('requiredCulture.time_zone')"
                name="selection_type"
                :data-vv-as="$t('requiredCulture.time_zone') | lowerCase"
                :append-outer-icon="$t('help.icon')"
                :disabled="requiredCulture.status!=='pending'"
                @click:append-outer="$store.dispatch('help/display', $t('help.requiredCulture.create.timezone'))"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                block
                large
                @click="changeStep(+step - 1)"
              >{{ $t('input.back') }}</v-btn>
            </v-col>

            <v-col cols="12" sm="6">
              <v-btn
                color="primary"
                block
                large
                type="submit"
              >{{ $t('input.next') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-stepper-content>
</template>

<script>

import timeZoneService from '../../services/time-zones'

export default {
  props: {
    requiredCulture: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      edit: Boolean(this.$route.params.id),
      hours: [],
      timezones: []
    }
  },
  computed: {
    validateUntil: function () {
      const { releaseAt, deadlineAt } = this.requiredCulture
      if (!(releaseAt.hour || deadlineAt.hour)) {
        return false
      }
      return {
        ref: new Date(`${releaseAt.value} ${releaseAt.hour}:00`).getTime(),
        finish: new Date(`${deadlineAt.value} ${deadlineAt.hour}:00`).getTime()
      }
    }
  },
  methods: {
    getOffsetObject () {
      const text = this.requiredCulture.timeZone
      const arr = []
      this.timezones.forEach(element => {
        if (element.text === `${text}`) {
          arr.push(element)
        }
      })
      return arr
    },
    validTimeZone () {
      if (this.edit) {
        return true
      }
      var offsetObj = this.getOffsetObject()
      if (offsetObj.length < 1) {
        return false
      }
      var date = new Date()
      var utc = date.getTime() + (date.getTimezoneOffset() * 60000)
      var newDate = new Date(utc + (3600000 * (parseFloat(offsetObj[0].offset))))
      const dates = this.validateUntil
      if (dates) {
        return newDate.getTime() < dates.ref
      } else {
        return false
      }
    },
    changeStep (step) {
      this.$emit('changeStep', this.requiredCulture, step)
    },
    onSubmit () {
      if (this.validTimeZone() && this.validateUntil.ref < this.validateUntil.finish) {
        this.changeStep(+this.step + 1)
      } else {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_dates'))
      }
    },
    getHours () {
      for (let i = 0; i < 24; i++) {
        i < 10 ? this.hours.push('0' + i + ':00') : this.hours.push(i + ':00')
      }
    }
  },
  created () {
    this.getHours()
    timeZoneService.list()
      .then(res => {
        this.timezones = res.items.map((item) => ({
          value: item.text,
          text: item.text,
          offset: item.offset
        }))
      })
  }
}
</script>
