
export default {
  customer_no_important: 'El cliente no es importante',
  customer_important: 'La gestion de la organizacion esta enfocada en el cliente',
  unknown_expectations: 'Se desconocen las necesidades y expectativas de los clientes',
  known_expectations: 'Se conocen y entienden las necesidades y expectativas de los clientes',
  partially_disagree: 'Parcialmente en desacuerdo',
  partially_agree: 'Parcialmente de acuerdo',
  fairly_disagree: 'Medianamente desacuerdo',
  fairly_agree: 'Medianamente de acuerdo',
  agree: 'De acuerdo',
  disagree: 'Desacuerdo',
  totally_disagree: 'Totalmente en desacuerdo',
  totally_agree: 'Totalmente de acuerdo',
  no_agree_no_desagree: 'Ni acuerdo ni desacuerdo',
  start: 'Bienvenido a la medición de Cultura Organizacional OCC',
  middle: '¡Ya has completado la mitad del proceso, mantente atento a cada pregunta!',
  end: 'Muchas gracias por tu participación. Has completado la encuesta satisfactoriamente.',
  connection_interrupted: 'Conexión interrumpida, continuaremos completando sus respuestas restableciendo la conexión.',
  connection_interrupted_trying: 'Intentando conectar en {seconds} segundos',
  connection_interrupted_out: 'Lo sentimos, por favor verifique su conexión a internet y refresque la página o intente conectar nuevamente',
  connection_interrupted_connecting: 'Conectando',
  connection_try_connect_now: 'Intentar conectar ahora',
  connection_try_connect: 'Intentar conectar',
  connection_try_save: 'Estamos guardando sus respuestas, por favor espere',
  invalid_token: 'No existe encuesta para este acceso, por favor coloque uno valido',
  before_date: 'La encuesta no se encuentra disponible para la fecha actual',
  expiration_date: 'La medición a la que está intentando ingresar ha terminado',
  instructions: 'A continuación, encontrarás 72 atributos que sirven para describir la Cultura Actual, es decir, la forma en que percibes la Cultura al interior de tu organización en el presente, mas no como te gustaría que fuera.<br/><br/>Recuerda que para obtener resultados fieles a la realidad debes responder honestamente como te sientes en tu día a día. Las respuestas no son buenas ni malas, se trata de encontrar el valor que refleje con mayor fidelidad los comportamientos en la organización. Confiamos en tu criterio y sinceridad.<br/><br/>Las respuestas de esta medición son anónimas y no serán compartidas a nivel individual.<br/><br/>El plazo máximo para completar la encuesta es: {deadline}',
  instructions_2: 'Cómo responder: cada atributo se presenta en una escala entre -4 y +4. En cada caso hay un atributo limitante a la izquierda y uno positivo a la derecha. De acuerdo con tu percepción debes identificar que atributo se presenta más en la organización y posteriormente calificar la frecuencia; ten en cuenta que +4 y -4 representa la mayor frecuencia. Seleccionar el 0 es también una opción válida y aplica cuando consideres que no se acerca a ninguno de los dos atributos.',
  example: 'Ejemplo',
  open_questions: 'Preguntas abiertas',
  information: 'Asegúrese de marcar todas las preguntas, así su respuesta sea 0, de lo contrario no podrá avanzar para completar la encuesta.',
  exampleInstructions: '<p style="font-size: 15px; color: blue; display: inline;"><b>DIMENSIÓN: </b></p> <b>COMUNICACIÓN</b><br/><br/><b>Cómo responder:</b> Cada atributo se presenta en una escala entre -4 y 4. En cada caso hay un atributo limitante a la izquierda y uno positivo a la derecha. De acuerdo a tu percepción debes identificar que atributo se presenta más en la organización y posteriormente calificar la frecuencia; ten en cuenta que 4 y -4 representa la mayor frecuencia. Seleccionar el 0 es también una opción y es cuando consideres que no se acerca a ninguno de los dos atributos.'
}
