<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="8" offset-sm="3" offset-md="2"
            class="pt-12 text-center grey--text text--darken-1 mt-5"
          >
            <section class="enterprise-logo">
              <img :src="logo" />
            </section>

            <h2 class="display-3 mt-5 break-name">{{ name }}</h2>

            <h4 class="display-1 mt-3">{{ $t('culturePulses.finish_title') }}</h4>

            <p class="headline mt-3" v-html="$t('culturePulses.finish_text')"></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    logo: String,
    backButton: Boolean,
    name: String,
    code: null
  }
}
</script>
<style>
  .break-name {
    max-width: 100%;
    overflow-wrap: break-word;
  }
</style>
