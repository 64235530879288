
<template>
  <v-container fluid grid-list-xl>
    <v-row>
      <v-col xs12>
        <h4 class="display-1"> {{$t('dashboard.general_title')}} </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <img src="../../public/img/20200302_occ_cultura_logo.png" style="max-width: 100%; max-height: 900px;" alt="OCC - Solutions Logo">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default { }
</script>
