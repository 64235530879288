
export default {
  customer_no_important: 'The customer is not important',
  customer_important: 'The organization\'s management is customer-focused',
  unknown_expectations: 'Customer needs and expectations are unknown',
  known_expectations: 'Customer needs and expectations have been identified and are understood',
  partially_disagree: 'Slightly disagree',
  partially_agree: 'Slightly agree',
  fairly_disagree: 'Moderately disagree',
  fairly_agree: 'Moderately agree',
  agree: 'Agree',
  disagree: 'Disagree',
  totally_disagree: 'Strongly disagree',
  totally_agree: 'Strongly agree',
  no_agree_no_desagree: 'Neither agree nor disagree',
  start: 'Welcome to OCC\'s Organizational Culture measurement',
  middle: 'You are now halfway through the process. Pay attention to every single question!',
  end: 'Thank you very much for participating. You have successfully completed the survey.',
  connection_interrupted: 'Connection interrupted. Your answers will be saved once the connection is re-established.',
  connection_interrupted_trying: 'Trying to connect in {seconds} seconds',
  connection_interrupted_out: 'We are sorry, please check your internet connection and refresh the page or try connecting again',
  connection_interrupted_connecting: 'Connecting',
  connection_try_connect_now: 'Try to connect now',
  connection_try_connect: 'Try to connect',
  connection_try_save: 'We are saving your answers, please wait',
  invalid_token: 'No surveys for this access, please enter a valid one',
  before_date: 'The survey is not available for the current date',
  expiration_date: 'The survey you are trying to access has finished',
  instructions: 'Below you will find 72 attributes that serve to describe the Current Culture, that is, the way you perceive the Culture within your organization at present, but not how you would like it to be.<br/><br/>Remember that to obtain results that are true to reality, you must honestly respond to how you feel on a daily basis. The answers are neither good nor bad, it is about finding the value that most faithfully reflects the behaviors in the organization. We trust your judgment and sincerity.<br/><br/>The answers to this measurement are anonymous and will not be shared on an individual level.<br/><br/>The maximum deadline to complete the survey is: {deadline}',
  instructions_2: 'How to answer: each attribute is presented on a scale between -4 and +4. In each case there is a limiting attribute on the left and a positive one on the right. Based on your perception, you must identify which attribute is most present in the organization and then rate the frequency; keep in mind that +4 and -4 represent the highest frequency. Selecting 0 is also a valid option and applies when you consider that it does not come close to either of the two attributes.',
  example: 'Example',
  open_questions: 'Open questions',
  information: 'Make sure to mark all questions, even if your answer is 0, otherwise you will not be able to move forward to complete the survey.',
  exampleInstructions: '<p style = "font-size: 15px; color: blue; display: inline;"> <b> DIMENSION: </b> </p> <b> COMMUNICATION </b> <br/> <br/> <b> How to answer: </b> Each attribute is presented on a scale from -4 to 4. In each case there is a limiting attribute on the left and a positive one on the right. Depending on your perception, you should identify which attribute is most present in the organization and subsequently rate its frequency; Note that 4 and -4 represent the highest frequency. Selecting 0 is also an option when you consider that it does not come close to either of the two attributes.'
}
