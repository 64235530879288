
<template>
  <v-container fluid>
    <v-card style="margin-bottom:10px;">
      <v-card-title class="d-none d-md-flex" style="background-color:#9E9E9E;">
        <v-row wrap row align="center">
          <v-col cols="12" sm="6">
            <v-card style="border-radius:10px;" min-height="">
              <div class="d-flex flex-no-wrap justify-space-around align-center">
                <img :src="(this.user.enterprise.logo === null || this.user.enterprise.logo === undefined) ? '/img/20200301_occ_solution_logo.png' : this.user.enterprise.logo" alt="Enterprise Logo" width="260" style="margin-top: 10px;"/>
                <v-card-title
                  class="headline"
                  style="white-space: nowrap;"
                >{{ this.user.enterprise.name }}</v-card-title>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <h2
              class="text-capitalize ml-5 white--text"
              style="font-size: 40px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 60px;"
            >
              {{ $t('evaluations.required_title') }}
            </h2>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- Increase/Decrease font size -->
      <v-card-text>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab x-small absolute top right :ripple="false"
              v-on="on"
              color="primary darken-1"
              class="mr-n7"
              elevation="4"
              @click="addSize -= 2"
            >
              <v-icon>mdi-format-font-size-decrease</v-icon>
            </v-btn>
          </template>
          <span>Disminuir tamaño de letra</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn fab x-small absolute top right :ripple="false"
              v-on="on"
              color="primary darken-1"
              class="mr-2"
              elevation="4"
              @click="addSize += 2"
            >
              <v-icon>mdi-format-font-size-increase</v-icon>
            </v-btn>
          </template>
          <span>Aumentar tamaño de letra</span>
        </v-tooltip>

        <!-- Calibration Table -->
        <table class="table" style="padding: 5px;">
          <thead>
            <tr>
              <th width="40%"></th>
              <th width="20%" class="text-center" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.actual_point') }}
              </th>
              <th width="20%" class="text-center" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.dispersion') }}
              </th>
              <th width="20%" class="text-center" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.final_point') }}
              </th>
            </tr>
            <tr style="background-color: #309DE0; color: #fff">
              <th colspan="1" class="border-left" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.responsible') }}
              </th>
              <th colspan="1" class="text-center">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.current_total') }}: {{ Math.round(this.actualResponsible) }}%
                  </span>
                </v-chip>
              </th>
              <th colspan="1"></th>
              <th colspan="1" class="border-right" style="text-align:right;">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.final_total') }}: {{ this.finalResponsible }}%
                  </span>
                </v-chip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in responsible" :key="i">
              <td :style="`font-size: ${fSize2 + addSize}px;`">
                {{ $t(`evaluations.${item.name}`) }}
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ item.actual_point }}%
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ item.dispersion }}
              </td>
              <td align="center" justify="center">
                <v-text-field
                  v-model="item.final_point"
                  type="number"
                  append-icon="fa-percentage"
                  min="0"
                  max="100"
                  width="100%"
                  :autofocus="i === 0 ? true : false"
                  @keypress.enter="validate(item.final_point, 'responsible', i)"
                  @focusout="validate(item.final_point, 'responsible', i)"
                  :rules="[rules.validate]"
                  class="custom-font-size"
                  style="padding: 0; margin: 0;"
                  :style="cssVars"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr style="background-color: #EC5F50; color: #fff">
              <th colspan="1" class="border-left" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.competitive') }}</th>
              <th colspan="1" class="text-center">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.current_total') }}: {{ Math.round(this.actualCompetitive) }}%
                  </span>
                </v-chip>
              </th>
              <th colspan="2" class="border-right" style="text-align:right;">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.final_total') }}: {{ this.finalCompetitive }}%
                  </span>
                </v-chip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in competitive" :key="i">
              <td :style="`font-size: ${fSize2 + addSize}px;`">
                {{ $t(`evaluations.${item.name}`) }}
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ (item.actual_point) }}%
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ item.dispersion }}
              </td>
              <td>
                <v-text-field
                  v-model="item.final_point"
                  type="number"
                  append-icon="fa-percentage"
                  min="0"
                  max="100"
                  @keypress.enter="validate(item.final_point, 'competitive', i)"
                  @focusout="validate(item.final_point, 'competitive', i)"
                  :rules="[rules.validate]"
                  class="custom-font-size"
                  style="padding: 0; margin: 0;"
                  :style="cssVars"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr style="background-color: #4ACAAE; color: #fff">
              <th colspan="1" class="border-left" :style="`font-size: ${fSize1 + addSize}px;`">
                {{ $t('evaluations.humanist') }}
              </th>
              <th colspan="1" class="text-center">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.current_total') }}: {{ Math.round(this.actualHumanist) }}%
                  </span>
                </v-chip>
              </th>
              <th colspan="2" class="border-right" style="text-align:right;">
                <v-chip small color="#fff">
                  <span :style="`font-size: ${fSize1 + addSize}px;`">
                    {{ $t('evaluations.final_total') }}: {{ this.finalHumanist }}%
                  </span>
                </v-chip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in humanist" :key="i">
              <td :style="`font-size: ${fSize2 + addSize}px;`">
                {{ $t(`evaluations.${item.name}`) }}
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ item.actual_point }}%
              </td>
              <td class="text-center" :style="`font-size: ${fSize2 + addSize}px;`">
                {{ item.dispersion }}
              </td>
              <td>
                <v-text-field
                  v-model="item.final_point"
                  type="number"
                  append-icon="fa-percentage"
                  min="0"
                  max="100"
                  @keypress.enter="validate(item.final_point, 'humanist', i)"
                  @focusout="validate(item.final_point, 'humanist', i)"
                  :rules="[rules.validate]"
                  class="custom-font-size"
                  style="padding: 0; margin: 0;"
                  :style="cssVars"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
    <v-row row wrap justify="center">
      <v-col cols="12" xs="4" md="6" sm="6" class="mt-3">
        <v-btn
          color="rgb(56, 153, 218)"
          class="white--text"
          :disabled="progressComplete !== 100 || Boolean(conectionAttempt)"
          width="100%"
          @click="saveScore">{{ $t('input.save') }}</v-btn>
      </v-col>
      <v-col cols="12" v-if="conectionTry">
        <v-alert type="info" :value="conectionTry">{{ $t('actualEvaluation.connection_try_save') }}</v-alert>
        <v-alert type="info" :value="conectionAttempt >= 10">{{ $t('actualEvaluation.connection_interrupted_out') }}</v-alert>
        <v-btn
          v-if="conectionAttempt >= 10"
          @click="retryConection"
          class="mt-4"
          color="primary"
          large
        >{{ $t('actualEvaluation.connection_try_connect_now') }}</v-btn>
      </v-col>
    </v-row>
    <v-col>
      <v-fab-transition style="bottom: 10px;">
        <v-btn
          :color="progressComplete === 100 ? 'green darken-3' : 'red darken-1'"
          dark
          fixed
          bottom
          right
          fab
          x-large
        >
          <span>{{ progressComplete }}%</span>
        </v-btn>
      </v-fab-transition>
    </v-col>
    <x-out-interval-dialog :dialog="outIntervalDialog" :icon="dialogIcon" :text="dialogText" :button="true"></x-out-interval-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
  .custom-font-size ::v-deep input {
    font-size: var(--custom-font-size) !important;
  }
</style>

<script>
import { mapState } from 'vuex'

import requiredCultureService from '../../services/required-culture'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data () {
    return {
      addSize: 0,
      fSize1: 16,
      fSize2: 17,
      responsible: [
        { name: 'values', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'sustainability', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'customers', actual_point: 0, dispersion: 0, final_point: 0 }
      ],
      competitive: [
        { name: 'achievement', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'leadership', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'innovation', actual_point: 0, dispersion: 0, final_point: 0 }
      ],
      humanist: [
        { name: 'talent', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'relationship', actual_point: 0, dispersion: 0, final_point: 0 },
        { name: 'communication', actual_point: 0, dispersion: 0, final_point: 0 }
      ],
      dispersions: [],
      panel: 0,
      progressResponsible: 0,
      progressCompetitive: 0,
      progressHumanist: 0,
      dimensionEndScore: {},
      outIntervalDialog: false,
      dialogIcon: '',
      dialogText: '',
      rules: {
        validate: value => {
          const pattern = /^[0-9][0-9]?$|^100$/
          return pattern.test(value) || 'Dato inválido'
        }
      },
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionCallBack: undefined,
      conectionCallBackResponse: undefined,
      conectionTry: false,
      intervalConection: undefined
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    cssVars () {
      return {
        '--custom-font-size': `${this.fSize2 + this.addSize}px`
      }
    },
    progressComplete () {
      return this.progressResponsible + this.progressCompetitive + this.progressHumanist
    },
    actualResponsible () {
      let total = 0
      for (const item of this.responsible) {
        total += parseFloat(item.actual_point)
      }
      return total
    },
    actualCompetitive () {
      let total = 0
      for (const item of this.competitive) {
        total += parseFloat(item.actual_point)
      }
      return total
    },
    actualHumanist () {
      let total = 0
      for (const item of this.humanist) {
        total += parseFloat(item.actual_point)
      }
      return total
    },
    finalResponsible () {
      let total = 0
      let self = this
      let i = 0
      for (i; i < this.responsible.length; i++) {
        if (isNaN(parseInt(this.responsible[i].final_point))) {
          total = parseInt(total)
        } else {
          total += parseInt(this.responsible[i].final_point)
        }
        self.progressResponsible = total
      }
      return total
    },
    finalCompetitive () {
      let total = 0
      let self = this
      let i = 0
      for (i; i < this.competitive.length; i++) {
        if (isNaN(parseInt(this.competitive[i].final_point))) {
          total = parseInt(total)
        } else {
          total += parseInt(this.competitive[i].final_point)
        }
        self.progressCompetitive = total
      }
      return total
    },
    finalHumanist () {
      let total = 0
      let self = this
      let i = 0
      for (i; i < this.humanist.length; i++) {
        if (isNaN(parseInt(this.humanist[i].final_point))) {
          total += 0
        } else {
          total += parseInt(this.humanist[i].final_point)
        }
        self.progressHumanist = total
      }
      return total
    }
  },
  created () {
    this.getRequiredCulture()
  },
  methods: {
    retryConection () {
      if (this.intervalConection) {
        clearInterval(this.intervalConection)
      }
      this.conectionAttempt = 0
      this.tryConection()
    },
    async tryConection (callBack) {
      this.conectionAttempt++
      if (callBack) {
        this.conectionCallBack = callBack
      }
      return this.conectionCallBack()
        .then((res) => {
          this.conectionTry = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return this.conectionCallBackResponse(res)
        })
        .catch(async (err) => {
          this.$store.dispatch('loading/hide')
          if (err.msg !== 'required-culture/not-found') {
            this.conectionTry = this.conectionAttempt > 3
            if (this.conectionAttempt < 10) {
              const time = this.timeConectionAttempt = this.conectionAttempt <= 3 ? 2 : (this.conectionAttempt <= 6 ? 5 : 10)
              this.intervalConection = setInterval(() => {
                this.timeConectionAttempt--
                if (this.timeConectionAttempt <= 0) {
                  clearInterval(this.intervalConection)
                }
              }, 1000)
              await sleep(time * 1000)
              const res = await this.tryConection()
              return res
            }
          } else {
            this.conectionAttempt = 0
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          throw err
        })
    },
    getRequiredCulture () {
      this.$store.dispatch('loading/show')
      requiredCultureService.getOneByTokenId(this.$route.params.id)
        .then((res) => {
          if (res.executed) {
            this.$store.dispatch('loading/hide')
            if (res.data.status === 'completed') {
              this.showDialog('/img/expiracion.png', this.$i18n.t('actualEvaluation.expiration_date'))
            } else {
              let timezone = res.data.timeZone.split('UTC')[1].split(')')[0]
              let releasedAtParsed = Date.parse(res.data.releaseAt.split('Z')[0] + timezone) / 1000
              let deadLineAtParsed = Date.parse(res.data.deadlineAt.split('Z')[0] + timezone) / 1000
              if (releasedAtParsed > parseInt(Date.now() / 1000)) {
                this.showDialog('/img/reloj.png', this.$i18n.t('actualEvaluation.before_date'))
              } else if (deadLineAtParsed < parseInt(Date.now() / 1000)) {
                this.showDialog('/img/expiracion.png', this.$i18n.t('actualEvaluation.expiration_date'))
              } else {
                this.responseParse(res.data)
                if (res.data.dimensionsScores.length !== 0) {
                  this.calcAverage(res.data.dimensionsScores)
                }
              }
            }
          } else {
            this.$store.dispatch('loading/hide')
            this.showDialog('/img/alerta.png', this.$i18n.t('actualEvaluation.invalid_token'))
          }
        })
    },
    responseParse (res) {
      this.dimensionEndScore = res.dimensionsEndScore
      if (this.dimensionEndScore !== undefined) {
        let i = 0
        for (const culture of this.dimensionEndScore['responsible']) {
          this.responsible[i].name = culture.name
          this.responsible[i].final_point = culture.score
          i++
        }
        i = 0
        for (const culture of this.dimensionEndScore['humanist']) {
          this.humanist[i].name = culture.name
          this.humanist[i].final_point = culture.score
          i++
        }
        i = 0
        for (const culture of this.dimensionEndScore['competitive']) {
          this.competitive[i].name = culture.name
          this.competitive[i].final_point = culture.score
          i++
        }
      }
    },
    calcAverage (dimensionsScores) {
      for (let cultures of dimensionsScores) {
        let i = 0
        for (const culture of cultures['responsible']) {
          this.responsible[i].name = culture.name
          this.responsible[i].actual_point += culture.score
          i++
        }
        i = 0
        for (const culture of cultures['humanist']) {
          this.humanist[i].name = culture.name
          this.humanist[i].actual_point += culture.score
          i++
        }
        i = 0
        for (const culture of cultures['competitive']) {
          this.competitive[i].name = culture.name
          this.competitive[i].actual_point += culture.score
          i++
        }
      }
      for (let k = 0; k < 3; k++) {
        this.responsible[k].actual_point = (this.responsible[k].actual_point / dimensionsScores.length).toFixed(2)
        this.humanist[k].actual_point = (this.humanist[k].actual_point / dimensionsScores.length).toFixed(2)
        this.competitive[k].actual_point = (this.competitive[k].actual_point / dimensionsScores.length).toFixed(2)
      }
      this.calcDispersion(dimensionsScores)
    },
    calcDispersion (dimensionsScores) {
      let responsibleDeviations = []
      let humanistDeviations = []
      let competitiveDeviations = []
      let deviation = 0
      // Por cada tipo de cultura inicializo cada posicion en 0 para evitar NaN
      for (let index = 0; index < 3; index++) {
        responsibleDeviations[index] = 0
        humanistDeviations[index] = 0
        competitiveDeviations[index] = 0
      }
      for (let cultures of dimensionsScores) {
        let i = 0
        for (const culture of cultures['responsible']) {
          deviation = Math.pow(culture.score - this.responsible[i].actual_point, 2)
          responsibleDeviations[i] += deviation
          i++
        }
        i = 0
        for (const culture of cultures['humanist']) {
          deviation = Math.pow(culture.score - this.humanist[i].actual_point, 2)
          humanistDeviations[i] += deviation
          i++
        }
        i = 0
        for (const culture of cultures['competitive']) {
          deviation = Math.pow(culture.score - this.competitive[i].actual_point, 2)
          competitiveDeviations[i] += deviation
          i++
        }
      }
      for (let j = 0; j < 3; j++) {
        let variance = responsibleDeviations[j] / dimensionsScores.length
        this.responsible[j].dispersion = Math.sqrt(variance).toFixed(2)
        variance = humanistDeviations[j] / dimensionsScores.length
        this.humanist[j].dispersion = Math.sqrt(variance).toFixed(2)
        variance = competitiveDeviations[j] / dimensionsScores.length
        this.competitive[j].dispersion = Math.sqrt(variance).toFixed(2)
      }
    },
    validate (value, culture, index) {
      const pattern = /^[0-9][0-9]?$|^100$/
      if (!pattern.test(value)) {
        if (culture === 'responsible') {
          if (this.responsible[index].final_point < 0 || this.responsible[index].final_point > 100) {
            this.responsible[index].final_point = 0
          } else {
            this.responsible[index].final_point = 0
          }
        } else if (culture === 'competitive') {
          if (this.competitive[index].final_point < 0 || this.competitive[index].final_point > 100) {
            this.competitive[index].final_point = 0
          } else {
            this.competitive[index].final_point = 0
          }
        } else {
          if (this.humanist[index].final_point < 0 || this.humanist[index].final_point > 100) {
            this.humanist[index].final_point = 0
          } else {
            this.humanist[index].final_point = 0
          }
        }
      }
    },
    showDialog (icon, text) {
      this.outIntervalDialog = true
      this.dialogIcon = icon
      this.dialogText = text
    },
    saveScore () {
      this.$store.dispatch('loading/show')
      this.dimensionEndScore = {
        responsible: [],
        competitive: [],
        humanist: []
      }
      let endScore = {}
      for (const dim of this.responsible) {
        endScore = {
          name: dim.name,
          score: parseInt(dim.final_point)
        }
        this.dimensionEndScore['responsible'].push(endScore)
      }
      for (const dim of this.humanist) {
        endScore = {
          name: dim.name,
          score: parseInt(dim.final_point)
        }
        this.dimensionEndScore['humanist'].push(endScore)
      }
      for (const dim of this.competitive) {
        endScore = {
          name: dim.name,
          score: parseInt(dim.final_point)
        }
        this.dimensionEndScore['competitive'].push(endScore)
      }
      this.conectionCallBackResponse = () => {
        this.$store.dispatch('loading/hide')
        this.$store.dispatch('alert/success', this.$t(`evaluations.calibrated`))
      }
      return this.tryConection(this.runSaveScore)
    },
    runSaveScore () {
      return requiredCultureService.updateEndScore(this.$route.params.id, this.dimensionEndScore)
      // .catch((err) => {
      //   this.$store.dispatch('loading/hide')
      //   this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      // })
    }
  }
}
</script>
