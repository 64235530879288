
export default {
  opetaion_init: 'An "Organizational Report" download process has started, this process may take a few minutes, you can see your progress in the download panel',
  copyright: 'OCC - All rights reserved',
  model: 'The model',
  ranking: 'Ranking',
  occ_tool: 'OCC tool',
  culture: 'Culture',
  subculture: 'Subculture',
  current_culture: 'Current Culture',
  current_culture_organization: 'Current organizational culture',
  open_questions: 'Open questions',
  commitment_belonging: 'Commitment + Belonging',
  selected: 'Index',
  no_demographic_items_selected: 'No demographic cut has been selected',
  error_generating_report: 'Unable to process your transaction',
  index: 'Index',
  topic: 'Topic',
  page: 'Page',
  current: 'Current',
  required: 'Required',
  humanist: 'Humanist',
  competitive: 'Competitive',
  responsible: 'Person responsible',
  our_recommendations: 'Our recommendations',
  introduction: 'Introduction',
  tool_desc: 'Tool description',
  understanding_results: 'Understanding the results',
  result_analysis: 'Interpreting the results',
  results: 'Results',
  results_per_dimensions: 'Results by dimensions',
  results_per_dimensions_actual_culture: 'Results by current culture dimension',
  results_per_dimensions_actual_culture_text: 'Total number of answers per value assigned to each dimension.',
  results_per_culture: 'Results by Types of Culture',
  results_per_culture_text: 'The abbreviation for each dimension is its first two letters.',
  index_stack1: [
    '1.1 What is Organizational Culture?\n',
    '1.2 Why measure Organizational Culture?\n'
  ],
  index_stack1_req: [
    '1.1 What is Organizational Culture?\n',
    '1.2 Why measure sub-cultures (Cuts)?\n'
  ],
  index_stack2: [
    '2.1 What does the Organizational Culture Compass (OCC) tool measure?\n',
    '2.2 Types of Culture\n',
    '2.3. Dimensions of Culture\n'
  ],
  index_stack3: [
    '3.1 Response rate',
    '3.2 Current context of the Organization\n',
    '3.3 Interpretating the Results\n',
    '3.4 What to do with the Results?\n'
  ],
  questionaire_result: 'The results of the survey can be found In this section',
  index_stack4: [
    '4.1 Response Rate\n',
    '4.2 Results by Types of Culture\n',
    '4.3 Results by Dimensions\n',
    '4.4 Results by Attributes\n',
    '4.5 Ranking of Dimensions for Current Culture\n',
    '4.6 Ranking of Attributes for Current Culture\n',
    '4.7 Ranking of Attributes with the highest/lowest Current Culture dispersion\n',
    '4.8 Ranking of Trends\n',
    '4.9 Summary of Open Questions\n'
  ],
  index_stack4_req: [
    '4.1 Response Rate\n',
    '4.2 Results by Types of Culture\n',
    '4.3 Results by Dimensions\n',
    '4.4 Results by Attributes\n',
    '4.5 Ranking of Dimensions with the Highest and Lowest Current Culture scores\n',
    '4.6 Ranking of Current Culture Attributes by Cut\n',
    '4.7 Ranking of Dimensions Gaps\n',
    '4.8 Attribute Gaps - Current Culture. Cut vs. Organization\n',
    '4.9 Attribute Gaps - Current Culture. Cut vs. Required\n',
    '4.10 Dispersion Analysis - Current Culture by Cut in terms of Attributes\n',
    '4.11 Ranking of Current Culture Trends\n',
    '4.12 Summary of Open Questions\n'
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    '1. Make sure that Engagement is given strategic importance ', { text: 'Engagement', italics: true }, ' as a lever to achieve outstanding and sustainable results.\n\n',
    // tslint:disable-next-line:max-line-length
    '2. Remember that ', { text: 'Engagement', italics: true }, ' and Organizational Culture complement each other; managing both is vital for attracting and retaining talent.\n\n',
    // tslint:disable-next-line:max-line-length
    '3. Empower each leader with responsibility for managing the ', { text: 'Engagement', italics: true }, ' and Culture. Human management areas facilitate and support.\n\n',
    // tslint:disable-next-line:max-line-length
    '4. When defining plans to close gaps, be inclusive and participatory. Mobilizing collective intelligence generates a higher level of commitment.\n\n',
    // tslint:disable-next-line:max-line-length
    '5. Tracking the process in short cycles is vital. Look for creative ways to close the gaps and measure frequently. Our Pulse tool is designed for this..\n\n',
    // tslint:disable-next-line:max-line-length
    '6. Always disclose results openly and transparently and include them in the leadership performance management process.\n\n',
    // tslint:disable-next-line:max-line-length
    '7. Finally, remember the importance of maintaining focus. Fewer high-impact actions are better than many actions without positive effects.'
  ],
  // Introduction
  what_is_organization_culture: 'What is Organizational Culture?',
  // tslint:disable-next-line:max-line-length
  organization_culture_definition: 'Organizational Culture is understood as the way of thinking, feeling and acting that distinguishes the members of an organization. Culture is the reflection of the deepest beliefs, of values, standards and traditions that define the Organization\'s personality.\n\n',
  organization_culture_definition2: 'Organizational Culture is the final frontier of competitive advantages and generates a sustainable differentiation that is difficult to imitate. The culture of an organization is reflected in the daily habits of its members, in its observable features and the way in which decisions are made . \n\n',
  organization_culture_definition3: 'Organizational Culture is directly related to the execution of the strategy and the achievement of the results; many organizations fail to execute their strategy because they do not have an adequate and coherent culture.\n\n',
  // Para qué definir la cultura organizacional
  for_what: 'Why measure organizational culture??',
  for_what_cuts: 'Why measure sub-cultures (cuts)?',
  for_what1: 'With the complexity of today\'s world and the challenges that arise in attracting clients, talent or investors, cultural capital has become the new priority in relation to the competitive advantages of an organization. The culture and values ​​it represents are as important as the quality of the products and services an organization offers. \n\n',
  for_what1_cuts: 'Although organizations have a Culture in general, they also have Subcultures. It is important to know what these are, in order to better understand the organization.\n\n',
  for_what2: 'The objective of diagnosing culture is to know the current state of the organization\'s culture and to compare it with the culture required or already defined. Positive and limiting aspects of the culture and priorities to work on can be defined from this information, as well as internal comparisons between different populations and trends. \n\n',
  for_what2_cuts: 'It is of interest to understand the subcultures, their similarities and differences with the Current and Required Culture of the Organization and the strengths and limiting aspects of each one. From this information, more specific and relevant intervention plans in Culture Transformation can be designed.\n\n',
  for_what3: 'A culture is measurable, dynamic, transformable and unique. To this extent, there are no better cultures than others, simply more or less adequate; it is not about comparing organizations, it is about knowing the reality and contrasting it with their needs and desires. \n\n',
  for_what3_cuts: 'Subcultures (also called cuts), can be made by area of ​​the organization, position level, geographic region, seniority, gender or origin (nationality or company from which it comes if it is after a merger or acquisition).\n\n',
  // Descripción de la herramienta occ
  what_the_tool_measure: 'What does the organizational culture compass (OCC) tool measure?',
  what_the_tool_measure_text: 'OCC\'s questionnaire is based on observable behaviors associated with values ​​that define the Organizational Culture. The results presented in this report are based on the perceptions of the people who answered the questionnaire. The 72 Attributes (behaviors) form nine dimensions organized in three types of culture.\n\n',
  what_the_tool_measure_text2: 'Analyzing data enables elements of the culture that predominate and ones that are less obvious to be identified.\n\n',
  // Tipos de cultura
  culture_types: 'Types of cultures',
  responsible_culture: 'Responsible culture',
  competitive_culture: 'Competitive culture',
  humanist_culture: 'Humanistic culture',
  responsible_text: 'An organization admired by society and its clients, a "good citizen", orientated towards its stakeholders and with ethical and impeccable management. It comprises the following dimensions:',
  competitive_text: 'An efficient and vibrant, results organization. It balances innovation and the effectiveness of its current processes through the management of world-class leaders. It comprises the following dimensions:',
  humanist_text: 'An organization which understands that it needs competent, committed talent if it is to achieve its results.  An company that attracts, develops and retains the best talent, promotes excellent relationships and effective and transparent communication. It comprises the following dimensions:',
  what_is_engagement_text: ['At OCC Solutions we view ', { text: 'Engagement', italics: true }, '  as a high level of emotional commitment, a sense of belonging combined with enthusiasm and passion for work and for the Organization, which manifests itself in a permanent desire to always do the best.'],
  // Dimensiones de cultura
  culture_dimensions: 'Culture Dimensions',
  culture_dimensions_next: 'The nine Dimensions of Culture are described below:',
  yes: 'Yes',
  no: 'No',
  // Principios
  values_positive: 'Leadership and a culture centered on principles define and determine guidelines for human behavior that become the moral compass of the organization. Principles can be understood as values ​​and today it is clear that their experience is essential to ensuring the survival of organizations.',
  values_negative: ['For organizations that ', { text: 'do not', decoration: 'underline' }, ' focus on principles, the end justifies the means. Values neither exist nor are experienced, and the associated conduct is not an example of behavior, morals or ethics. They usually have a bad reputation and do not build trust with their stakeholders'],
  // Sostenibilidad
  sustainability_positive: 'A culture of sustainability promotes the efficient use of current resources to ensure the future sustainability of the organization. It gives the same importance to all stakeholders and seeks balanced results in financial, human, social and planet issues.',
  sustainability_negative: ['A culture ', { text: 'do not', decoration: 'underline' }, ' A culture that does not focus on sustainability is oriented towards obtaining short-term results, prioritizing achievements for shareholders over other stakeholders. Social responsibility, the planet and future generations ', { text: 'are not', decoration: 'underline' }, ' the priority.'],
  // Clientes
  customers_positive: 'A customer-centric culture focuses its efforts on understanding, meeting and exceeding customer needs and expectations. When processes and systems, structure, and people\'s competences are defined, current and future customer realities are taken into account.',
  customers_negative: ['A ', { text: 'non', decoration: 'underline' }, ' customer-centric culture defines the customer as a means to obtain results and does not show interest in their satisfaction or loyalty. The vision is more inward, towards processes, control and short-term financial results. Requests, complaints, claims and suggestions are seen as a nuisance and customers, at best, are only important to those who are in direct contact with them.'],
  // Innovación
  innovation_positive: 'Innovation is viewed as the ability to create new realities, it is a vital aspect for the success of organizations in today\'s world. A culture of innovation fosters creativity, initiative and the ability to reinvent yourself, in order to prosper and compete over time, while allowing you to take risks and learn from mistakes.',
  innovation_negative: ['In organizations that they ', { text: 'do not', decoration: 'underline' }, '  value innovation, they seek to maintain and preserve what is known to them. Most people are paid to "do their job and obey" and the ability to innovate is, at best, the privilege of a few. There is inflexibility, difficulty in adapting and changing, fear of taking risks, and mistakes are often punished.'],
  // Logro
  achievement_positive: 'Organizations must deliver positive results in order to make progress and achieve their goals. A culture of effectiveness and obtaining results promotes achievement-oriented behaviors and processes with a high level of performance. A direct line is created between the contribution of individual and team performance and the performance of the organization. Positive results are the consequence of a culture of high performance and commitment.',
  achievement_negative: ['In organizations where results ', { text: 'are not', decoration: 'underline' }, '  valued, they are not important, and when they are not achieved, there are usually "excuses or culprits". There is usually a lack of commitment and sense of belonging. Compliance with processes and controls are more important than achieving defined goals.'],
  // Liderazgo
  leadership_positive: 'The quality and capability of leaders is essential to understanding the success of organizations; understanding leadership as the art of mobilizing and guiding people to ensure the achievement of common objectives and purposes. In this dimension, we analyze how leaders achieve commitment, generate trust, become interested in developing people, give autonomy and create environments that empower and accompany their teams in their development.',
  leadership_negative: ['In organizations where transformative leadership ', { text: 'is not', decoration: 'underline' }, ' valued, people are simply one more resource that is dedicated only to implementing the goals, objectives and tasks defined by the bosses. Managers assign activities and control results. Everything is managed through imposition, authoritarianism and distrust, and there is usually a high level of "micro-management".'],
  // Talento
  talent_positive: 'A people-centered culture seeks to attract, develop and keep the best talent in the organization as the main lever for achieving its goals. The aim is to have competent people with high commitment and performance levels who feel proud of belonging to the organization. These organizations strive to deserve the enthusiasm and intelligence of all, with their vision, their coherence with values, their leadership and their achievements.',
  talent_negative: ['For companies where people ', { text: 'are not', decoration: 'underline' }, ' valued, people are resources used to achieve their objectives, regardless of their individual objectives and goals. People\'s emotions, skills and development are not important or, at most, they are only important for the Human Resources areas.'],
  // Relaciones
  relationship_positive: 'In these organizations, the connection between the quality of relationships and results is clear. These companies promote teamwork, solidarity and the ability to join forces in order to achieve common goals. Topics such as shared targets, trust, coordination, interdependence and collaboration are common in these types of organizations.',
  relationship_negative: 'Organizations that value only individual work, internal competition, and the win-lose game describe the everyday way of acting and leading. Relationships are strictly professional, collaboration is understood as a favor and division between areas reflects a clear sense of independence. Energy is wasted on bad relationships and mistrust.',
  // Comunicación
  communication_positive: 'The way in which people communicate and the stories that are told are part of and reflect and define the culture and leadership styles. In an organization with a strong communication culture, information is shared transparently, is listened to carefully, questions are encouraged, and relationships of trust and mutual respect are created and maintained. Vision and goals are clearly communicated.',
  communication_negative: ['In organizations where communication ', { text: 'is not', decoration: 'underline' }, ' valued, "he who has information has the power." Information does not flow in all directions, nor is the importance of timeliness and transparency valued. Strategies and objectives are not clearly communicated. There is often no “time” to listen to people, and questions are perceived as inappropriate and unnecessary. The complexity of communications reflects mistrust, disrespect, and hierarchical leadership styles.'],
  // Entendimiento de los resultados
  current_context: 'Current Context of the Organization',
  current_context_text: 'When interpreting the results, it is essential to take into account the current context of the organization and the population being analyzed. The following questions can give you some guidelines:\n\n',
  current_context_questions: [
    'What are our current challenges?',
    'Where are we going?',
    'What do we want to achieve?',
    'What are our opportunities and threats?',
    'What are our strengths and opportunities for improvement?\n\n'
  ],
  results_analysis: [
    'Current Culture is displayed on a -4 to +4 scale.',
    'If the average of an Attribute is greater than or equal to 2 in the Current Culture, it indicates a positive Culture.',
    'If the average of an Attribute is less than or equal to 0 in the Current Culture, it indicates a potentially limiting Culture.',
    'If the average of an Attribute is between 0 and + 2, it indicates a neutral Culture. In this case, more analysis is required, in order to understand the situation.',
    'The biggest gaps between Required Culture and Current Culture indicate an area of ​​opportunity and should generate strategies to close gaps.',
    'Attributes with greater dispersion indicate less consensus and require more detailed analysis.',
    'The trend means the evolution of the result. If, compared to the previous evaluation, it improved by more than 0.5, the value will be highlighted in bold. If it deteriorated by more than 0.5, the value will be underlined. Between -0.5 and +0.5 referring to the previous result, it will remain the same.\n\n'
  ],
  results_analysis_req_p1: [
    'If the difference between the Current Culture of the Cut and the Required Culture is greater than or equal to 1, the sign (+) will appear, if it is less than 1 the sign (-) will appear. In both cases there is misalignment.',
    'If the average of an Attribute is greater than or equal to 2, it indicates a positive Culture. If the average of an Attribute is less than or equal to 0, it indicates a potentially limiting Culture. If the average of an Attribute is between 0 and + 2, it indicates a neutral Culture. In this case, more analysis is required, in order to understand the situation.',
    'The biggest gaps between the Organization\'s  Current Subculture and Required Subculture need special analysis. When this information is being analyzed, it is important to review the Desired Culture of said Subculture (if it exists). If the valuation is high it will be easier to close the gap and vice versa.',
    'In all cases, it is important to analyze the gap between each Subculture and the Organization\'s Required and Current Culture. This is essential for defining specific plans for each Subculture. Reviewing the Desired Culture, if measured, helps to assess the ease or difficulty of the process.  \n\n'
  ],
  results_analysis_req_p2: [
    'Dimensions and Attributes with greater dispersion indicate less consensus and require more detailed analysis.',
    'The trend means the evolution of the result. If, compared to the previous evaluation, it improved by more than 0.5, the value will be highlighted in bold. If it deteriorated by more than 0.5, the value will be underlined.\n\n'
  ],
  what_is_organizational_culture: 'What is Organizational culture?',
  // tslint:disable-next-line:max-line-length
  what_is_organizational_culture_text: [
    'Organizational Culture is understood as the way of thinking, feeling and acting that distinguishes the members of an organization. Culture is the reflection of the deepest beliefs, of the values, standards and traditions that define the organization\'s personality. \n\n',
    'Organizational Culture is the final frontier of competitive advantages and generates a sustainable differentiation that is difficult to imitate. The culture of an organization is reflected in the daily habits of its members, in its observable features and the way in which decisions are made.\n\n',
    'Organizational Culture is directly related to executing strategy and achieving results; many organizations fail to execute their strategy because they do not have an adequate and coherent culture.\n\n'
  ],
  what_is_engagement_positive: [
    'Give your best every day and have a passion for work.\n\n',
    'Emotional commitment to the purpose, objectives and values ​​of the Organization.\n\n',
    'Motivation to contribute to the success of the Organization with a greater sense of well-being.\n\n',
    'Trust, integrity, two-way commitment and communication between the Organization and its members.\n\n',
    'High level of enthusiasm and dedication that a person feels towards their work.'
  ],
  answers_rate_items: [
    'How was the measurement process communicated?\n',
    'Was the importance of this process hinted at?\n',
    'What does the fact that not everyone answered indicate?\n\n'
  ],
  how_does_our_model_works: 'How does our model work?',
  what_do_with_results: 'What to do with the Results?',
  what_do_with_results_text: 'After understanding these results you are recommended to take the following steps:\n\n',
  results_steps: [
    'Analyze the results identifying similarities and differences between the Current Culture and the one defined in the Organization.\n\n',
    'Identify the Organization\'s strengths, in order to leverage a Culture Transformation or Alignment process.\n\n',
    'Identify the main potentially limiting factors and gaps in Culture and draw up a plan of action for those that could affect the development of your strategy most.\n\n',
    'Always analyze the report in a comprehensive way also connecting information between Types and Dimensions.\n\n',
    'Analyze aspects where there is greater dispersion. This will indicate where there could be bigger differences between people\'s or area\'s perceptions.\n\n',
    'Make sure that people who need the results are notified of them properly.'
  ],
  results_steps_req: [
    'Get to know the environment of each Subculture, its strategic plan and its main challenges as much as possible.\n\n',
    'In terms of the Current Culture, analyze the results by identifying similarities and differences between the Organization\'s Subculture and Culture. Small gaps mean similarities, big gaps can mean challenges, obstacles or opportunities for improvement.\n\n',
    'Identify the strengths and limiting factors of each Subculture to understand reality and plan a process of transformation of Culture more pertinent and appropriate to each reality.\n\n',
    'Carefully review the assessment of the Desired Culture in each case if you have the information, as this will give you an indication of how easy or not the Transformation process can be or how motivated or inspired people can be.\n\n',
    'The strengths of each Subculture and the gaps in relation to the Required Culture are essential information for defining specific plans of action.\n\n',
    'Analyze the aspects in which there is greater dispersion. This will indicate those Attributes where there may be greater differences between the perceptions of people / areas.\n\n',
    'Make sure that people who need the results are notified of them properly.'
  ],
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text1: 'Our model allows us to measure six dimensions which indicate people\'s level of commitment and sense of belonging to the Organization. Each dimension in turn has 3 attributes and each attribute has 3 questions, called variables. The six dimensions are:',
  how_does_our_model_works_text2: 'Each of the 6 dimensions has three attributes:',
  my_inspiration: 'My inspiration',
  sense_of_purpose: 'Sense of purpose',
  transcendental_work: 'Significant work',
  they_know_me: 'They know me',
  my_job: 'My job',
  reputation_of_the_organization: 'Reputation of the Organization',
  reputation_of_my_area: 'Reputation of my area',
  work_enviroment: 'Work environment',
  positive_work_enviroment: 'Positive work environment',
  relationships_quality: 'Quality of relationships',
  peoples_treat: 'Treatment of people',
  equity_and_transparency: 'Fairness and transparency',
  my_team: 'My team',
  team_dynamics: 'Team dynamics',
  communication_and_coordination: 'Communication and coordination',
  agility_and_decision_making: 'Agility and decision making',
  my_development_and_learning: 'My development and learning',
  opportunities_in_my_role: 'Growth opportunities in my rolel',
  opportunities_in_the_organization: 'Opportunities for growth in the Organization',
  learning_culture: 'Learning culture',
  the_leaders: 'The leaders',
  my_leader: 'My leader',
  support: 'Support ',
  our_leaders: 'Our leaders',
  methodology: 'Methodology',
  // tslint:disable-next-line:max-line-length
  completed_questionaires_persons: 'This table shows the number of people who were included in the process and which group completed the questionnaire.',
  methodology_conclusion: 'Additionally, in the final part of the survey, three open questions are asked, and these are shown graphically in the report.',
  results_text: 'In this section you will find the results of the survey.',
  response_rate: 'Response rate.',
  // tslint:disable-next-line:max-line-length
  response_rate_description: 'This table shows the number of people who were included in the process and which group completed the questionnaires. The higher the response rate, the more accurate the information will be.',
  total_obtained: 'Total answered:',
  totals: 'Totals',
  total_sent: 'Total expected:',
  answers_rate: 'Response rate',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'It is important to understand the results in relation to the response rate. If the response rate was not what you expected, you should ask yourself the following:\n\n',
  general_score: 'Results',
  results_by_dimension: 'Results by dimension',
  results_by_attribute: 'Results by attribute',
  results_by_dim_attr: 'Results by dimension - attribute',
  total: 'Total',
  attribute: 'Attribute',
  limitant_attribute: 'Limiting Attribute',
  positive_attribute: 'Positive Attribute',
  act: 'ACT',
  disp: 'DISP',
  tend: 'TEND',
  req: 'REQ',
  act_org: 'CURRENT ORG',
  act_cut: 'CURRENT CUT',
  diff: 'BRE',
  diff2: 'GAP CUT-ORG',
  diff3: 'GAP CUT-REQ',
  current_population: 'Current score',
  preview_population: 'Previous score',
  rate: 'Trend',
  actual_organization: 'Organization Score',
  gap: 'Gap',
  culture_alignment: 'Alignment with culture',
  purpose: 'Purpose',
  future_vision: 'Future vision',
  autonomy: 'Autonomy',
  strengths_and_talents: 'Strengths and talents',
  my_contribution: 'My contribution',
  motivation: 'Motivation',
  they_value_me: 'They value me',
  change_value: 'Change value',
  performance: 'Execution',
  recognized_organization: 'Recognized organization',
  organization_performance: 'Organization performance',
  pride_of_belonging_organization: 'Pride in belonging to the Organization',
  recognized_area: 'Recognized area',
  area_performance: 'Performance of the area',
  pride_of_being_part_area: 'Pride in being part of the area',
  materials_and_equipment: 'Materials and equipment',
  offices_and_dress_code: 'Offices and dress codes',
  balance_personal_professional: 'Balance between personal and professional life',
  being_myself: 'Be myself',
  freedom_of_expression: 'Freedom of expression',
  interpersonal_relationships: 'Interpersonal relationships',
  care_for_people: 'Care for people',
  inclusive_workplace: 'Inclusive workplace',
  respectful_treatment: 'Respectful treatment',
  equal_opportunities: 'Equal opportunities',
  salary_and_benefits: 'Salary and benefits',
  recognition_culture: 'Recognition culture',
  trust_quality_relationships: 'Trust and quality in relationships',
  responsibility_meeting_goals: 'Responsibility for meeting targets',
  workload_balance: 'Balance in workload',
  team_network: 'Team network',
  communication_team: 'Communication in my team',
  diversity: 'Diversity',
  agility_processes: 'Agility in processes',
  innovation: 'Innovation',
  achievement: 'Achievement',
  leadership: 'Leadership',
  talent: 'Talent',
  relationship: 'Relations',
  communication: 'Communication',
  values: 'Principles',
  sustainability: 'Sustainability',
  customers: 'Clients',
  access_transparency_information: 'Access to and transparency of information',
  autonomous_learning: 'Autonomous Learning',
  development_potential: 'Development of my potential',
  expectations_role: 'Expectations of my role',
  career_plan: 'Career plan',
  future_organization: 'Future in the Organization',
  horizontal_mobility: 'Horizontal mobility',
  learning_tracking: 'Tracking learning',
  genuine_interest: 'Genuine interest',
  admiration: 'Admiration',
  transparency_honesty: 'Transparency and honesty',
  clear_transparent_objectives: 'Clear and transparent objectives',
  coaching_feedback: 'Coaching and feedback',
  leader_access: 'Access to leader',
  systematic_thinking: 'Systematic thinking',
  strategic_planning: 'Strategic planning',
  social_intelligence_collaboration: 'Social intelligence and cooperation',
  ranking_of_dimensions: 'Ranking of dimensions for current culture',
  dimension: 'Dimension',
  initial: 'Init',
  initials: 'Acronyms',
  actual: 'Current',
  actual_org: 'Current Org.',
  actual_organizational: 'Organizational Current',
  actual_cut: 'Current Cut',
  difference: 'Gap',
  difference_cut_org: 'Gap Cut-Org ',
  difference_cut_org_2: 'Gap Cut-Org',
  difference_cut_req: 'Gap Cut- Req',
  difference_cut_req_2: 'Gap Cut- Req',
  difference_cut_required: 'Gap Cut- Required',
  trend: 'Trend',
  dimensions_with_higher_lower: 'Ranking of the Dimensions - Highest and lowest scores',
  current_dimensions_with_higher_score: 'Dimensions with the highest score according to Current Culture',
  current_dimensions_per_higher_score_org: 'Highest Scored Dimensions by Organization',
  current_dimensions_per_higher_score_cut: 'Highest Scored Dimensions by Cut',
  required_dimensions_with_higher_score: 'Highest Scored Dimensions by Required Culture',
  current_dimensions_with_lower_score: 'Dimensions with the lowest score according to Current Culture',
  current_dimensions_per_lower_score_org: 'Lowest Scored Dimensions by Organization',
  current_dimensions_per_lower_score_cut: 'Lowest Scored Dimensions by Cut',
  required_dimensions_with_lower_score: 'Dimensions with the lowest score according to Required Culture',
  current_organization: 'Current organization',
  dimensions_with_higher_score: 'Highest Scored Dimensions',
  dimensions_with_lower_score: 'Lowest Scored Dimensions',
  dimensions_with_higher_dispersion: 'Dimensions with highest dispersion',
  dimensions_with_lower_dispersion: 'Lowest dispersion dimensions',
  ranking_of_the_attributes: 'Ranking of attributes for current culture',
  ranking_of_the_attributes_cut: 'Ranking of attributes for the current cutting culture',
  ranking_of_the_attributes_org: 'Ranking of attributes for current organizational culture',
  required_ranking_attributes: 'Ranking of attributes for the required culture',
  ranking_of_the_attributes_dispersion: 'Ranking of attributes with the highest / lowest dispersion of current culture',
  attributes_with_higher_score: 'Highest scored attributes',
  attributes_with_lower_score: 'Lowest scored attributes',
  diff_attributes_with_higher_score: 'Highest positive gaps',
  diff_attributes_with_lower_score: 'Highest negative gaps',
  dimensions_higher_pos_diff: 'Highest positive gaps',
  dimensions_higher_neg_diff: 'Highest negative gaps',
  attributes_higher_pos_diff: 'Highest positive gaps',
  attributes_higher_neg_diff: 'Highest negative gaps',
  dimensions_diff_analysis: 'Analysis of the gaps in the dimensions',
  attributes_diff_analysis: 'Gap analysis for attributes',
  dispersion_ranking: 'Ranking of Attributes with the highest / lowest dispersion of current culture',
  ranking_of_the_variables: 'Ranking of variables',
  ranking_of_rates: 'Trend ranking',
  variable: 'Variable',
  variables_with_higher_score: 'Variables with the highest current score',
  variables_with_lower_score: 'Variables with the lowest current score',
  dispersion: 'Dispersion',
  dispersion_cut: 'Dispersion Cut',
  dispersion_org: 'Org dispersion',
  dispersion_by_attributes: 'Dispersion by attribute',
  dispersion_by_variables: 'Dispersion by variable ',
  attributes_with_higher_dispersion: 'Attributes with highest dispersion',
  attributes_with_lower_dispersion: 'Attributes with lowest dispersion',
  preview_dispersion: 'Previous dispersion',
  current_dispersion: 'Current dispersion',
  variables_with_higher_dispersion: 'Variables with highest dispersion',
  variables_with_lower_dispersion: 'Variables with lowest dispersion',
  rates_by_dimension: 'Trends by dimension',
  variation: 'Variation',
  rates: 'Trends',
  gaps: 'Gaps',
  dimensions_ranking: 'Ranking of Dimensions with Highest and Lowest Scores',
  highest_positive_rate_attribute: 'Major positive trends',
  highest_negative_rate_attribute: 'Major negative trends',
  highest_positive_rate_variable: 'Highest positive trends by variable',
  highest_negative_rate_variable: 'Highest negative trends by variable',
  rates_by_variables: 'Trends by variables',
  rates_by_attributes: 'Trends by attributes',
  open_questions_summary: 'Closed question graphs',
  word_questions_summary: 'Summary of open questions',
  the_leaders_text: 'THE LEADERS: The leaders\nin this Organization\ninspire me',
  my_development_text: 'MY DEVELOPMENT\nAND LEARNING: I see myself growing\nin this Organization\nin the future',
  my_team_text: 'MY TEAM: I am part\nof a team committed\nto the Organization',
  // tslint:disable-next-line:max-line-length
  work_enviroment_text: 'POSITIVE\nWORK ENVIRONMENT: I ​​would recommend\na friend or family member to work\nin this Organization',
  my_job_text: 'MY WORK: I\nfeel committed\nand proud to work\nin this company',
  // tslint:disable-next-line:max-line-length
  my_inspiration_text: 'MY INSPIRATION: If I werw\noffered a similar job\nin another company, I would stay\nwhere I am',
  reports: 'Reports',
  general_title: 'General',
  general_desc: 'This report has the statistics of the entire population included in the survey.',
  demographic_title: 'By demographic cuts',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'This report features population statistics filtered through certain demographic cuts; these cuts can be selected below:',
  demographic_button: 'Generate Engagement report by demographic cuts',
  display_demographic: 'Show demographic cuts',
  population: 'Population',
  sent: 'Sent',
  obtained: 'Obtained',
  AcademicDegree: 'Academic title',
  Age: 'Age',
  Antiquity: 'Seniority',
  Charge: 'Post',
  Country: 'Country',
  Department: 'Area / Department',
  Gender: 'Gender',
  JobType: 'Contract type',
  years_range: 'Between {min} and {max} years',
  my_inspiration_chart: 'My\ninspiration',
  my_job_chart: 'My\njob',
  work_enviroment_chart: 'Positive\nwork environment',
  my_team_chart: 'My\nteam',
  my_development_and_learning_chart: 'My development\nand learning',
  the_leaders_chart: 'The\nleaders',
  cover: {
    byItems: 'Report by population',
    general: 'General Report'
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  active_employees: 'Active employees',
  average: 'Sector average',
  people: 'people',
  note: 'Notes:',
  colors: 'Colors',
  dim_color: 'Dimension color ',
  meaning: 'Meaning',
  previous_measurement: 'Previous measurement',
  strengths: 'Strengths',
  opportunities: 'Opportunities for improvements',
  feelings: 'Feelings',
  what_is_it: 'What is it?',
  what_is_not: 'What is it not?',
  critical_situation: 'Critical situation',
  requires_attention: 'Requires urgent attention',
  insufficient: 'Insufficient',
  aceptable: 'Acceptable',
  very_good: 'Very good',
  remarkable: 'Outstanding',
  how_to_read: 'How to read the report',
  title: 'OCC - Current Culture Report',
  combined_title: 'Current Culture Report + Required',
  att_var: 'Attribute - Variable',
  dispersion_by_dimensions: 'Dispersion by dimensions',
  open_questions_button: 'Additional question graphs',
  open_question_select_label: 'Select a question',
  org_dispersion: 'Organization dispersion',
  positive_attribute_footer: 'The following attributes have a high score exactly equal to the attribute ',
  negative_attribute_footer: 'The following attributes have a low score exactly equal to the attribute ',
  positive_variable_footer: 'The following variables have a high score exactly equal to the variable ',
  negative_variable_footer: 'The following variables have a low score exactly equal to the variable ',
  no_answers_modal_msg: 'The current survey has no responses, so a report cannot be generated.',
  positive: 'positive',
  limiting: 'limiting',
  atc: 'Act',
  values_code: 'Pr',
  sustainability_code: 'Su',
  customers_code: 'Cl',
  innovation_code: 'In',
  achievement_code: 'Ac',
  leadership_code: 'Le',
  talent_code: 'Ta',
  relationship_code: 'Re',
  communication_code: 'Co',
  undefined_code: '-',
  ranking_of_gaps_dimensions: 'Ranking of dimension gaps',
  positive_gaps_dimensions_with_higher_score: 'Highest positive gaps current culture',
  negative_gaps_dimensions_with_higher_score: 'Highest negative gaps current culture',
  analysis_difference_section_cut_org_report: 'Attribute Gaps Current Culture Cut vs Organization',
  analysis_difference_section_cut_req_report: 'Attribute Gaps Current Culture Cut vs Required',
  analysis_dispersion_section_cut_req_report: 'Current Culture Dispersion Analysis by Cut at Attribute level',
  attribute_higher_dispersion: 'Attributes with highest cut dispersion',
  attribute_lower_dispersion: 'Attributes with lowest cut dispersion'
}
