
<template>
  <v-row>
    <v-col xs="12" sm="6" v-for="(item, i) in demographicCuts" :key="i">
      <v-select outlined chips multiple light
        v-if="i !== 'spacer'"
        v-model="cutsSelected[i]"
        :label="$t(`reports.demographicCuts.${i}`)"
        :items="(i === 'age' || i === 'antiquity') ? demographicCuts[i][lang] : demographicCuts[i]"
        item-value='id'
        :item-text="i === 'headquarter' ? 'name': 'translate.label'"
        :menu-props="{ top: false, offsetY: true }"
        @blur="onBlur"
        @change="onChange"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ i === 'headquarter' ? item.name : item.translate.label }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption">
            (+{{ cutsSelected[i].length - 1 }} otros)
          </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'

import currentCultureAssessment from '../../services/current-culture-assessment'

export default Vue.extend({
  props: {
    lang: String,
    currentId: String,
    demographicCuts: Object
  },
  data () {
    return {
      cutsSelected: {}
    }
  },
  methods: {
    onBlur () {
      currentCultureAssessment.getPopulationByAnswers(this.currentId, this.cutsSelected)
        .then(res => this.$emit('updatePopulation', res.population))
    },
    onChange () {
      Object.keys(this.cutsSelected).forEach(key => {
        if (!this.cutsSelected[key].length) {
          delete this.cutsSelected[key]
        }
      })
      this.$emit('updateCutsSelected', this.cutsSelected)
    }
  }
})
</script>
