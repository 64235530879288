
const baseAttr = (num: number) => {
  return {
    name: `attributte${num + 1}`,
    positive: { es: '', en: '' },
    limiting: { en: '', es: '' },
    score: 0,
    attrIndex: num
  }
}
const baseDimension = (num: number) => {
  const start = 8 * num
  const dimension = []
  for (let i = start; i < (start + 8); i++) {
    dimension.push(baseAttr(i))
  }
  return dimension
}

export default {
  base () {
    return {
      responsible: {
        values: baseDimension(0),
        sustainability: baseDimension(1),
        customers: baseDimension(2)
      },
      competitive: {
        innovation: baseDimension(3),
        achievement: baseDimension(4),
        leadership: baseDimension(5)
      },
      humanist: {
        talent: baseDimension(6),
        relationship: baseDimension(7),
        communication: baseDimension(8)
      }
    }
  }
}
