
import dataObj from '../utils/data-obj'

export default {
  methods: {
    $calculateWithsByScore (score) {
      const result = (((score - 2) * 120) / 2) - 5
      return result
    },
    $calculateWithsByAverage (score) {
      const result = (((score - 2) * 180) / 2) - 2
      return result
    },
    $generateAttributes () {
      let tables = []
      // 309DE0 EB493C 48BBA1
      let dimensions = [
        this.$t('requiredReport.values'),
        this.$t('requiredReport.sustainability'),
        this.$t('requiredReport.customers'),
        this.$t('requiredReport.innovation'),
        this.$t('requiredReport.achievement'),
        this.$t('requiredReport.leadership'),
        this.$t('requiredReport.talent'),
        this.$t('requiredReport.relationship'),
        this.$t('requiredReport.communication')
      ]
      for (let i = 0; i < 9; i++) {
        let color = ''
        if (i < 3) {
          color = '#309DE0'
        } else if (i > 2 && i < 6) {
          color = '#EB493C'
        } else {
          color = '#48BBA1'
        }
        tables.push(
          {
            table: {
              widths: [479],
              body: [
                [{ text: `4.3 ${this.$t('requiredReport.results_per_attribute')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 35, 0, 0],
            pageBreak: 'before'
          },
          {
            layout: {
              defaultBorder: '',
              fillColor: color
            },
            table: {
              widths: [479],
              body: [
                [{ text: dimensions[i].toUpperCase(), margin: [30, 5, 0, 5], bold: true, color: '#fff', fontSize: 16 }]
              ]
            },
            margin: [50, 10, 20, 20]
          },
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              body: [
                [{ text: this.$t('requiredReport.attribute'), margin: [110, 12, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: { headerRows: 1, body: [[{ text: '2\n|', margin: [0, 2, 30, 0] }, { text: '3\n|', margin: [15, 2, 15, 0] }, { text: '4\n|', margin: [30, 2, 0, 0] }]] },
                    bold: true,
                    style: { color: '#fff' },
                    border: [true, true, true, true]
                  },
                  { text: this.$t('requiredReport.required_abbreviation'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] },
                  { text: this.$t('requiredReport.previous_score_abbreviation'), margin: [5, 5, 0, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] }
                ],
                [{ text: this.attributesName[i][0], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][0].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][0].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 0), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }
                ],
                [{ text: this.attributesName[i][1], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][1].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][1].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 1), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }
                ],
                [{ text: this.attributesName[i][2], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][2].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][2].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }],
                [{ text: this.attributesName[i][3], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][3].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][3].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 3), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }],
                [{ text: this.attributesName[i][4], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][4].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][4].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 4), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }],
                [{ text: this.attributesName[i][5], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][5].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][5].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 5), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }],
                [{ text: this.attributesName[i][6], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][6].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][6].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 6), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }],
                [{ text: this.attributesName[i][7], color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { layout: { defaultBorder: '', fillColor: color }, table: { headerRows: 1, widths: [this.$calculateWithsByScore(this.dataAttributes[i][7].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [2, 5, 0, 5], style: { color: '#fff' } },
                  { text: this.dataAttributes[i][7].score.toFixed(2), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [5, 6, 0, 6] },
                  { text: dataObj.objectInArrayFindByNameFixed(this.previousDataAttributes[i], 'score', 7), alignment: 'center', color: '#6d7a7a', borderColor: ['', '', '', '#ddd'], border: [false, false, false, true], margin: [6, 6, 0, 6] }]
              ]
            },
            margin: [0, 0, 15, 10],
            border: [false, false, false, true]
          },
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              body: [
                [{ text: this.$t('requiredReport.dimension'), margin: [60, 10, 60, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] },
                  { text: this.$t('requiredReport.required_abbreviation'), margin: [10, 5, 10, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] },
                  { text: this.$t('requiredReport.previous_score_abbreviation'), margin: [5, 5, 5, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' }, table: { headerRows: 1, body: [[{ text: '2\n|', margin: [0, 2, 50, 0] }, { text: '3\n|', margin: [25, 2, 25, 0] }, { text: '4\n|', margin: [50, 2, 0, 0] }]] }, margin: [1, 0, 1, 0], bold: true, style: { color: '#fff' }, border: [true, true, true, true] }
                ],
                [{ text: dimensions[i], color: '#6d7a7a', border: [true, true, true, true], margin: [5, 15, 0, 15] },
                  { text: (this.dimensionAverage[i].score).toFixed(2), alignment: 'center', color: '#6d7a7a', border: [true, true, true, true], margin: [10, 15, 10, 15] },
                  { text: (dataObj.propertieOrDefault(this.previousDimensionAverage[i], 'score', '')), alignment: 'center', color: '#6d7a7a', border: [true, true, true, true], margin: [5, 15, 0, 15] },
                  { layout: { defaultBorder: '', fillColor: color }, border: [true, true, true, true], table: { headerRows: 1, widths: [this.$calculateWithsByAverage(this.dimensionAverage[i].score)], body: [[{ text: '', margin: [6, 10, 0, 10] }]] }, margin: [0, 5, 0, 5], style: { color: '#fff' } }
                ]
              ]
            },
            margin: [0, 0, 15, 20],
            border: [false, false, false, true]
          }
        )
      }
      return tables
    }
  }
}
