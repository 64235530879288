
export default {
  follow_up: 'Seguimiento',
  followup_report: 'Reporte de Seguimiento',
  followup_report_desc: 'Selecciona hasta dos demográficos para poder generar el reporte de seguimiento.',
  total_participants: 'Total Participantes',
  total_completed: 'Completados',
  total_remaining: 'Faltantes',
  totals: 'Totales',
  generate: 'Generar',
  export: 'Exportar',
  age: {
    '1': 'Menos de 25 años',
    '2': 'De 25 a 35 años',
    '3': 'De 35 a 45 años',
    '4': 'De 45 a 50 años',
    '5': 'Más de 50 años'
  },
  antiquity: {
    '1': 'Menos de 6 meses',
    '2': 'De 6 meses a 1 año',
    '3': 'De 1 a 3 años',
    '4': 'De 3 a 5 años',
    '5': 'De 5 a 10 años',
    '6': 'De 10 a 20 años',
    '7': 'Más de 20 años'
  }
}
