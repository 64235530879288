<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6"
            offset-md="3"
            class="text-center grey--text text--darken-1"
            style="margin: 170px auto!important;"
          >
            <img :src="image" width="220"/>
            <h5 class="headline mt-5" v-html="$t('culturePulses.congratulations', { progress })"></h5>
            <v-btn large
              color="primary"
              class="mt-4"
              @click="() => { $emit('continue-random') }"
            >
              {{ $t('input.continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    image: String,
    text: String,
    progress: Number
  }
}
</script>
