
import requiredCultureService from '../../../services/required-culture'

import RequiredUtils from '../utils/required-average'

export default {
  methods: {
    async $getInitialData (id) {
      await requiredCultureService.getOneById(id)
        .then((res) => {
          this.currentRequired.name = res.name
          this.dimensionsEndScore = res.dimensionsEndScore
          this.attributes = res.attributeRelevance
          // Check backend generated results or generate'em client side
          if (res.results) {
            this.cultureAverage = res.results.cultureAverage
            this.dimensionAverage = res.results.dimensionAverage
            this.dataAttributes = res.results.dataAttributes
            this.attributesArray = res.results.dataRanking
            if (res.previousResults) {
              this.backendPrevResults = res.previousResults
              this.hasBackendPrevResults = true
            }
          } else {
            this.averages = this.$getAllRelevancesWithWeight(res.attributeRelevance, res.dimensionsEndScore)
            this.$calculateDimensionsAttr(this.averages, false)
          }
          let array = []
          let attributesNames = []
          this.renderPart.dimensions = 0
          for (const dimension of this.attributes[0].responsible) {
            for (const attribute of dimension.questions) {
              array.push(attribute.positive[this.user.lang])
            }
            attributesNames.push(array)
            array = []
            this.renderPart.dimensions++
          }
          for (const dimension of this.attributes[0].competitive) {
            for (const attribute of dimension.questions) {
              array.push(attribute.positive[this.user.lang])
            }
            attributesNames.push(array)
            array = []
            this.renderPart.dimensions++
          }
          for (const dimension of this.attributes[0].humanist) {
            for (const attribute of dimension.questions) {
              array.push(attribute.positive[this.user.lang])
            }
            attributesNames.push(array)
            array = []
            this.renderPart.dimensions++
          }
          this.attributesName = attributesNames
        })
      /**
       * ENCUESTA PREVIA (OPCIONAL)
       */
      this.previousRequired = await requiredCultureService.getPrevious(id)
      this.previousRequired = this.previousRequired[0]
      if (this.previousRequired === undefined) {
        this.hasPreviousRequired = false
      } else {
        if (this.previousRequired.status !== undefined) {
          this.hasPreviousRequired = this.previousRequired.status !== 404
        }
      }
      if (this.hasPreviousRequired && this.previousRequired.attributeRelevance.length > 0) {
        if (this.hasBackendPrevResults) {
          this.previousCultureAverage = this.backendPrevResults.cultureAverage
          this.previousDimensionAverage = this.backendPrevResults.dimensionAverage
          this.previousDataAttributes = this.backendPrevResults.dataAttributes
          this.previousAttributesArray = this.backendPrevResults.dataRanking
        } else {
          this.previousAverages = this.$getAllRelevancesWithWeight(this.previousRequired.attributeRelevance, this.previousRequired.dimensionsEndScore)
          this.$calculateDimensionsAttr(this.previousAverages, true)
        }
      }
    },
    $getAllRelevancesWithWeight (attributeRelevance, dimensionsEndScore) {
      const qty = attributeRelevance.length
      const getAttrNum = (name) => { return +(name.replace('attributte', '')) }
      const cultureTypeNames = ['responsible', 'competitive', 'humanist']
      const dimensionsNames = ['values', 'sustainability', 'customers', 'innovation', 'achievement', 'leadership', 'talent', 'relationship', 'communication']
      const averages = RequiredUtils.base()

      for (let i = 0; i < attributeRelevance.length; i++) {
        for (const keyCT of cultureTypeNames) {
          for (const dimension of attributeRelevance[i][keyCT]) {
            const nameDimension = dimension.name
            if (!nameDimension || dimensionsNames.indexOf(nameDimension) === -1) {
              continue
            }
            const attributes = dimension.questions.sort((a, b) => getAttrNum(a.name) - getAttrNum(b.name))
            for (let l = 0; l < attributes.length; l++) {
              averages[keyCT][nameDimension][l].score += Number(attributes[l].score)
              averages[keyCT][nameDimension][l].positive = attributes[l].positive
              averages[keyCT][nameDimension][l].limiting = attributes[l].limiting
            }
          }
        }
      }

      let max = 0
      let min = 4
      for (const keyCT of cultureTypeNames) {
        for (const keyD of Object.keys(averages[keyCT])) {
          const dimensionsES = dimensionsEndScore[keyCT].find(d => d.name === keyD)
          for (let i = 0; i < averages[keyCT][keyD].length; i++) {
            averages[keyCT][keyD][i].score = (Number(averages[keyCT][keyD][i].score) / qty) * dimensionsES.score / 100
            max = max < averages[keyCT][keyD][i].score ? averages[keyCT][keyD][i].score : max
            min = min > averages[keyCT][keyD][i].score ? averages[keyCT][keyD][i].score : min
          }
        }
      }

      const results = {}
      const diff = max - min
      for (const keyCT of cultureTypeNames) {
        results[keyCT] = []
        for (const dimension in averages[keyCT]) {
          results[keyCT].push({
            name: dimension,
            questions: averages[keyCT][dimension].map(item => {
              item.score = Math.round(((((Number(item.score) - min) * 2) / diff) + 2) * 100) / 100
              return item
            })
          })
        }
      }

      return results
    },
    $calculateDimensionsAttr (averages, previous) {
      const dataAttributes = []
      const dimensionAverage = []
      const dataRanking = []
      let attrObject = {}
      let average = 0
      let cultureAverage = [0, 0, 0]
      for (let i = 0; i < 3; i++) {
        dataAttributes.push(averages.responsible[i].questions)
        // Calculo de promedio por dimension responsable
        for (let j = 0; j < 8; j++) {
          attrObject = {
            dimension: averages.responsible[i].name,
            name: averages.responsible[i].questions[j].name,
            positive: averages.responsible[i].questions[j].positive,
            limiting: averages.responsible[i].questions[j].limiting,
            score: averages.responsible[i].questions[j].score
          }
          dataRanking.push(attrObject)
          average += averages.responsible[i].questions[j].score
        }
        average = average / 8
        dimensionAverage.push({
          name: averages.responsible[i].name,
          score: Math.round((average + Number.EPSILON) * 100) / 100
        })
        cultureAverage[0] += average
        average = 0
      }
      cultureAverage[0] /= 3
      cultureAverage[0] = Math.round((cultureAverage[0] + Number.EPSILON) * 100) / 100
      for (let i = 0; i < 3; i++) {
        dataAttributes.push(averages.competitive[i].questions)
        // Calculo de promedio por dimension competitiva
        for (let j = 0; j < 8; j++) {
          attrObject = {
            dimension: averages.competitive[i].name,
            name: averages.competitive[i].questions[j].name,
            positive: averages.competitive[i].questions[j].positive,
            limiting: averages.competitive[i].questions[j].limiting,
            score: averages.competitive[i].questions[j].score
          }
          dataRanking.push(attrObject)
          average += averages.competitive[i].questions[j].score
        }
        average = average / 8
        dimensionAverage.push({
          name: averages.competitive[i].name,
          score: Math.round((average + Number.EPSILON) * 100) / 100
        })
        cultureAverage[1] += average
        average = 0
      }
      cultureAverage[1] /= 3
      cultureAverage[1] = Math.round((cultureAverage[1] + Number.EPSILON) * 100) / 100
      for (let i = 0; i < 3; i++) {
        dataAttributes.push(averages.humanist[i].questions)
        // Calculo de promedio por dimension humanista
        for (let j = 0; j < 8; j++) {
          attrObject = {
            dimension: averages.humanist[i].name,
            name: averages.humanist[i].questions[j].name,
            positive: averages.humanist[i].questions[j].positive,
            limiting: averages.humanist[i].questions[j].limiting,
            score: averages.humanist[i].questions[j].score
          }
          dataRanking.push(attrObject)
          average += averages.humanist[i].questions[j].score
        }
        average = average / 8
        dimensionAverage.push({
          name: averages.humanist[i].name,
          score: Math.round((average + Number.EPSILON) * 100) / 100
        })
        cultureAverage[2] += average
        average = 0
      }
      cultureAverage[2] /= 3
      cultureAverage[2] = Math.round((cultureAverage[2] + Number.EPSILON) * 100) / 100
      if (previous) {
        this.previousCultureAverage = cultureAverage
        this.previousDimensionAverage = dimensionAverage
        this.previousDataAttributes = dataAttributes
        this.previousAttributesArray = dataRanking
      } else {
        this.cultureAverage = cultureAverage
        this.dimensionAverage = dimensionAverage
        this.dataAttributes = dataAttributes
        this.attributesArray = dataRanking
      }
    }
  }
}
