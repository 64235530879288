
export default {
  no_data: 'No hay datos disponibles para este corte demográfico.',
  // tslint:disable-next-line:max-line-length
  master_references_section: 'Esta sección está reservada para la configuración de cortes demográficos. Recomendamos seleccionarlos conforme a sus necesidades pues de esto depende la generación de los reportes con alto valor.',
  // tslint:disable-next-line:max-line-length
  master_references_action: 'Estas acciones son de caracter delicado, el uso indebido de una referencia maestra está sujeta a su responsabilidad.',
  // tslint:disable-next-line:max-line-length
  master_references_enable_des: 'El corte estará disponibles nuevamente en los listados de selección. Se podrá registrar o actualizar información con este corte.',
  // tslint:disable-next-line:max-line-length
  master_references_disable_des: 'Este corte no estará disponible en las listas de selección hasta que se active nuevamente. La información previamente registrada con este corte no sufrirá ningún cambio.',
  // tslint:disable-next-line:max-line-length
  master_references_delete_des: 'Este corte no estará disponible en las listas de selección y no podrá ser accedido de ninguna manera. La información previamente registrada con este corte no sufrirá ningún cambio.',
  actions: 'Acciones',
  label: 'Nombre o etiqueta',
  description: 'Descripción.',
  no_translation: 'No hay traducción para este idioma.',
  academic_degree: 'Configuración de títulos académicos',
  academic_degree_read: 'Niveles académicos configurados',
  academic_degrees: 'Niveles académicos',
  academic_degree_info: 'Indicar los niveles académicos que se serán asignados a los colaboradores de todas las empresas',
  academic_degree_add: 'Agregar niveles académicos',
  academic_degree_enable: 'Habilitar título académico',
  academic_degree_disable: 'Inhabilitar título académico',
  academic_degree_delete: 'Borrar título académico',
  language: 'Configuración de idiomas',
  languages: 'Idiomas',
  language_info: 'Indicar los idiomas que se serán asignados a colaboradores y empresas',
  language_add: 'Agregar idioma',
  language_enable: 'Habilitar idioma',
  language_disable: 'Inhabilitar idioma',
  language_delete: 'Borrar idioma',
  department: 'Configuración de áreas o departamentos de la empresa',
  departments: 'Áreas o departamentos de la empresa',
  department_info: 'Asigna todas las áreas o departamentos que conforman la organización',
  department_add: 'Agregar área o departamento',
  department_enable: 'Habilitar área o departamento',
  department_disable: 'Inhabilitar área o departamento',
  department_delete: 'Borrar área o departamento',
  sector: 'Configuración de sectores empresariales',
  sectors: 'Sectores empresariales',
  sector_info: 'Asígna todas las sectores empresariales que manejará el sistema',
  sector_add: 'Agregar sector',
  sector_enable: 'Habilitar sector',
  sector_disable: 'Inhabilitar sector',
  sector_delete: 'Borrar sector',
  gender: 'Configuración géneros de la empresa',
  genders: 'Géneros de la empresa',
  gender_info: 'Asígna todas las géneros que conformán la organización',
  gender_add: 'Agregar género',
  gender_enable: 'Habilitar género',
  gender_disable: 'Inhabilitar género',
  gender_delete: 'Borrar género',
  job_type: 'Configuración de tipos de contratación de la empresa',
  job_types: 'Tipos de contratación de la empresa',
  job_type_info: 'Asígna los tipos de contratación que conformán la organización',
  job_type_add: 'Agregar tipos de contratación',
  job_type_enable: 'Habilitar tipos de contratación',
  job_type_disable: 'Inhabilitar tipos de contratación',
  job_type_delete: 'Borrar tipos de contratación',
  country: 'Configuración de países',
  country_read: 'Países configurados.',
  countries: 'Países.',
  culture_pulses: 'Pulsos en Cultura',
  current_culture: 'Cultura Actual',
  country_info: 'Asígna todos países que estarán disponibles en el sistema ',
  country_add: 'Agregar países',
  country_enable: 'Habilitar países',
  country_disable: 'Inhabilitar países',
  country_delete: 'Borrar países',
  enterprise_size: 'Configuración de los tamaños de empresas.',
  enterprise_sizes: 'Tamaños de empresas.',
  enterprise_size_info: 'Asígna todos tamaños de empresas que estarán disponibles en el sistema ',
  enterprise_size_add: 'Agregar tamaños de empresas',
  enterprise_size_enable: 'Habilitar tamaños de empresas',
  enterprise_size_disable: 'Inhabilitar tamaños de empresas',
  enterprise_size_delete: 'Borrar tamaños de empresas',
  headquarters_title: 'Configuración de sedes',
  headquarters_country: 'País',
  headquarters_head: 'Sede',
  headquarters_countries: 'Paises',
  headquarters_heads: 'Sedes',
  headquarters_create: 'Crear sede',
  headquarters_actions: 'Acciones',
  headquarters_enable: 'Habilitar',
  headquarters_disable: 'Inhabilitar',
  headquarters_delete: 'Eliminar',
  headquarters_updated: '¡Sede actualizada correctamente!',
  headquarters_deleted: '¡Sede eliminada!',
  headquarters_delete_title: 'Eliminar sede',
  headquarters_toggle_true_title: 'Inhabilitar sede',
  headquarters_toggle_false_title: 'Habilitar sede',
  headquarters_add: 'Añadir nueva sede',
  headquarters_created: '¡Sede creada correctamente!',
  charges_title: 'Configuración de niveles de cargos',
  charges_department: 'Departamento',
  charges_head: 'Cargo',
  charges_departments: 'Departamentos',
  charges_heads: 'Cargos',
  charges_create: 'Crear cargo',
  charges_actions: 'Acciones',
  charges_enable: 'Habilitar',
  charges_disable: 'Inhabilitar',
  charges_delete: 'Eliminar',
  charges_updated: '¡Cargo actualizado correctamente!',
  charges_deleted: '¡Cargo eliminado!',
  charges_delete_title: 'Eliminar cargo',
  charges_toggle_true_title: 'Inhabilitar cargo',
  charges_toggle_false_title: 'Habilitar cargo',
  charges_add: 'Añadir nuevo cargo',
  charges_created: '¡Cargo creado correctamente!',
  antiquity_title: 'Rangos de antigüedad',
  antiquity_subtitle: 'Rangos de antigüedad disponibles',
  ages_title: 'Rangos de edad',
  ages_subtitle: 'Rangos de edad disponibles',
  tooltip_edit: 'Debe presionar ENTER para editar',
  terms_and_conditions_settings: 'Configuración de Términos y Condiciones',
  terms_and_conditions: 'Términos y Condiciones',
  terms_and_conditions_for_personal: 'Terminos y condiciones para clientes Única Empresa',
  terms_and_conditions_for_commercial: 'Terminos y condiciones para clientes Multi Empresa',
  products_title: 'Configuración de Productos',
  products_logo: 'Logo del Producto',
  products_conf: 'Configuración de Producto',
  products_description: 'Descripción: ',
  products_status: 'status',
  products_edit: 'editar producto',
  products_modal_enable: '¿Desea habilitar el producto?',
  products_modal_disable: '¿Desea inhabilitar el producto?',
  products_modal_enable_des: 'Una vez habilitado, el producto estará disponible en el sistema',
  products_modal_disable_des: 'Una vez sea inhabilitado, el producto no podrá ser usado en el sistema',
  products_edited: 'EL producto ha sido editado correctamente',
  products_description_max_limit: 'Máximo 230 caracteres',
  tokens_title: 'Configuración Precio Tokens',
  tokens_edit_price: 'editar precio',
  tokens_edit_minrange: 'Rango Mínimo',
  tokens_edit_maxrange: 'Rango Maximo',
  tokens_edit_price_amount: 'Precio en USD',
  tokens_price_edited: 'El precio ha sido editado correctamnete',
  service_edit: 'editar servicio'
}
