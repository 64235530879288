
export default {
  add: 'Create',
  id: 'ID',
  name: 'Name',
  status: 'Status',
  participants: 'Participants',
  period: 'Period',
  action: 'Actions',
  no_data: 'No information to show',
  new: 'New Culture Pulse Survey',
  overview: 'Survey Name',
  receptors: 'Receivers',
  date: 'Date',
  questions: 'Questions',
  revition: 'Revision',
  poll_name: 'Survey Name',
  want_external_name: 'Do you want to use an external name for the respondents?',
  external_name: 'External Name',
  want_to_send: 'Who do you want to send this survey to?',
  hours: 'Time sent',
  hours_valid_until: 'Valid until',
  minutes: 'Minute',
  date_delivery: 'Launch date',
  poll_valid_until: 'Survey valid until',
  time_zone: 'Time zone',
  want_send_reminders: 'Do you want to send reminders?',
  reminders: 'Send reminder',
  reminders_info: 'The reminder will be sent to participating employees who have not completed the survey.',
  want_current_culture_questions: 'Do you want to use the questionnaire of a previous Current Culture assessment?',
  selected: 'Selected(s):',
  questionnaire_table_legend: 'You can select from 1 to 3 dimensions for your assessment',
  want_open_question: 'Do you want to add additional questions?',
  additional_n: 'question {n}',
  open_question: 'Additional questions',
  open_question_info: 'You can indicate additional customized questions, indicating the possible answers.',
  add_open_question: 'Add another additional question',
  remove_open_question: 'Delete last additional question',
  min_open_question: 'You must have at least one open question available',
  max_open_question: 'You can only have {n} open questions per survey',
  insert_question: 'Insert content of question {n}',
  insert_question_option: 'Insert option {o} for question {n}',
  add_question_option: 'Add another option',
  remove_question_option: 'Delete option',
  min_question_option: 'You must have at least {n} options available',
  max_question_option: 'You can only indicate {n} options for an open question',
  option_n: 'option {n}',
  total_receptors: 'Total respondents: {n}',
  porcent_total: 'Percentage of the total to be taken',
  general_revition: 'General Review',
  settings: 'Settings',
  cost: 'Survey cost:',
  poll_paid: 'Survey has already been paid for.',
  inter_name: 'Internal name',
  send_reminders: 'Dispatch of reminders',
  personalization: 'Email personalization',
  poll_invitation: 'survey invitation',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message Subject',
  body_message: 'Message body',
  message_from_the_company: 'Message from the company',
  automatic_message: 'This message was sent automatically from',
  created_culture_pulse: 'Culture Pulses Survey successfully created! You will be redirected to the summary of the operation shortly',
  filter_by: 'Filter by...',
  look_for: 'Find a survey',
  engagement_realized: 'Engagements performed',
  add_engagement: 'Create Engagement',
  creating: 'Creating',
  pending: 'Pending',
  completed: 'Completed',
  cancelled: 'Cancelled',
  progress: 'In progress',
  in_progress: 'In progress',
  building: 'Under construction',
  at: 'At',
  download: 'Download',
  aprogress: 'Progress',
  please_wait: 'Please wait while all demographics are loaded',
  edit: 'Survey edited successfully!',
  tooltip_name: 'Internal name of the survey',
  tooltip_display_name: 'Survey name for respondents',
  summary: 'Survey Summary',
  to_edit: 'Edit survey',
  total_progress: 'Total progress',
  end_date: 'Closing date',
  poll_completed: 'Surveys completed',
  poll_no_completed: 'Surveys not completed',
  resend: 'Resend uncompleted Surveys',
  resend_success: 'Surveys resent successfully!',
  question_n: '{n} Questions',
  no_actions: 'Not available',
  all_right: 'All rights reserved 2020. ©',
  questionnaire_e: 'Culture Questionnaire',
  questionnaire: 'Questionnaire',
  closed_poll: 'Survey closed successfully',
  pulse_report: 'Culture Pulse Report',
  associate_required_culture: 'Associate required culture',
  associate_required_culture_title: 'Associate Required Culture',
  associate_required_culture_desc: 'Select a previously performed Required Culture measure in order to compare it in the reports of this Culture Pulse.',
  associate_required_culture_select: 'Select a Required Culture:',
  associate_saved: 'Association saved.',
  no_questionnaries_title: 'You cannot continue creating this survey',
  dimensions: 'Dimensions',
  results: 'Results',
  select_age_range: 'Select an age range',
  to_all_my_organization: 'To all my organization',
  to_demographic_cuts: 'To demographic cuts',
  countries: 'Select some country',
  select_antiquity_range: 'Select antiquity range',
  genders: 'Genders',
  charges: 'Charges',
  job_types: 'Type of contract',
  departments: 'Departments',
  academic_degrees: 'Academic degrees',
  age_low: 'Less than {n} years',
  age_range: 'From {n} to {p} years',
  age_upper: 'More than {n} years',
  antiquity_low: 'Less than {n} months',
  antiquity_range_single: 'From {n} months to {p} year',
  antiquity_range_one: 'From {n} year to {p} years',
  antiquity_range: 'From {n} years to {p} years',
  antiquity_upper: 'More than {n} years',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'There are no active questionnaires for your company. Consequently, you cannot continue to create surveys of this type. Please contact the system administrator to solve this problem.',
  pdf_explained: 'In this file you can see the questions belonging to the "{name}" questionnaire grouped by dimension and attribute.',
  requested: 'Expected',
  received: 'Received',
  confirm_create_title: 'Confirm assessment creation',
  assessment_cost: 'Assessment cost',
  balance_after: 'Balance after creating measurement:',
  no_culture_pulse: 'No records of culture pulses',
  go_creation: 'Go to creation of culture pulses',
  answers_percent: 'Percentage of responses:',
  resume: 'Summary of Culture Pulses',
  fileExist: 'Attached video',
  deleteFile: 'Delete file',
  fileExistNote: 'To replace the attached file it is not necessary to delete the previous one.',
  formatDateAt: 'at',
  default_body: '<p class="ql-align-justify">Welcome to the Culture Pulses process with the OCC tool which will allow us to obtain valuable information about the Organizational Culture of our company. Key facts about this survey: The assessment takes less than 30 minutes. The information provided will be treated as confidential and will be used for statistical purposes. For a better experience, use Google Chrome or Internet Explorer 11 or higher and remember that you must be connected to the internet. If you have any questions or problems with the survey, please do not hesitate to contact us at: info@occsolutions.org "Remember that this link is personal and must not be shared. We thank you in advance for your time and valuable contributions in this important process.</p>',
  default_body_r: '<p class="ql-align-justify">Hello again. Remember to complete the Culture Pulses process with the OCC tool, which will allow us to obtain valuable information about the Organizational Culture of our company. Key facts about this survey: The assessment takes less than 30 minutes. The information provided will be treated as confidential and will be used for statistical purposes. For a better experience, use Google Chrome and remember that you must be connected to the internet. If you have any questions or problems with the survey, please do not hesitate to contact us at: info@occsolutions.org "Remember that this link is personal and must not be shared. We thank you in advance for your time and valuable contributions in this important process.</p>',
  current_culture: 'Current Culture Assessment',
  // Fill
  entry_title: 'Welcome to your Culture Pulse poll',
  entry_text: 'We invite you to answer the survey as honestly as possible, confident that with your responses we will build a better organizational culture. You have your own link for your survey, remember not to share it.',
  questions_n: '{n} Questions',
  minutes_n: '{n} Minutes',
  answer_poll: 'Answer poll',
  totally_disagree: 'Totally<br/> disagree',
  parcially_disagree: 'Parcially<br/> disagree',
  nor_agree_disagree: 'Nor disagree<br/> nor agree',
  parcially_agree: 'Parcially<br/> agree',
  totally_agree: 'Totally<br/> agree',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Read each one of the assertions presented down bellow and select the one that fits the best with your opinion based on the next scale: Totally disagree, Parcially disagree, Nor disagree nor agree, Parcially agree, Totally agree.',
  // tslint:disable-next-line:max-line-length
  connection_interrupted: 'Connection interrupted, we will continue to complete your responses by reestablishing the connection.',
  connection_interrupted_trying: 'Trying to connect in {seconds} seconds',
  connection_interrupted_out: 'Sorry, please check your internet connection and refresh the page',
  connection_interrupted_connecting: 'Connecting',
  finish_title: 'Thanks a lot for completing the poll',
  finish_text: 'Your perception is very important for us.',
  congratulations: 'Contratulations, you have completed the <span class="headline" style="color: #44C156">{progress}%</span> of the poll!'
}
